import React, { useState, useEffect } from "react";
import ModalSelector from "./ModalSelector";
import DataTable from "../Tables/DataTable";
import { useCentrosTrabajo } from "../../hooks";
import { Typography, Box, TextField } from "@mui/material";
import { Iconify } from "../../utilities";
import LaeButton from "../Buttons/LaeButton";

const WorkcenterSelector = ({ open, onClose, propertieInfo }) => {
  const { loading, data, fetchData } = useCentrosTrabajo();
  /* const [filter, setFilter] = useState("all"); */
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const headers = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Nombre",
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
  ];
  
  const handleSelect = (row) => {
    onClose(row);
  };

  const handleFetchData = async ({offset, limit}) => {
    try {
      const filtros = { razonSocial: search };
      await fetchData({ offset, limit, filtros });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setFilteredData([]);
    if (data?.rows) {
      const filteredRows = data.rows.filter((row) => row.prop_id === propertieInfo.id);
      setFilteredData((prevData) => [...prevData, ...filteredRows]);
    }
  }, [data, propertieInfo.id]);

  return (
    <ModalSelector
      open={open}
      onClose={onClose}
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        p: 2,
        pt: 8,
        gap: 5,
        alignItems: "center",
      }}
    >
      <Box
        sx={{ position: "absolute", right: 10, top: 5 }}
        onClick={() => handleSelect()}
      >
        <Iconify icon="mdi:close" sx={{ height: 24, width: 24 }} />
      </Box>
      <Typography variant="h4">Seleccionar Centro de Trabajo</Typography>
      
      <DataTable
        // data={data?.rows}
        data={filteredData}
        meta={data?.meta}
        isLoading={loading}
        headers={headers}
        onClickRow={handleSelect}
        fetcher={handleFetchData}
        currentPage={data?.current_page}
        clicable
      >
        <Box
          m={2}
          display={"flex"}
          flexDirection={"row"}
          width={"100%"}
          gap={2}
        >
          <TextField
            id="razonSocial-input"
            label="Razón Social"
            variant="outlined"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            inputProps={{
              "aria-label": "controlled",
            }}
          />
          <LaeButton onClick={handleFetchData} sx={{ width: 100 }}>
            <Typography>Buscar</Typography>
          </LaeButton>
        </Box>
      </DataTable>
    </ModalSelector>
  );
};

export default WorkcenterSelector;
