import { useState } from "react";

import { getFincasAAFF, getFincasTech, getSuppListPropertiAaff } from "../services";
import { useContext } from "react";
import { UsuarioContext } from "../providers/userContext";
import { Link } from "react-router-dom";
import { diferenciaMesesCaducidad, formatoFechasEs } from "../functions/funciones";
import { FaEye } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../functions/parametrosURL/funcionesParamsURL';


function useComunidades(busqueda) {

  const user = useContext(UsuarioContext)
  
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [loading, setLoading] = useState(true);
  const {filtroOrder} = useRecogerURLParamsFiltros();
  
  
  const navigate = useNavigate();


  const fetchData = async ({ offset, limit, filtros = busqueda,  order,orderBy } = {}) => {


    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    // let nifAAFF = filtros.nifAAFF;
   

    // const filtrosURL = filtros;
    
    // delete filtrosURL.nifAAFF;
    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,order, orderBy);

    
    const payload = {
      params: { page: offset, limit:limit,order, orderBy },
      filtros,
    };
    try {
      setLoading(true);
      let response = []
      if (user.rol === 'SUPP') {
        response = await getSuppListPropertiAaff(payload);
      } else if (user.rol === 'TECH') {
        response = await getFincasTech(payload);
        const listado = []
        response.data.forEach(res => {
          listado.push(res)

        })
        response.data = listado
      } else {

        response = await getFincasAAFF(payload);

      }

      const { meta, data } = response;
     console.log(data)
      setData({
        meta,
        rows: formatData(data, meta),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data, meta) => {
    const formattedData = []
    data.map((row, i) => {
      // const diferenciaMeses = diferenciaMesesCaducidad(row.fechaRenovacion, row.createdAt)
      const sale_cae = row?.sales?.find(s => [1, 3, 6, 7].includes(s.servp_id)) || null;
      const sale_lopd = row?.sales?.find(s => [2, 4].includes(s.servp_id)) || null;
      formattedData.push({
        i: (meta.current_page - 1) * meta.per_page + (i+1),
        aaff: row.aaff,
        finca: user.rol === "SUPP" ? row : {...row, properties:{nif: row.nif}, detailsCae: row.sales[0]?.detailsCae},
        sales: row.workcenters && row.workcenters.length > 0 ? [] : row.sales,
        aaff_id: row?.aaff?.id,
        aaff_nif: row?.aaff?.nif,
        commer_id: row?.aaff?.commer_id,
        tech_id: row?.aaff?.tech_id,
        razonSocial: row.aaff?.razonSocial,
        nif: row.nif,
        id: row.id,
        community: row.razonSocial || row.propertie?.razonSocial,
        community_name: row.razonSocial || row.propertie?.razonSocial,
        direccion: row.direccion || row.propertie?.direccion,
        codigoPostal: row.codigoPostal || row.propertie?.codigoPostal,
        municipio: row.municipio || row.propertie?.municipio,
        empleados: row.trabajadores === null ? false : row.trabajadores,
        createdAt: formatoFechasEs(row.createdAt),
        fechaUltVisita: row.workcenters && row.workcenters.length === 0 ? row.visitSheets?.[0]?.createdAt ? formatoFechasEs(row.visitSheets[0].createdAt) : null : null,
        centrosTrabajo: row.workcenters,
        // btn_to: <Link to={`/comunidades/${row.nif}`} className="btn btn-light"><FaEye /></Link>,
        hojasVisita: row.nif,
        isWorkcenter: false,
        // caducada: diferenciaMeses > 13 ? true : false
        saleCae_id: sale_cae?.id || null,
        saleLopd_id: sale_lopd?.id || null,
        estadoCae: sale_cae ? sale_cae.estado_id === null ? 1 : sale_cae.estado_id : "No contratada",
        estadoLopd: sale_lopd ? sale_lopd.estado_id === null ? 1 : sale_lopd.estado_id : "No contratada",
      })
      if (row.workcenters?.length > 0) {
        row.workcenters?.forEach((wc) => {
          formattedData.push({
            i:"",
            id: wc.id,
            aaff: row.aaff,
            finca: wc,
            sales: wc.workSales,
            aff_name: "",
            community: wc.razonSocial,
            nif: "",
            fechaAlta: "",
            fechaUltVisita: wc.visitSheets?.[0]?.createdAt ? formatoFechasEs(wc.visitSheets[0].createdAt) : null,
            direction: "",
            cp: "",
            municipio: "",
            empleados: "",
            isWorkcenter: true,
            estadoCae: "",
            estadoLopd: "",
            saleCae_id: "",
            saleLopd_id: "",
          })
        })
      }
    });
    return formattedData;
  };



  return {
    data,
    fetchData,
    loading
  };
}

export default useComunidades;
