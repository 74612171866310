import React, {useState, useEffect, useContext} from "react";
import Tick from "../../icons/tick.svg";
import Cross from "../../icons/cross.svg";
import Minus from "../../icons/minus.svg";
import Adjuntar from "../../icons/upload.svg";
import DescargarNegro from "../../icons/descargar-negro.svg"
import { Iconify } from "../../utilities";
import { withRouter } from "../../providers/withRouter";
import { getDeclResOfSupplier, getProveedor, getServicios } from "../../services";
import { eliminaDuplicados } from "../../functions/funciones";
import {
    abrirArchivo,
    subirDeclaracionResponsable,
    validarDeclRes,
  } from "../../providers/documentos";
//import { getDeclResList } from "../../services/declRes.service";
import { UsuarioContext } from "../../providers/userContext";
import { CircularProgress } from '@mui/material';

const DeclRes = (props) => {

    const user = useContext(UsuarioContext)
    const [aaff, setAAFF] = useState([]);
    const [recargar, setRecargar] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    
    
    const fetchData = async () => {
        const res = await getDeclResOfSupplier(props.nifProv);
        setAAFF(res)
        setIsLoading(false)
    }

    useEffect(() => {
        fetchData()
        
      }, [!recargar, props.proveedor]);


      const handleSubirDocsCallback = () => {
        setRecargar(!recargar);
      };

      const comprobarDeclRes = async (nifProv, id, ruta, idDeclRes) => {
        
          subirDeclaracionResponsable(nifProv, id, ruta, idDeclRes).then((resultado) => {
            
            handleSubirDocsCallback(resultado)
          })
      };
      const comprobarValDeclRes = async (ruta, idDeclRes, nifAAFF) => {
        
          validarDeclRes(ruta, idDeclRes, nifAAFF).then((resultado) => {
            
            handleSubirDocsCallback(resultado)
          })
      };
      
    return ( <>
      <div className="gridCard" style={{ position: 'relative' }}>
        <div className="gridHead">DECLARACIONES RESPONSABLES</div>
        <div style={{ overflow: 'scroll', maxHeight: 750 }}>
          {user.rol === 'SUPP' ? 
            <div className="gridSubhead" style={{ display: 'flex', justifyContent: 'end'}}>
              <a
                href="/files/Modelo-Declaracion-Responsable.pdf"
                download="Modelo-Declaracion-Responsable.pdf"
              >
                <span style={{ marginRight: 10 }} >Descargar modelo</span> <img src={DescargarNegro} width={12} alt="Descargar declaración responsable" />
              </a>
            </div>
          : null
          }
          
          {isLoading ? <CircularProgress style={{ height: 40, width: 40, color:'#92004D', position: 'absolute', top: '65%', left: '5%'}} /> : 
          aaff.length === 0 ? <p style={{fontSize:'14px !important', textAlign: 'center', padding: '20px'}}>No tiene declaraciones responsables</p> : 
            <table style={{ margin: 15 }}>
              <thead>
                <tr
                  style={{
                    fontSize: 11,
                    textAlign: 'center',
                    margin: 0,
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                    fontWeight: 400,
                    lineHeight: 1.5,
                    letterSpacing: '0.00938em'
                  }}
                >
                  <th></th>
                  <th></th>
                  <th style={{ margin: 5 }}>Ver</th>
                  <th>Adjuntar</th>
                </tr>
              </thead>
              <tbody>
                {aaff.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>
                        {e?.declRes?.pendiente ? (
                          <a
                            onClick={() => {
                              comprobarValDeclRes(e.declRes?.ruta, e.declRes?.id, e.nif)
                            }}
                          >
                            <img
                              src={Minus}
                              alt="Pendiente"
                              className="tableIcon"
                            />
                          </a>
                        ) : e.declRes?.validado ? (
                          <img
                            src={Tick}
                            alt="Validado"
                            className="tableIcon"
                          />
                        ) : (
                          <img
                            src={Cross}
                            alt="No validado"
                            className="tableIcon"
                          />
                        )}
                      </td>
                      <td style={{ width: "100%" }}>{e.razonSocial}</td>
                      <td
                        style={{
                          textAlign: "center",
                          opacity: e.declRes?.ruta ===undefined ? 0.4 : 1,
                        }}
                      >
                        <Iconify icon="solar:eye-line-duotone" 
                          alt="Ver"
                          className="tableIcon"
                          onClick={() => abrirArchivo(e?.declRes?.ruta)} />
                        
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <label
                          htmlFor="declaracionResponsable"
                          onClick={() =>
                            comprobarDeclRes(props.nifProv, e.id, e?.declRes?.ruta, e?.declRes?.id )
                          }
                        >
                          <img
                            src={Adjuntar}
                            alt="Adjuntar"
                            className="tableIcon"
                          />
                        </label>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          }
        </div>
      </div>
    </> );
}
 
export default withRouter(DeclRes);