import React, { useState, useContext } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Apagar from "../../icons/apagar.svg";
import "./Menu.css";
import Logo from "./../../images/LAE.png";
import { logout } from "../../services";
import { MdLogout } from "react-icons/md";
import { Dropdown } from 'react-bootstrap';
import { UsuarioContext } from '../../providers/userContext';
import { BsFillGearFill } from "react-icons/bs";
import UserMenu from "../navbar/UserMenu";

const Menu = ({ menu, routesClientes, routesPersonal }) => {
  const [navExpanded, setNavExpanded] = useState(false);

  const ListaMenu = () => {
    return (
      <ul>
        {routesClientes} {routesPersonal} {menu}
      </ul>
    )
  }
  const handleMenu = () =>
    document.querySelector(".animated-icon1").classList.toggle("open");

  const closeNav = () => {
    setNavExpanded(false);
    handleMenu();
  };

  return (
    <>
      <Navbar
        onToggle={setNavExpanded}
        expanded={navExpanded}
        className="bg-topNavbar"
        sticky="top"
        expand="lg"
      >
        <Navbar.Brand>
          <Navbar.Toggle onClick={handleMenu}>
            <div className="animated-icon1">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </Navbar.Toggle>
          <img
            src={Logo}
            alt="logo"
            className="logoUrl"
            style={{
              width: "60px",
              filter: "brightness(0) invert(1)",
              marginLeft: 5,
              marginTop: 3,
            }}
          />
        </Navbar.Brand>
        <div className="topbar">
          <div></div>
          <UserMenu isMobile={true} />
          {/* <div className="cerrarSesion" onClick={logout}>
            <img src={Apagar} alt="Cerrar sesión" />
          </div> */}
          {/* <div className="buttons">


            <Dropdown className="btn btn-default mx-2 p-0">
              <Dropdown.Toggle variant="default" id="dropdown-basic">

                <BsFillGearFill />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={`/perfil`}>Perfil</Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
            <button className="btn btn-default" onClick={logout}>
              <MdLogout />
            </button>
          </div> */}
          
          <div className="buttons">
            {/* <Dropdown className="btn btn-default mx-2 p-0">
              <Dropdown.Toggle
                variant="default"
                id="dropdown-basic"
                className="custom-dropdown-toggle"
              >
                  <BsFillGearFill />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href={`/perfil`}>Perfil</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <button className="btn btn-default" onClick={logout}>
              <MdLogout />
            </button> */}
          </div>
          
        </div>

        <Navbar.Collapse className="bg-topNavbar mt-2">
          <Nav onClick={closeNav}>{<ListaMenu />}</Nav>
          <div>
            <div className="navbar-info">Aviso Legal - Política de Cookies</div>
            <div className="navbar-info">
              Aplicación desarrollada por LAE Consulting Normativas, S.L.
            </div>
          </div>
        </Navbar.Collapse>
      </Navbar>
      <style jsx="true">{`
          .dropdown-toggle:after {
            display: none !important;
          }              
      `}</style>
    </>
  );
};

export default Menu;
