import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import { Box, CircularProgress, InputLabel, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { toast } from "react-toastify";
import { Select } from "@mui/material";
import { editarAAFF } from '../../../services';
import { formatoFechas, municipiosIniciales, cambiarFormatoNombre } from './../../../functions/funciones'
import provinces from '@doncicuto/es-provinces';
import municipalities from '@doncicuto/es-municipalities';
import { ordenarProvinciasYMunicipios } from '../../../functions/ordenarListas';
import { verificarCorreo, verificarTelefono, verificarFecha, verificarCodigoPostal } from "../../../functions/validator/formValidator";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '85ch',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
};

const modal = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
const paper = {
  backgroundColor: '#fff',
  border: '2px solid #000',
  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
  padding: '16px 32px 24px'
};
const form = {

  margin: '8px',
  width: '100ch',
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: ' repeat(2, 1fr)',
  gridGap: '15px',
  fontSize: '12px',
};
export default function TransitionsModal(props) {
  // let fechaEncargo = props.aaff.fechaEncargo === null ? null : formatoFechas(props.aaff.fechaEncargo)

  let fechaBienvenida = props.aaff.fechaBienvenida === null ? null : formatoFechas(props.aaff.fechaBienvenida)

  let fechaReclamacion = props.aaff.fechaReclamacion === null ? null : formatoFechas(props.aaff.fechaReclamacion)

  let fechaInforme = props.aaff.fechaInforme === null ? null : formatoFechas(props.aaff.fechaInforme)



  const [editarDatos, setEditarDatos] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [activo, setActivo] = useState(false)

  useEffect(() => {

    const aaffData = {
      id: props.aaff.id,
      razonSocial: props.aaff.razonSocial,
      email: props.aaff.user?.email,
      nif: props.aaff.nif,
      nombreContacto: props.aaff.nombreContacto,
      telefono: props.aaff.telefono,
      municipio: props.aaff.municipio,
      provincia: props.aaff.provincia,
      codigoPostal: props.aaff.codigoPostal,
      // fechaEncargo: fechaEncargo,
      fechaBienvenida: fechaBienvenida,
      fechaReclamacion: fechaReclamacion,
      fechaInforme: fechaInforme,
      // comentarios: props.aaff.comentarios
    }
    setEditarDatos(aaffData)
    setListadoMunicipios(municipiosIniciales(provinces, municipalities, props.aaff.provincia))
  }, [props])

  function handleChange(evt) {
    const value = evt.target.value;
    setEditarDatos({
      ...editarDatos,
      [evt.target.name]: value
    });
  }


  const editar = (aaff) => {
    setActivo(true);
    const toastInfo = toast.info("Guardando datos", { autoClose: false });

    let data = {
      razonSocial: aaff.razonSocial,
      nif: aaff.nif,
      email: aaff.email,
      nombreContacto: aaff.nombreContacto,
      telefono: aaff.telefono,
      municipio: aaff.municipio,
      provincia: aaff.provincia,
      codigoPostal: aaff.codigoPostal,
      commer_id: aaff.commer_id,
      // fechaEncargo: aaff.fechaEncargo,
      fechaBienvenida: aaff.fechaBienvenida,
      fechaReclamacion: aaff.fechaReclamacion,
      fechaInforme: aaff.fechaInforme,
      //comentarios: c
    };

    const validarCampos = () => {



      let errores = false;
      const requiredFields = ['razonSocial', 'email', 'telefono'];

      requiredFields.forEach((field) => {
        if (!data[field]) {
          toast.error(`El campo ${field} es obligatorio`);
          errores = true;
        }
      });

      Object.keys(data).forEach((key) => {
        switch (key) {
          case "email":
            if (data.email !== null && data.email !=="" ) {
              const correoCorrecto = verificarCorreo(data.email);
              if (!correoCorrecto) {
                toast.dismiss(toastInfo);
                toast.error("El email es incorrecto");
                errores = true;
              }
            }
            break;
          case "telefono":
            if (data.telefono !== null && data.telefono !=="") {
              const telefonoCorrecto = verificarTelefono(data.telefono);
              if (!telefonoCorrecto) {
                toast.dismiss(toastInfo);
                toast.error("El teléfono es incorrecto");
                errores = true;
              }
            }
            break;
          case "codigoPostal":
            if (data.codigoPostal !== null && data.codigoPostal !=="") {
              const codigoPostalCorrecto = verificarCodigoPostal(data.codigoPostal);
              if (!codigoPostalCorrecto) {
                toast.dismiss(toastInfo);
                toast.error("El código postal es incorrecto");
                errores = true;
              }
            }
            break;
          // case "fechaEncargo":
          //   if (data.fechaEncargo !== null && data.fechaEncargo !=="") {
          //     const fechaEncargoCorrecto = verificarFecha(data.fechaEncargo);
          //     if (!fechaEncargoCorrecto) {
          //       toast.dismiss(toastInfo);
          //       toast.error("La fecha de Encargo no puede ser superior a la actual");
          //       errores = true;
          //     }
          //   }
          //   break;
          case "fechaBienvenida":
            if (data.fechaBienvenida !== null && data.fechaBienvenida !=="") {
              const fechaBienvenidaCorrecto = verificarFecha(data.fechaBienvenida);
              if (!fechaBienvenidaCorrecto) {
                toast.dismiss(toastInfo);
                toast.error("La fecha de Bienvenida no puede ser superior a la actual");
                errores = true;
              }
            }
            break;
          case "fechaReclamacion":
            if (data.fechaReclamacion !== null && data.fechaReclamacion !=="") {
              const fechaReclamacionCorrecto = verificarFecha(data.fechaReclamacion);
              if (!fechaReclamacionCorrecto) {
                toast.dismiss(toastInfo);
                toast.error("La fecha de Reclamación no puede ser superior a la actual");
                errores = true;
              }
            }
            break;
          case "fechaInforme":
            if (data.fechaInforme !== null && data.fechaInforme !=="") {
              const fechaInformeCorrecto = verificarFecha(data.fechaInforme);
              if (!fechaInformeCorrecto) {
                toast.dismiss(toastInfo);
                toast.error("La fecha del Informe no puede ser superior a la actual");
                errores = true;
              }
            }
            break;
          default:
            break;
        }
      });

      return errores;
    };

    const enviarSolicitudCambio = () => {
      editarAAFF(data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            toast.update(toastInfo, {
              render: "Datos editados con éxito",
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            props.handleClose();
          } else if (res.status === 403) {
            toast.update(toastInfo, {
              render: res.data.error[0],
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          } else {
            toast.update(toastInfo, {
              render: "Error al editar los datos",
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
        })
        .catch((err) => {
          toast.update(toastInfo, {
            render: "Error al editar los datos",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
          console.log(err);
        })
        .finally(() => {
          setActivo(false);
        });
    };

    const errores = validarCampos();
    if (!errores) {
      enviarSolicitudCambio();
    } else {
      setActivo(false);
    }
  };


  const seleccionarMunicipios = (e) => {
    let codProvincia = ""
    let municipios = []
    provinces.map(p => {
      if (p.name === e.target.value) {
        codProvincia = p.code
      }
    })
    setEditarDatos({
      ...editarDatos,
      [e.target.name]: e.target.value
    });
    municipalities.map(m => {
      if (m.provCode === codProvincia) {
        municipios.push({ ...m, name: cambiarFormatoNombre(m.name), value: m.name })
      }
    })
    setListadoMunicipios(municipios)
  }
  return (
    <div>


      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={modal}
        open={props.opened}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <div style={{
            backgroundColor: '#fff',
            border: '2px solid #000',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
            padding: '16px 32px 24px'
          }}
          >
            <h2 id="transition-modal-title">Editar datos</h2>
            <div id="transition-modal-description">
              <form noValidate autoComplete="off">
                <div style={{
                  margin: '8px',
                  width: '100ch',
                  position: 'relative',
                  display: 'grid',
                  gridTemplateColumns: ' repeat(2, 1fr)',
                  gridGap: '15px',
                  fontSize: '12px',
                }}
                >

                  <TextField variant="standard" id="razonSocial" name="razonSocial" label="Razón Social" value={editarDatos.razonSocial} onChange={handleChange} />
                  <TextField variant="standard" id="email" name="email" label="Email" value={editarDatos.email} onChange={handleChange} />
                  <TextField variant="standard" id="nombreContacto" name="nombreContacto" label="Contacto" minLength="3" value={editarDatos.nombreContacto} onChange={handleChange} />
                  <TextField variant="standard" id="telefono" name="telefono" label="Telefono" value={editarDatos.telefono} onChange={handleChange} />
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="provincia">Provincia</InputLabel>
                    <Select
                      label="provincia"
                      variant="standard"
                      native
                      name="provincia"
                      id="provincia"
                      style={{ textTransform: 'uppercase' }}
                      onChange={seleccionarMunicipios}
                      defaultValue={editarDatos?.provincia}
                    >
                      <option value=""></option>
                      {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                          <option key={i} value={e.name}>
                            {e.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="municipio">Municipio</InputLabel>
                    <Select
                      label="municipio"
                      variant="standard"
                      native
                      name="municipio"
                      id="municipio"
                      style={{ textTransform: 'uppercase' }}
                      onChange={handleChange}
                      defaultValue={editarDatos?.municipio}
                    >
                      <option value=""></option>
                      {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                        return (
                          <option key={i} value={e.value}>
                            {cambiarFormatoNombre(e.name)}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField variant="standard" id="codigoPostal" name="codigoPostal" label="Codigo Postal" value={editarDatos.codigoPostal} onChange={handleChange} />
                  {/* <TextField variant="standard" id="fechaEncargo" type="date" name="fechaEncargo" InputLabelProps={{ shrink: true }} label="Fecha Encargo" defaultValue={editarDatos.fechaEncargo} onChange={handleChange} /> */}
                  <TextField variant="standard" id="fechaBienvenida" type="date" name="fechaBienvenida" InputLabelProps={{ shrink: true }} label="Fecha Bienvenida" defaultValue={editarDatos.fechaBienvenida} onChange={handleChange} />
                  <TextField variant="standard" id="fechaReclamacion" type="date" name="fechaReclamacion" InputLabelProps={{ shrink: true }} label="Fecha Reclamacion" defaultValue={editarDatos.fechaReclamacion} onChange={handleChange} />
                  <TextField variant="standard" id="fechaInforme" type="date" name="fechaInforme" InputLabelProps={{ shrink: true }} label="Fecha Informe" defaultValue={editarDatos.fechaInforme} onChange={handleChange} />
                  <div></div>
                </div>

                <div style={{
                  margin: '8px',
                  width: '100ch',
                  position: 'relative',
                  display: 'grid',
                  gridTemplateColumns: ' repeat(2, 1fr)',
                  gridGap: '15px',
                  fontSize: '12px',
                }}
                >
                  <Button className="btn-Default" variant="contained" onClick={props.handleClose} disabled={activo} style={{ opacity: activo ? .6 : 1 }}>Cancelar</Button>
                  <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => editar(editarDatos)} disabled={activo} style={{ opacity: activo ? .6 : 1 }}>{activo ? <CircularProgress style={{ height: 20, width: 20, color: '#fff' }} /> : "Editar"}</Button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}