import React, { useState, useEffect } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import { getAlertas, getAllConsultores } from "../../../services";
import {
  daysDiff,
  formatoFechasEs,
} from "../../../functions/funciones";
import { Tabs, Tab, Box } from "@mui/material";
import TabPanel from "../../../components/tabs/Tabs";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import NoDocumentsAlert from "../../../components/alerts/noDocumentsAlert";
import WithDocumentsAlert from "../../../components/alerts/withDocumentsAlert";
import AllSuppAlert from "../../../components/alerts/allSuppAlert";
import { useLocation, useNavigate } from 'react-router-dom';
import {  construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';

const proveedoresRows = [
  {
    id: "proveedor_id",
    numeric: false,
    disablePadding: false,
    label: "",
  },
  {
    id: "proveedor_name",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
    overrideFunc: (data, row) => <Link to={"/proveedores/" + row.nif}>{data}</Link>
  },
  {
    id: "ultimaReclamacion",
    numeric: false,
    disablePadding: false,
    label: "ÚLTIMA RECLAMACIÓN",
    overrideFunc: (data, row) => <span style={{color: daysDiff(new Date(data), new Date()) > 30 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span>
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "planificacionPreventiva",
    numeric: false,
    disablePadding: false,
    label: "PLANIFICACIÓN PREVENTIVA",
    overrideFunc: (data) =>  <img style={{ width: "20px" }} src={data ? Cross : Tick} alt="vacio" />,
      
  },
  {
    id: "evaluacionRiesgos",
    numeric: false,
    disablePadding: false,
    label: "EVALUACIÓN RIESGOS",
    overrideFunc: (data) =>  <img style={{ width: "20px" }} src={data ? Cross : Tick} alt="vacio" />,
  },
  {
    id: "vigilancia",
    numeric: false,
    disablePadding: false,
    label: "Vigilancia salud",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
  },
  {
    id: "certificado",
    numeric: false,
    disablePadding: false,
    label: "Certificado Seg. Social",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
  },
  {
    id: "justificante",
    numeric: false,
    disablePadding: false,
    label: "Justificante pago SPA",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
  },
  {
    id: "seguro",
    numeric: false,
    disablePadding: false,
    label: "Seguro Resp. Civil",
    overrideFunc: (data) => data ? <span style={{color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span> : '-',
    // overrideFunc: (row) => {
    //     console.log(row.find( d => d.tipoDoc === 2 )?.fechaCaducidad)

    // },
  },
];

const Alertas = () => {

  const location = useLocation();

  let params = new URLSearchParams(location.search);

  const tabParam = parseInt(params.get("valorTab") ) ;
  
  const urlPadre = window.location.pathname;
  const navigate = useNavigate();

  const [Consultores, setConsultores] = useState([]);

  
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [value, setValue] = useState(tabParam || 0);
  // const [data, setData] = useState({
  //   meta: {},
  //   rows: [],
  // });
  

  // useEffect(() => {
  //   fetchData();
  //   getAllConsultores().then((res) => {
  //     setConsultores(res);
  //   });
  // }, [recargar]);



  // const fetchData = async ({ offset = 1, limit = limite, filtros } = {}) => {
  //   // setLimite(limit);
  //   // const payload = {
  //   //   // filtros:{ultimaReclamacion:new Date().setDate(new Date().getDate() - 60)},
  //   //   filtros,
  //   //   params: { page: offset, limit: limite },
  //   // };

  //   // try {
  //   //   setLoading(true);
      
  //   //   const response1 = await getAlertas(payload);
  //   //   const { meta, data} = response1;
  //   //   setData({
  //   //     meta,
  //   //     rows: formatData(data),
  //   //   });
  //   // } catch (error) {
  //   //   console.log(error);
  //   // } finally {
  //   //   setLoading(false);
  //   // }
  // };

  const formatData = (data) => {
    // data.map((row, i) => (
    //   console.log(row.docssupps?.find((d) => d.tipoDoc === 4)?.validado)
    // ))
    // return data.map((row, i) => ({
    //   proveedor_id: row.id,
    //   proveedor_name: row.razonSocial,
    //   ultimaReclamacion: row.ultimaReclamacion || row.createdAt,
    //   nif: row.nif,
    //   planificacionPreventiva:!row.docssupps?.find((d) => d.tipoDoc === 4)?.validado === true,
    //   evaluacionRiesgos:
    //     !row.docssupps?.find((d) => d.tipoDoc === 5)?.validado === true,
    //   vigilancia: row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad || '',
    //   certificado: row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad || '',
    //   justificante: row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad || '',
    //   seguro: row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad || '',
    // }));
  };
  
  // const filtrarDatos = (filtros) => {
  //   setBusqueda(filtros)
  //   fetchData({ offset: 1, limit: limite, filtros: filtros });
  // } 

  const handleChange = (event, newValue) => {
    setValue(newValue);
    construirURLParamsFiltros(navigate, 1, limite || 50, busqueda, newValue);
  };

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/alertas", label: `Alertas` }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            ALERTAS PROVEEDORES
            {/* <Link to="/consultores"><div className="gridHeadRight">{"<< volver al listado de Consultores"}</div></Link> */}
          </div>
          <Box sx={{ padding: '10px 40px', border: 'none' }}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs example">
              { <Tab style={{ color:"#92004D" }} label="TODOS" /> }
              { <Tab style={{ color:"#92004D" }} label="SIN DOCUMENTOS" /> }
              { <Tab style={{ color:"#92004D" }} label="CON DOCUMENTOS" /> }
            </Tabs>
          </Box>
          <TabPanel value={value}  index={0}>
            <AllSuppAlert valorTab={0}/>
          </TabPanel>
          <TabPanel value={value}  index={1}>
            <NoDocumentsAlert valorTab={1}/>
          </TabPanel>
          <TabPanel value={value}  index={2}>
            <WithDocumentsAlert valorTab={2}/>
          </TabPanel>
        </div>
      </div>
      <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
    `}</style>
    </>
  );
};

export default withRouter(Alertas);
