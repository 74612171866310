import { useLocation } from 'react-router-dom';

export const useRecogerURLParamsFiltros = () => {
  const location = useLocation();
 
  let params = new URLSearchParams(location.search);

  const filtrosIniciales = {};
  const filtroOrder ={}

  const formatter = {
    aaff_id: parseInt,
    commer_id: parseInt,
    tech_id: parseInt,
    consul_id: parseInt,
    consultor: parseInt,
    visitada: value => value === 'true',
    surveillanceVideo: value => value === 'true',
    workers: value => value === 'true',
  };

  for (const [key, value] of params) {
    if (key !== "pagina" && key !== "limite" && key !== "valorTab" && key !== "orden" && key !== "ordenadoPor" ) {
      const funcionParseador = formatter[key];
      filtrosIniciales[key] = funcionParseador ? funcionParseador(value) : value;
    }
    else if (key == "orden" || key == "ordenadoPor"){
      filtroOrder[key] = value;
    }
  }

  return {filtrosIniciales , filtroOrder};
}

export const construirURLParamsFiltros = (navigate, offset, limit, filtros, order,orderBy, valorTab) => {
  
  let path;
  
  if(orderBy!="id" && orderBy!=undefined){
    path = window.location.pathname + `?pagina=${offset}&limite=${limit}&orden=${order.toUpperCase()}&ordenadoPor=${orderBy}`;
  }
  else{
    
    path = window.location.pathname + `?pagina=${offset}&limite=${limit}`;
  }
  if (valorTab !== undefined) {
    path += `&valorTab=${valorTab}`;
  }
  if (Object.keys(filtros).length !== 0) {
    const parametrosBusqueda = new URLSearchParams();

    Object.keys(filtros).forEach(key => {
      
        parametrosBusqueda.append(key, filtros[key] !== undefined ? filtros[key] : '');
      
    });
    path += `&${parametrosBusqueda.toString()}`;
  }
  navigate(path, {replace: true});
}

