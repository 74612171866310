import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { DataTable } from "../../../components";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import { getAlertas, getAllSuppDocs, getConsultores } from "../../../services";
import {
  daysDiff,
  formatoFechasEs,
} from "../../../functions/funciones";
import {
  Box,
} from "@mui/material";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { UsuarioContext } from "../../../providers/userContext";
import { useLocation,useNavigate } from "react-router-dom";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';



const proveedoresRows = [
  
  {
    id: "razonSocial",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
  },
  {
    id: "ultimaReclamacion",
    numeric: false,
    disablePadding: false,
    label: "ÚLTIMA RECLAMACIÓN",
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "planificacionPreventiva",
    numeric: false,
    disablePadding: false,
    label: "PLANIFICACIÓN PREVENTIVA",
    noObjeto:true,
  },
  {
    id: "evaluacionRiesgos",
    numeric: false,
    disablePadding: false,
    label: "EVALUACIÓN RIESGOS",
    noObjeto:true,
  },
  {
    id: "vigilancia",
    numeric: false,
    disablePadding: false,
    label: "Vigilancia salud",
    noObjeto:true,
  },
  {
    id: "certificado",
    numeric: false,
    disablePadding: false,
    label: "Certificado Seg. Social",
    noObjeto:true,
  },
  {
    id: "justificante",
    numeric: false,
    disablePadding: false,
    label: "Justificante pago SPA",
    noObjeto:true,
  },
  {
    id: "seguro",
    numeric: false,
    disablePadding: false,
    label: "Seguro Resp. Civil",
    noObjeto:true,
    
  },
];

const Alertas = () => {

  const urlPadre = window.location.pathname;
  const navigate = useNavigate();
  const user = useContext(UsuarioContext)
  const [Consultores, setConsultores] = useState([]);
  const {filtroOrder} = useRecogerURLParamsFiltros();
  
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const location = useLocation();


  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {

      fetchData();
      localStorage.setItem("hasReloaded", "false"); 
    }
  }, [location]);

  useEffect(() => {
    // fetchData();
    getConsultores().then((res) => {
      setConsultores(res);
    });
  }, [recargar]);

  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {

      fetchData();
      localStorage.setItem("hasReloaded", "false"); 
    }
  }, [location]);

  const fetchData = async ({ offset, limit, filtros = {},order,orderBy } = {}) => {
    
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,order, orderBy);

    const payload = {
      // filtros:{ultimaReclamacion:new Date().setDate(new Date().getDate() - 60)},
      filtros: {tech_id: user.id},
      params: { page: offset, limit,order, orderBy },
    };

    try {
      setLoading(true);
      
      const response1 = await getAllSuppDocs(payload);
      const { meta, data} = response1;
     
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    
    return data.map((row, i) => ({
      
      razonSocial: (
        <Link to={"/proveedores/" + row.nif}>{row.razonSocial}</Link>
      ),
      ultimaReclamacion:
        row.ultimaReclamacion !== undefined &&
        row.ultimaReclamacion !== null ? (
          daysDiff(new Date(row.ultimaReclamacion), new Date()) > 30 ? (
            <span style={{ color: "red" }}>
              {formatoFechasEs(row.ultimaReclamacion)}
            </span>
          ) : (
            <span style={{ color: "black" }}>
              {formatoFechasEs(row.ultimaReclamacion)}
            </span>
          )
        ) : daysDiff(new Date(row.createdAt), new Date()) > 30 ? (
          <span style={{ color: "red" }}>{formatoFechasEs(row.createdAt)}</span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(row.createdAt)}
          </span>
        ),
      nif: row.nif,

      planificacionPreventiva:
        !row.docssupps?.find((d) => d.tipoDoc === 4)?.validado ? (
          <img style={{ width: "20px" }} src={Cross} alt="vacio" />
          ) : (
            <img style={{ width: "20px" }} src={Tick} alt="correcto" />
        ),
      evaluacionRiesgos:
        !row.docssupps?.find((d) => d.tipoDoc === 5)?.validado ? (
          <img style={{ width: "20px" }} src={Cross} alt="vacio" />
        ) : (
          <img style={{ width: "20px" }} src={Tick} alt="correcto" />
        ),
      vigilancia:
        row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad
            )}
          </span>
        ),
      certificado:
        row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad
            )}
          </span>
        ),
      justificante:
        row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad
            )}
          </span>
        ),
      seguro:
        row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad ===
          undefined ||
        row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad === null ||
        row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad === "" ? (
          "-"
        ) : daysDiff(
            new Date(
              row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad
            ),
            new Date()
          ) > 30 ? (
          <span style={{ color: "red" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad
            )}
          </span>
        ) : (
          <span style={{ color: "black" }}>
            {formatoFechasEs(
              row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad
            )}
          </span>
        ),
    }));
  };

  
  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/alertas", label: `Alertas` }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            ALERTAS
            
          </div>
          <div className="tableContainer">
            
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    meta={data.meta}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Alertas);
