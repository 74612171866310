import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import AcordeonRiesgos from "./accRiesgos";
import { getAAFFPropVisit } from "../../../services";
import { useParams } from "react-router-dom";
import { riesgosData } from "../../../components/aaff/riesgos";


const VerRiesgos = () => {

    const { nif } = useParams();
    const [fincas, setFincas] = useState([])
    const [riesgos, setRiesgos] = useState([])
    const [aaff, setAAFFS] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        fetchData()
    }, [])

    const fetchData = async () => {

        try {
            const response = await getAAFFPropVisit(nif)
            // console.log(response)
            const riesgosFincas = processResponse(response)
            setAAFFS(response)
            setFincas(response?.properties)
            setRiesgos(riesgosFincas)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }
    const processResponse = (response) => {
        const statementArrays = {};
        const respRules = {
            3: "si",
            2: "si",
            5: "si",
            6: "si",
            7: "si",
            8: "si",
            9: "no",
            11: "no",
            12: "no",
            14: "no",
            15: "no",
            16: "no",
            17: "no",
            18: "no",
            19: "no",
            20: "no",
            22: "no",
            23: "no",
            24: "no",
            27: "no",
            28: "no",
            29: "no",
            30: "no",
            32: "no",
            33: "no",
            34: "no",
            35: "no",
            36: "no",
            37: "no",
            38: "no",
            39: "no",
            40: "no",
            41: "no",
            43: "si",
            47: "si",
            48: "no",
            49: "no",
            50: "no",
            51: "no",
            53: "no",
            54: "no",
            56: "no",
            57: "no",
            58: "no",
            59: "no",
            60: "no",
            61: "no",
            62: "no",
        };


        const uniqueFincas = new Map();

        response?.properties?.forEach((property) => {
            if (property.workcenters?.length > 0) {
                property.workcenters.forEach((workcenter) => {
                    workcenter.visitSheets?.forEach((sheet) => {
                        sheet.questions.forEach((question) => {
                            if (respRules[question.statements_id] === question.resp) {
                                if (!statementArrays[question.statements_id]) {
                                    statementArrays[question.statements_id] = new Set();
                                }
                                statementArrays[question.statements_id].add(workcenter.razonSocial);
                            }
                        });
                    });
                });
            } else {
                property.visitSheets?.forEach((sheet) => {
                    sheet.questions.forEach((question) => {
                        if (respRules[question.statements_id] === question.resp) {
                            if (!statementArrays[question.statements_id]) {
                                statementArrays[question.statements_id] = new Set();
                            }
                            statementArrays[question.statements_id].add(property.razonSocial);
                        }
                    });
                });
            }
        });



        const groupedRiesgos = {};
        const riesgos = riesgosData
        .map((riesgo) => ({
            ...riesgo,
            fincas: Array.from(statementArrays[riesgo.key] || [])
        }))
        .filter((riesgo) => riesgo.fincas.length > 0);
        riesgos.map((riesgo) => {
            if (riesgo.riesgo[0] && riesgo.origen) {
                const groupKey = `${riesgo.riesgo[0]}-${riesgo.origen}`;
                if (!groupedRiesgos[groupKey]) {
                    groupedRiesgos[groupKey] = {
                        key: riesgo.key,
                        riesgo: riesgo.riesgo,
                        origen: riesgo.origen,
                        subRiesgos: []
                    };
                }

                groupedRiesgos[groupKey].subRiesgos.push({
                    ...riesgo
                });
            }
        })


        const result = Object.values(groupedRiesgos).map((group, i) => {
            console.log("group ", group);
            return (
                {
                    key: i,
                    riesgo: group.riesgo,
                    origen: group.origen,
                    subRiesgos: [...group.subRiesgos]
                }
            );
        });


        return result;
    };




    const RiesgoRow = React.memo(({ r }) => {
        // console.log("riesgo", r[0].key);
        console.log("riesgo", r);

        // console.log("riesgo", r[0].riesgo[0]);
        // console.log("origen", r[0].origen);

        return (
            <>

                <AcordeonRiesgos
                    RiesgoIdentificado={r.riesgo[0]}
                    Origen={r.origen}
                    Riesgos={r.subRiesgos}
                    // {...(r.img != null && { Senal: r.img })}
                    // ComunidadesAfectadas={r.fincas?.slice(0, 150).join('\n')}
                    // numeroPanel={r[0].key}
                />

            </>
        );


    });


    return (
        <>
            <BreadCrumb rutas={[{ path: '/riesgos', label: `Riesgos` }]} style={{ margin: '0 10px' }} />
            <div className="listPage">
                <div className="gridCard">
                    <div className="gridHead">
                        Riesgos
                    </div>
                    <>

                        {riesgos.map((r, index) => (

                            
                            <RiesgoRow r={r} />
                        ))}

                    </>
                </div>
            </div>

        </>
    );
}

export default VerRiesgos;