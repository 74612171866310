import React, {useEffect, useState} from "react";
import { withRouter } from "../../providers/withRouter";
const CheckPass = ({password, enviar, passValida}) => {
    const [minuscula, setMinuscula] = useState(false);
    const [mayuscula, setMayuscula] = useState(false);
    const [numero, setNumero] = useState(false);
    const [caracterEspecial, setCaracterEspecial] = useState(false);
    const [distintoCaracterE, setDistintoCaracterE] = useState(false);
    const [longitud, setLongitud] = useState(false);
    const [envio, setEnvio] = useState(false);


    useEffect(() => {
        setEnvio(enviar)
        validarPass(password)
    },[password, enviar])

    function validarPass(pass) {
        const tieneMinuscula = /[a-z]+/.test(pass);
        const tieneMayuscula = /[A-Z]+/.test(pass);
        const tieneNumero = /\d+/.test(pass);
        const tieneCaracterEspecial = /[@$!%*?&]+/.test(pass);
        const tieneCaracterDistintoE =  pass === "" ? true : /^[a-zA-Z0-9\s@$!%*?&]*$/.test(pass);
        const longitud = pass.length >= 8 ? true : false
        setMinuscula(tieneMinuscula)
        setMayuscula(tieneMayuscula)
        setNumero(tieneNumero)
        setCaracterEspecial(tieneCaracterEspecial)
        setDistintoCaracterE(tieneCaracterDistintoE);
        setLongitud(longitud)
        passValida(tieneMinuscula && tieneMayuscula && tieneNumero && tieneCaracterEspecial && tieneCaracterDistintoE && pass.length >= 8)
        return tieneMinuscula && tieneMayuscula && tieneNumero && tieneCaracterEspecial && tieneCaracterDistintoE && pass.length >= 8;
    }

    return ( 
        <ul style={{margin:'10px auto'}}>
            <li style={{color: longitud ? 'green' : envio ? 'red' : 'gray' }}>Al menos 8 carácteres</li>
            <li style={{color: minuscula ? 'green' : envio ? 'red' : 'gray' }}>Letra minúscula (a-z)</li>
            <li style={{color: mayuscula ? 'green' : envio ? 'red' : 'gray' }}>Letra mayúscula (A-Z)</li>
            <li style={{color: numero ? 'green' : envio ? 'red' : 'gray' }}>Números (0-9)</li>
            <li style={{color: caracterEspecial && distintoCaracterE ? 'green' : distintoCaracterE ? 'gray': !distintoCaracterE ? 'red' : envio ? 'red' : 'gray' }}>Carácteres especiales ( @$!%*?& )</li>
        </ul>
     );
}
 
export default withRouter(CheckPass);