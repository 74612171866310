import React, { useState, useEffect } from "react";
// import { styled } from '@mui/system';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { Grid } from '@mui/material';


export default function ControlledAccordions(props) {
  // console.log(props.Senal);
  const [expanded, setExpanded] = useState(false);



  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
console.log(props)

  return (
    <div className="root" style={{ margin: "15", position: 'relative', maxHeight: 'auto' }}>

      <Accordion
        key={props.numeroPanel}
        style={{ margin: "0" }}
        expanded={expanded === "panel" + props.numeroPanel}
        onChange={handleChange("panel" + props.numeroPanel)}
      >
        <AccordionSummary
          style={{ background: "rgb(251 251 251)" }}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          
          <Typography style={{ flex: "auto", fontSize: "0.95rem" }} className="heading">
          {props.RiesgoIdentificado} 
          <Typography style={{ flex: "auto", fontSize: "0.80rem" }} >
                <b>Origen: </b>
              
                 {props.Origen}
              </Typography>
            
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} style={{ margin: '10px 0' }}>
            <table width={'100%'}>
              <thead>
                <tr>
                  <th>Medidas propuestas</th>
                  <th>Comunidades afectadas</th>                
                </tr>
              </thead>
              <tbody>
                  {props.Riesgos.map(r=>{
                    return (
                      <>
                        <tr>
                          <td style={{ width: '50%', padding: '20px 20px', border: '1px solid'}}>
                            {r.key === 60 || r.key === 61 ?
                            <>
                              <p>
                                {r.medidas}
                              </p>
                              {r.img && (
                                <img
                                    src={r.img}
                                    style={{
                                        width: r.img === 'peligro' ? 80 : 100,
                                    }}
                                    alt={r.textoImg}
                                />
                              )}
                              <p>
                                {r.medidas}
                              </p>
                            </>
                          :
                            <>
                              <p>
                                {r.medidas}
                              </p>
                              {r.img && (
                                <img
                                    src={r.img}
                                    style={{
                                        width: r.img === 'peligro' ? 80 : 100,
                                    }}
                                    alt={r.textoImg}
                                />
                              )}
                            </>
                          }  
                          </td>
                          <td style={{ width: '50%', padding: '20px 20px', border: '1px solid'}}>
                          {r.fincas.map((finca, fincaIndex) => (
                            <React.Fragment key={fincaIndex}>
                              {finca}
                              <br />
                            </React.Fragment>
                          ))}
                          </td>
                        </tr>
                      </>  
                    )
                  })}
              </tbody>
            </table>
            
            {/* <Grid item xs={12} sm={5}>
              <Typography >
                <b>Medidas Propuestas:</b>
                <ul>
                  {props.MedidasPropuestas.map((medida, index) => (
                    <li key={index}>{medida}</li>
                  ))}
                </ul>
                {props.Senal && (
                  <img
                    src={props.Senal}
                    alt={props.Senal}
                    style={{ maxWidth: '80px', marginTop: '10px' }}
                  />
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Typography>
                <b>Comunidades Afectadas:</b>
                {props.ComunidadesAfectadas ? (
                  <ul>
                    {props.ComunidadesAfectadas.split('\n').map((comunidad, index) => (
                      <li key={index}>{comunidad}</li>
                    ))}
                  </ul>
                ) : (
                  <span>Ninguna</span>
                )}
              </Typography>
            </Grid> */}
          </Grid>
        </AccordionDetails>
      </Accordion>


      <style jsx="true">{`
        .root {
          width: "100%";
        }
        .heading: {
          font-size: 0.375rem;
          flex-basis: "33.33%";
          flex-shrink: 0;
        }
        .secondaryheading: {
          fontsize: 0.9375rem;
          color: theme.palette.text.secondary;
        }
      `}</style>
    </div>
  );
}
