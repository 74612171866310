import React, { useLayoutEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import Editar from "../../../icons/editar.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModalEditar from "./editarDatosComunidad";
import { CircularProgress, Tabs, Tab, Box } from "@mui/material";
import TabPanel from "../../../components/tabs/Tabs";

import {
  getFinca,
} from "../../../services";
import {  cambiarFormatoNombre, estadoServicio } from "../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import ComunidadDocs from "../../../components/Comunidad/ComunidadDocs";
import { construirURLParamsFiltros } from "../../../functions/parametrosURL/funcionesParamsURL";


const Comunidad = (props) => {
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [recargar, setRecargar] = useState(false);
  const [isLoading, setIsLoading] = useState(true)
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const tabParam = parseInt(params.get("valorTab") ) ;
  const [value, setValue] = useState(tabParam || 1);
  const navigate = useNavigate();

  

  useLayoutEffect(() => {
    getFinca(props.params.nifFinca).then((res) => {
      // res.documentos = { hojasVisita: { ano: 2023 } };
      // res.centrosTrabajo = [];
      let provs = res.suppliers.filter(objeto => objeto.status === true)
      const ultimaVenta = res.sales[res.sales.length - 1];
      console.log(ultimaVenta)
      const sale_cae = res?.sales?.reverse()?.find(s => [1, 3, 6, 7].includes(s.servp_id)) || null;
      const sale_lopd = res?.sales?.reverse()?.find(s => [2, 4].includes(s.servp_id)) || null;
      console.log(sale_cae)
      console.log(sale_lopd)
      res.estadoCae = sale_cae?.estado_id ? sale_cae.estado_id : 1
      res.estadoLopd = sale_lopd?.estado_id ? sale_lopd.estado_id : 1
      console.log(res)
      setFinca(res);
      setProveedores(provs);

      if (!res.gruposServicios.includes(1) && tabParam === 1 || !res.gruposServicios.includes(2) && tabParam === 2) {
        const defaultTab = res.gruposServicios[0]
        construirURLParamsFiltros(navigate, 1, 50, {}, defaultTab);
        setValue(defaultTab)
      }
      setIsLoading(false);
    });
  }, [recargar]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return isLoading || finca == {} ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : 
    <>
      <ModalEditar
        finca={finca}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Comunidad / <b>Vecinos {finca.razonSocial}</b>
      </div> */}
      <BreadCrumb rutas={[{path:'/aaff', label:`Administradores de fincas`}, {path:`/aaff/${props.params.nif}/comunidades`, label:`${finca?.aaff?.razonSocial}`}, {path:`/aaff/${props.params.nif}/comunidades/${props.params.nifFinca}`, label:`${finca.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS COMUNIDAD</div>
            <div
              className="editarBtn"
              onClick={() => setModalEditarOpened(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(finca.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b> {finca.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {finca.codigoPostal}
            </p>
            <p>
              <b>Email de contacto:</b> {finca.emailContacto}
            </p>
            <p>
              <b>Nombre del presidente:</b> {finca.nombrePresidente}
            </p>
            <p>
              <b>Nif del presidente:</b> {finca.nifPresidente}
            </p>
            <p>
              <b>Estado CAE: </b> 
              <span style={{ color: finca.estadoCae === 1 || finca.estadoCae == null ? "#3eae3e"  : finca.estadoCae === 2 || finca.estadoCae === 3 ? '#FF8300' : finca.estadoCae === 4 ? "#EE4534" : "#000"}}>
                {estadoServicio(finca.estadoCae)}
              </span> 
            </p>
            <p>
              <b>Estado LOPD: </b> 
              <span  style={{ color: finca.estadoLopd === 1 || finca.estadoLopd == null ? "#3eae3e"  : finca.estadoLopd === 2 || finca.estadoLopd === 3 ? '#FF8300' : finca.estadoLopd === 4 ? "#EE4534" : "#000"}}>
                {estadoServicio(finca.estadoLopd)}
              </span> 
            </p>
            {/* <p>
            <b>Activo:</b> {finca.activo==true || finca.activo==undefined ? <span style={{ color: 'green' }}>Si</span> :  <span style={{ color: 'red' }}>No</span>}
            </p> */}

            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
          <Box sx={{ padding: "10px 40px", border: "none" }}>
            {!finca?.gruposServicios || finca?.gruposServicios.length === 0 ? (
              "No tiene documentos"
            ) : (
              <Tabs value={value} onChange={handleChange} aria-label="tabs example">
                {finca?.gruposServicios?.includes(1) && <Tab style={{ color: "#92004D" }} label="CAE" value={1} />}
                {finca?.gruposServicios?.includes(2) && <Tab style={{ color: "#92004D" }} label="LOPD" value={2} />}
              </Tabs>
            )}
          </Box>
          {finca?.gruposServicios?.map((grupoServicio) => (
                
                <TabPanel key={grupoServicio} value={value} index={grupoServicio}>
                  <div className="pageGrid" style={{ gap:0 }}>
                    {grupoServicio === 1 && (
                      <>
                        <div id="ventas" >
                          <ComunidadDocs idPropiedad={finca.id} grupoServicio={1} />
                        </div>
                        <div id="proveedores" className="tableContainer">
                          <div className="gridHeadProv">PROVEEDORES</div>
                          <div >
                            <table>
                            <tbody>
                            {proveedores.length === 0 ? (
                              <small style={{ paddingLeft: "14px" }}>No tiene proveedores</small>
                            ) : (
                              proveedores.map((e, i) => {
                                return (
                                  <tr key={i}>
                                    <td style={{ width: "100%", padding: 10 }}>
                                      <Link to={"/proveedores/" + e.nif}>
                                        {e.razonSocial}
                                        <p style={{ opacity: 0.55, fontSize: 14 }}>{e?.serviceSupps?.length > 0 ? e?.serviceSupps?.find((serv) => serv.id === e.service_to_propertie?.service)?.nombre : null}</p>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                            </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                    {grupoServicio === 2 && <ComunidadDocs idPropiedad={finca.id} grupoServicio={2}/>}
                  </div>
                </TabPanel>
            ))}
        </div>
        {/* <div className="gridCard">
          <div className="gridHead">PROVEEDORES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
                // <th>Aviso</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(proveedores)
                ? proveedores.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            paddingTop: 10,
                          }}
                        >
                          <Link to={"/proveedores/" + e.nif}>
                            {e.razonSocial}
                            <p style={{ opacity: 0.55, fontSize: 14 }}>
                              {
                                e?.serviceSupps?.length > 0 ? e?.serviceSupps?.find( serv => serv.id === e.service_to_propertie.service)?.nombre : null
                              }
                              // {e.serviceSupps.map((s, j) => {
                              //   return e.serviceSupps.length === j + 1
                              //     ? s.nombre
                              //     : s.nombre + ", ";
                              // })} 
                            </p>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : null}
              //  {proveedores?.map((e, i) => {
              //               return (
              //               <tr key={i}>
              //                   <td style={{ width: '100%', paddingBottom: 10, paddingTop: 10 }}>
              //                       <Link to={"/proveedores/" + e.nif}>{e.razonSocial}<p style={{ opacity: 0.55, fontSize: 14 }}>{serviciosProveedores[e.servicio]}</p></Link>
              //                   </td>
              //               </tr>
              //               )
              //           })} 
            </tbody>
          </table>
        </div> */}
      </div>
      <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
        .gridHeadProv {
          border-radius: 5px 5px 0 0;
          background-color: #92004D;
          color: #ffffff;
          padding: 10px 15px;
          font-weight: bold;
          font-size: 12px;
          height: 34px;
        }
        #ventas{
          width:70%;
        }
        #proveedores{
          width:30%;
        }
        .pageGrid{
          display:flex;
          flex-direction: row;
        }
        @media screen and (max-width: 992px) {
          #ventas{
            overflow-y: auto;
            width:100%;
          }
          #proveedores{
            width:100%;
          }
          .pageGrid{
            flex-direction: column;
          }
        }
      `}</style>
    </>
};

export default withRouter(Comunidad);


