import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { pendingNotifications } from './../../providers/api'
import Sidebar from "./sidebar";
// import { BellFill } from 'react-bootstrap-icons';
import "./navbar.css";
import { useEffect } from "react";
import UserMenu from "./UserMenu";


function Navbar(props) {
  const { user, routes, routesClientes, routesPersonal, routesListados } = props;
  const [sidebar, setSidebar] = useState(true);
  const [checked, setChecked] = useState(true);
  

  useEffect(() => {
    // pendingNotifications().then(result => setNumNotification(result))
  }, []);

  const handleChange = (event) => {
    setChecked(!event.target.value);
    showSidebar();
  };

  const showSidebar = () => {
    setSidebar(!sidebar);
    props.showSidebar();
  };
  

  return (
    <>
      <Sidebar
        sidebar={sidebar}
        checked={checked}
        rol={user.rol}
        routes={routes}
        routesClientes={routesClientes}
        routesPersonal={routesPersonal}
        routesListados={routesListados}
      />

      <nav className="navbar navbar-light bg-light justify-content-between">
        <div className="container-fluid">
          {/* <button className="navbar-brand btn" onClick={() => showSidebar()}><SquareHalf /></button> */}
          <div className="check-menu form-switch">
            <label className="switch">
              <input
                type="checkbox"
                value={checked}
                checked={checked ? checked : null}
                onChange={handleChange}
              />
              <span className="slider"></span>
            </label>
          </div>
          <div>
            <UserMenu />
          </div>
        </div>
      </nav>
      <style jsx="true">{`
          .dropdown-toggle:after {
            display: none !important;
          }              
      `}</style>
    </>
  );
}

export default Navbar;
