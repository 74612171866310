import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "../../providers/withRouter";
import { createComment, getComments } from '../../services/comment.service';
import { IoSave } from "react-icons/io5";
import Delete from "../../icons/delete.svg";
import Editar from "../../icons/editar.svg";
import { UsuarioContext } from '../../providers/userContext';
import { editarComentario, eliminarComentario, formatoFechasEs } from '../../functions/funciones';
import ModalEditarComentarios from "./../../components/proveedores/AllComments";
import { CircularProgress } from '@mui/material';

const Comentarios = (props) => {

  const user = useContext(UsuarioContext)
  const [comentario, setComentario] = useState('');
  const [recargar, setRecargar] = useState(0);
  const [comentarios, setComentarios] = useState([]);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const supp = props.supp;
  useEffect(() => {
    const fetchComentarios = async () => {
      setIsLoading(false);
      try {
        const response = await getComments({ filtros: { supp_id: supp.id, tipo: "comment" } });
        const comentariosFiltrados = response.data.map(comment => {
          comment.usuario = comment.user?.consultant?.[0] || comment.user?.admin?.[0];
          return comment;
        });
        setComentarios(comentariosFiltrados);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    };
    fetchComentarios();
  }, [supp.id, recargar]);



  function guardarComentarios() {
    try {
      let infoComentario = {
        prop_id: null,
        supp_id: supp.id,
        aaff_id: null,
        texto: comentario,
        tipo: "comment",
        status: 1,
      }
      createComment(infoComentario).then(res => {
        handleSubirDocsCallback(res)
        setComentario('')
      })
    } catch (error) {
      console.log(error)
    }

  }

  function cambiarComentarios(evt) {
    const value = evt.target.value;
    setComentario(value);
  }


  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const editarComment = async (id, comm) => {
    editarComentario(id, user.id, comm).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  const eliminarComment = async (id) => {
    eliminarComentario(id).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  return (
    <>
      <ModalEditarComentarios
        comentarios={comentarios}
        opened={modalEditarOpened}
        eliminarComment={eliminarComment}
        editarComment={editarComment}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <div style={{ position: 'relative' }}>
        <p style={{ paddingBottom: 10 }}>
          <b>Comentarios</b>
        </p>
        <div style={{ overflow: 'scroll', maxHeight: 200 }}>
          {!comentarios || isLoading ? <CircularProgress style={{ height: 20, width: 20, color: '#92004D', position: 'absolute', top: '15%', left: '5%' }} /> :
            comentarios.length === 0
              ? "No hay comentarios"
              :
              <>
                {comentarios.slice(0, 4).map(comm => {
                  return (
                    <div>
                      <img
                        src={Delete}
                        alt="ELIMINAR"
                        className="tableIcon"
                        onClick={() => eliminarComment(comm.id)}
                        style={{ width: 14 }}
                      ></img>
                      <img
                        src={Editar}
                        alt="Editar"
                        className="tableIcon"
                        onClick={() => editarComment(comm.id, comm)}
                        style={{ width: 14 }}
                      ></img>
                      {formatoFechasEs(comm.createdAt)} - {comm.usuario?.nombre + " " + comm.usuario?.apellidos} - {comm.texto}</div>
                  )
                })}
                {comentarios.length > 4 &&
                  <div >
                    <button onClick={() => setModalEditarOpened(true)} style={{ margin: '10px 0', borderRadius: '5px 5px', backgroundColor: '#92004D', color: '#ffffff', padding: '5px 10px' }}>Mostrar más</button>
                  </div>
                }
              </>
          }{" "}
        </div>

      </div>
      <div>
        <form
          noValidate
          autoComplete="off"
          style={{ display: "flex", alignItems: "end" }}
        >
          <textarea
            style={{
              marginRight: "10px",
              border: "1px solid #ccc",
              width: "100%",
            }} id="comentarios" name="comentarios" value={comentario} label="Comentarios" onChange={cambiarComentarios} multiline rows={6} columns={100}
          />
          <div>
            <button type="button" className="bigBtn" onClick={() => guardarComentarios()}>
              <IoSave style={{ fontSize: 23 }} />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default withRouter(Comentarios);