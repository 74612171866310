import React, { useState, useEffect, useContext } from "react";
import {
  Box
} from "@mui/material";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import { useComunidades } from "../../../hooks";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosComunidadesTech from '../../../components/filtros/filtrosComunidadesTech';

import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';

const comunidadesRows = [
  {
    id: "razonSocial",
    numeric: false,
    disablePadding: false,
    label: "ADMINISTRADOR DE FINCAS",
    noObjeto:true,
  },
  {
    id: "community",
    numeric: false,
    disablePadding: false,
    noObjeto:true,
    label: "COMUNIDAD",
    overrideFunc: (data, row) => (
      <Link to={`/aaff/${row.aaff_nif}/comunidades/${row.nif}`}>
        {data}
      </Link>
    )
  },
  {
    id: "nif",
    numeric: false,
    disablePadding: false,
    label: "NIF",
  },
  {
    id: "direccion",
    numeric: false,
    disablePadding: false,
    label: "DIRECCION",
  },
  {
    id: "codigoPostal",
    numeric: false,
    disablePadding: false,
    label: "C.P",
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },

];

const TabFilters = [

  {
    value: "unvisited",
    label: "NO VISITADAS",
  },
  {
    value: "visited",
    label: "VISITADAS",
  },
  {
    value: "refreshed_unvisited",
    label: "RENOVADAS NO VISITADAS",
  },
  {
    value: "refreshed_visited",
    label: "RENOVADAS VISITADAS",
  },

];

const listaFiltros = [
  { value: "all", label: "TODOS" },
  { value: "comunidades", label: "Comunidades" },
  { value: "aaff", label: "Administrador de Fincas" },
];

const Comunidades = (props) => {

  
  // const { loading, data, fetchData} = useComunidades(props.params.nif );
  const { loading, data, fetchData } = useComunidades();
  console.log("data",data);
  const {filtrosIniciales} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  // const [busqueda, setBusqueda] = useState({ nifAAFF: props.params.nif });
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [recargar, setRecargar] = useState(0);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);

  const handleFetchData = async ({ offset, limit,order, orderBy }) => {


    try {
      await fetchData({ offset, limit, filtros: busqueda ,order, orderBy});
    } catch (error) {
      console.log(error);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }


  useEffect(() => {
    setRecargar(false);
  }, [recargar]);

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData({ offset: 1, limit: limite, filtros: filtros });
  }

  return (
    <>

      <BreadCrumb
        rutas={[{ path: "/comunidades", label: "Comunidades" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
          </div>
          <div className="pageContent tableContainer">
            <FiltrosComunidadesTech onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
            <Box>
              <Box sx={{ mb: 10 }}>
                <DataTable
                  data={data?.rows}
                  isLoading={loading}
                  headers={comunidadesRows}
                  fetcher={handleFetchData}
                  currentPage={data.current_page}
                  meta={data?.meta}
                  totalItems={data.total}
                  totalPages={data.totalPages}
                  nextPage={data.next_page}
                  filtros={busqueda}
                  busquedaActiva={busquedaActiva}
                  setBusquedaActiva={setBusquedaActiva}
                  clearFilterActivo={clearFilterActivo}
                  setClearFilterActivo={setClearFilterActivo}
                  mensaje={"No hay fincas"}
                >
                </DataTable>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Comunidades);
