
import { formatoFechas } from "../funciones";

export const verificarFecha = (fechaIntroducida) => {
  let fechaActual = formatoFechas(new Date());
  const [yearActual, monthActual, dayActual] = fechaActual.split('-');
  const fechaActualDate = new Date(yearActual, monthActual - 1, dayActual);
  const [yearIntroducido, monthIntroducido, dayIntroducido] = fechaIntroducida.split('-');
  const fechaIntroducidaDate = new Date(yearIntroducido, monthIntroducido - 1, dayIntroducido);

  if (fechaIntroducidaDate > fechaActualDate) {
    return false;
  } else {
    return true;
  }


};

export const verificarCodigoPostal = (codigoPostal) => {
  const requisitoCodigoPostal = /^[0-9]{5}$/;
  return requisitoCodigoPostal.test(codigoPostal);

}

export const verificarCorreo = (correo) => {
  const requisitoCorreo = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
  return requisitoCorreo.test(correo);


}

export const verificarTelefono = (telefono) => {
  const requisitoTelefono = /^(\(?\d{3}\)?[-]?\d{3}[-]?\d{3})$/;
  return requisitoTelefono.test(telefono);
}

export const verificarNif = (nif) => {
  //  DNI
  const dniRegex = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;

  // NIE
  const nieRegex = /^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKE]$/i;
  // CIF
  const cifRegex = /^[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$/i;

  return dniRegex.test(nif) || nieRegex.test(nif) || cifRegex.test(nif);
}

export const verificarContrasena = (contrasena) => {
  const requisitoContrasena = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$/i;
  return requisitoContrasena.test(contrasena);
} 