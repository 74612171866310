import React, {useState, useEffect} from "react";
import { PDFViewer, Document, Page, Image, Text, View, StyleSheet, Font, Svg, Link } from "@react-pdf/renderer"
import LogoLAE from "../../../images/LogoLAE.png"
import extintores1 from "../../../images/extintores1.jpg"
import extintores2 from "../../../images/extintores2.jpg"
import bocaIncendio from "../../../images/bocaIncendio.png"
import cuartoContadores from "../../../images/cuartoContadores.png"
import estado from "../../../images/estado.png"
import { withRouter } from "../../../providers/withRouter";
import { getVisitSheet, getVisitSheetIdFromSale } from "../../../services";
import CircularProgress from '@mui/material/CircularProgress';
import RobotoRegular from "../../../fonts/Roboto-Regular.ttf"
import RobotoBold from "../../../fonts/Roboto-Bold.ttf"
import RobotoItalic from "../../../fonts/Roboto-Italic.ttf"
import RobotoBoldItalic from "../../../fonts/Roboto-BoldItalic.ttf"
import { formatoFechasEs } from "../../../functions/funciones";
import portada from '../../../images/PortadaEmergencia.png'
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import PDFHandler from "../../../components/pdfs/PDFHandler";


Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoItalic, fontWeight: 400 },
      { src: RobotoRegular, fontWeight: 400 },
      { src: RobotoBold, fontWeight: 700 },
      { src: RobotoBoldItalic, fontWeight: 800 },
    ]
});
const styles = StyleSheet.create({
    docTitle: {
        position: 'absolute',
        left: 100,
        right: 100,
        bottom: '100px',
        textAlign: 'center',
        fontSize: '16px',
        color: '#92004D',
        fontFamily: 'Roboto',
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    docTitle2: {
        position: 'absolute',
        left: 70,
        right: 70,
        bottom: '60px',
        textAlign: 'center',
        fontSize: '16px',
        color: '#92004D',
        fontFamily: 'Roboto',
        fontWeight: 700,
        textTransform: 'uppercase'
    },
    body: {
        // paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: '2cm',
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0 
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColHeader: { 
        width: "33%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#375491',
        color: '#ffffff',
    }, 
    tableColHeader1: { 
        width: "34%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#375491',
        color: '#ffffff',
    }, 
    tableColHeaderProv: { 
        width: "33%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#ccc',
        color: '#000000',
    }, 
    tableColHeaderProv1: { 
        width: "34%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#ccc',
        color: '#000000',
    }, 
    tableColHeaderSOS: { 
        width: "50%",
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor:'#ccc',
        color: '#000000',
    }, 
    tableColHeaderNM: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor: '#ffffff',
        color: '#000000',
    }, 
    tableColHeaderExt: { 
        width: "9.09%",
        height:60,
        padding: 0,
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor:'#ccc',
        color: '#000000',
    }, 
    tableColHeaderExt1: { 
        width: "9.1%",
        height:60,
        padding: 0,
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        backgroundColor:'#ccc',
        color: '#000000',
    }, 
    tableColExt: { 
        width: "9.09%", 
        height:16,
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCol1Ext: { 
        width: "9.1%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCellExt: { 
        margin: "auto", 
        marginTop: 5, 
        fontSize: 8 
    },
    tableCol: { 
        width: "33%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCol1: { 
        width: "34%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableColSOS: { 
        width: "50%",
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
        color:'red',
    }, 
    tableColNM: { 
        width: "100%", 
        borderStyle: "solid", 
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0,
    }, 
    tableCell: { 
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 5, 
        fontSize: 8 
    },
    pdfHeader: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        backgroundColor: '#ffffff',
        /* margin:'2cm', */
    },
    pdfHeaderImg:{
        width:'60px', 
        height:'60px',
        marginBottom:'30px',
    },
    tablePage: {
        width: '100%',
    },
    headerSpace: {
        height: '90px',
    },
    div: {
        fontSize: '10px'
    },
    h1: {
        /* marginLeft:'2cm',
        marginRight:'2cm', 
        marginTop: '50px',*/
        marginBottom: '30px',
        fontSize: '14px',
        fontWeight: '600',
    },
     h2: {
        fontSize: '12px',
        fontWeight: '600',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        marginTop: '15px',
        marginBottom: '25px',
    },
    h3: {
        fontSize: '9px',
        width: '100%',
        padding: '5px 10px',
        backgroundColor: '#92004D',
        color:'#fff',
        textTransform: 'uppercase',
        // border: '1px solid #e6e6e6',
        marginTop: '25px',
        marginBottom: '15px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'20cm', */
    },
    h4: {
        color: '#ab2a3e',
        marginBottom: '10px',
        marginTop: '20px',
        fontSize: '9px',
        fontWeight:'bold',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    h5: {
        fontSize: '10px',
        marginTop: '4px',
        marginBottom: '4px',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    h6: {
        fontSize: '9px',
        marginTop: '2px',
        marginBottom: '2px',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
    },
    textBox: {
        border: '2px solid #e6e6e6',
        padding: '15px',
        marginTop: '15px',
    },
    tableRiesgos: {
        borderCollapse: 'collapse',
        width: '100%',
        tableLayout: 'fixed',
        marginBottom: '24px',
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        backgroundColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
    },
    simpleTableTh: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    simpleTableTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    tableRiesgosTd: {
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    tableRiesgosTh: {
        backgroundColor: '#375491',
        color: '#ffffff',
        fontSize: '12px',
        fontWeight: 'bold',
        border: '1px solid #000000',
        padding: '5px',
        lineHeight: 1.1
    },
    simpleTable: {
        borderCollapse: 'collapse',
        width: '80%',
        margin: '0 10% 24px 10%',
    },
    simpleTableTh: {
        textTransform: 'uppercase',
    },
    subul: {
        lineHeight: '1.8',
        fontSize:'8px',
        flexDirection: "column", 
        width: 400,
        marginLeft: '8px',
    },
    ul: {
        lineHeight: '1.8',
        fontSize:'7px',
        flexDirection: "column", 
        width: 400,
    },
    ol: {
        lineHeight: '1.8',
        flexDirection: "row", 
        marginBottom: 4,
        fontSize: '7px',
    },
    p: {
        lineHeight: '1.8',
        textAlign: 'justify',
        padding: '5px 10px',
        /* marginLeft:'2cm',
        marginRight:'2cm', */
        fontSize: '9px',
    },
    subp: {
        lineHeight: '1.8',
        textAlign: 'justify',
        marginLeft: '8px',
        fontSize: '8px',
    },
    br: {
        display: 'block',
        margin: '10px 0 2px 0',
        lineHeight: '5px',
        content: " ",
    },
    pageTitle: {
        textAlign: 'center',
        textDecoration: 'underline',
        fontWeight: 'bold',
        fontSize: '12px',
        marginBottom: '20px'
    },
    page: {
        size: 'A4 portrait',
        margin: '2cm',
    },
    pagebreak: {
        clear: 'both',
        pageBreakAfter: 'always',
    },
    textoNormal: {
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    textoItalic: {
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    textoNegritaItalic: {
        fontFamily: 'Roboto',
        fontWeight: 800
    },
    textoNegrita: {
        fontFamily: 'Roboto',
        fontWeight: 700
    },
    hr:{
        width: '90px',
        height: '3px',
        backgroundColor: '#92004D',
        borderColor: '#92004D',
        position: 'absolute',
        left: 250,
        right: 250,
        bottom: '90px',
        textAlign: 'center',
        margin: ' 0 auto',
        color: '#92004D',
    },
    pageHeader: {
        marginTop: 15, 
        paddingBottom: 15, 
        marginHorizontal: '1cm'
    },
    cuadrado:{
        border:1,
        marginTop:'20px',
        marginBottom:'20px'
    },
    lines: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    line: {
        flexDirection: 'row',
        // justifyContent: 'space-between',
        alignItems: 'center',
    },
    textoGirado: {
        transform: 'rotate(-90deg)',
        transformOrigin: 'center center',
      },
  });

const PlanEmergenciaRender = (props) => {

    const backgroundImageUrl = portada;
    const [isLoading, setIsLoading] = useState(true)
    const [proveedores, setProveedores] = useState([])
    const [hojaVisita, setHojaVisita] = useState({})
    const rows = Array.from({ length: 14 });
    const fetchData = async () => {
        // const response = await getFinca(props.params.nif)
        const sale = await getVisitSheetIdFromSale(props.params.id);
        const visitSheet_id = sale.detailsCae[0].visitSheet_id;
        const respVisit = await getVisitSheet(visitSheet_id)
        const { data } = respVisit
        let hojaVisita = data
        hojaVisita.imgURL = `${process.env.REACT_APP_STORAGE_URL}${data.img}`
        hojaVisita.fechaVisita = formatoFechasEs(data.createdAt)
        const renewDate =  new Date(data.createdAt);
        hojaVisita.mesVisita = format(renewDate, 'MMMM yyyy', {locale: es})
        let provs = data.propertie?.suppliers.filter( s => s.status === true )
        setHojaVisita(hojaVisita);
        setProveedores(provs)
        setIsLoading(false)
    }

    useEffect(() =>{
        fetchData();
    },[])

    return (
        <>
            {isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> :
                <PDFHandler style={{ width:"100%", height:"100vh"}}>
                    <Document size="A4" margin="2cm">
                        <Page>
                            <View>
                                <Image src={backgroundImageUrl} style={{ width: '100vw', height: '100vh' }}/>
                                <Text style={styles.docTitle}>
                                    {hojaVisita.propertie.direccion}
                                </Text>
                                <Text style={styles.hr}></Text>
                                <Text style={styles.docTitle2}>
                                    {hojaVisita.mesVisita}
                                </Text>
                            </View>
                        </Page>
                        
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View style={[styles.ul, {marginTop:'25px'}]}>
                                    <View style={styles.ol}>
                                        <Text style={[styles.h5, styles.textoNegrita]}>INTRODUCCIÓN</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.h5, styles.textoNegrita]}>MEDIDAS DE EMERGENCIA</Text>
                                    </View>
                                    <View style={styles.ul}>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >1. OBJETO</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >2. MEDIDAS DE PROTECCIÓN</Text>
                                        </View>
                                        <View style={styles.ul}>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >a. Pasivas</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >b. Activas</Text>
                                            </View>
                                            <View style={styles.ul}>
                                                <View style={[styles.ol, {paddingLeft: 60}]}>
                                                    <Text style={[styles.h6, styles.textoNegrita]} >i. Detección</Text>
                                                </View>
                                                <View style={[styles.ol, {paddingLeft: 60}]}>
                                                    <Text style={[styles.h6, styles.textoNegrita]} >ii. Alerta y señalización</Text>
                                                </View>
                                                <View style={[styles.ol, {paddingLeft: 60}]}>
                                                    <Text style={[styles.h6, styles.textoNegrita]} >iii. Sistemas de extinción</Text>
                                                </View>
                                                <View style={[styles.ol, {paddingLeft: 60}]}>
                                                    <Text style={[styles.h6, styles.textoNegrita]} >iv. Extintores portátiles</Text>
                                                </View>
                                                <View style={[styles.ol, {paddingLeft: 60}]}>
                                                    <Text style={[styles.h6, styles.textoNegrita]} >v. Bocas de incendio equipadas</Text>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >3. CONSIDERACIONES</Text>
                                        </View>
                                        <View style={styles.ul}>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >a. Zonas comunes</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >b. Ascensor</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >c. Cuarto de contadores</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >d. Trasteros</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >e. Garajes</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 40}]}>
                                                <Text style={[styles.h6, styles.textoNegrita]} >f. Locales comerciales</Text>
                                            </View>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >4. PREVENCIÓN DE RIESGOS LABORALES EN ZONAS COMUNES</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >5. PROPUESTAS</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >6. RECOMENDACIONES DE ACTUACIÓN EN CASO DE ACCIDENTE </Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >7. RECOMENDACIONES DE ACTUACIÓN EN CASO DE INCENDIOS</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >8. TELÉFONOS DE UTILIDAD</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 20}]}>
                                            <Text style={[styles.h5, styles.textoNegrita]} >9. ANEXO I</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                            <Text style={[styles.h2, styles.textoNegrita, {textAlign:'center', textDecoration:'underline'}]}>INTRODUCCIÓN</Text>
                            </View>
                            <View>
                                <Text style={styles.p}>Según el artículo 20 de la Ley de Prevención de Riesgos Laborales, y su reforma, la Ley 54/2003: “el empresario deberá analizar las posibles situaciones de emergencia y adoptar las medidas necesarias en materia de primeros auxilios, lucha contra incendios y evacuación de los trabajadores”. En este documento se desarrollarán las medidas de emergencia orientadas a las comunidades de propietarios. </Text>
                                <Text style={styles.p}>Según el Instituto Nacional de Seguridad y Salud en el Trabajo, se entiende por emergencia “una situación o accidente que acontece de forma imprevista y puede afectar a la integridad física de las personas, a los bienes y/o al medioambiente, ya sea individualmente o colectivamente, pudiendo, en ocasiones, llegar a constituir una situación de grave riesgo colectivo, catástrofe o calamidad pública.”</Text>
                                <Text style={styles.p}>Para evitar situaciones de emergencia derivadas de la actividad, la normativa contempla una serie de obligaciones, de manera que se prevenga la aparición de estas situaciones o bien queden controladas, de forma rápida y eficaz, una vez hayan ocurrido. De no ser así, la amplitud y gravedad de los efectos podrían ser tales, que pasarían al ámbito de la protección civil.</Text>
                                <Text style={styles.p}>En muchas ocasiones, más de las deseadas, no existe una planificación de la emergencia, porque:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Es difícil imaginar que a uno le suceda.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Requiere invertir tiempo y esfuerzo.</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Existen diferentes factores que pueden producir una situación de emergencia:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Fallos humanos.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Fallos técnicos.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Defectos de diseño de instalaciones y organización.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Causas de la naturaleza: catástrofes naturales (riadas, terremotos, etc.).</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Causas del entorno: por instalaciones externas, etc.</Text>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={[styles.h2, styles.textoNegrita, {textAlign:'center', textDecoration:'underline'}]}>MEDIDAS DE EMERGENCIA</Text>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center'}]}>OBJETO</Text>
                                <Text style={styles.p}>Las medidas de emergencia pretenden conseguir que cualquier incidente, que pueda afectar a las personas, las propias instalaciones y el edificio así como la continuidad de las instalaciones, tenga una incidencia mínima o nula. Para hacerlo posible, se debe conseguir delante de una emergencia, una buena coordinación, en el tiempo y el espacio, tanto de las personas afectadas como de los medios de protección existentes, de manera que sean utilizados eficazmente para obtener, según la emergencia:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Una rápida evacuación de las instalaciones.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>La extinción del incendio.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>La limitación de los daños materiales.</Text>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center'}]}>MEDIDAS DE PROTECCIÓN EN COMUNIDADES DE PROPIETARIOS</Text>
                                <Text style={styles.p}>Las medidas fundamentales pueden clasificarse en dos tipos:</Text>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>A) MEDIDAS PASIVAS:</Text>
                                <Text style={styles.p}>Se trata de las medidas que afectan al proyecto o a la construcción del edificio, cuyos fines son:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Facilitar la evacuación de los usuarios presentes en caso de emergencia, mediante pasillos y escaleras de suficiente amplitud.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>En caso de incendio retardar y confinar la acción del fuego para disminuir la rapidez en su avance o pararlo antes de invadir otras zonas.</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Las medidas pasivas son aquellas orientadas a conseguir una fácil y rápida evacuación de los ocupantes del edificio en caso de emergencia. Las diversas normativas que las definen determinan:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>El ancho mínimo de los pasillos, escaleras y puertas de evacuación.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Las distancias máximas a recorrer hasta llegar a un lugar seguro.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Las disposiciones constructivas (apertura de las puertas en el sentido de la evacuación, escaleras con pasamanos,...).</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>- Recorridos de evacuación protegidos (pasillos y escaleras), de modo que no solamente tienen paredes, suelos y techos resistentes a la acción del fuego, sino que están decorados con materiales incombustibles</Text>
                                <Text style={styles.p}>¿Qué es la sectorización y para qué sirve?</Text>
                                <Text style={styles.p}>Para retardar el avance del fuego el edificio se divide en sectores de incendio de superficie máxima, sectores limitados por paredes, techos, suelos y puertas de una cierta resistencia al fuego. En la evacuación, pasar de un sector a otro es llegar a un lugar más seguro. Todo edificio, toma- do como una unidad, ha de ser también un sector de incendio, con la intención de evitar que el fuego pase a los edificios colindantes. Es importante no solo disponer de estos sistemas, que por un lado van a evitar la propagación del incendio y del humo tóxico, facilitando la evacuación, sino mantener en perfecto estado sus sistemas de accionamiento y cierre automáticos, evitando que se rompa la sectorización con la utilización de elementos que impidan el cierre de puertas como ceniceros, cuñas, etc.</Text>
                                <View style={[styles.cuadrado, {marginBottom:0}]}>
                                    <Text style={[styles.p, {color:'#92004D', textAlign:'center', marginTop:10}]}>RECOMENDACIONES</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>Si alguna puerta falla, avisar al presidente o administrador. Las puertas cortafuegos también deben ser revisadas como el resto de instalaciones.</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>Las puertas cortafuegos deben estar siempre cerradas en los vestíbulos de independencia de cada planta, garaje o trasteros, para así evitar la propagación del humo y el fuego. Es responsabilidad de todos.</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>No alterar los sistemas de  apertura  y  cierre  de las puertas con cerrojos o candados que dificulten la evacuación o el acceso de los servicios de emergencia.</Text>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>B)	MEDIDAS ACTIVAS:</Text>
                                <Text style={styles.p}>Detección, alarma e instalaciones de extinción de incendios.</Text>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>1 - Detección</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Detectores automáticos: según los materiales existentes en el lugar donde se coloquen pueden ser:</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Ópticos o de humos: habituales en locales y viviendas. Detectan las partículas del humo, incluso antes de que el ojo humano sea capaz de verlo.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Térmicos o de llamas: utilizados en garajes, cocinas industriales, lavanderías, etc. Detectan incrementos de temperatura.</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Pulsadores de alarma: son timbres situados en zonas comunes que cualquiera puede pulsar si detecta un conato de incendio</Text>
                                <View style={styles.cuadrado}>
                                    <Text style={[styles.p, {color:'#92004D', textAlign:'center', marginTop:10}]}>RECOMENDACIONES</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>Los elementos de detección deben estar siempre activos.</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>Al detectar una anomalía, comunicarla al presidente o administrador lo antes posible, la vida de todos los vecinos depende de ello.</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>Si la comunidad es antigua y NO dispone de estos elementos, animar a los vecinos para que los instalen tanto en viviendas como en zonas comunes.</Text>
                                </View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginBottom: '10px', marginTop: '10px'}]}>2 - Alerta y señalización</Text>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9}]}>Las vías de evacuación deben estar indicadas con señales de color verde y deben marcar el camino a seguir. Existen también señales de color rojo que nos muestran aquellos caminos que no son recorridos de evacuación. Además es necesario que exista una iluminación mínima a lo largo de todo el recorrido y debe garantizarse mediante lámparas con baterías propias que se encienden en caso de que ocurra un fallo en la alimentación del sistema eléctrico.</Text>
                                <View style={styles.cuadrado}>
                                    <Text style={[styles.p, {color:'#92004D', textAlign:'center', marginTop:10}]}>RECOMENDACIONES</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>La señalización de evacuación debe ser visible.</Text>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>Todas las vías de evacuación deben estar señalizadas.</Text>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>3 - Sistemas de extinción</Text>
                                <Text style={styles.p}>Hoy en día, la mayoría de edificios de nueva construcción cuenta con estos medios de protección. Si su edificio es antiguo, se debe instar al resto de vecinos a equiparlo con el mayor número de elementos de protección contra incendios posibles. La simple instalación de extintores portátiles en los rellanos de las escaleras, puede ser suficiente para poder actuar ante un conato de incendio.</Text>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>4 - Extintores portátiles</Text>
                                <Text style={styles.p}>Los más habituales en el entorno doméstico son los extintores portátiles de 6 kg y polvo polivalente, que son capaces de extinguir los tipos de fuego más habituales y además bajo tensiones eléctricas de hasta 35.000 voltios. En zonas con riesgos específicos como salas de control, de comunicaciones y cuartos de contadores, se instalan extintores portátiles de CO2, que además de no ser conductores de la electricidad, en caso de descarga accidental o por incendio, no resultan dañinos para los aparatos electrónicos.</Text>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>¿Cómo se usa?</Text>
                                <Text style={styles.p}>En primer lugar, recordar que un extintor es tanto más eficaz cuanto antes se ataque el fuego. Un extintor portátil de polvo ABC de 6 kg tiene una duración inferior a un minuto. Por ello, debemos valorar siempre la magnitud del incendio y solo usar los extintores si el fuego está en los inicios.</Text>
                                <Text style={styles.p}>En la etiqueta de cada extintor se especifica su modo de empleo y las precauciones a tomar. Es muy habitual que en el momento de la emergencia no se asimilen todas las reglas prácticas de utilización del aparato. De ahí la importancia de familiarizarse con los extintores que tiene cada uno en su comunidad.</Text>
                                <Text style={styles.p}>- Con una periodicidad trimestral, el titular propietario del extintor debe comprobar que la señalización, la accesibilidad y el estado aparente de extintor son correctos. Anexo I.</Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9}]}>Manejo de extintores</Text>
                                <Image src={extintores1} style={{ width: '100%', marginTop: 24 }} ></Image>
                                <View style={{display:'flex', position: 'relative', paddingLeft: 20}}>
                                    <Text style={[styles.p, {fontSize: 6, width: 100}]}>Descolgar el extintor haciéndolo por la maneta o asa fija y dejarlo sobre el suelo en posición vertical.</Text>
                                    <Text style={[styles.p, {fontSize: 6, width: 100, position: 'absolute', top: 0, left: 180}]}>Sacar el pasador de seguridad tirando de su anilla.</Text>
                                    <Text style={[styles.p, {fontSize: 6, width: 100, position: 'absolute', top: 0, left: 350}]}>Presionar la palanca de la cabeza del extintor y en caso de que exista apretar la palanca de la boquilla realizando una pequeña descarga de comprobación.</Text>
                                </View>
                                <Image src={extintores2} style={{ width: '75%', marginTop: 24, marginBottom: 18 }} ></Image>
                                <Text style={styles.p}>Dirigir el chorro a la base de las llamas con movimiento de barrido. En caso de incendio de líquidos proyectar superficialmente el agente extintor efectuando un barrido evitando que la propia presión provoque derrame del líquido incendiado. Aproximarse lentamente al fuego hasta un máximo de un metro.</Text>
                                <Text style={styles.p}>Sofocar el incendio siempre dando la espalda a la salida, de manera que no podamos quedar atrapados por las llamas.</Text>
                                <View style={[styles.cuadrado, {mmarginTop:10, marginBottom:10}]}>
                                    <Text style={[styles.p, {color:'#92004D'}]}>En caso de no poder sofocar el incendio llamaremos inmediatamente a los equipos de emergencia (112)</Text>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>5 - Bocas de Incendio Equipadas</Text>
                                <View style={{position:'relative'}}>
                                    <Text style={[styles.p, {width: 300}]}>La Boca de Incendio Equipada, más conocida por sus siglas (BIE), es un equipo completo de protección y lucha contra incendios, que se instala de forma fija sobre la pared y está conectado a una red de abastecimiento de agua. Incluye, dentro de un armario, todos los elementos necesarios para su uso: manguera, devanadera, válvula y lanza boquilla.</Text>
                                    <Text style={[styles.p, {width: 300}]}>La BIE es un sistema eficaz e inagotable para la protección contra incendios, que por su eficacia y facilidad de manejo, puede ser utilizado directamente por los usuarios de un edificio en la fase inicial de un fuego o incendio.</Text>
                                    <Image src={bocaIncendio} style={{ width: 150, position:'absolute', right:0, top:0 }} ></Image>
                                </View>
                                <Text style={styles.p}>Es idónea para ser instalada en lugares donde, debido a su elevada ocupación y/o tránsito de personas, se precise un sistema de extinción fácil de usar, ya que funciona con agua de la red de abastecimiento.</Text>
                                <Text style={[styles.p, styles.textoNegrita]}>¿Cómo se usa una BIE de 25 mm?</Text>
                                <Text style={styles.p}>Si necesita usar una BIE, siga estos pasos:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>1)</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Abrir la puerta del armario o romper el cristal en su caso.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>2)</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Abrir el grifo que hay en la base de la devanadera.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>3)</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Aproximarse a la zona del incendio, a la vez que se va desenrollando la manguera.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 20}]}>
                                        <Text style={{ marginHorizontal: 8 }}>4)</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Abrir el surtidor que lleva en la punta de la manguera, girándolo a derechas, y dirigir el agua a la base de las llamas haciendo un barrido de lado a lado.</Text>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center'}]}>CONSIDERACIONES</Text>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline'}]}>A) ZONAS COMUNES:</Text>
                                <Text style={styles.p}>Son los espacios compartidos por la totalidad de los vecinos. Los más habituales son los portales, escaleras, terrazas de cubierta, garajes, piscinas y viales peatonales interiores con sus patios y jardines. Estas zonas deberán estar libres de obstáculos que impidan su uso como vía de evacuación si están destinadas a ello:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Los viales que sirven de acceso a los servicios de emergencia tienen que estar señalizados, despejados y en caso de incendio, siempre habrá algún responsable para facilitarles el acceso.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Si se cuenta con hidrantes o columnas secas para el abastecimiento de agua de los servicios de bomberos, deberán estar señalizados y bien mantenidos.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>El suelo debe estar en perfecto estado para evitar caídas o resbalones.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Mantener una buena iluminación tanto en zonas interiores como exteriores.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Las escaleras deben contar con pasamanos y barandillas.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Mantener el tejado en buen estado, limpiar los desagües antes de la época de lluvias y comprobar que no hay cornisas degradadas con riesgo de caer a la vía pública.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Los conserjes y los miembros de la junta deben tener llaves de acceso a la cubierta por si los servicios de rescate necesitasen hacer uso de ella.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>No alterar los huecos de ventilación de escaleras ni los sistemas de apertura o cierre de puertas cortafuegos.</Text>
                                    </View>
                                </View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop:5}]}>B) ASCENSOR:</Text>
                                <Text style={styles.p}>Es responsabilidad de la comunidad de propietarios el cumplimiento de las revisiones por una empresa autorizada, así como el conocimiento y mantenimiento de algunas partes del ascensor.</Text>
                                <Text style={styles.p}>Cuarto de máquinas</Text>
                                <Text style={styles.p}>Está situado habitualmente en la azotea del edificio, aunque también lo podemos encontrar en la planta más baja o en los más modernos, incorporado en los raíles.</Text>
                                <Text style={styles.p}>Solo deberán manipular la maquinaria el servicio técnico o los bomberos.</Text>
                                <View style={[styles.cuadrado, {marginTop:5, marginBottom:5}]}>
                                    <Text style={[styles.p, styles.textoItalic, {fontSize:7, color:'#555'}]}>Los miembros de la junta deben tener las llaves de acceso  al  cuarto de máquinas para garantizar que los bomberos puedan acceder en caso de emergencia</Text>
                                </View>
                                <Text style={styles.p}>Foso del ascensor</Text>
                                <Text style={styles.p}>Está situado en la parte más baja del hueco del ascensor, es su final de carrera inferior. Cuenta con un soporte de hormigón y unos grandes muelles para recibir al camarín.</Text>
                                
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={styles.p}>Camarín</Text>
                                <Text style={styles.p}>Es la cabina que transporta a los pasajeros a las diferentes plantas. Los bomberos pueden abrirla desde el exterior para realizar las tareas de rescate, y aunque cuentan con llaves, se deben tener también varias copias en la comunidad.</Text>
                                <Text style={styles.p}>En caso de quedarse encerrado:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Mantener la calma.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Pulsar el botón de emergencia y llamar al 112.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Nunca se deben forzar las puertas del camarín.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Mientras actúen los equipos de rescate, los ocupantes deben situarse al fondo de la cabina.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Tras el rescate comprobar que las puertas de todas las plantas están bien cerradas.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Avisar a la empresa mantenedora.</Text>
                                    </View>
                                </View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop:5}]}>C) CUARTO DE CONTADORES:</Text>
                                <Text style={styles.p}>Situado habitualmente en la planta baja o semisótano de la comunidad, es uno de los focos de riesgo de incendio al que se debe prestar especial atención, debido a su situación, el humo generado en el incendio ascenderá por la escalera y hueco del ascensor.</Text>
                                <Image src={cuartoContadores} style={{ width: 180, textAlign:'center', marginHorizontal: 'auto', marginBottom:20, marginTop:20 }} ></Image>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Mantenerlo limpio.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>No acumular objetos, recordar que no es un trastero.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Mantener bien cerrada la puerta del cuarto.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Instalar un extintor de CO2 junto con una alarma de incendios.</Text>
                                    </View>
                                </View>
                                
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop:5}]}>D) TRASTEROS:</Text>
                                <Text style={styles.p}>Los trasteros suelen situarse en la planta semisótano de los edificios de viviendas, lo que implica un riesgo añadido, ya que en caso de incendio, si las puertas que sectorizan la zona no están cerradas, el humo y el fuego acabarán por invadir el resto del edificio.</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>No acumular productos inflamables que generen gases como disolventes o aerosoles.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Vigilar el buen estado de las instalaciones de protección contra incendios.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Mantener las puertas bien cerradas.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>No dar un uso diferente al de almacenamiento.</Text>
                                    </View>
                                </View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop:5}]}>E) GARAJES:</Text>
                                <Text style={styles.p}>Los garajes más modernos cuentan con sistemas de protección contra incendios, los cuales hay que preocuparse de tener bien mantenidos como propietarios. Entre otros tienen: detectores de incendio, sistemas de evacuación de humos, extintores portátiles, bocas de incendio equipadas, puertas resistentes al fuego e incluso muros cortina que sectorizan de forma automática el garaje para evitar la propagación del incendio de una planta a otra.</Text>
                                <Text style={styles.p}>Si el garaje es antiguo y no cuenta con estos equipamientos de protección, valorar seriamente su instalación. La simple colocación de unas puertas cortafuegos que separen la zona de garaje del resto de la vivienda, pueden evitar la propagación del humo del incendio al resto del edificio.</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Instalar extintores portátiles con los que poder acometer un pequeño conato de incendio.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Mantener siempre cerradas las puertas de acceso a zonas peatonales.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>No utilizarlo como almacén o taller de reparaciones.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>En caso de incendio, no arriesgar la vida por tratar de sacar del garaje el coche. Avisar al 112 y persuadir al resto de vecinos para que no entren.</Text>
                                    </View>
                                </View>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop:5}]}>F)	LOCALES COMERCIALES:</Text>
                                <Text style={styles.p}>Suelen estar situados a pie de calle, aunque también se pueden encontrar actividades empresariales en pisos habilitados como clínicas u oficinas. Muchas de estas actividades deben contar con un Plan de Autoprotección o Emergencia, dependiendo de sus características, que deberán estar actualizados. Estos planes tienen en cuenta el tamaño y la actividad de la empresa, así como la posible presencia de personas ajenas a la misma. Deben analizar las posibles situaciones de emergencia y adoptar las medidas necesarias en materia de primeros auxilios, lucha contra incendios y evacuación de los trabajadores, designando para ello al personal encargado de poner en práctica estas medidas y comprobando periódicamente, en su caso, su correcto funcionamiento.</Text>
                                <Text style={styles.p}>- Solicitar el plan y difundirlo entre todos los vecinos. Participar en los simulacros anuales, si procede</Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center'}]}>PREVENCIÓN DE RIESGOS LABORALES EN ZONAS COMUNES</Text>
                                <Text style={styles.p}>Si en una comunidad existen trabajadores asalariados es necesario: elaborar un plan de prevención, evaluar los riesgos existentes, planificar las actividades preventivas, proporcionar el servicio de vigilancia de la salud, disponer de equipos de trabajo, formar e informar al trabajador, adoptar las medidas de emergencia y organizar los recursos para las actividades preventivas.</Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center'}]}>PROPUESTAS</Text>
                                <Text style={styles.p}>Medidas preventivas </Text>
                                <Text style={styles.p}>Se preparará un programa anual con el correspondiente calendario, que comprenderá las siguientes actividades:</Text>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>a)</Text>
                                        <Text style={{ width:280, fontSize:9 }}>Mantenimiento de las instalaciones susceptibles de provocar un incendio</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>b)</Text>
                                        <Text style={{ fontSize:9 }}>Mantenimiento de las instalaciones de detección, alarma y extinción de incendios según lo establecido en la Norma Básica de la Edificación/ CTE.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>c)</Text>
                                        <Text style={{ fontSize:9 }}>Inspecciones de Seguridad</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Investigación de siniestros.</Text>
                                <Text style={styles.p}>Si se produjera una emergencia en la comunidad, se investigarán las causas que posibilitaron su origen, propagación y consecuencias, se analizará el comportamiento de las personas y los equipos de emergencia y se adoptarán las medidas correctoras precisas.</Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <View style={[styles.h3, styles.lines]}>
                                    <Text style={[styles.textoNegrita, {textAlign:'center', display:'block'}]}>RECOMENDACIONES DE ACTUACIÓN EN CASO DE ACCIDENTE</Text>
                                    <Text style={[styles.p, {fontSize:8, textAlign:'center', display:'block'}]}>(para proveedores con acceso a la comunidad)</Text>
                                </View>
                                <View style={styles.ul}>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>a)</Text>
                                        <Text style={{ fontSize:9 }}>En caso de accidente leve de un trabajador que requiera atención médica: Realizar la primera cura con el material sanitario disponible en el botiquín portátil de la empresa y trasladar al accidentado al Centro Asistencial de la Mutua o a un Centro de urgencias.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>b)</Text>
                                        <Text style={{ fontSize:9 }}>En caso de accidente grave de un trabajador: Trasladar urgentemente al trabajador al Hospital más cercano.</Text>
                                    </View>
                                    <Text style={styles.p} >En los dos casos de accidente se tiene que activar el sistema de emergencia (PAS: Proteger, Avisar, Socorrer). Al comunicarse, se tiene que dar un mensaje preciso sobre:</Text>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Lugar donde ha sucedido el accidente.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Tipo de accidente (intoxicación, quemada térmica o química, herida, etc.).</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Número de víctimas.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Estado aparente de las víctimas (conciencia, sangran, respiran, etc.).</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Si el aviso se realiza por teléfono, no colgar antes de que el interlocutor lo haya autorizado, ya que puede necesitar otras informaciones complementarias.</Text>
                                    </View>
                                    <View style={[styles.ol, {paddingLeft: 10}]}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{ fontSize:9 }}>Se dispondrá de una persona que reciba y acompañe a los servicios de socorrismo con la finalidad de guiarlos rápidamente hasta el lugar del accidente.</Text>
                                    </View>
                                </View>
                                <View>
                                    <Text style={[styles.p, styles.textoNegrita]}>1 - Salpicaduras en los ojos y sobre la piel.</Text>
                                    <Text style={styles.p}>Sin perder un instante lavarse con agua durante 10 o 15 minutos, empleando si es necesario la ducha de los vestuarios (o de seguridad si existiesen); quitarse la ropa y objetos previsiblemente mojados del producto.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>2 - Mareo o pérdida de conocimiento debido a una fuga tóxica que persista.</Text>
                                    <Text style={styles.p}>Nos tenemos que proteger del medio con un aparato respiratorio antes de aproximarse a la víctima. Trasladar al accidentado a un lugar seguro y dejarlo recostado sobre el lado izquierdo. Aflojar la ropa y todo lo que pueda oprimirlo, verificando si ha perdido el conocimiento y si respira; tomar el pulso. Activar el PAS, y practicar si fuese necesario, la reanimación cardiorespiratoria. No subministrar alimentos, bebidas ni productos para activar la respiración.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>3 - Electrocución.</Text>
                                    <Text style={styles.p}>La electrocución o choque eléctrico tiene lugar cuando, por un contacto eléctrico directo o indirecto, una persona pasa a formar parte de un circuito eléctrico, pasando por su organismo una determinada intensidad eléctrica durante un tiempo. La intensidad depende del voltaje y de la resistencia del organismo, que a su vez, depende del camino recorrido y de factores fisiológicos. Las acciones para llevar a cabo cuando alguno queda atrapado por la corriente son las siguientes.</Text>
                                    <View style={styles.ul}>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Cortar la alimentación eléctrica del aparato causante del accidente antes de acercarse a la víctima para evitar otro accidente y retirar al accidentado.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Activar el PAS y practicar, si es necesario, la reanimación cardiorespiratoria.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No suministrar alimentos, bebidas ni productos para activar la respiración.</Text>
                                        </View>
                                    </View>
                                    <Text style={[styles.p, styles.textoNegrita]}>4 - Quemaduras térmicas.</Text>
                                    <Text style={styles.p}>Las instrucciones básicas por el tratamiento de quemaduras térmicas son: lavar abundantemente con agua fría para enfriar la zona quemada, no quitarse la ropa pegada en la piel, tapar la parte quemada con la ropa limpia. Se tiene que acudir siempre al médico, aunque la superficie afectada y la profundidad sean pequeñas. Son recomendaciones específicas en estos casos.</Text>
                                    <View style={styles.ul}>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No aplicar nada en la piel (ni pomada, ni grasa, ni desinfectante).</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No enfriar demasiado al accidentado.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No dar bebidas ni alimentos.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No romper las ampollas.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No dejar solo al accidentado.</Text>
                                        </View>
                                    </View>
                                    <Text style={[styles.p, styles.textoNegrita]}>5 - Intoxicación digestiva.</Text>
                                    <Text style={styles.p}>Se tiene que tratar en función del contenido del tóxico ingerido, por lo cual se tiene que disponer de información a partir de la etiqueta y de la ficha de seguridad. La actuación inicial está encaminada a evitar la acción directa del tóxico mediante su neutralización o evitar su absorción por el organismo. Posteriormente, o en paralelo, se tratan los síntomas causados por el tóxico. Es muy importante la atención médica rápida, lo cual requerirá el traslado del accidentado, que debe llevarse a cabo en condiciones adecuadas. No se tiene que provocar el vómito cuando el accidentado presente convulsiones o esté inconsciente, o bien se trata de un producto corrosivo o volátil.</Text>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center', display:'block'}]}>RECOMENDACIONES DE ACTUACIÓN EN CASO DE INCENDIOS</Text>
                                <View>
                                    <Text style={[styles.p, styles.textoNegrita]}>Como evacuar un edificio en llamas.</Text>
                                    <View style={styles.ul}>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>El último en salir de la casa cerrará la perta (nunca con llave), comprobando que no quede nadie dentro. El cerrar las puertas dificulta el esfuerzo de rescate y de búsqueda por parte del departamento de Bomberos.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Dirigirse hacia la salida más cercana.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No utilizar los ascensores bajo ningún concepto.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Manténgase cerca del suelo para evitar humos y gases tóxicos. La mejor atmosfera respirable se encuentra a nivel del suelo, así que circule a cuatro patas.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Si es posible cubrirse la boca y la nariz con un trapo o pañuelo húmedo para facilitar la respiración.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Si trabaja en un edificio con diferentes pisos, las escaleras serán su primera ruta de escapada. Una vez en la escalera, proceda hacia el primer piso, y nunca se dirija hacia un piso más alto (salvo petición del equipo de emergencia).</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Una vez fuera del edificio, diríjase al área preestablecida por tal de facilitar el recuento de personal.</Text>
                                        </View>
                                    </View>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué hacer si se está atrapado en un edificio en llamas?</Text>
                                    <Text style={styles.p}>Si está probando de escapar de un fuego, nunca debe abrir ninguna puerta cerrada sin palparla. Utilice la parte posteriormente de la mano para evitar quemaduras. Si la puerta está caliente, busque otra salida. Si no existe otra salida, selle las grietas alrededor de las puertas y ventanas con lo que tenga a mano.</Text>
                                    <Text style={styles.p}>Si respira con dificultad, pruebe de ventilar la habitación y utilice un pañuelo mojado.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué hacer si una persona está envuelta en llamas?</Text>
                                    <Text style={styles.p}>Si usted se encuentra envuelta en llamas:</Text>
                                    <View style={styles.ul}>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Deténgase.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Tírese al suelo.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Ruede sobre sí mismo.</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.p}>Si un compañero está envuelto en llamas, se tendrá que apagar envolviéndolo con una manta, alfombra, abrigo.</Text>
                                    <View style={styles.ul}>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Los aparatos eléctricos no indispensables se deben apagar y desconectar si no se van a utilizar.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No sobrecargar los enchufes.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Evitar la acumulación de materias y productos inflamables y alejarlos de toda fuente de calor.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Colocar los productos de limpieza inflamables en los armarios o locales cerrados con llave y ventilados.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Mantener cerrados los cubos de basura.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Es necesario saber distinguir los diferentes tipos de extintores y la forma de utilizarlos.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Si se han utilizado, deberán volverse a recargar de inmediato.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>En general, si se produce un incendio no se debe intentar apagar sólo por una persona, salvo que sea un conato y se esté seguro de que no ofrece ningún tipo de riesgo.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Los dispositivos de extinción de incendios (extintores) deben ser fácilmente visibles. No colocar obstáculos delante de ellos que dificulten la accesibilidad.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>En caso de incendio, si el humo es abundante, camine agachado. El humo es más ligero que el aire y tenderá a concentrarse en la parte superior.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No abrir las puertas de golpe, ni de frente.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Si la puerta de un local está caliente, significa que el fuego está próximo. Se evitará acercarse a ella.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No se debe intentar recoger objetos valiosos o efectos personales.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>No se deberá realizar ninguna operación que entrañe peligro de incendio.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Es recomendable que el edificio esté equipado de sistema de detección de incendios y equipos de protección contra incendios adecuados para poder llevar a cabo una actuación eficaz.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Todos los equipos de protección contra incendios deben estar señalizados señalizados, así como las salidas de emergencia y su dirección.</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center', display:'block'}]}>BIBLIOGRAFÍA</Text>
                                <View>
                                    <View style={styles.ul}>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Información obtenida de “guía de prevención de incendios y otros riesgos, Comunidades de vecinos” elaborada por APTB (Asociación Profesional de Técnicos de Bomberos).</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>INSST.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Real Decreto 513/2017, de 22 de mayo, por el que se aprueba el Reglamento de instalaciones de protección contra incendios.</Text>
                                        </View>
                                        <View style={[styles.ol, {paddingLeft: 10}]}>
                                            <Text style={{ marginHorizontal: 8 }}>•</Text>
                                            <Text style={{ fontSize:9 }}>Código técnico de edificación.</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View>
                                <Text style={[styles.h3, styles.textoNegrita, {textAlign:'center', display:'block'}]}>TELÉFONOS DE UTILIDAD</Text>
                                <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColHeaderSOS}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={styles.tableColHeaderSOS}> 
                                            <Text style={styles.tableCell}>TELÉFONO</Text> 
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColSOS}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {fontSize:12}]}>COORDINACIÓN DE EMERGENCIAS</Text> 
                                        </View> 
                                        <View style={styles.tableColSOS}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {fontSize:12}]}>112 </Text> 
                                        </View>    
                                    </View> 
                                </View>
                                <View>
                                    <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop: 20}]}>DIRECTORIO DEL PERSONAL DEL CENTRO DE TRABAJO</Text></View>
                                    <Text style={[styles.p, styles.textoNegrita, {fontSize: 8}]}>Centro de trabajo: {props.params.idCentroTrabajo ? hojaVisita.workcenter?.razonSocial : hojaVisita.propertie?.direccion}</Text>
                                    <View style={styles.line}>
                                        <Text style={[styles.p, styles.textoNegrita, {fontSize: 8, marginRight: 150}]}>Persona de contacto: {hojaVisita.propertie?.nombreContacto}</Text>
                                        <Text style={[styles.p, styles.textoNegrita, {fontSize: 8}]}>Teléfono: {hojaVisita.propertie?.telefonoContacto}</Text>
                                    </View>
                                    <Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop: 20}]}>EMPRESAS DE MANTENIMIENTO</Text>
                                    <View style={styles.table}>
                                        {proveedores?.length !== 0 ?
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tableColHeaderProv}> 
                                                    <Text style={styles.tableCell}>SERVICIO</Text> 
                                                </View> 
                                                <View style={styles.tableColHeaderProv}> 
                                                    <Text style={styles.tableCell}>NOMBRE</Text> 
                                                </View> 
                                                <View style={styles.tableColHeaderProv1}> 
                                                    <Text style={styles.tableCell}>TELÉFONO</Text> 
                                                </View> 
                                            </View>  
                                        :
                                            <View style={styles.tableRow}> 
                                                <View style={styles.tableColHeaderNM}> 
                                                    <Text style={styles.tableCell}>NO TIENE PROVEEDORES</Text> 
                                                </View> 
                                            </View>
                                        }                                             
                                            
                                        {proveedores?.length !== 0 ?
                                            proveedores?.map((proveedor, i) => {
                                                return (
                                                    <View key={i} style={styles.tableRow}> 
                                                        <View style={styles.tableCol}> 
                                                            <Text style={styles.tableCell}>{proveedor?.serviceSupps?.find( serv => serv.id === proveedor?.r_propSupp?.service) !== undefined ? proveedor?.serviceSupps?.find( serv => serv.id === proveedor?.r_propSupp?.service).nombre : null}</Text> 
                                                        </View>
                                                        <View style={styles.tableCol}> 
                                                            <Text style={styles.tableCell}>{proveedor.razonSocial}</Text> 
                                                        </View>
                                                        <View style={styles.tableCol1}> 
                                                            <Text style={styles.tableCell}>{proveedor.telefonoContacto}</Text> 
                                                        </View>
                                                    </View>
                                                )
                                            })
                                        : 
                                            <></>
                                        }
                                            
                                    </View>
                                    <View ><Text style={[styles.p, styles.textoNegrita, {fontSize: 9, textDecoration:'underline', marginTop: 20}]}>TÉCNICO ASOCIADO</Text></View>
                                    <Text style={styles.p}>
                                        El informe ha sido realizado por <Text style={styles.bold}>{hojaVisita.technician?.nombre} {hojaVisita.technician?.apellidos}</Text>, técnico en prevención de riesgos laborales  
                                    </Text>
                            </View>
                        </Page>
                        <Page style={styles.body} orientation="landscape">
                            <View style={styles.pageHeader} fixed>
                                <View>
                                    <Image src={LogoLAE} style={{marginTop: 10, width:'15%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={[styles.h3, styles.lines]}>
                                <Text style={[styles.textoNegrita, {textAlign:'center', display:'block'}]}>ANEXO I: REVISIÓN TRIMESTRAL DE EXTINTORES</Text>
                                <Text style={[styles.p, {fontSize:8, textAlign:'center', display:'block'}]}>(mantenimiento por parte del usuario o titular de la instalación)</Text>
                            </View>
                            <View style={styles.line}>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 8, marginRight: 150}]}>Fecha: </Text>
                                <Text style={[styles.p, styles.textoNegrita, {fontSize: 8}]}>Realizado Por:</Text>
                            </View>
                            <View style={styles.table}> 
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableColHeaderExt, {width:'3.09%', position:'relative'}]}> 
                                            <Text style={[styles.tableCellExt, styles.textoNegrita, styles.textoGirado, {position:'absolute', margin:0, bottom:15, height:25, width:40}]}>Extintor nº</Text> 
                                        </View> 
                                        <View style={[styles.tableColHeaderExt, {width:'12.09%'}]}> 
                                            <View style={styles.lines}>
                                                <Text style={[styles.tableCellExt, styles.textoNegrita]}>Tipo</Text> 
                                                <Text style={{fontSize:7}}>(ej. ABC, CO2)</Text> 
                                            </View>
                                        </View>
                                        <View style={[styles.tableColHeaderExt, {width:'12.09%', margin: 0, padding:0}]}> 
                                            <View style={styles.lines}>
                                                <Text style={[styles.tableCellExt, styles.textoNegrita]}>Ubicación</Text> 
                                                <Text style={{fontSize:7, margin: 0, padding:0}}>(ej. 1planta, 2planta, portal...)</Text> 
                                            </View>
                                        </View> 
                                        <View style={[styles.tableColHeaderExt, {width:'4.09%'}]}> 
                                            <Text style={[styles.tableCellExt, styles.textoNegrita, styles.textoGirado, {position:'absolute', margin:0, bottom:15, height:25, width:50}]}>Señalización</Text> 
                                        </View>
                                        <View style={[styles.tableColHeaderExt, {width:'4.09%'}]}> 
                                            <Text style={[styles.tableCellExt, styles.textoNegrita, styles.textoGirado, {position:'absolute', margin:0, bottom:15, height:25, width:50}]}>Accesibilidad</Text> 
                                        </View> 
                                        <View style={styles.tableColHeaderExt}> 
                                            <View style={[styles.lines, styles.tableCellExt]}>
                                                <Text style={[styles.textoNegrita, {fontSize:8}]}>Estado</Text> 
                                                <Text style={[styles.textoNegrita, {fontSize:8}]}>aparente de</Text> 
                                            </View>
                                        </View>
                                        <View style={[styles.tableColHeaderExt, {width:'6.09%'}]}> 
                                            <View style={styles.lines}>
                                                <Text style={[styles.tableCellExt, styles.textoNegrita]}>Precinto</Text> 
                                                <Text style={[styles.textoNegrita, {fontSize:8}]}>pasador</Text> 
                                            </View>
                                        </View> 
                                        <View style={styles.tableColHeaderExt}> 
                                            <View style={styles.lines}>
                                                <Text style={[styles.tableCellExt, styles.textoNegrita]}>Presión</Text> 
                                                <Text style={{fontSize:7}}>(dentro de la zona verde)</Text> 
                                            </View>
                                        </View>
                                        <View style={styles.tableColHeaderExt}> 
                                            <View style={styles.lines}>
                                                <Text style={[styles.tableCellExt, styles.textoNegrita]}>Boquilla</Text> 
                                                <Text style={[styles.textoNegrita, {fontSize:8}]}>válvula</Text>  
                                            </View>
                                        </View> 
                                        <View style={[styles.tableColHeaderExt, {width:'3.09%'}]}> 
                                            <Text style={[styles.tableCellExt, styles.textoNegrita, styles.textoGirado, {position:'absolute', bottom:15}]}>Caja</Text> 
                                        </View>
                                        <View style={[styles.tableColHeaderExt1, {width:'28.01%', display:'flex', alignItems:'center'}]}> 
                                            <Text style={[styles.tableCellExt, styles.textoNegrita]}>OBSERVACIONES</Text> 
                                        </View>
                                    </View>
                                    {rows.map((_, index) => (
                                        <View key={index} style={styles.tableRow}> 
                                            <View style={[styles.tableColExt, {width:'3.09%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View> 
                                            <View style={[styles.tableColExt, {width:'12.09%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View>    
                                            <View style={[styles.tableColExt, {width:'12.09%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View> 
                                            <View style={[styles.tableColExt, {width:'4.09%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View>    
                                            <View style={[styles.tableColExt, {width:'4.09%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View> 
                                            <View style={styles.tableColExt}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View>    
                                            <View style={[styles.tableColExt, {width:'6.09%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View> 
                                            <View style={styles.tableColExt}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View>    
                                            <View style={styles.tableColExt}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View> 
                                            <View style={[styles.tableColExt, {width:'3.09%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View>
                                            <View style={[styles.tableColExt, {width:'28.01%'}]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}></Text> 
                                            </View> 
                                        </View> 
                                    ))}
                                </View>
                            <Image src={estado} style={{width:100}} ></Image>
                        </Page>
                    </Document>
                </PDFHandler>
            }
        </>
        
    )
}
 
export default withRouter(PlanEmergenciaRender);