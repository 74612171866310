import React, { useState, useEffect, useContext } from 'react'
import noVisitada from "../icons/noVisitada.svg"
import { withRouter } from "../providers/withRouter";
import Swal from 'sweetalert2';
import { Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { UsuarioContext } from "../providers/userContext";
import { formatoFechas, formatoFechasEs } from '../functions/funciones';
import { noRealizada } from '../services';
import { toast } from "react-toastify";
import { FaEye } from "react-icons/fa";
const theme = createTheme();



const NoVisitado = (props) => {

  const user = useContext(UsuarioContext)
  // let finca = props.finca  
  const [noRealizado, setNoRealizado] = useState(false);
  const [ObsTexto, setObsTexto] = useState('');
  const [recargar, setRecargar] = useState(false);
  const [finca, setFinca] = useState(props.finca);
  const [visitado, setVisitado] = useState(false);
  
  useEffect(() => {
    if (props.tipo === 'finca') {
      setVisitado(finca.detailsCae[0]?.visitada);
      if (finca.detailsCae[0]) {
        if (finca.detailsCae[0]?.unRealized !== false && finca.properties.nif === props.nif) {
          setNoRealizado(finca.detailsCae[0]?.unRealized);
          setObsTexto(formatoFechasEs(finca.detailsCae[0]?.unrealizedDate) + " - " + finca.detailsCae[0]?.observaciones)
        }
      }
    }

    if (props.tipo === 'centroTrabajo') {
      setVisitado(finca.workSales[0]?.detailsCae?.[0]?.visitada);
      if (finca.id === props.nif && finca.workSales[0]?.detailsCae[0]?.unRealized !== false) {
        setNoRealizado(finca.workSales[0]?.detailsCae[0]?.unRealized)
        setObsTexto(formatoFechasEs(finca.workSales[0]?.detailsCae[0]?.unrealizedDate) + " - " + finca.workSales[0]?.detailsCae[0]?.observaciones)
      }
    }
  }, [finca, recargar])


  const handleCallback = () => {
    setRecargar(!recargar);
    setNoRealizado(true)
  };

  const comprobar = async () => {

    handleAdd().then((resultado) => {
      handleCallback(resultado)
    })
  };

  const handleAdd = () => {
    return new Promise(async (resolve, reject) => {
      let toastInfo = undefined
      try {
        Swal.fire({
          title: 'Motivo de la no visita',
          html: `<select id="motivoTexto" style="width: 373px; height: auto; font-size:14px; padding:10px;" className="swal2-input" >
                    <option>No nos dejan entrar </option>
                    <option>Garaje sin llave</option>
                    <option>Finca no localizada </option>
                    <option>Otros</option>
                    </select>`,
          confirmButtonText: 'Guardar',
          confirmButtonColor: '#05811b',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#d33',
          showDenyButton: noRealizado ? true: false,
          denyButtonText: `Quitar motivo no visita`,
          denyButtonColor:`#05811b`,
          focusConfirm: false,

          preConfirm: () => {
            toastInfo = toast.info("Guardando datos", { autoClose: false });
            const texto = Swal.getPopup().querySelector('#motivoTexto').value
            if (!texto) {
              Swal.showValidationMessage(`insertar texto`)
            }
            return { texto: texto }
          }


        }).then(async (result) => {
          if (result.isConfirmed) {
            const datos = {
              unRealized: true,
              unrealizedDate: formatoFechas(new Date()),
              observaciones: result.value.texto
            }

            await noRealizada(finca.id, datos).then(() => {
              toast.update(toastInfo, {
                render: "datos guardados correctamente",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
            })
            let fincaUpdated = {
              ...finca,
              detailsCae: finca.detailsCae.map((detalle, index) =>
                index === 0 ? { ...detalle, ...datos } : detalle
              )
            }
            setFinca(fincaUpdated)
          }
          else if (result.isDenied) {
            
            const datos = {
              unRealized: false,
              unrealizedDate: null,
              observaciones: null
            }

            await noRealizada(finca.id, datos).then(() => {
              toast.update(toastInfo, {
                render: "Actualizando cambios",
                type: toast.TYPE.SUCCESS,
                autoClose: true
              });
              resolve(true);
            })
            let fincaUpdated = {
              ...finca,
              detailsCae: finca.detailsCae.map((detalle, index) =>
                index === 0 ? { ...detalle, ...datos } : detalle
              )
            }
            setFinca(fincaUpdated)
            setNoRealizado(false);
            
          }
        })
      } catch (error) {
        reject(error);
      }
    })
  }
  const handleAdd1 = () => {
    let toastInfo = undefined
    Swal.fire({
      title: 'Motivo de la no visita',
      // html: `<textarea id="motivoTexto" style="width: 373px; height: 210px; font-size:11px; padding:10px;" className="swal2-input" ></textarea>`,
      html: `<select id="motivoTexto" style="width: 373px; height: auto; font-size:14px; padding:10px;" className="swal2-input" >
                <option>No nos dejan entrar </option>
                <option>Garaje sin llave</option>
                <option>Finca no localizada </option>
                <option>Otros</option>
                </select>`,
      confirmButtonText: 'Guardar',
      confirmButtonColor: '#05811b',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: '#d33',
      focusConfirm: false,
      /* didOpen: () => {
        const textarea = document.getElementById('motivoTexto');
        textarea.value = initialText;
        textarea.setSelectionRange(initialText.length, initialText.length);
        textarea.addEventListener('keydown', handleKeydown);
        textarea.focus();
 
        // Asignar el elemento textarea al useRef
        textareaRef.current = textarea;
      },
      willClose: () => {
        const textarea = textareaRef.current;
        if (textarea) {
          textarea.removeEventListener('keydown', handleKeydown);
          textareaRef.current = null; // Limpiar la referencia al elemento
        }
      }, */
      preConfirm: () => {
        toastInfo = toast.info("Guardando datos", { autoClose: false });
        const texto = Swal.getPopup().querySelector('#motivoTexto').value
        if (!texto) {
          Swal.showValidationMessage(`insertar texto`)
        }
        return { texto: texto }
        /* const textarea = textareaRef.current;
        if (textarea) {
          const inputValue = textarea.value;
          // const texto = Swal.getPopup().querySelector('#motivoTexto').value
          if (!inputValue) {
            Swal.showValidationMessage(`insertar texto`)
          }
        return {texto: inputValue }
        } */
      }
    }).then(async (result) => {
      if (result.value !== undefined) {

        const datos = {
          unRealized: true,
          unrealizedDate: formatoFechas(new Date()),
          observaciones: result.value.texto
        }
        await noRealizada(finca.id, datos).then(() => {
          toast.update(toastInfo, {
            render: "datos guardados correctamente",
            type: toast.TYPE.SUCCESS,
            autoClose: true
          });
        })


        setRecargar(!recargar);
        setNoRealizado(true);
      }
    })
  }


  return (
    <>
      {(visitado === undefined || visitado === null || visitado === false) && (
        <>
          {user.rol === 'TECH' && !noRealizado && (
            <button type="button" className='btn' onClick={comprobar}>
              <FaEye />
            </button>
          )}
          {noRealizado && (
            <>
              <button type="button" style={{padding: 0}} className='btn' onClick={comprobar}>
                <ThemeProvider theme={theme}>

                  <Tooltip title={ObsTexto} >
                    <img src={noVisitada} alt="No visitado" className="tableIcon" />
                  </Tooltip>
                </ThemeProvider>
              </button>
            </>
          )}
        </>
      )}
    </>
  );
}

export default withRouter(NoVisitado);