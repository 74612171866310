import React, { useState, useEffect, useContext } from "react";
import { LinearProgress, Box, styled, linearProgressClasses } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { AaffListPropertie, getSalesPropsIds } from "../../../services";
import DataTable from "../../../components/Tables/DataTable";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { Link } from "react-router-dom";
import FiltrosComunidades from '../../../components/filtros/filtrosComunidades';
import { useLocation, useNavigate } from "react-router-dom";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import { IoDocumentText } from "react-icons/io5";
import { estadoServicio } from "../../../functions/funciones";

const BorderLinearProgress = styled(LinearProgress)((props) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#eee",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: props.value,
    backgroundColor:
      props.value < 25 ? "#FF5252" : props.value < 75 ? "#fcba03" : "#7bc977",
  },
}));

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={2}>
        <BorderLinearProgress {...props} variant="determinate" />
      </Box>
      <Box minWidth={35}>{`${Math.round(props.value * 100) / 100}%`}</Box>
    </Box>
  );
}

const Comunidades = (props) => {
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();

  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);

  const [show, setShow] = useState(false)
  // const [propertiesCae, setPropertiesCae] = useState([])
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const user = useContext(UsuarioContext)
  console.log("user",user.nif);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  // useEffect(() => {
  //   fetchData({ offset: 1, limit: limite, filtros: busqueda });
  // }, [recargar, limite, busqueda]);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order, orderBy } = {}) => {

    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy);

    const payload = {
      filtros,
      params: { page: offset, limit, order, orderBy },
    };

    try {
      setLoading(true);
      const response = await AaffListPropertie(payload);

      //Asier
      // console.log("respuesta",response);
      // const ids = response ? await getSalesPropsIds(response?.id) : []

      // const resultado = response?.properties?.filter(item => ids.includes(item.id) && item.status === true);

      // setPropertiesCae(undefined);

      const { meta, data } = response;
      console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    const formattedData = []
    data.map((row) => {
      const fechaRenovacion = new Date(row.fechaRenovacion);
      const fechaCreacion = new Date(row.createdAt);
      const hoy = new Date();
      // const diferenciaMeses = fechaRenovacion ? (hoy.getFullYear() - fechaRenovacion.getFullYear()) * 12 + (hoy.getMonth() - fechaRenovacion.getMonth()) : (hoy.getFullYear() - fechaCreacion.getFullYear()) * 12 + (hoy.getMonth() - fechaCreacion.getMonth());
      // const fr = row.fechaRenovacion ? `${row.nif} row.fechaRenovacion ${diferenciaMeses}` : `${row.nif} row.createdAt ${diferenciaMeses}`
      const sale_cae = row?.sales?.find(s => [1, 3, 6, 7].includes(s.servp_id)) || null;
      const sale_lopd = row?.sales?.find(s => [2, 4].includes(s.servp_id)) || null;
      formattedData.push({
        comunidad: row.razonSocial,
        nif: row.nif,
        codigoPostal: row.codigoPostal,
        direccion: row.direccion,
        municipio: row.municipio,
        provincia: row.provincia,
        ultimaVisita: row.visitSheets[0]?.createdAt ? new Date(row.visitSheets[0]?.createdAt).getFullYear() : "" ,
        estado: row.porcentajeDocs === undefined ? 0 : row.porcentajeDocs,
        // caducada: diferenciaMeses > 13 ? true : false,
        saleCae_id: sale_cae?.id || null,
        saleLopd_id: sale_lopd?.id || null,
        estadoCae: sale_cae ? sale_cae.estado_id === null ? 1 : sale_cae.estado_id : "No contratada",
        estadoLopd: sale_lopd ? sale_lopd.estado_id === null ? 1 : sale_lopd.estado_id : "No contratada",
      })
    });
    return formattedData;
  };

  const comunidadesRows = [
    {
      id: "comunidad",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
        <Link to={`/comunidades/${row.nif}`}>{data}</Link>
      ),
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "CÓDIGO POSTAL",
    },
    {
      id: "direccion",
      numeric: false,
      disablePadding: false,
      label: "DIRECCIÓN",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    // {
    //   id: "provincia",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "PROVINCIA",
    // },
    {
      id: "ultimaVisita",
      numeric: false,
      disablePadding: false,
      label: "Última visita",
    },
    {
      id: "estadoCae",
      numeric: false,
      disablePadding: false,
      label: "ESTADO CAE",
      overrideFunc: (data, row) => (
        !row.isWorkcenter ?
            <div className="activoSelect">
                <span style={{ color: data === 1 || data == null ? "#3eae3e"  : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                  {data === "No contratada" ? data : estadoServicio(data)}
                </span>
            </div>
        : ""
      ),
    },
    {
      id: "estadoLopd",
      numeric: false,
      disablePadding: false,
      label: "ESTADO LOPD",
      overrideFunc: (data, row) => (
          !row.isWorkcenter ?
              <div className="activoSelect">
                  <span style={{ color: data === 1 || data == null ? "#3eae3e"  : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                  {data === "No contratada" ? data : estadoServicio(data)}
                  </span>
              </div>
          : ""
      ),
    },
    {
      id: "estado",
      numeric: false,
      disablePadding: false,
      label: "ESTADO ACTUAL",
      overrideFunc: (data, row) => (
        <LinearProgressWithLabel value={data} variant="determinate" />
      ),
    },
  ];

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
    // fetchData({ offset: 1, limit: limite, filtros: filtros });
  }

  return (
    <>
      <BreadCrumb
        rutas={[{ path: `/aaff/${props.params.nif}/comunidades`, label: "Comunidades" }]}
        style={{ margin: '0 10px' }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">COMUNIDADES</div>
        </div>
      </div>
      <div className="pageContent tableContainer">


        <div style={{ display: "flex", justifyContent: "space-between", alignItems: 'center', paddingLeft: 10, paddingRight: 10, marginTop: 20 }}>
          <div className="bigBtn">
            <Link to={`/doc-render/${user.nif}/situacion-comunidades`} target={"_blank"}>
              <IoDocumentText alt="Informe comunidades" />
              <span style={{ marginLeft: 15 }}>Informe de comunidades</span>
            </Link>
          </div>

        </div>
        <FiltrosComunidades onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.meta.current_page}
                meta={data.meta}
                totalItems={data.meta.total}
                totalPages={data.meta.totalPages}
                nextPage={data.meta.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay fincas"}
                // propCaducada={true}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default withRouter(Comunidades);
