import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";

export const UsuarioContext = React.createContext();

export const UsuarioProvider = ({ children }) => {
  const [user, setUser] = useState(localStorage.getItem("token"));
  const location = useLocation();

  const checkToken = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded.exp * 1000 > Date.now()) {
        const ownToken = decoded?.data?.ownToken;
        let ownData;
        if (ownToken) {
          const {data: ownDecodedData, ...restDecodedData} = jwt_decode(decoded?.data?.ownToken);
          ownData = {...restDecodedData, ...ownDecodedData, token: ownToken};
        }
        
        const datos = {
          exp: decoded?.exp,
          iat: decoded?.iat,
          id: decoded?.data?.id,
          user_id: decoded?.data?.user_id,
          nif: decoded?.data?.nif,
          nombre: decoded?.data?.nombre,
          apellidos: decoded?.data?.apellidos,
          email: decoded?.email,
          rol: decoded?.rol,
          gruposServicios: decoded?.data.gruposServicios,
          servicios: decoded?.data.servicios,
          ownData
        }
        if(decoded?.rol === "AAFF"){
          datos.tecnico = decoded?.data.tecnico 
          datos.consultores = decoded?.data.consultores
        } 
        setUser(datos);
      } else {
        setUser(null);
      }
    } else {
      setUser(null);
    }
  }
  useEffect(() => {
    window.addEventListener("storage", checkToken);

      return () => {
        // When the component unmounts remove the event listener
        window.removeEventListener("storage", checkToken);
      };
  }, []);
  

  useEffect(checkToken, [location]);

  return (
    <UsuarioContext.Provider value={user}>
      {children}
    </UsuarioContext.Provider>
  );
};
