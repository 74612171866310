import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { createTec, getAllEquipos } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import provinces from "@doncicuto/es-provinces";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { verificarNif, verificarCorreo, verificarTelefono } from "../../../functions/validator/formValidator";



const NuevoTecnico = () => {

  const [tecnico, setTecnico] = useState({});
  const [equipos, setEquipos] = useState([]);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const response = await getAllEquipos();
    setEquipos(response)
  }

  function handleSubmit(data) {
    const toastInfo = toast.info("Guardando tecnico");
    let correoCorrecto = verificarCorreo(data.email);
    let nifCorrecto = verificarNif(data.nif);
    let telefonoCorrecto = verificarTelefono(data.telefono);
    let errores = false;
    const camposRequeridos = ['nif', 'email', 'nombre', 'apellidos','telefono'];

    if (Object.keys(data).length === 0) {
      toast.update(toastInfo, {
        render: "El técnico está vacío",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
      errores = true;
    } else {
      if (correoCorrecto === false && data.email != undefined) {
        toast.dismiss(toastInfo);
        toast.error("el correo introducido no es correcto");
        errores = true;
      }
      if (nifCorrecto === false && data.nif != undefined) {
        toast.dismiss(toastInfo);
        toast.error("el nif introducido no es correcto");
        errores = true;
      }
      if (telefonoCorrecto === false && data.telefono != undefined) {
        toast.dismiss(toastInfo);
        toast.error("el teléfono introducido no es correcto");
        errores = true;
      }

      const requiredFields = [ 'nif', 'email', 'apellidos','nombre'];
  
      requiredFields.forEach((field) => {
          if (!data[field]) {
              toast.error(`El campo ${field} es obligatorio`);
              errores = true;
          }
      });
      

    }

    if (!errores) {
      createTec(data)
        .then((res) => {
          if (res.status === 201) {
            toast.update(toastInfo, {
              render: "Técnico creado con éxito",
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
           window.location.replace("/tecnicos");
          }
          else if(res.status === 400){
            toast.update(toastInfo, {
              render: res.data.error,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
          else if (res.status === 403) {
            if (res.message === '"email" is required') {
              toast.update(toastInfo, {
                render: "Email obligatorio",
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            } else if (res.message === '"nif" is required') {
              toast.update(toastInfo, {
                render: "NIF obligatorio",
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            } else {
              toast.update(toastInfo, {
                render: "Ha ocurrido un error",
                type: toast.TYPE.ERROR,
                autoClose: true,
              });
            }
          } else {
            // Manejar otros posibles estados
            toast.update(toastInfo, {
              render: "Ha ocurrido un error desconocido",
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          toast.error("Error al crear el técnico");
        });
    }
    

  }
  function handleChange(evt) {
    const value = evt.target.value;
    setTecnico({
      ...tecnico,
      [evt.target.name]: value,
    });
  }

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Técnicos / <b>Nuevo</b>
      </div> */}
      <BreadCrumb rutas={[{ path: '/tecnicos', label: "Técnicos" }, { path: '/tecnicos', label: "Nuevo" }]} style={{ margin: '0 10px' }} />
      <div className="pageContent">
        <div className="gridHead">
          Crear nuevo Técnico
        </div>
        {/* <div className="titleContent">Crear nuevo Técnico</div> */}
        <form className="nuevoAAFFForm">
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20, padding: '0 20px' }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="NIF"
                name="nif"
                value={tecnico.nif}
                onChange={handleChange}
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                required
                placeholder="Email"
                name="email"
                value={tecnico.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Nombre"
                name="nombre"
                value={tecnico.nombre}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Apellidos"
                name="apellidos"
                value={tecnico.apellidos}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="tel"
                required
                placeholder="Teléfono"
                name="telefono"
                value={tecnico.telefono}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                required
                name="equipo"
                onChange={handleChange}
              >
                <option value={tecnico.equipo}>Equipo</option>
                {equipos.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>
                      {e.nombre}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                required
                name="provincia"
                onChange={handleChange}
              >
                <option value={tecnico.provincia}>Provincia</option>
                {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <option key={i} value={e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>
          <Grid style={{ display: 'flex', justifyContent: 'end', padding: 20 }}>
            <input
              type="button"
              value="Guardar y publicar"
              onClick={() => handleSubmit(tecnico)}
            ></input>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default NuevoTecnico;
