import React, { useState, useEffect } from "react";
// import { styled } from '@mui/system';
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import {getAaffSuppServ } from "../../../services";
import { formatoFechasEs } from "../../../functions/funciones";
import { Link } from "react-router-dom";
import { CircularProgress } from '@mui/material';

export default function ControlledAccordions(props) {
  const [expanded, setExpanded] = useState(false);
  const [AAFF, setAAFF] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getData()
  }, [props.proveedor])

  const getData = async () => {

    if (Object.keys(props.proveedor).length > 0) {
      const resA = await getAaffSuppServ(props.proveedor.nif);
      if(Object.keys(props.aaff).length > 0){
        resA.forEach(a=> {
          if(a.id === props.aaff.id){
            setAAFF([a])
          }
        })
      }else{
        setAAFF(resA)
      }
    }
    setIsLoading(false)
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className="root" style={{ margin: "15", position: 'relative', overflow: 'scroll', maxHeight: 750 }}>
      {isLoading ? <CircularProgress style={{ width: '40px', height: '40px', color: '#92004D', position: 'absolute', top: '50%', left: '50%', margin: '50px 0px' }} /> : 
        AAFF?.length === 0 ? <p style={{ padding: '20px'}}>No tiene comunidades</p> :
        AAFF?.map((e, i) => (
          <Accordion
            key={i}
            style={{ margin: "0" }}
            expanded={expanded === "panel" + i}
            onChange={handleChange("panel" + i)}
          >
            <AccordionSummary
              style={{ background: "rgb(251 251 251)" }}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography style={{ flex: "auto" }} className="heading">
                {e.razonSocial}
              </Typography>
            </AccordionSummary>
            <AccordionDetails key={i}>
              <Typography>
                {e.properties?.length === 0 ? (
                  <span key={0} style={{ margin: "10px 0" }}>
                    No tiene fincas
                  </span>
                ) : (
                  e.properties?.map((f, j) => (
                    <>
                      <span key={j} style={{ margin: "10px 0" }}>
                        <Link to={`/aaff/${e.nif}/comunidades/${f.nif}`} target="_blank">{f.razonSocial}</Link> <br />
                        <small>
                          <b>Identificación de riesgos:</b>{" "}
                          {f.docsProps.find( dr => dr.nombre === "identificacion de riesgos")?.docsReads[0].updatedAt !== undefined ?
                            formatoFechasEs(f.docsProps.find( dr => dr.nombre === "identificacion de riesgos")?.docsReads[0].updatedAt)
                            : "Nunca"
                          }
                          {/* {f.docsRead.find( dr => dr.nombre === "identificacion de riesgos" )?.r_doc_reads?.updatedAt !==undefined ?
                            formatoFechasEs(f.docsRead.find( dr => dr.nombre === "identificacion de riesgos" )?.r_doc_reads?.updatedAt)
                          : "Nunca"
                          } */}
                        </small>
                        <br />
                        <small>
                          <b>Plan de emergencia: </b>
                          {
                            f.docsProps.find( dr => dr.nombre === "plan de emergencia")?.docsReads[0].updatedAt !== undefined ?
                            formatoFechasEs(f.docsProps.find( dr => dr.nombre === "plan de emergencia")?.docsReads[0].updatedAt)
                            : "Nunca"
                          }
                          {/* {f.docsRead.find( dr => dr.nombre === "plan de emergencia" )?.r_doc_reads?.updatedAt !==undefined ?
                            formatoFechasEs(f.docsRead.find( dr => dr.nombre === "plan de emergencia" )?.r_doc_reads?.updatedAt)
                          : "Nunca"
                          } */}
                        </small>
                        <br />
                        <small>
                          <b>Servicio: </b>
                          {f?.servicio.map((s, i) => {
                            return (
                              <span key={s?.nombre}>
                                {s?.nombre}
                                {i < f.servicio.length - 1 && ', '}
                              </span>
                            )
                          })}
                        </small>
                      </span>
                      <br />
                      <br />
                    </>
                  ))
                )
                }
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))
      }
      <style jsx="true">{`
        .root {
          width: "100%";
        }
        .heading: {
          font-size: 0.9375rem;
          flex-basis: "33.33%";
          flex-shrink: 0;
        }
        .secondaryheading: {
          fontsize: 0.9375rem;
          color: theme.palette.text.secondary;
        }
      `}</style>
    </div>
  );
}
