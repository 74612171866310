import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { getAAFFs, getCommercial } from "../../../services";
import Warning from "../../../icons/warning.svg";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';





const AAFFComercial = (props) => {


  const AAFFRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
      overrideFunc: (data, row) => (
        <Link to={"/aaff/" + row.nif + "/comunidades"}>
          {data}
        </Link>
      ),
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "numeroComunidades",
      numeric: false,
      disablePadding: false,
      label: "Nº COMUNIDADES",
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          {data}
        </Typography>
      ),
    },
    {
      id: "proveedores",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDORES",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <Typography style={{ textAlign: 'left' }}>
          <Link to={`/comerciales/${props.params.nifComercial}/aaff/${row.nif}/proveedores`}>{data}</Link>
        </Typography>
      ),
    },
    {
      id: "docsPendientes",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto:true,
      overrideFunc: (row) => (
        <>
          {row > 0 ?
            <img src={Warning} width={15} alt="pendientes" />
            : null}
        </>
      ),
    },
  ];



  const user = useContext(UsuarioContext);
  const [recargar, setRecargar] = useState(0);
  const [loading, setLoading] = useState(true);
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [comercial, setComercial] = useState({});
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    // fetchData();
    setRecargar(false);
  }, [recargar]);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  useEffect(() => {
    loadConsultor()
  }, [])

  const loadConsultor = async () => {
    const commercial = await getCommercial(props.params.nifComercial)
    setComercial(commercial)
    setBusqueda({ ...busqueda });
  }

  const fetchData = async ({ offset, limit, filtros = busqueda , order,orderBy} = {}) => {

    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';    delete filtros.commer_id;

    setLoading(true);

    if (comercial.id) {


      construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order,orderBy);

      const payload = {
        filtros: { ...filtros, commer_id: comercial.id },
        params: { page: offset, limit , order,orderBy},
      };


      try {
        setLoading(true);
        const response = await getAAFFs(payload);
       
        const { meta, data } = response;
        setData({
          meta,
          rows: formatData(data),
        });
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };



  const formatData = (data) => {
    
    return data.map((row) => {
      const numComunidades = row.pendientesCount + row.visitadasCount + row.renovadasCount + row.renovadasVisitadasCount
      return {
        razonSocial: row.razonSocial || row.propertie?.aaff?.nombreContacto,
        nif: row.nif || row.propertie?.nif,
        id: row.id,
        municipio: row.municipio || row.propertie?.municipio,
        provincia: row.provincia,
        numeroComunidades: row.visitadasCount+row.renovadasCount,
        proveedores: row.suppliersCount,
        docsPendientes: row.hasPendingDocs,
      }
    });
  };

  const filtrarDatos = (filtros) => {
    setBusqueda({ ...filtros, commer_id: comercial.id })
    setBusquedaActiva(true);
    // fetchData();
  }

  return (
    <>
      <BreadCrumb rutas={[{ path: '/comerciales', label: `Comerciales` }, { path: '/comerciales', label: `${comercial.nombre + " " + comercial.apellidos}` }]} style={{ margin: '0 10px' }} />
      <div className="pageContent tableContainer">
        <FiltrosAAFF onFilter={filtrarDatos} consultorOculto={true} comercialOculto={true} clearFilter={clearFilters} filtrosIniciales={busqueda}/>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={AAFFRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>

    </>
  );
};

export default withRouter(AAFFComercial);
