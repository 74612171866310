import axios from "axios";

const formHeaders = {
  "Content-Type": "multipart/form-data",
  "Access-Control-Allow-Origin": "*",
};

export const plantillaAAFF = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  try {
    const response = await axios.post(`aaff/file`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const plantillaFincas = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  try {
    
    const response = await axios.post(`sale/create-salescae-multiple`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    //console.log(err.data);
    return err;
  }
};
export const plantillaRenovaciones = async (data) => {
  const formData = new FormData();
  formData.append("file", data);

  try {
    
    const response = await axios.post(`sale/renovate-sales-multiple`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    //console.log(err.response);
    return err;
  }
};
export const plantillaFincasLOPD = async (data) => {
  
  const formData = new FormData();
  formData.append("file", data);

  try {
    
    const response = await axios.post(`sale/xlsx`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const plantillaProveedores = async (data) => {
  
  const formData = new FormData();
  formData.append("file", data);

  try {
    const response = await axios.post(`supplier/file`, formData, {
      ...formHeaders,
    });
    return response.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const plantillaMatcheo = async (data) => {
  
  const formData = new FormData();
  formData.append("file", data);

  try {
    const response = await axios.post(`aaff/matchFileProp`, formData, {
      ...formHeaders,
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
