import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";

import { Link } from "react-router-dom";
import {
  subirDocumentacionProveedor,
  verDocumentacion,
  validarDocumentacion
} from "../../../providers/documentos";
import Add from "../../../icons/add.svg";
import Editar from "../../../icons/editar.svg";
import ControlledAccordions from "./accAAFFCom";
import ModalEditar from "./editarDatosProv";
import { getProveedor, getServicios } from "../../../services";

import { eliminaDuplicados, daysDiff, formatoFechasEs, eliminarComentario, editarComentario, cambiarFormatoNombre } from "../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { TiArrowBack } from "react-icons/ti";
import { UsuarioContext } from "../../../providers/userContext";
import DeclRes from "../../../components/proveedores/declRes";
import Delete from "../../../icons/delete.svg";
import Delegations from "../../../components/suppliers/delegations";
import {CustomSwitch,CustomPendienteSwitch} from "../../../components/Buttons/Switch";


const ProveedorDatos = (props) => {
  
  const delegations = 0;
  const user = useContext(UsuarioContext)
  const [aaff, setAAFF] = useState([]);
  const [fincas, setFincas] = useState([]);
  const [proveedor, setProveedor] = useState({});
  const [servicios, setServicios] = useState([]);
  const [recargar, setRecargar] = useState(false);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [comentarios, setComentarios] = useState([]);

  const hijoAPadre = () => {
    setRecargar(!recargar);
  };

  useEffect(() => {
    fetchData()
  }, [!recargar]);

  const fetchData = async () => {

    const responseServ = await getServicios();
    setServicios(responseServ);

    const responseProv = await getProveedor(props.params.nifProveedor)
    setProveedor(responseProv);
    

    const comments = responseProv.comments?.length > 0 ? responseProv.comments.map(comment => {
      comment.usuario = comment.user?.consultant?.[0] || comment.user?.admin?.[0]
      return comment
    }) : []
    setComentarios(comments)
  }

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const comprobarArchivos = async (validado, status, ruta, id, idDoc, rol, nif) => {
    // Llama a la función importada y pasa la devolución de llamada
    
    if(validado && status){
     verDocumentacion(ruta, id, idDoc, rol, nif).then((resultado) => {
       handleSubirDocsCallback(resultado)
     })
    }else{
      subirDocumentacionProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    }
  };

  const comprobarValidacion = async (ruta, id, nif) => {
    validarDocumentacion(ruta, id, nif).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }

  const editarComment = async (id, comm) => {
    editarComentario(id, user.id, comm).then( (resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  const eliminarComment = async (id) => {
    eliminarComentario(id).then( (resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  
  return (
    <>
      <ModalEditar
        proveedor={proveedor}
        opened={modalEditarOpened}
        hijoAPadre={hijoAPadre}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <BreadCrumb rutas={[{path:'/proveedores', label:'Proveedores'}, {path:`proveedores/${proveedor.nif}`, label: `${proveedor.razonSocial}`}]} />
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
            <Link to={`/proveedores`}>
              <div className="gridHeadRight">
                {/* {"<< volver al listado de Proveedores"} */}
                <TiArrowBack style={{ fontSize: 23}} />
              </div>
            </Link>
          </div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS EMPRESA</div>
            <div
              className="editarBtn"
              onClick={() => setModalEditarOpened(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
          <div className="datosGrid">
            <p>
              <b>Nombre proveedor:</b> {proveedor.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {proveedor.direccion}
            </p>
            <p>
              <b>NIF:</b> {proveedor.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(proveedor.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b> {proveedor.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {proveedor.codigoPostal}
            </p>
            <p>
              <b>Email de contacto:</b> {proveedor.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {proveedor.provincia}
            </p>
            <p>
              <b>Email de login:</b> {proveedor.user?.email}
            </p>
            <p>
              <b>Telefono:</b> {proveedor.telefonoContacto}
            </p>
            <p>
              <b>Fecha de alta:</b>{" "}
              {new Date(proveedor.createdAt).toLocaleDateString()}
            </p>
            <p>
              <b>Ultima reclamación:</b>{" "}
              {proveedor.ultimaReclamacion === undefined ||
              proveedor.ultimaReclamacion === null
                ? new Date(proveedor.createdAt).toLocaleDateString()
                : new Date(proveedor.ultimaReclamacion).toLocaleDateString()}
            </p>
            {/* <p><b>Servicio:</b> {fincas.proveedores.servicio <= 18 ? serviciosProveedores[fincas.proveedores.servicio] : "Desconocido"}</p> */}
          </div>
          <div className="datosGridComentarios">
            <p>
              <b>Comentarios:</b>
            </p>
            <p style={{ whiteSpace: "pre-line" }}>
              {comentarios.lenght === 0
              ? "-"
              : comentarios.map(comm => {
                return (
                  <div>
                    <img
                      src={Delete}
                      alt="ELIMINAR"
                      className="tableIcon"
                      onClick={() => eliminarComment(comm.id)}
                      style = {{width:14}}
                    ></img> 
                    <img
                      src={Editar}
                      alt="Editar"
                      className="tableIcon"
                      onClick={() => editarComment(comm.id, comm)}
                      style = {{width:14}}
                    ></img> 
                    {formatoFechasEs(comm.updatedAt)} - {comm.usuario.nombre+" "+comm.usuario.apellidos} - {comm.texto}</div>
                )
              })}{" "}
            </p>
          </div>
          <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                  <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                  <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                  <th style={{ textAlign: "center" }}>
                    Planificación preventiva
                  </th>
                  <th style={{ textAlign: "center" }}>
                    Identificación riesgos
                  </th>
                  <th style={{ textAlign: "center" }}>Certificado Seg. Social</th>
                  <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.id, 
                            1, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.pendiente && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.validado && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.id, 
                            2, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad !==
                      null && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.id, 
                            3, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad !==
                      null ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={
                          proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.validado
                        }
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.id, 
                            4, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={
                          proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.validado
                        }
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.id, 
                            5, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.id, 
                            6, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad !==
                      null ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.id, 
                            7, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad !==
                      null ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 30
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                </tr>
              </tbody>
            </table>
            <input type="file" id="fileElem" hidden></input>
          </div>
        </div>
        <div className="gridCard">
          <div className="gridHead">
            COMUNIDADES
          </div>
          <ControlledAccordions
              AAFFS={aaff}
              proveedor={proveedor}
              fincas={fincas}
            />
        </div>   
      </div>
      <div className="pageGrid mt-3">
        <DeclRes nifProv={props.params.nifProveedor} />
        
        <div className="gridCard" /* style={delegations > 0 ? { display: 'block' } : { display: 'none' }} */>
          <div className="gridHead">
            DELEGACIONES
            <Link to={`/delegacion/nueva/${proveedor.id}`} >
              <div className="gridHeadRight">
                <img src={Add} alt="Más" width={15} />
              </div>
            </Link>
          </div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
                {/* <th>Aviso</th> */}
              </tr>
            </thead>
            <tbody>
              <Delegations nif={props.params.nifProveedor} />
            </tbody>
          </table>
        </div>
      </div>
      <style jsx="true">{`
          .pageGrid {
            position: relative;
            top: 00px;
            margin: 0 10px;
            display: grid;
            /* grid-template-columns: ${delegations > 0 ? '5fr 3fr 3fr' : '5fr 3fr'} ; */
            grid-template-columns: 5fr 3fr;
            grid-gap: 20px;
          }                
      `}</style>
    </>
  );
};

export default withRouter(ProveedorDatos);
