import React from "react";
import { Fade, Modal, Typography, Backdrop, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "40%",
  left: "57%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
  ":focus": {
    outline: "none",
  },
};

function SmoothModal({ open, children, onClose, sx }) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={onClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={{ ...style, ...sx }}>
          {children ?? (
            <>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Text in a modal
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
              </Typography>
            </>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}

export default SmoothModal;
