import React, { useState } from 'react';
import { PDFViewer, Document, Page, Image, Text, View } from "@react-pdf/renderer";
import rgpd from '../../images/certificado_rgpd_implantacion.png'
import { withRouter } from '../../providers/withRouter';
import { useEffect } from 'react';
import { getFinca, getSale } from '../../services';
import { decryptParameter, formatoFechasEs } from '../../functions/funciones';
import { CircularProgress } from '@mui/material';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import PDFHandler from "./PDFHandler";

const CertificadoVigilancia = (props) => {

    const backgroundImageUrl = rgpd; 

    const [isLoading, setIsLoading] = useState(true)
    const [sale, setSale] = useState({})
    

    useEffect(()=>{

        fetchData()
    },[])

    const fetchData = async () => {
        const response = await getSale(props.params.id);

        const renewDate =  new Date(response.saleDate);
        renewDate.setFullYear(renewDate.getFullYear() + 1);
        const fechaFormateada = format(renewDate, 'MMMM yyyy', {locale: es})
        setSale({...response, fechaFormateada });
        setIsLoading(false);
    }
    return isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : 
      <PDFHandler style={{ width: "100%", height: "100vh" }}>
        <Document>
          <Page>
            <View>
                <Image src={backgroundImageUrl} />
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              top:"345px",
                              color: 'black', 
                              fontSize: '12px'}}>
                  {sale.properties.razonSocial}
                </Text>
                <Text style={{textAlign: 'center',
                              position: 'absolute',
                              left: 170,
                              top:"670px",
                              color: 'white', 
                              fontSize: '12px'}}>
                  {sale.fechaFormateada}
                </Text>
            </View>
          </Page>
        </Document>
      </PDFHandler>
}
 
export default withRouter(CertificadoVigilancia);