import React, { useContext } from 'react'
import { Modal, Box } from "@mui/material";
import Delete from "../../icons/delete.svg";
import Editar from "../../icons/editar.svg";
import { formatoFechasEs } from '../../functions/funciones';
import { UsuarioContext } from '../../providers/userContext';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85ch',
    maxHeight: '500px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    overflow: 'auto',

};

const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const AllObservations = (props) => {

    const user = useContext(UsuarioContext)

    const eliminar = (id) => {
        props.eliminarComment(id)
        props.handleClose()
    }
    const editar = (id, comentario) => {
        props.editarComment(id, comentario)
        props.handleClose()
    }

    return (
        <>
            <Modal
                open={props.opened}
                onClose={props.handleClose}
                className={modal}
            >
                <Box sx={style}>
                    {props?.observations?.length === 0 ?
                        <p>No hay comentarios</p>
                        :
                        <>
                            <h3>COMENTARIOS</h3>
                            {props?.observations?.map(comm => {
                                return (
                                    <div key={comm.id} style={{ display: 'flex', alignItems: 'center' }}>
                                        <span style={{ width: '40px', margin: '3px 0' }}>
                                            {comm.user_id === user.user_id ?
                                                <>
                                                    <img
                                                        src={Delete}
                                                        alt="ELIMINAR"
                                                        className="tableIcon"
                                                        onClick={() => eliminar(comm.id)}
                                                        style={{ width: 14, margin: 0 }}
                                                    ></img>
                                                    <img
                                                        src={Editar}
                                                        alt="Editar"
                                                        className="tableIcon"
                                                        onClick={() => editar(comm.id, comm)}
                                                        style={{ width: 14, margin: '0 0 0 3px' }}
                                                    ></img>
                                                    { }
                                                </>
                                                : null}
                                        </span>
                                        <span>
                                            {formatoFechasEs(comm.createdAt)} - {comm.usuario?.nombre + " " + comm.usuario?.apellidos} - {comm.texto}
                                        </span>
                                    </div>
                                )
                            })}
                        </>}
                </Box>
            </Modal>
        </>
    );
}

export default AllObservations;