import React from "react"
import { Routes, Route, NavLink } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import CheckControl from "../icons/controlComunidad.svg";
import VentasIcon from "../icons/ventas.svg";
import Comunidad from "./pages/comercial/Comunidad";
import Inicio from "./pages/comercial/Inicio";
import AAFF from "./pages/comercial/AAFF";
import AAFFInformes from "./pages/comercial/AAFFInformes";
import Ventas from "./pages/general/Ventas";
import Comunidades from "./pages/comercial/Comunidades";
import DatosPerfil from "../components/perfil/datosPerfil";
import Proveedores from "./pages/comercial/Proveedores";
import Proveedor from "./pages/comercial/Proveedor";

export function RouteBundle() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/aaff" element={<AAFF />} />
      <Route path="/aaff-informes" element={<AAFFInformes />} />
      <Route path="/servicios/" element={<Ventas />} />
      <Route path="comunidades/:nif" element={<Comunidad />} />
      <Route path="/aaff/:nif/comunidades" element={<Comunidades />} />
      <Route path="/aaff/:nif/comunidades/:nifFinca" element={<Comunidad />} />
      <Route path="/perfil" element={<DatosPerfil />} />
      <Route path="/aaff/:nif/proveedores" element={<Proveedores />} />
      <Route path="/proveedores/:nifProveedor" element={<Proveedor />} />
    </Routes>
  )
}

const refrescoLocal = () => {
  localStorage.setItem("hasReloaded", "true"); 
}

export function MenuLinksClientes(props) {
  return (
    <>
      <li>
        <NavLink activeclassname="active" to="/aaff" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "",  color: props.showSidebar ? "#33383b" : "#fff"}}>
          <div className="menuBtn" onClick={refrescoLocal}>
            <img src={Edificios} alt="Administradores de Fincas" />Administradores de Fincas
          </div>
        </NavLink>
      </li>
      <li>
        <NavLink
          activeclassname="active"
          to="/servicios"
          style={{
            display: props.showSidebar ? "flex" : "block",
            alignItems: props.showSidebar ? "center" : "",
          }}
        >
          <div className="menuBtn" onClick={refrescoLocal}>
            <img src={VentasIcon} alt="Servicios" />
            Servicios
          </div>
        </NavLink>
      </li>
    </>
  )
}
export function MenuLinksListado(props) {
  return (
    <>
      <li>
        <NavLink activeclassname="active" to="/aaff-informes" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
          <div className="menuBtn" onClick={refrescoLocal}>
            <img src={CheckControl} alt="Informes cae" />Informes cae
          </div>
        </NavLink>
      </li>
    </>
  )
}