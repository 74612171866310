import React, { useState, useEffect} from "react";
import {
  Box,
  Typography,

} from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { getOneAAFF } from "../../../services";
import ObservacionesTecnico from "../../../components/aaff/observacionesTecnico";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import ListadoComunidades from "./ListadoComunidades";
import { cambiarFormatoNombre } from "../../../functions/funciones";
import { CircularProgress } from '@mui/material';





const DetallesAAFF = ({ params }) => {

  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true)

  // const [recargar, setRecargar] = useState(true);


  useEffect(() => {
    fetchAAFFInfo();
    
  }, []);

  const fetchAAFFInfo = async () => {
    // setRecargar(false);
    try {
      const response = await getOneAAFF(params?.nif) || {};

      setInfo(response);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };




  return isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
    <>
      <BreadCrumb rutas={[{ path: '/aaff', label: `Administrador` }, { path: '/aaff', label: `${info && info.razonSocial}` }]} style={{ margin: '0 10px' }} />
      <Box className="listPage">
        <div className="gridCard">
          <div className="gridHead" style={{ backgroundColor: "#92004D" }}>
            <Typography fontWeight="bold">INFORMACION GENERAL</Typography>
          </div>
        </div>
        <Box sx={{ mb: 10 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              backgroundColor: "#fff",
              mb: 1,
              borderRadius: "0 0 10px 10px",
              boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="gridSubhead editarSubhead">
              <div>DATOS EMPRESA</div>
            </div>
            <Box className="lae-flex lae-flex-row" sx={{padding: 2 }}>
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3}}>
                <Typography><b>Nombre AAFF:</b> {info?.razonSocial}</Typography>
                <Typography><b>Teléfono:</b> {info?.telefono}</Typography>
                <Typography><b>Email:</b> {info?.user?.email}</Typography>
                <Typography><b>Municipio:</b> {cambiarFormatoNombre(info?.municipio)}</Typography>
                <Typography>
                  <b>Técnico:</b>
                  {` ${info?.technician?.nombre} ${info?.technician?.apellidos}`}
                </Typography>
              </Box>
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography><b>NIF:</b> {info?.nif}</Typography>
                <Typography>
                  <b>Persona de contacto:</b> {info?.nombreContacto}
                </Typography>
                <Typography><b>Provincia:</b> {info?.provincia}</Typography>
                <Typography>
                <b>Consultor:</b>
                  {info?.consultant === undefined
                    ? ''
                    : (info?.consultant?.nombre === undefined
                      ? ''
                      : ` ${info?.consultant?.nombre} `) +
                    (info?.consultant?.apellidos === undefined
                      ? ''
                      : ` ${info?.consultant?.apellidos}`)}
                </Typography>
                <Typography>
                  <b>Comercial:</b>
                  {info?.commercial === undefined
                    ? ''
                    : (info?.commercial?.nombre === undefined
                      ? ''
                      : ` ${info?.commercial?.nombre} `) +
                    (info?.commercial?.apellidos === undefined
                      ? ''
                      : ` ${info?.commercial?.apellidos}`) +
                    (info?.commercial?.user?.[0]?.email === undefined
                      ? ''
                      : ` (${info.commercial.user[0].email})`)
                  }
                </Typography>



              </Box>

            </Box>
            <hr />
            <div className="datosGrid">
              <ObservacionesTecnico aaff={info} />
            </div>
            <div className="gridSubhead editarSubhead">
              <div>LISTADO COMUNIDADES</div>
            </div>
              <ListadoComunidades aaff={info} />
            {/* <div className="tableContainer">
            </div> */}
          </Box>


        </Box>
      </Box>
    </>
};

export default withRouter(DetallesAAFF);
