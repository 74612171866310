import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { Tabs, Tab, Box, CircularProgress } from "@mui/material";
import TabPanel from "../../../components/tabs/Tabs";
import { getFinca } from "../../../services";
import HojaVisitaRender from "../tecnico/HojaVisitaRenderKK";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { ComunidadDocs } from "../../../components";


const Comunidad = (props) => {
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);

  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const [generar, setGenerar] = useState(false);

  useEffect(() => {
    fetchData()
  }, []);

  const fetchData = async () => {
    const response = await getFinca(props.params.nifFinca)
    console.log(response)
    setFinca(response)
    if (response?.gruposServicios?.includes(1)) {
      setValue(1);
    } else {
      setValue(2);
    }
    setIsLoading(false);
    // setProveedores(response.suppliers)


  } 

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const generarArchivo = () => {
    <HojaVisitaRender generarPDF={true} />;
  };

  return finca == {} || isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : 
    <>
      <BreadCrumb rutas={[{path:'/aaff', label:`Administradores de fincas`}, {path:`/aaff/${props.params.nif}/comunidades`, label:`${finca?.aaff?.razonSocial}`}, {path:`/aaff/${props.params.nif}/comunidades/${props.params.nifFinca}`, label:`${finca.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead">DATOS COMUNIDAD</div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca?.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca?.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca?.nif}
            </p>
            <p>
              <b>Municipio:</b> {finca?.municipio}
            </p>
            <p>
              <b>Persona de contacto:</b>{" "}
            </p>
            <p>
              <b>CP:</b> {finca?.codigoPostal}
            </p>
            <p>
              <b>Email:</b> {finca?.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {finca?.provincia}
            </p>
            <p>
              <b>Telefono:</b> {finca?.telefonoContacto}{" "}
            </p>
            <p>
              <b>Presidente:</b> {finca?.nombrePresidente}{" "}
            </p>
            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div>
            <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
            <Box sx={{ padding: '10px 40px', border: 'none' }}>
            {!finca?.gruposServicios || finca?.gruposServicios.length === 0 ? (
              "No tiene documentos"
            ) : (
            <Tabs value={value} onChange={handleChange} aria-label="tabs example">
              { finca?.gruposServicios?.includes(1) && <Tab style={{ color:"#92004D" }} label="CAE" value={1} /> }
              { finca?.gruposServicios?.includes(2) && <Tab style={{ color:"#92004D" }} label="LOPD" value={2} /> }
            </Tabs>
            )}
          </Box>
          {finca?.gruposServicios?.map((grupoServicio) => (
                
                <TabPanel key={grupoServicio} value={value} index={grupoServicio}>
                  <div className="pageGrid" style={{ gap:0 }}>
                    {grupoServicio === 1 && (
                      <>
                        <div id="ventas" >
                          <ComunidadDocs idPropiedad={finca.id} grupoServicio={1} />
                        </div>
                        <div id="proveedores" className="tableContainer">
                          <div className="gridHeadProv">PROVEEDORES</div>
                          <div >
                            <table>
                            <tbody>
                            {proveedores.length === 0 ? (
                              <small style={{ paddingLeft: "14px" }}>No tiene proveedores</small>
                            ) : (
                              proveedores.map((e, i) => {
                                return (
                                  <tr key={i}>
                                    <td style={{ width: "100%", padding: 10 }}>
                                      <Link to={"/proveedores/" + e.nif}>
                                        {e.razonSocial}
                                        <p style={{ opacity: 0.55, fontSize: 14 }}>{e?.serviceSupps?.length > 0 ? e?.serviceSupps?.find((serv) => serv.id === e.service_to_propertie?.service)?.nombre : null}</p>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })
                            )}
                            </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                    {grupoServicio === 2 && <ComunidadDocs idPropiedad={finca.id} grupoServicio={2}/>}
                  </div>
                </TabPanel>
            ))}
          </div> 
        </div>
        {/* <div className="gridCard">
          <div className="gridHead">PROVEEDORES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
              </tr>
            </thead>
            <tbody>
              {proveedores.map((e, i) => {
                return (
                  <tr key={i}>
                    <td
                      style={{
                        width: "100%",
                        paddingBottom: 10,
                        paddingTop: 10,
                      }}
                    >
                      <Link to={"/proveedores/" + e.nif}>
                        {e.razonSocial}
                        <p style={{ opacity: 0.55, fontSize: 14 }}>
                          {e?.serviceSupps?.length > 0 ? e?.serviceSupps?.find( serv => serv.id === e.service_to_propertie?.service)?.nombre : null}
                        </p>
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
      <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
        .gridHeadProv {
          border-radius: 5px 5px 0 0;
          background-color: #92004D;
          color: #ffffff;
          padding: 10px 15px;
          font-weight: bold;
          font-size: 12px;
          height: 34px;
        }
        #ventas{
          width:70%;
        }
        #proveedores{
          width:30%;
        }
        .pageGrid{
          display:flex;
          flex-direction: row;
        }
        @media screen and (max-width: 992px) {
          #ventas{
            overflow-y: auto;
            width:100%;
          }
          #proveedores{
            width:100%;
          }
          .pageGrid{
            flex-direction: column;
          }
        }
      `}</style>
    </>
};

export default withRouter(Comunidad);
