import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { UsuarioContext } from "../../../providers/userContext";
import { getProveedor } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { daysDiff, formatoFechasEs, eliminarComentario, editarComentario, cambiarFormatoNombre } from "../../../functions/funciones";
import CircularProgress from "@mui/material/CircularProgress";
import ControlledAccordions from "../admin/accAAFFCom";
import ModalEditarComentarios from "./../../../components/suppliers/AllComments";
import ModalEditar from "./../admin/editarDatosProv";
import DeclRes  from "./../../../components/proveedores/declRes";
import Delegations from "../../../components/suppliers/delegations";
import Editar from "../../../icons/editar.svg";
import Delete from "../../../icons/delete.svg";
import { IoSave } from "react-icons/io5";
import { createComment } from "../../../services/comment.service";
import {
  subirDocumentacionProveedor,
  verDocumentacion,
  validarDocumentacion
} from "../../../providers/documentos";

const Proveedor = (props) => {
  const delegations = 0;
  const user = useContext(UsuarioContext);
  const [aaff, setAAFF] = useState([]);
  const [fincas, setFincas] = useState([]);
  const [proveedor, setProveedor] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [modalCommentsOpened, setModalCommentsOpened] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [comentario, setComentario] = useState('');

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#FF5252",
      "&:hover": {
        backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#FF5252",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#7bc977",
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
      "&:hover": {
        backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#7bc977",
    },
  }));
  
  const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#FFD700",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#FFD700",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#FFD700",
      backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#FFD700",
    },
  }));

  // useEffect(() => {
  //   getProveedor(props.params.nif)
  //     .then((res) => {
  //       let proveedor = {
  //         ...res,
  //         documentos: {
  //           lopd: { pendiente: true, ruta: "", validado: false },
  //           seguro: { pendiente: false, ruta: "", validado: true },
  //           vigilancia: { pendiente: false, ruta: "", validado: false },
  //           evaluacionRiesgos: { pendiente: false, ruta: "", validado: true },
  //         }
  //       }
  //       setProveedor(proveedor);
  //       getUserPropertieList(props.params.nif)
  //         .then((resP) => {
  //           console.log(resP)
  


  //           setFincas(resP.properties)

  //         })
  //         setIsLoading(false);


  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       // window.location.replace("/");
  //     });
  // }, []);

  const hijoAPadre = () => {
    setRecargar(!recargar);
  };

  useEffect(() => {
    fetchData()
  }, [!recargar]);

  const fetchData = async () => {

    const responseProv = await getProveedor(props.params.nif)
    setProveedor(responseProv);
    console.log("responseProv",responseProv);
    const comments = responseProv?.comments?.length > 0 ? responseProv?.comments?.map(comment => {
      comment.usuario = comment.user?.consultant?.[0] || comment.user?.admin?.[0] || comment.user?.technician?.[0]
      return comment
    }) : []
    setComentarios(comments)
    setIsLoading(false);
  }

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const comprobarArchivos = async (validado, status, ruta, id, idDoc, rol, nif) => {
    // Llama a la función importada y pasa la devolución de llamada
    if(validado && status){
     verDocumentacion(ruta, id, idDoc, rol, nif).then((resultado) => {
       handleSubirDocsCallback(resultado)
     })
    }else{
      subirDocumentacionProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    }
  };

  const comprobarValidacion = async (ruta, id, nif) => {
    validarDocumentacion(ruta, id, nif).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }

  const editarComment = async (id, comm) => {
    editarComentario(id, user.id, comm).then( (resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  const eliminarComment = async (id) => {
    eliminarComentario(id).then( (resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }

  function guardarComentarios() {
    try {
    let infoComentario = {
        prop_id:null,
        supp_id:proveedor.id,
        aaff_id:null,
        texto: comentario,
        tipo: "comment",
        status:1,
    }
    createComment(infoComentario).then(res => {
        handleSubirDocsCallback(res)
        setComentario('')
    })
    } catch (error) {
    console.log(error)
    }
    
  }

  function cambiarComentarios(evt) {
    const value = evt.target.value;
    setComentario(value);
  }

  const redirectToSomeRoute = (nif) => {
    setIsLoading(true)
    setRecargar(!recargar)
    props.navigate(`/proveedores/${nif}`);
  }

  return isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
    <>
      <ModalEditarComentarios
        comentarios={comentarios}
        opened={modalCommentsOpened}
        eliminarComment={eliminarComment}
        editarComment={editarComment}
        handleClose={() => {
          setModalCommentsOpened(false);
          setRecargar(!recargar);
        }}
      />
      <ModalEditar
        proveedor={proveedor}
        opened={modalEditarOpened}
        hijoAPadre={hijoAPadre}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      <BreadCrumb rutas={[{ path: '/proveedores/', label: "Proveedores" }, { path: `/proveedores/${props.params.nif}`, label: `${proveedor?.razonSocial}` }]} style={{ margin: '0 10px' }} />
      <div className="pageGrid">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead">DATOS EMPRESA</div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {proveedor.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {proveedor.direccion}
            </p>
            <p>
              <b>NIF:</b> {proveedor.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(proveedor?.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b> {proveedor.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {proveedor.codigoPostal}
            </p>
            <p>
              <b>Email:</b> {proveedor.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {proveedor.provincia}
            </p>
            <p>
              <b>Telefono:</b> {proveedor.telefonoContacto}
            </p>
            <p>
              <b>Última reclamación:</b>{" "}
              {proveedor.ultimaReclamacion !== undefined &&
                proveedor.ultimaReclamacion > proveedor.createdAt
                ? new Date(proveedor.ultimaReclamacion).toLocaleDateString()
                : "-"}
            </p>
          </div>
          <div className="datosGrid">
            <div>
              <p>
                <b>Comentarios:</b>
              </p>
              <p style={{ whiteSpace: "pre-line" }}>
                {comentarios.lenght === 0
                ? "-"
                : 
                  <>
                    {comentarios.slice(0,4).map(comm => {
                      return (
                        <div>
                          <img
                            src={Delete}
                            alt="ELIMINAR"
                            className="tableIcon"
                            onClick={() => eliminarComment(comm.id)}
                            style = {{width:14}}
                          ></img> 
                          <img
                            src={Editar}
                            alt="Editar"
                            className="tableIcon"
                            onClick={() => editarComment(comm.id, comm)}
                            style = {{width:14}}
                          ></img> 
                          {formatoFechasEs(comm.updatedAt)} - {comm.usuario?.nombre+" "+comm.usuario?.apellidos} - {comm.texto}</div>
                      )
                    })}
                    {comentarios.length > 4 && 
                      <div >
                        <button onClick={() => setModalCommentsOpened(true)} style={{margin: '10px 0', borderRadius: '5px 5px', backgroundColor: '#92004D', color: '#ffffff', padding: '5px 10px'}}>Mostrar más</button>
                      </div>
                    }
                  </>
                }{" "}
              </p>
            </div>
            <div>
              <form
                noValidate
                autoComplete="off"
                style={{ display: "flex", alignItems: "end" }}
              >
                <textarea
                  style={{
                    marginRight: "10px",
                    border: "1px solid #ccc",
                    width: "100%",
                  }}id="comentarios" name="comentarios" label="Comentarios" onChange={cambiarComentarios} multiline rows={6} columns={100}
                />
                <div>
                  <button type="button" className="bigBtn" onClick={() => guardarComentarios(comentarios)}>
                    <IoSave style={{ fontSize:23 }} />
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                  <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                  <th style={{ textAlign: "center" }}>Justificante pago SPA</th>
                  <th style={{ textAlign: "center" }}>
                    Planificación preventiva
                  </th>
                  <th style={{ textAlign: "center" }}>
                    Identificación riesgos
                  </th>
                  <th style={{ textAlign: "center" }}>Certificado Seg. Social</th>
                  <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 1 )?.id, 
                            1, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.pendiente && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.validado && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.id, 
                            2, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad !==
                      null && proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 0
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.noProcede ? "No procede" : 
                      <>
                        {proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.pendiente ? (
                          <CustomPendienteSwitch
                            checked={false}
                            onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.id, proveedor.nif)}
                          />
                        ) : (
                          <CustomSwitch
                            checked={proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.validado}
                            onClick={
                              () => comprobarArchivos(
                                proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.validado,
                                proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.status,
                                proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta,
                                proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.id, 
                                3, 
                                user.rol, 
                                proveedor.nif
                              )
                            }
                          />
                        )}
                        <small style={{ display: "block" }}>
                          {proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad !==
                          null && proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad !== undefined ? (
                            <span
                              style={{
                                color:
                                  daysDiff(
                                    new Date(
                                      proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                                    ),
                                    new Date()
                                  ) > 0
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {new Date(
                                proveedor?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                              ).toLocaleDateString()}
                            </span>
                          ) : (
                            "-"
                          )}
                        </small>
                      </>
                    }
                  </td>
                  <td style={{ textAlign: "center" }}>
                  {proveedor?.noProcede ? "No procede" : 
                    <>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.pendiente ? (
                        <CustomPendienteSwitch
                          checked={false}
                          onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.id, proveedor.nif)}
                        />
                      ) : (
                        <CustomSwitch
                          checked={
                            proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.validado
                          }
                          onClick={
                            () => comprobarArchivos(
                              proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.validado,
                              proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.status,
                              proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta,
                              proveedor?.docssupps?.find( d => d.tipoDoc === 4 )?.id, 
                              4, 
                              user.rol, 
                              proveedor.nif
                            )
                          }
                        />
                      )}
                      <small style={{ display: "block" }}>-</small>
                    </> 
                  }
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={
                          proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.validado
                        }
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 5 )?.id, 
                            5, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.pendiente ? (
                      <CustomPendienteSwitch
                        checked={false}
                        onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.id, proveedor.nif)}
                      />
                    ) : (
                      <CustomSwitch
                        checked={proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.validado}
                        onClick={
                          () => comprobarArchivos(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.validado,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.status,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta,
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.id, 
                            6, 
                            user.rol, 
                            proveedor.nif
                          )
                        }
                      />
                    )}
                    <small style={{ display: "block" }}>
                      {proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad !==
                      null && proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad !== undefined ? (
                        <span
                          style={{
                            color:
                              daysDiff(
                                new Date(
                                  proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                                ),
                                new Date()
                              ) > 0
                                ? "red"
                                : "black",
                          }}
                        >
                          {new Date(
                            proveedor?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                          ).toLocaleDateString()}
                        </span>
                      ) : (
                        "-"
                      )}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {proveedor?.noProcede ? "No procede" : 
                      <>
                        {proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.pendiente ? (
                          <CustomPendienteSwitch
                            checked={false}
                            onClick={() => comprobarValidacion(proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta, proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.id, proveedor.nif)}
                          />
                        ) : (
                          <CustomSwitch
                            checked={proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.validado}
                            onClick={
                              () => comprobarArchivos(
                                proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.validado,
                                proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.status,
                                proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta,
                                proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.id, 
                                7, 
                                user.rol, 
                                proveedor.nif
                              )
                            }
                          />
                        )}
                        <small style={{ display: "block" }}>
                          {proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad !==
                          null && proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad !== undefined ? (
                            <span
                              style={{
                                color:
                                  daysDiff(
                                    new Date(
                                      proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                                    ),
                                    new Date()
                                  ) > 0
                                    ? "red"
                                    : "black",
                              }}
                            >
                              {new Date(
                                proveedor?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                              ).toLocaleDateString()}
                            </span>
                          ) : (
                            "-"
                          )}
                        </small>
                      </> 
                    }
                  </td>
                </tr>
              </tbody>
            </table>
            <input type="file" id="fileElem" hidden></input>
          </div>
        </div>
        <div className="gridCard">
          <div className="gridHead">
            COMUNIDADES
          </div>
          <ControlledAccordions
            aaff={aaff}
            proveedor={proveedor}
            fincas={fincas}
          />
        </div>  
        {/* <div className="gridCard">
          <div className="gridHead">FINCAS</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
                <th></th>
                //<th></th>
              </tr>
            </thead>
            <tbody>
              {fincas?.length === 0 || fincas === undefined ? "No tiene comunidades" :
                fincas?.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ width: "100%", padding: "5px 0" }}>
                        <Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        //<img src={Aviso} alt="Aviso" className="tableIcon" />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div> */}
      </div>
      <div className="pageGrid mt-3">
          <DeclRes nifProv={props.params.nif} proveedor={proveedor}/>
          
          <div className="gridCard" /* style={delegations > 0 ? { display: 'block' } : { display: 'none' }} */>
            <div className="gridHead">
              {proveedor.parent_id !== null ? 'PROVEEDOR PRINCIPAL' : 'DELEGACIONES' }
            </div>
            <table style={{ margin: 15 }}>
              <thead>
                <tr
                  style={{
                    fontSize: 10,
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  <th></th>
                  {/* <th>Aviso</th> */}
                </tr>
              </thead>
              <tbody>
                <Delegations nif={props.params.nif} proveedor={proveedor} redirectToSomeRoute={redirectToSomeRoute} />
              </tbody>
            </table>
          </div>
        </div>
        <style jsx="true">{`
            .pageGrid {
              position: relative;
              top: 00px;
              margin: 0 10px;
              display: grid;
              /* grid-template-columns: ${delegations > 0 ? '5fr 3fr 3fr' : '5fr 3fr'} ; */
              grid-template-columns: 5fr 3fr;
              grid-gap: 20px;
            }                
        `}</style>
    </>

};

export default withRouter(Proveedor);
