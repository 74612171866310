import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";



const CustomSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#FF5252",
      "&:hover": {
        backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#FF5252",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#7bc977",
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
      "&:hover": {
        backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#7bc977",
    },
  }));
  
  const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase": {
      color: "#FFD700",
    },
    "& .MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "#FFD700",
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#FFD700",
      backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#FFD700",
    },
  }));

  export {CustomSwitch,CustomPendienteSwitch};