import axios from "axios";

const urlPath = "commercial";

export const createCommercial = async (data) => {
  const datos = {
    email: data.email,
    nif: data.nif,
    nombre: data.nombre,
    apellidos: data.apellidos,
  };
  try {
    const response = await axios.post(urlPath, datos);

    return response;
  } catch (err) {
    console.log(err);
    return err;
    
  }
};

export const createCommercialCombo = async (data) => {
  const datos = {
    userIds: data.userIds,
    nombre: data.nombre,
  };
  try {
    const response = await axios.post(`${urlPath}/combo`, datos);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const getCommercials = async (data) => {

  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {params: data.params});

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getAllCommercials = async ({onlyPrimary = false} = {}) => {

  try {
    const response = await axios.get(urlPath, {params: {isPrimary: onlyPrimary}});

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const listCommercials = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/listarBusqueda`, data.filtros, {
      params: data.params,
      
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};


export const getCommercial = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/${id}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const asignarComercial = async (nif, id) => {
  const datos = {
    commer_id: id,
  };
  try {
    const response = await axios.put(`aaff/${nif}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const deleteCommercial = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/${id}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
