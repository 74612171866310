import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.divider}`,
    marginBottom: '10px',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        marginBottom: '10px !important',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '1.3rem', color: 'white' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: '#92004D',
    color: '#ffffff',
    padding: '10px 5px',
    height: '40px',
    borderRadius: '10px 10px 0 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(1),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: '#ffffff',
    borderRadius: '0 0 10px 10px'
}));

const EstiloTypography = styled(Typography)(({ theme }) => ({
    fontWeight: 800,
    fontSize: '12px !important',
    fontFamily: 'sans-serif',
    '&.estiloEspecifico': {
        fontSize: '12px !important',
    },
}));

export default function CustomizedAccordions({ titulo, body, primero }) {
    const [expanded, setExpanded] = useState(primero === "true" ? true : false);

    const handleChange = (event, estadoEspandido) => {
        setExpanded(estadoEspandido);
    };

    return (
        <div>
            <Accordion expanded={expanded} onChange={handleChange}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <EstiloTypography className="estiloEspecifico">
                        {titulo}
                    </EstiloTypography>
                </AccordionSummary>
                <AccordionDetails>
                    {body}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
