import axios from "axios";

const urlPath = "technician";

export const createTec = async (data) => {
  const datos = {
    nombre: data.nombre,
    apellidos: data.apellidos,
    nif: data.nif,
    email: data.email,
    telefono: data.telefono,
    provincia: data.provincia,
    equipo: data.equipo
  };
  try {
    const response = await axios.post(urlPath, datos);

    return response;
  } catch (err) {
    console.log(err);
    return(err);
  }
};

export const getAllTecnicos = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getTecnicos = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params 
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const listTecnicos = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/listarBusqueda`, data.filtros, {
      params: data.params,
      
    });

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getTecnico = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const asignarTecnico = async (nif, id) => {
  const datos = {
    tech_id: id,
    fechaAddTech: new Date()
  };
  try {
    const response = await axios.put(`aaff/asignTech/${nif}`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteTecnico = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const getAllEquipos = async () => {
  try {
    const response = await axios.get(`${urlPath}/teams`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getEquipos = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/teams/filter`, data.filtros, {
      params: data.params 
    });

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
export const getEquipo = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/team/${id}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteEquipo = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/teams/${id}`);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

export const updateEquipo = async (data) => {
  const datos = {
    id: data.id,
    userIds: data.userIds,
    nombre: data.nombre,
  };
  try {
    const response = await axios.put(`${urlPath}/team`, datos);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};
export const createTechnicianTeam = async (data) => {
  const datos = {
    userIds: data.userIds,
    nombre: data.nombre,
  };
  try {
    const response = await axios.post(`${urlPath}/team`, datos);

    return response;
  } catch (err) {
    console.log(err.response);
  }
};