import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Desvincular from "../../../icons/desvincular.svg";
import {
  daysDiff,
  desvincularProveedor,
  desvincularProveedores,
} from "../../../functions/funciones";
import {
  subirDocumentacionProveedor,
  subirDeclaracionResponsable,
  validarDocumentacion,
  verDocumentacion,
  verDeclRes,
  validarDeclRes
} from "../../../providers/documentos";
import { getFinca, getFincaDocsSupp } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { TiArrowBack } from "react-icons/ti";
import { UsuarioContext } from "../../../providers/userContext";
import { CircularProgress } from "@mui/material";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FF5252",
    "&:hover": {
      backgroundColor: alpha("#FF5252", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FF5252",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#7bc977",
    backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    "&:hover": {
      backgroundColor: alpha("#7bc977", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#7bc977",
  },
}));

const CustomPendienteSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase": {
    color: "#FFD700",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#FFD700",
    backgroundColor: alpha("#FFD700", theme.palette.action.hoverOpacity),
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#FFD700",
  },
}));

const Proveedores = (props) => {
  const user = useContext(UsuarioContext)
  const [aaff, setAAFF] = useState({});
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [recargar, setRecargar] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const res =  await getFincaDocsSupp(props.params.nifFinca);
    setAAFF(res?.aaff);
    setFinca(res);
    let provs = res.suppliers.filter(objeto => objeto.status === true)
    setProveedores(provs)
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [!recargar]);


  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const comprobarArchivos = async (validado, status, ruta, id, idDoc, rol, nif) => {
    // Llama a la función importada y pasa la devolución de llamada
    if(validado && status){
     verDocumentacion(ruta, id, idDoc, rol, nif).then((resultado) => {
       handleSubirDocsCallback(resultado)
     })
    }else{
      subirDocumentacionProveedor(ruta, id, idDoc, rol, nif).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    }
  };

  const comprobarValidacion = async (ruta, id, nif) => {
    validarDocumentacion(ruta, id, nif).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  const comprobarDeclRes = async (nifProv, id, nifAAFF, ruta, idDeclRes, validado, status) => {
    // Llama a la función importada y pasa la devolución de llamada
    if(validado && status){
     verDeclRes(nifProv, id, nifAAFF, ruta, idDeclRes).then((resultado) => {
       handleSubirDocsCallback(resultado)
     })
    }else{
      subirDeclaracionResponsable(nifProv, id, ruta, idDeclRes).then((resultado) => {
        handleSubirDocsCallback(resultado)
      })
    }
  };
  const comprobarValDeclRes = async (ruta, idDeclRes, nifAAFF) => {
    
      validarDeclRes(ruta, idDeclRes, nifAAFF).then((resultado) => {
        
        handleSubirDocsCallback(resultado)
      })
  };

  const desvincularProv = async (nifFinca, nifProv) => {
    desvincularProveedor(nifFinca, nifProv).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }
  const desvincularProvs = async (nifFinca, provs) => {
    desvincularProveedores(nifFinca, provs).then((resultado) => {
      handleSubirDocsCallback(resultado)
    })
  }

  return (
    <>
      <BreadCrumb rutas={[{path:'/aaff', label: 'Administrador'},{path:`/aaff/${aaff.nif}/comunidades`, label: `${aaff.razonSocial}`},{path:`/aaff/${aaff.nif}/comunidades/${finca.nif}`, label: `${finca.razonSocial}`}, {path:`/aaff/${aaff.nif}/comunidades/${finca.razonSocial}/proveedores`, label: 'proveedores'}]} style={{ margin: '0 10px'}} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            LISTADO PROVEEDORES
            <Link to={`/aaff/${props.params.nifAAFF}/comunidades`}>
              <div className="gridHeadRight">
                {/* {"<< volver al listado de comunidades"} */}
                <TiArrowBack style={{ fontSize: 23}} />
              </div>
            </Link>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 20,
              paddingTop: 20,
            }}
          >
            <div
              className="bigBtn"
              onClick={() => desvincularProvs(finca.id, proveedores)}
            >
              <img src={Desvincular} alt="Desvincular" />
              DESVINCULAR TODOS
            </div>
          </div>
          <div className="tableContainer">
            <div className="tableWrapper">
              <table>
                <thead>
                  <tr>
                    <th>Proveedor</th>
                    <th>NIF</th>
                    <th>Servicio</th>
                    <th style={{ textAlign: "center" }}>Certificado LOPD</th>
                    <th style={{ textAlign: "center" }}>Seguro Resp. Civil</th>
                    <th style={{ textAlign: "center" }}>
                      Justificante pago SPA
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Planificación preventiva
                    </th>
                    <th style={{ textAlign: "center" }}>
                      Identificación riesgos
                    </th>
                    <th style={{ textAlign: "center" }}>Certificado Seg. Social</th>
                    <th style={{ textAlign: "center" }}>Vigilancia salud</th>
                    <th style={{ textAlign: "center" }}>
                      Declaración responsable
                    </th>
                    <th style={{ textAlign: "center" }}>Desvincular</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? <tr><td colSpan='15' style={{textAlign:'center'}}><CircularProgress style={{color:'#92004D'}} /> </td></tr> : 
                  proveedores.length===0 ? <tr><td colSpan='15' style={{textAlign:'center'}}>{"No hay proveedores"}</td></tr> :
                  proveedores.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          <Link
                            to={`/aaff/${props.params.nifAAFF}/proveedores/${e.nif}`}
                          >
                            {e.razonSocial}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/aaff/${props.params.nifAAFF}/proveedores/${e.nif}`}
                          >
                            {e.nif}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/aaff/${props.params.nifAAFF}/proveedores/${e.nif}`}
                          >
                            {e.serviceSupps.map((serv) => {
                              return serv.nombre + " ";
                            })}
                          </Link>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {e?.docssupps?.find( d => d.tipoDoc === 1 )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValidacion(e?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 1 )?.id, e.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.docssupps?.find( d => d.tipoDoc === 1 )?.validado}
                              onClick={
                                () => comprobarArchivos(
                                  e?.docssupps?.find( d => d.tipoDoc === 1 )?.validado,
                                  e?.docssupps?.find( d => d.tipoDoc === 1 )?.status,
                                  e?.docssupps?.find( d => d.tipoDoc === 1 )?.ruta,
                                  e?.docssupps?.find( d => d.tipoDoc === 1 )?.id, 
                                  1, 
                                  user.rol, 
                                  e.nif
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>-</small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {e?.docssupps?.find( d => d.tipoDoc === 2 )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValidacion(e?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 2 )?.id, e.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.docssupps?.find( d => d.tipoDoc === 2 )?.validado}
                              onClick={
                                () => comprobarArchivos(
                                  e?.docssupps?.find( d => d.tipoDoc === 2 )?.validado,
                                  e?.docssupps?.find( d => d.tipoDoc === 2 )?.status,
                                  e?.docssupps?.find( d => d.tipoDoc === 2 )?.ruta,
                                  e?.docssupps?.find( d => d.tipoDoc === 2 )?.id, 
                                  2, 
                                  user.rol, 
                                  e.nif
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>
                          {e?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad !==
                            null && e?.docssupps?.find( d => d.tipoDoc === 2 )?.status ? (
                              <span
                                style={{
                                  color:
                                    daysDiff(
                                      new Date(
                                        e?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                                      ),
                                      new Date()
                                    ) > 30
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {new Date(
                                  e?.docssupps?.find( d => d.tipoDoc === 2 )?.fechaCaducidad
                                ).toLocaleDateString()}
                              </span>
                            ) : (
                              "-"
                            )}
                          </small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {e?.docssupps?.find( d => d.tipoDoc === 3 )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValidacion(e?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 3 )?.id, e.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.docssupps?.find( d => d.tipoDoc === 3 )?.validado}
                              onClick={
                                () => comprobarArchivos(
                                  e?.docssupps?.find( d => d.tipoDoc === 3 )?.validado,
                                  e?.docssupps?.find( d => d.tipoDoc === 3 )?.status,
                                  e?.docssupps?.find( d => d.tipoDoc === 3 )?.ruta,
                                  e?.docssupps?.find( d => d.tipoDoc === 3 )?.id, 
                                  3, 
                                  user.rol, 
                                  e.nif
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>
                          {e?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad !==
                            null && e?.docssupps?.find( d => d.tipoDoc === 3 )?.status ? (
                              <span
                                style={{
                                  color:
                                    daysDiff(
                                      new Date(
                                        e?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                                      ),
                                      new Date()
                                    ) > 30
                                      ? "red"
                                      : "black",
                                }}
                              >
                                {new Date(
                                  e?.docssupps?.find( d => d.tipoDoc === 3 )?.fechaCaducidad
                                ).toLocaleDateString()}
                              </span>
                            ) : (
                              "-"
                            )}
                          </small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {e?.docssupps?.find( d => d.tipoDoc === 4 )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValidacion(e?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 4 )?.id, e.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.docssupps?.find( d => d.tipoDoc === 4 )?.validado}
                              onClick={
                                () => comprobarArchivos(
                                  e?.docssupps?.find( d => d.tipoDoc === 4 )?.validado,
                                  e?.docssupps?.find( d => d.tipoDoc === 4 )?.status,
                                  e?.docssupps?.find( d => d.tipoDoc === 4 )?.ruta,
                                  e?.docssupps?.find( d => d.tipoDoc === 4 )?.id, 
                                  4, 
                                  user.rol, 
                                  e.nif
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>-</small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {e?.docssupps?.find( d => d.tipoDoc === 5 )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValidacion(e?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 5 )?.id, e.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.docssupps?.find( d => d.tipoDoc === 5 )?.validado}
                              onClick={
                                () => comprobarArchivos(
                                  e?.docssupps?.find( d => d.tipoDoc === 5 )?.validado,
                                  e?.docssupps?.find( d => d.tipoDoc === 5 )?.status,
                                  e?.docssupps?.find( d => d.tipoDoc === 5 )?.ruta,
                                  e?.docssupps?.find( d => d.tipoDoc === 5 )?.id, 
                                  5, 
                                  user.rol, 
                                  e.nif
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>-</small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {e?.docssupps?.find( d => d.tipoDoc === 6 )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValidacion(e?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 6 )?.id, e.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.docssupps?.find( d => d.tipoDoc === 6 )?.validado}
                              onClick={
                                () => comprobarArchivos(
                                  e?.docssupps?.find( d => d.tipoDoc === 6 )?.validado,
                                  e?.docssupps?.find( d => d.tipoDoc === 6 )?.status,
                                  e?.docssupps?.find( d => d.tipoDoc === 6 )?.ruta,
                                  e?.docssupps?.find( d => d.tipoDoc === 6 )?.id, 
                                  6, 
                                  user.rol, 
                                  e.nif
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad !==
                              null && e?.docssupps?.find( d => d.tipoDoc === 6 )?.status ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {new Date(
                                    e?.docssupps?.find( d => d.tipoDoc === 6 )?.fechaCaducidad
                                  ).toLocaleDateString()}
                                </span>
                              ) : (
                                "-"
                              )}
                          </small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                        {e?.docssupps?.find( d => d.tipoDoc === 7 )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValidacion(e?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta, e?.docssupps?.find( d => d.tipoDoc === 7 )?.id, e.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.docssupps?.find( d => d.tipoDoc === 7 )?.validado}
                              onClick={
                                () => comprobarArchivos(
                                  e?.docssupps?.find( d => d.tipoDoc === 7 )?.validado,
                                  e?.docssupps?.find( d => d.tipoDoc === 7 )?.status,
                                  e?.docssupps?.find( d => d.tipoDoc === 7 )?.ruta,
                                  e?.docssupps?.find( d => d.tipoDoc === 7 )?.id, 
                                  7, 
                                  user.rol, 
                                  e.nif
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>
                            {e?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad !==
                              null && e?.docssupps?.find( d => d.tipoDoc === 7 )?.status ? (
                                <span
                                  style={{
                                    color:
                                      daysDiff(
                                        new Date(
                                          e?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                                        ),
                                        new Date()
                                      ) > 30
                                        ? "red"
                                        : "black",
                                  }}
                                >
                                  {new Date(
                                    e?.docssupps?.find( d => d.tipoDoc === 7 )?.fechaCaducidad
                                  ).toLocaleDateString()}
                                </span>
                              ) : (
                                "-"
                              )}
                          </small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {e?.declres?.find( d => d.aaff_id === aaff.id )?.pendiente ? (
                            <CustomPendienteSwitch
                              checked={false}
                              onClick={() => comprobarValDeclRes(e?.declres?.find( d => d.aaff_id === aaff.id )?.ruta, e?.declres?.find( d => d.aaff_id === aaff.id )?.id, aaff.nif)}
                            />
                          ) : (
                            <CustomSwitch
                              checked={e?.declres?.find( d => d.aaff_id === aaff.id )?.validado}
                              onClick={
                                () => comprobarDeclRes(
                                  e.nif,
                                  aaff.id,
                                  aaff.nif,
                                  e?.declres?.find( d => d.aaff_id === aaff.id )?.ruta,
                                  e?.declres?.find( d => d.aaff_id === aaff.id )?.id,
                                  e?.declres?.find( d => d.aaff_id === aaff.id )?.validado,
                                  e?.declres?.find( d => d.aaff_id === aaff.id )?.status, 
                                )
                              }
                            />
                          )}
                          <small style={{ display: "block" }}>-</small>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <img
                            src={Desvincular}
                            alt="Desvincular"
                            className="tableIcon"
                            onClick={() => desvincularProv(finca.id, e.id)}
                          ></img>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <input type="file" id="fileElem" hidden></input>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Proveedores);
