import { useCallback } from "react";
import { toast } from "react-toastify";

export const useToastHook = () => {
  // Mostrar un toast inicial con tiempo configurable
  const showToast = useCallback((message, options = {}) => {
    const { duration = 2500 } = options; // Valor predeterminado: 5000ms (5 segundos)
    return toast.info(message, { autoClose: duration, ...options });
  }, []);

  // Actualizar un toast existente
  const updateToast = useCallback((toastId, message, type = "info", duration = 2500) => {
    toast.update(toastId, {
      render: message,
      type: toast.TYPE[type.toUpperCase()], // success, error, etc.
      autoClose: duration, // Tiempo de cierre configurable
    });
  }, []);

  // Cerrar un toast
  const closeToast = useCallback((toastId) => {
    toast.dismiss(toastId);
  }, []);

  return { showToast, updateToast, closeToast };
};
