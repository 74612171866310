import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Typography, CircularProgress } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { BsSearch } from "react-icons/bs";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { editarAAFF, getAAFFs, getTecnico, visitTec } from "../../../services";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { formatoFechas, formatoFechasEs } from "../../../functions/funciones";
import Lupa from "../../../icons/lupa.svg";
import { ImCross } from "react-icons/im";
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';



const AAFFTecnico = (props) => {
  const user = useContext(UsuarioContext);
  const [recargar, setRecargar] = useState(0);
  //   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [desde, setDesde] = useState(undefined);
  const [hasta, setHasta] = useState(undefined);
  const [tecnico, setTecnico] = useState({});
  const [msjFincas, setMsjFincas] = useState("");
  const [cargandoVisitas, setCargandoVisitas] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    // fetchData();
    setRecargar(false);
  }, [recargar]);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    
    
    setDesde(undefined);
    setHasta(undefined);
    setBusqueda({});
    setClearFilterActivo(true);
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {

  
    delete filtros.tech_id;

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order,orderBy);
    
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    try {
      setLoading(true);
      const responseTecnico = await getTecnico(props.params.nif)
      const payload = {
        filtros: { ...filtros, tech_id: responseTecnico.id },
        params: { page: offset, limit, order,orderBy },
      };
      const response = await getAAFFs(payload);
      setTecnico(responseTecnico)
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const cambiarFechaEncargo = (nif) => {
    Swal.fire({
      title: "Cambiar fecha encargo",
      html: `<input type="date" id="fecha" class="swal2-input">`,
      confirmButtonText: "Cambiar",
      focusConfirm: false,
      preConfirm: () => {
        const fecha = Swal.getPopup().querySelector("#fecha").value;
        if (!fecha) {
          Swal.showValidationMessage(`insertar fecha`);
        }
        return { fecha: fecha };
      },
    }).then((result) => {
      let data = {
        nif: nif,
        // fechaEncargo: formatoFechas(result.value.fecha),
      };
      editarAAFF(data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            toast.success("Datos editados con éxito");
            setRecargar(!recargar);
          } else {
            let mensajes = [];

            res.data.error.map((err) => {
              mensajes = err;
              console.log(err.message);
              toast.error("error al editar");
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error al editar los datos");
        });
    });
  };
  const AAFFRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
      overrideFunc: (data, row) => (
        <Link to={"/tecnicos/" + row.paramNif + "/aaff/" + row.nif + "/comunidades"}>
          {data}
        </Link>
      ),
    },
    // {
    //   id: "fechaEncargo",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "FECHA ENCARGO",
    //   overrideFunc: (data, row) => (
    //     <Typography onClick={() => cambiarFechaEncargo(row.nif)} style={{ textAlign: 'left' }}>
    //       {data ? formatoFechasEs(data) : "-"}
    //     </Typography>
    //   ),
    // },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    // {
    //   id: "localizacion",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "LOCALIZACIÓN",
    // },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    // {
    //   id: "numeroComunidades",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Nº COMUNIDADES",
    //   overrideFunc: (data) => (
    //     <Typography style={{ textAlign: 'left' }}>
    //       {data}
    //     </Typography>
    //   ),
    // },
    {
      id: "comunidadesVisitadas",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES VISITADAS",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <ProgressBarWithColors
          value={data}
          label={
            <Typography>
              {row.visitadasCount} /{" "}
              {row.pendientesCount + row.visitadasCount}
            </Typography>
          }
        />
      ),
    },
    {
      id: "comunidadesRenovasVisitadas",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES RENOVADAS VISITADAS",
      noObjeto:true,
      overrideFunc: (data, row) => (

        <ProgressBarWithColors
          value={data}
          label={
            <Typography>
              {row.renovadasVisitadasCount} /{" "}
              {row.renovadasCount + row.renovadasVisitadasCount}
            </Typography>
          }
        />
      ),
    },
    {
      id: "hojaVisita",
      numeric: false,
      disablePadding: false,
      label: "HOJAS DE VISITA",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <Link to={"/tecnicos/" + row.paramNif + "/aaff/" + row.nif + "/hojas-visita"}>
          <img src={Lupa} alt="Hojas de visita" className="tableIcon" />
        </Link>
      )
    },
  ];

  const formatData = (data) => {
    return data.map((row) => {
      const comunidadesActivas = row.properties?.filter(objeto => objeto.status === true) ?? [];
      return {
        razonSocial: row.razonSocial || row.propertie?.aaff?.nombreContacto,
        // fechaEncargo: row.fechaEncargo || '',
        nif: row.nif,
        id: row.id,
        localizacion: row.municipio + "-" + row.provincia,
        municipio: row.municipio,
        provincia: row.provincia,
        numeroComunidades: comunidadesActivas.length,
        comunidades: comunidadesActivas,
        pendientesCount: row.pendientesCount,
        visitadasCount: row.visitadasCount,
        renovadasCount: row.renovadasCount,
        renovadasVisitadasCount: row.renovadasVisitadasCount,
        comunidadesVisitadas: row.visitadasCount / (row.pendientesCount + (row.visitadasCount || 1)) * 100 ?? 0,
        comunidadesRenovasVisitadas: row.renovadasVisitadasCount / (row.renovadasCount + (row.renovadasVisitadasCount || 1)) * 100 ?? 0,
        hojaVisita: row,
        paramNif: props.params.nif
      }
    });
  };
  const filtrarFechas = async () => {
    let d = desde
    let h = hasta
    let msjFincas = ''
    setCargandoVisitas(true)
    if (h < d || d === "Invalid Date" || h === "Invalid Date") {
      msjFincas = "Las fechas no son correctas"
      setMsjFincas(msjFincas)
      setCargandoVisitas(false)
    } else {
      let data = {
        tech_id: tecnico.id,
        desde: d,
        hasta: h
      };
      const resp = await visitTec(data);
      const numFincasVisitadas = resp.filter(item => item.detailsCae.sales.servp_id === 1).length;
      const numFincasVisitadasRenovadas = resp.filter(item => item.detailsCae.sales.servp_id === 3).length;
      if (numFincasVisitadas === 0 && numFincasVisitadasRenovadas === 0) {
        msjFincas = "No se han realizado visitas en este periodo de tiempo"
      } else {
        msjFincas = "Se han realizado " + numFincasVisitadas + " visitas nuevas y " + numFincasVisitadasRenovadas + " visitas de renovación en este periodo de tiempo"
      }
      setMsjFincas(msjFincas)
      setCargandoVisitas(false)
    }
  }
  function filtrarFechas1() {

    let d = desde
    let h = hasta

    const fechas = [
      {
        fechaVisita: '2022-01-15',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-02-20',
        fechaVisitaRenovacion: '2023-02-20',
      },
      {
        fechaVisita: '2023-09-10',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-04-05',
        fechaVisitaRenovacion: '2023-04-05',
      },
      {
        fechaVisita: '2022-05-30',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-06-25',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-07-12',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2023-08-08',
        fechaVisitaRenovacion: '1970-01-01',
      },
      {
        fechaVisita: '2022-09-22',
        fechaVisitaRenovacion: '2023-09-08',
      },
      {
        fechaVisita: '2022-10-17',
        fechaVisitaRenovacion: '1970-01-01',
      },
    ];

    let numFincasVisitadas = 0
    let numFincasVisitadasRenovadas = 0

    setCargandoVisitas(true)
    let msjFincas = ''
    if (h < d || d === "Invalid Date" || h === "Invalid Date") {
      msjFincas = "Las fechas no son correctas"
      setMsjFincas(msjFincas)
      setCargandoVisitas(false)
    } else {
      let data = {
        tech_id: tecnico.id,
      };
      fechas.forEach(f => {
        if (d <= f.fechaVisita && h >= f.fechaVisita || d <= f.fechaVisitaRenovacion && h >= f.fechaVisitaRenovacion) {
          if (f.fechaVisitaRenovacion > f.fechaVisita) {
            numFincasVisitadasRenovadas++
          }
          else {
            numFincasVisitadas++
          }
        }
      })
      if (numFincasVisitadas === 0 && numFincasVisitadasRenovadas === 0) {
        msjFincas = "No se han realizado visitas en este periodo de tiempo"
      } else {
        msjFincas = "Se han realizado " + numFincasVisitadas + " visitas nuevas y " + numFincasVisitadasRenovadas + " visitas de renovación en este periodo de tiempo"
      }
      setMsjFincas(msjFincas)
      setCargandoVisitas(false)
    }
  }




  return (
    <>
      <BreadCrumb rutas={[{ path: '/tecnicos', label: `Tecnicos` }, { path: '/tecnicos', label: `${tecnico.nombre + " " + tecnico.apellidos}` }]} style={{ margin: '0 10px' }} />
      <div className="pageContent tableContainer">

        <div className="tableWrapper">
          <div className="buscador">
            <input
              type="date"
              placeholder="Desde"
              value={desde || ""}
              onChange={(event) => setDesde(event.currentTarget.value)}
            />
            <input
              type="date"
              placeholder="Hasta"
              value={hasta || ""}
              onChange={(event) => setHasta(event.currentTarget.value)}
            />
            <button className="btn-buscador" onClick={() => clearFilters()}>
              <ImCross />
            </button>
            <button onClick={() => filtrarFechas()}><BsSearch /></button>
            <div>
              {cargandoVisitas ? (
                <CircularProgress
                  style={{
                    color: "#92004D",
                    height: "20px",
                    width: "20px",
                    padding: "0",
                  }}
                />
              ) : (
                <p>{msjFincas}</p>
              )}
            </div>
          </div>
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={AAFFRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
                enumerate={true}
              />
            </Box>
          </Box>
        </div>
      </div>

    </>
  );
};

export default withRouter(AAFFTecnico);
