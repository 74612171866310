import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createAAFF, getAllCommercials } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { useNavigate } from "react-router-dom";
import { verificarNif, verificarCorreo, verificarTelefono, verificarCodigoPostal } from "../../../functions/validator/formValidator";
const NuevoAAFF = () => {

  const navigate = useNavigate();
  const [aaff, setAaff] = useState({})
  const [comerciales, setComerciales] = useState([])
  const [listadoMunicipios, setListadoMunicipios] = useState([])
  const [provincia, setProvincia] = useState('')

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const response = await getAllCommercials()
    setComerciales(response)
  }

  const handleChange = (evt) => {
    const value = evt.target.value;
    setAaff({
      ...aaff,
      [evt.target.name]: value,
    });

  }

  const seleccionarMunicipios = (e) => {
    let provincia = ""
    let municipios = []
    provinces.map(p => {
      if (p.code === e.target.value) {
        provincia = p.name
      }
    })
    // setProvincia(provincia)
    setAaff({
      ...aaff,
      provincia,
    });

    municipalities.map(m => {
      if (m.provCode === e.target.value) {

        municipios.push(m)
      }
    })
    setListadoMunicipios(municipios)
  }

  const crearAAFF = async (e) => {
    e.preventDefault();
    let nifCorrecto = verificarNif(aaff.nif);
    let emailCorrecto = verificarCorreo(aaff.email);
    let telefonoCorrecto = verificarTelefono(aaff.telefono);
    let codigoPostalCorrecto = verificarCodigoPostal(aaff.codigoPostal);
    let toastInfo = toast.info("Guardando datos...");

    let errores = false;

    if (Object.keys(aaff).length === 0) {
        toast.dismiss(toastInfo);
        toast.error("El administrador de fincas está vacío");
        errores = true;
    } else {
        if (nifCorrecto === false && aaff.nif !== undefined) {
            toast.dismiss(toastInfo);
            toast.error("El NIF introducido es incorrecto");
            errores = true;
        }
        if (emailCorrecto === false && aaff.email !== undefined) {
            toast.dismiss(toastInfo);
            toast.error("El correo introducido es incorrecto");
            errores = true;
        }
        if (telefonoCorrecto === false && aaff.telefono !== undefined) {
            toast.dismiss(toastInfo);
            toast.error("El teléfono introducido es incorrecto");
            errores = true;
        }

        if (codigoPostalCorrecto === false && aaff.codigoPostal !== undefined) {
          toast.dismiss(toastInfo);
          toast.error("El código postal introducido es incorrecto");
          errores = true;
      }

        if (!errores) {
          const response = await createAAFF(aaff);

          console.log("response.data",response.data);
      
          if (response.status === 403) {
              toast.update(toastInfo, {
                  render: response.data.error[0],
                  type: toast.TYPE.ERROR,
                  autoClose: true,
              });
          } else if (response.data === "ERROR_CREATE_AAFF") {
              toast.update(toastInfo, {
                  render: "Error al crear el administrador",
                  type: toast.TYPE.ERROR,
                  autoClose: true,
              });
          } else if (response.data === "ERROR_AAFF_EXIST") {
              toast.update(toastInfo, {
                  render: "El administrador ya existe",
                  type: toast.TYPE.ERROR,
                  autoClose: true,
              });
            }
          else{
            const requiredFields = ['nif', 'email'];
          let campoVacio = false;
          for (const field of requiredFields) {
              if (!aaff[field]) {
                  campoVacio = true;
                  break;
              }
          }
      
          if (campoVacio) {
              toast.update(toastInfo, {
                  render: "Hay algún campo obligatorio",
                  type: toast.TYPE.ERROR,
                  autoClose: true,
              });
          } else {
             
            
               if (response.status === 200) {
                  toast.update(toastInfo, {
                      render: "El AAFF se ha creado correctamente",
                      type: toast.TYPE.SUCCESS,
                      autoClose: true,
                  });
                  setTimeout(() => {
                      navigate('/aaff');
                  }, 2000);
              }
          }
          }
            
      }
      
    }
};

  return (
    <>
      <BreadCrumb rutas={[{ path: '/aaff', label: "Administradores de fincas" }, { path: '/aaff', label: "Nuevo" }]} style={{ margin: '0 10px' }} />
      <div className="pageContent">
        <div className="gridHead">
          Crear nuevo Administrador de Fincas
        </div>
        <form className="nuevoAAFFForm" onSubmit={crearAAFF}>
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="NIF"
                name="nif"
                value={aaff.nif}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Razón social"
                name="razonSocial"
                value={aaff.razonSocial}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                placeholder="Email"
                name="email"
                value={aaff.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="tel"
                placeholder="Telefono"
                name="telefono"
                value={aaff.telefono}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                name="provincia"
                onChange={(e) =>
                  seleccionarMunicipios(e)
                }
              >
                <option value={aaff.provincia}>Provincia</option>
                {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <option key={i} value={e.code}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                name="municipio"
                onChange={handleChange}
              >
                <option value={aaff.municipio}>Municipio</option>
                {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="codigoPostal"
                placeholder="codigoPostal"
                name="codigoPostal"
                value={aaff.codigoPostal}
                onChange={handleChange}
              ></input>
            </Grid>

            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Persona de contacto"
                name="nombreContacto"
                value={aaff.contacto}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                name="commer_id"
                onChange={handleChange}
              >
                <option value={0}>Comercial</option>
                {comerciales?.map((e, i) => {
                  return (
                    <option key={i} value={e.id}>
                      {e.nombre + " " + e.apellidos}
                    </option>
                  );
                })}
              </select>
            </Grid>
          </Grid>
          <input type="submit" value="Guardar y publicar"></input>
        </form>
      </div>
    </>
  );
}

export default NuevoAAFF;