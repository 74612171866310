import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from "../../providers/withRouter";
import { BsSearch } from "react-icons/bs";
import { getConsultores, getServiciosFincas, listTecnicos, listCosultor } from '../../services';
import { UsuarioContext } from '../../providers/userContext';
import { ordenarNombreYApellido, orderArray } from '../../functions/ordenarListas';
import { ImCross } from "react-icons/im";
import { listAAFFs } from '../../services';
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,

} from "@mui/material";

import Acordeon from './acordeonFiltros';

import SearchProgresState from "./SearchProgresState";
import { useLocation } from "react-router-dom";


const FiltrosVentas = ({ onFilter, clearFilter, filtrosIniciales }) => {


  const { rol } = useContext(UsuarioContext)

  const [busqueda, setBusqueda] = useState(filtrosIniciales)
  const anoActual = new Date().getFullYear();

  const [servicios, setServicios] = useState([])
  const [aaff, setAAFF] = useState([])
  const [tecnicos, setTecnicos] = useState([])
  const [consultores, setConsultores] = useState([])
  const user = useContext(UsuarioContext)
  const [typeVentaInicio, setTypeVentaInicio] = useState('text');
  const [typeVentaFin, setTypeVentaFin] = useState('text');
  const [typeCreacionInicio, setTypeCreacionInicio] = useState('text');
  const [typeCreacionFin, setTypeCreacionFin] = useState('text');
  const [listAnos, setListAnos] = useState([]);
  const location = useLocation();
  const [sinConsul, setSinConsul] = useState(false);


  const handleFocusVentaInicio = () => setTypeVentaInicio('date');
  const handleBlurVentaInicio = () => {
    if (!busqueda.fechaVentaInicio) setTypeVentaInicio('text');
  };
  const handleFocusVentaFin = () => setTypeVentaFin('date');
  const handleBlurVentaFin = () => {
    if (!busqueda.fechaVentaFin) setTypeVentaFin('text');
  };
  const handleFocusCreacionInicio = () => setTypeCreacionInicio('date');
  const handleBlurCreacionInicio = () => {
    if (!busqueda.fechaCreacionInicio) setTypeCreacionInicio('text');
  };
  const handleFocusCreacionFin = () => setTypeCreacionFin('date');
  const handleBlurCreacionFin = () => {
    if (!busqueda.fechaCreacionFin) setTypeCreacionFin('text');
  };

  useEffect(() => {
    getDatas();
    listadoAnos();
    if (user.rol === "AAFF" && !busqueda.ano) {
      setBusqueda((prevBusqueda) => ({
        ...prevBusqueda,
        ano: anoActual
      }));
    }
  }, []);

  useEffect(() => {
    cargarUser();
  }, []);

  const cargarUser = () => {
    if (user.rol === "CONSUL" && sinConsul == false) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id
      });
    }
    localStorage.setItem("hasReloaded", "false");
  }
  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {
      clearAllFilters();
      cargarUser();
      localStorage.setItem("hasReloaded", "false");
    }

  }, [location]);

  const clearAllFilters = () => {


    setBusqueda({});
    setTypeVentaInicio('text');
    setTypeVentaFin('text');
    setTypeCreacionInicio('text');
    setTypeCreacionFin('text');
    clearFilter();
    cargarUser();

  }

  const listadoAnos = () => {

    let Anos = []
    for (let i = 2021; i <= anoActual; i++) {
      Anos.push(i)
    }
    setListAnos(Anos)
  }



  const getDatas = async () => {

    const serviciosFincas =
      user.rol === "AAFF" || user.rol === "ADMIN" || user.rol === "CONSUL" || user.rol === 'TECH' || user.rol == 'COMM'
        ? await getServiciosFincas()
        : [];
    if ((user.rol === "AAFF") && serviciosFincas?.length > 0) {
      setServicios(serviciosFincas.filter(item => user.servicios?.includes(item.id) && item.id !== 6 && item.id !== 7))
    }
    else {
      setServicios(serviciosFincas)
    }

    const consul =
      user.rol === "CONSUL"
        ? await getConsultores({ filtros: { id: user.id } })
        : [];

    if (user.rol === "CONSUL" && consul.data?.length > 0) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id,
      });

      setConsultores(ordenarNombreYApellido(consul.data));
    }

  };


  const handleAsynchronousChange = (name, selectedValue) => {
    setBusqueda({
      ...busqueda,
      [name]: selectedValue?.id,
    });
  };

  const busquedaInput = (name, valorBusqueda) => {
    console.log(name, valorBusqueda)
    if (name === 'aaff_id' && user.rol !== 'AAFF' && (busqueda.aaff_id !== undefined || valorBusqueda)) {
      listAAFFs({
        filtros: {
          razonSocial: valorBusqueda
        }, params: {}
      }).then(res => {
        setAAFF(orderArray(res.data))
      })
    }

    if (name === 'tech_id' && (busqueda.tech_id !== undefined || valorBusqueda)) {

      listTecnicos({
        filtros: {
          nombreA: valorBusqueda
        }, params: {}
      }).then(res => {
        setTecnicos(ordenarNombreYApellido(res.data))
      })
    }


    if (name === 'consul_id' && (busqueda.consul_id !== undefined || valorBusqueda)) {


      listCosultor({
        filtros: {
          nombreA: valorBusqueda
        }, params: {}
      }).then(res => {
        setConsultores(ordenarNombreYApellido(res.data))
      })
    }

  };

  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    if (busqueda.consul_id == undefined) {
      setSinConsul(true);
    }
    onFilter(busqueda);
  };

  return (
    <>
      <div className="buscadorFiltros">

        <Box
          mx={1}
          display="flex"
          flexDirection="column"
          gap={2}
        >

          {rol !== "AAFF" && (
            <div className="filtrosContent">
              <label className='titleLabel'>Filtros Roles</label>
              {rol !== "AAFF" && (
                <>
                  {(user.rol !== 'SUPP' && user.rol !== 'AAFF') &&
                    <>
                      {/* <TextField
                        id="aaff-input"
                        labelId="aaff-label"
                        label="Razón social AAFF"
                        name="aaffNombre"
                        className="filtros altura"
                        value={busqueda.aaffNombre || ""}
                        onChange={handleChange}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        inputProps={{ "aria-label": "controlled" }}
                      /> */}
                      <FormControl variant="outlined" className="filtros-select">
                        <SearchProgresState
                          labelId="aaff-label"
                          id="aaff-select"
                          label="Administrador de fincas"
                          name="aaff_id"
                          className="filtros altura"
                          tipo="razonSocial"
                          array={aaff}
                          onOptionSelected={handleAsynchronousChange}
                          busquedaInput={busquedaInput}
                          valueOnLoad={aaff.find(c => c.id === busqueda.aaff_id) || null}
                        />
                      </FormControl>
                      <TextField
                        id="aaffNif-input"
                        labelId="aaffNif-label"
                        label="Nif AAFF"
                        name="aaffNif"
                        className="filtros altura"
                        value={busqueda.aaffNif || ""}
                        onChange={handleChange}
                        onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </>
                  }
                  {(user.rol === 'ADMIN' || user.rol === 'CONSUL') && (
                    <>
                      <FormControl variant="outlined" className="filtros-select">
                        <SearchProgresState
                          labelId="tecnicos-label"
                          id="tecnicos-select"
                          label="Técnicos"
                          name="tech_id"
                          className="filtros"
                          tipo="nombre"
                          array={tecnicos}
                          onOptionSelected={handleAsynchronousChange}
                          busquedaInput={busquedaInput}
                          valueOnLoad={tecnicos.find(c => c.id === busqueda.tech_id)}
                        />
                      </FormControl>
                      <FormControl variant="outlined" className="filtros-select">
                        <SearchProgresState
                          labelId="consultores-label"
                          id="consultores-select"
                          label="Consultores"
                          name="consul_id"
                          className="filtros"
                          tipo="nombre"
                          array={consultores}
                          onOptionSelected={handleAsynchronousChange}
                          busquedaInput={busquedaInput}
                          valueOnLoad={consultores.find(c => c.id === busqueda.consul_id)}
                        />
                      </FormControl>

                    </>
                  )}

                </>
              )}
            </div>
          )}
          <div className="filtros-container">
            <div className='filtrosContent'>
              <label className='titleLabel'>{user.rol === "AAFF" ? <></> : "Filtros ventas"} </label>
              <FormControl variant="outlined" className="filtros-select">
                <InputLabel id="ano-label">Año</InputLabel>
                <Select
                  labelId="ano-label"
                  id="ano-select"
                  label="Año venta"
                  name="ano"
                  className="filtros"
                  value={busqueda.ano || ""}
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                  <MenuItem value="" style={{ height: 40 }}></MenuItem>
                  {listAnos?.map((e, i) => (
                    <MenuItem key={i} value={Number(e)}>
                      {e}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>


              {(user.rol !== 'SUPP' && user.rol !== 'AAFF') && (
                <>
                  <TextField
                    type={typeVentaInicio}
                    id="fechaVentaInicio-input"
                    label="Fecha venta (Desde)"
                    variant="outlined"
                    name='fechaVentaInicio'
                    className="filtros altura"
                    value={busqueda.fechaVentaInicio || ""}
                    onChange={handleChange}
                    onFocus={handleFocusVentaInicio}
                    onBlur={handleBlurVentaInicio}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <TextField
                    type={typeVentaFin}
                    id="fechaVentaFin-input"
                    label="Fecha venta (Hasta)"
                    variant="outlined"
                    name='fechaVentaFin'
                    className="filtros altura"
                    value={busqueda.fechaVentaFin || ""}
                    onChange={handleChange}
                    onFocus={handleFocusVentaFin}
                    onBlur={handleBlurVentaFin}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </>
              )}

              {(user.rol !== 'SUPP' && user.rol !== 'COMM' && user.rol !== 'AAFF') && (
                <>
                  <TextField
                    type={typeCreacionInicio}
                    id="fechaCreacionInicio-input"
                    label="Fecha realización (Desde)"
                    variant="outlined"
                    name='fechaCreacionInicio'
                    className="filtros altura"
                    value={busqueda.fechaCreacionInicio || ""}
                    onChange={handleChange}
                    onFocus={handleFocusCreacionInicio}
                    onBlur={handleBlurCreacionInicio}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <TextField
                    type={typeCreacionFin}
                    id="fechaCreacionFin-input"
                    label="Fecha realización (Hasta)"
                    variant="outlined"
                    name='fechaCreacionFin'
                    className="filtros altura"
                    value={busqueda.fechaCreacionFin || ""}
                    onChange={handleChange}
                    onFocus={handleFocusCreacionFin}
                    onBlur={handleBlurCreacionFin}
                    onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </>
              )}

              {rol !== "AAFF" && (
                <>
                  <FormControl variant="outlined" className='filtros-select'>
                    <InputLabel id="estado-label">Estado</InputLabel>
                    <Select
                      labelId="estado-label"
                      id="estado-select"
                      label="Estado"
                      name="visitada"
                      className="filtros"
                      value={busqueda?.visitada ?? ""}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    >
                      <MenuItem value="" style={{ height: 40 }}>Todos</MenuItem>
                      <MenuItem value={false} style={{ height: 40 }}>Pendiente</MenuItem>
                      <MenuItem value={true} style={{ height: 40 }}>Visitada</MenuItem>
                    </Select>
                  </FormControl>


                </>
              )}
            </div>
            <div className="filtrosContent">
              <label className='titleLabel'>{user.rol === "AAFF" ? <></> : "Filtros comunidad"} </label>
              <FormControl variant="outlined" className='filtros-select'>
                <TextField
                  id="razonSocial-input"
                  label="Comunidad"
                  variant="outlined"
                  name='razonSocial'
                  className="filtros altura"
                  value={busqueda.razonSocial || ""}
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </FormControl>
              <TextField
                id="nif-input"
                label="NIF comunidad"
                variant="outlined"
                name='nif'
                className="filtros altura"
                value={busqueda.nif || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                inputProps={{ "aria-label": "controlled" }}
              />
              <TextField
                id="codigoPostal-input"
                label="Código postal"
                variant="outlined"
                name='codigoPostal'
                className="filtros altura"
                value={busqueda.codigoPostal || ""}
                onChange={handleChange}
                onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                inputProps={{ "aria-label": "controlled" }}
              />
              <FormControl variant="outlined" className='filtros-select'>
                <InputLabel id="servicio-label">Servicio</InputLabel>
                <Select
                  labelId="servicio-label"
                  id="servicio-select"
                  label="Servicio"
                  name="servp_id"
                  className="filtros"
                  value={busqueda.servp_id || ""}
                  onChange={handleChange}
                  onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                >
                  <MenuItem value="" style={{ height: 40 }}></MenuItem>
                  {servicios?.map((e, i) => (
                    (rol === "TECH" && (e.id === 1 || e.id === 3)) || rol !== "TECH" ? (
                      <MenuItem key={i} value={Number(e.id) || ""}>
                        {e.nombre}
                      </MenuItem>
                    ) : null
                  ))}
                </Select>
              </FormControl>

              {rol !== "AAFF" && rol !== "TECH" && (
                <>

                  <FormControl variant="outlined" className='filtros-select'>
                    <InputLabel id="videoVigilancia-label">Videovigilancia</InputLabel>
                    <Select
                      labelId="videoVigilancia-label"
                      id="videoVigilancia-select"
                      label="videoVigilancia"
                      name="surveillanceVideo"
                      className="filtros"
                      value={busqueda?.surveillanceVideo ?? ""}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    >
                      <MenuItem value="" style={{ height: 40 }}></MenuItem>
                      <MenuItem value={false}> NO </MenuItem>
                      <MenuItem value={Boolean(true)}> SÍ </MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className='filtros-select'>
                    <InputLabel id="trabajadores-label">Trabajadores</InputLabel>
                    <Select
                      labelId="trabajadores-label"
                      id="trabajadores-select"
                      label="trabajadores"
                      name="workers"
                      className="filtros"
                      value={busqueda?.workers ?? ""}
                      onChange={handleChange}
                      onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
                    >
                      <MenuItem value="" style={{ height: 40 }}></MenuItem>
                      <MenuItem value={Boolean(false)}> NO </MenuItem>
                      <MenuItem value={Boolean(true)}> SÍ </MenuItem>
                    </Select>
                  </FormControl>
                </>
              )}
            </div>


          </div>

        </Box>

        <div>
          <Button className="btn-buscador" onClick={clearAllFilters}>
            <ImCross />
          </Button>

        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
            <BsSearch />
          </Button>
        </div>
      </div>

      <style jsx="true">
        {`
          .btn-buscador {

            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004d;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width: 40px;
            height: 40px;
            margin: 5px 10px 5px;
          }
          .btn-buscador:hover {
            color: black;
            background-color: #dee2e6;
          }
        `}
      </style>
    </>
  );
}

export default withRouter(FiltrosVentas);