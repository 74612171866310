import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./routes/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import HojaVisitaRender from "./routes/pages/tecnico/HojaVisitaRender";
import EvaluacionRiesgosRender from "./routes/pages/tecnico/EvaluacionRiesgosRender";
/*import PlanPrevencionRender from "./routes/pages/tec/PlanPrevencionRender"; */
import PlanEmergenciaRender from "./routes/pages/tecnico/PlanEmergenciaRender";
/*import HojaVisitaRenderCentroTrabajo from "./routes/pages/tec/HojaVisitaRenderCentroTrabajo";
import EvaluacionRiesgosRenderCentroTrabajo from "./routes/pages/tec/EvaluacionRiesgosRenderCentroTrabajo";
import PlanPrevencionRenderCentroTrabajo from "./routes/pages/tec/PlanPrevencionRenderCentroTrabajo";
import PlanEmergenciaRenderCentroTrabajo from "./routes/pages/tec/PlanEmergenciaRenderCentroTrabajo";*/
import InformeSituacionProveedores from "./routes/pages/admin/InformeSituacionProveedores";
import InformeSituacionComunidades from "./routes/pages/admin/InformeSituacionComunidades";
import InformeComunidadesSinProveedores from "./routes/pages/admin/InformeComunidadesSinProveedores";
import InformeSituacionRiesgos from "./routes/pages/admin/InformeSituacionRiesgos";
import PDF from "./providers/pdf";
import { AxiosInterceptor } from "./interceptors/axios.interceptor";
import CertificadoVigilancia from "./components/pdfs/certificadosVigilancia";
import CertificadosRGPD from "./components/pdfs/certificadosRGPD";
import CertificadosRGPD_Reno from "./components/pdfs/certificadosRGPD_Reno";
import DocumentacionLOPD from "./components/pdfs/documentacionLOPD";
import DocumentacionLOPD_Reno from "./components/pdfs/documentacionLOPD_Reno";
import CambioPassword from "./routes/changePassword"
import NuevaPassword from "./routes/newPassword"
import GenerarPassword from "./routes/generatePassword"
import HojaVisitaPDF from "./routes/pages/admin/HojaVisitaPDF";
import PlanEmergenciaPDF from "./routes/pages/admin/PlanEmergenciaPDF";
import EvaluacionRiesgosPDF from "./routes/pages/admin/EvaluacionRiesgosPDF";
import { UsuarioProvider } from "./providers/userContext";
import { createTheme, ThemeProvider } from '@mui/material/styles';

AxiosInterceptor();

const theme = createTheme({
  palette: {
    primary: {
      main: '#92004D',
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path="/cambiarPassword" element={<CambioPassword />} />
        <Route path="/nuevaPassword/:token" element={<NuevaPassword />} />
        <Route path="/generarPassword/:token" element={<GenerarPassword />} />
        {/* <Route path="passwordset" element={<PasswordSet />} /> */}
        <Route path="login" element={<Login />} />
        <Route
          path="/doc-render/:nif/:id/hoja-visita"
          element={<HojaVisitaPDF />}
        />
        <Route
          path="/doc-render/:nif/:idCentroTrabajo/:id/hoja-visita"
          element={<HojaVisitaPDF />}
        />
         <Route path="/doc-render/:nif/:id/identificacion-riesgos" element={<EvaluacionRiesgosPDF />} />
         <Route path="/doc-render/:nif/:idCentroTrabajo/:id/identificacion-riesgos" element={<EvaluacionRiesgosPDF />} />
        {/*  <Route path="/doc-render/:nif/plan-prevencion" element={<PlanPrevencionRender />} /> */}
        <Route
          path="/doc-render/:nif/:id/plan-emergencia"
          element={<PlanEmergenciaPDF />}
        />
        <Route
          path="/doc-render/:nif/:idCentroTrabajo/:id/plan-emergencia"
          element={<PlanEmergenciaPDF />}
        />
        {/* <Route path="/doc-render/:nif/:nifCentroTrabajo/hoja-visita" element={<HojaVisitaRenderCentroTrabajo />} />
          <Route path="/doc-render/:nif/:nifCentroTrabajo/evaluacion-riesgos" element={<EvaluacionRiesgosRenderCentroTrabajo />} />
          <Route path="/doc-render/:nif/:nifCentroTrabajo/plan-prevencion" element={<PlanPrevencionRenderCentroTrabajo />} />
          <Route path="/doc-render/:nif/:nifCentroTrabajo/plan-emergencia" element={<PlanEmergenciaRenderCentroTrabajo />} /> */}
        <Route
          path="/doc-render/:nif/situacion-proveedores"
          element={<InformeSituacionProveedores />}
        />
        <Route
          path="/doc-render/:nif/situacion-comunidades"
          element={<InformeSituacionComunidades />}
        />
        <Route
          path="/doc-render/:nif/comunidades-sin-proveedores"
          element={<InformeComunidadesSinProveedores />}
        />
        <Route
          path="/doc-render/:nif/situacion-riesgos"
          element={<InformeSituacionRiesgos />}
        />
        <Route
          path="/doc-render/:nif/:id/video-vigilancia"
          element={<CertificadoVigilancia />}
        />
        <Route
          path="/doc-render/:nif/:id/certificado-implantacion"
          element={<CertificadosRGPD />}
        />
        <Route
          path="/doc-render/:nif/:id/certificado-auditoria"
          element={<CertificadosRGPD_Reno />}
        />
        <Route
          path="/doc-render/:nif/:id/documentacion-implantacion"
          element={<DocumentacionLOPD />}
        />
        <Route
          path="/doc-render/:nif/:id/documentacion-auditoria"
          element={<DocumentacionLOPD_Reno />}
        />
        <Route path="/doc-render/:nif/pdf" element={<PDF />} />
        <Route path="/*" element={
          <UsuarioProvider>
            <App />
          </UsuarioProvider>
        } />
      </Routes>
    </Router>
    <ToastContainer
      position="top-right"
      autoClose={7000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </ThemeProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
