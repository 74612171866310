import axios from "axios";

const urlPath = "consultant";

export const createCons = async (data) => {
  const datos = {
    nombre: data.nombre,
    apellidos: data.apellidos,
    nif: data.nif,
    email: data.email,
  };
  try {
    const response = await axios.post(urlPath, datos);

    return response;
  } catch (err) {
    console.log(err);
    throw err; 
  }
};


export const getAllConsultores = async () => {
  try {
    const response = await axios.get(urlPath);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const listCosultor= async (data) => {
  try {
    const response = await axios.post(`${urlPath}/listarBusqueda`, data.filtros, {
      params: data.params,
      
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getConsultores = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, 
    {params:data.params});

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getConsultor = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const desAsignarConsultor = async (idAaff, id, idGroup) => {
  const datos = {
    aaff_id: idAaff,
    consul_id: id,
    group_id: parseInt(idGroup)
  };
  try {
    const response = await axios.post(`aaff/desasignar`, datos);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const asignarConsultor = async (idAaff, id, idGroup) => {
  console.log("idAaff",idAaff);
  console.log("id",id);
  console.log("idGroup",idGroup);
  const datos = {
    aaff_id: idAaff,
    consul_id: id,
    group_id: parseInt(idGroup)
  };
  try {
    const response = await axios.post(`aaff/asignar`, datos);
    console.log(response.data);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const deleteConsultor = async (id) => {
  try {
    const response = await axios.delete(`${urlPath}/${id}`);

    return response.data;
  } catch (err) {
    console.log(err);
  }
};
