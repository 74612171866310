import React from 'react'
import { Modal, Box } from "@mui/material";
import Delete from "../../icons/delete.svg";
import Editar from "../../icons/editar.svg";
import { formatoFechasEs } from '../../functions/funciones';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85ch',
    maxHeight: '500px',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: '20px',
    overflow: 'auto',

};

const modal = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

/**
 * Muestra los comentarios principales.
 *
 */

const AllComments = (props) => {

    const eliminar = (id) => {
        props.eliminarComment(id)
        props.handleClose()
    }
    const editar = (id, comentario) => {
        props.editarComment(id, comentario)
        props.handleClose()
    }

    return (
        <>
            <Modal
                open={props.opened}
                onClose={props.handleClose}
                className={modal}
            >
                <Box sx={style}>
                    {props.comentarios.length === 0 ?
                        <p>No hay comentarios</p>
                        :
                        <>
                            <h3>COMENTARIOS</h3>
                            {props.comentarios.map((comentario, index) => (
                                <div key={index}>
                                    <img
                                        src={Delete}
                                        alt="ELIMINAR"
                                        className="tableIcon"
                                        onClick={() => eliminar(comentario.id)}
                                        style={{ width: 14 }}
                                    ></img>
                                    <img
                                        src={Editar}
                                        alt="Editar"
                                        className="tableIcon"
                                        onClick={() => editar(comentario.id, comentario)}
                                        style={{ width: 14 }}
                                    ></img>
                                    {formatoFechasEs(comentario.createdAt)} - {comentario.usuario?.nombre + " " + comentario.usuario?.apellidos} - {comentario.texto}
                                </div>
                            ))}
                        </>}
                </Box>
            </Modal>
        </>
    );
}

export default AllComments;