import React, { useRef } from "react";
import jsPDF from "jspdf";
import { useEffect } from "react";
import LogoLAE from "../../../images/LAE.png"
import Cabecera from "../../../images/cabecera.png"

// import { incidencias } from "../../../providers/incidencias";

function PdfGenerator() {
  const contentRef = useRef(null);

  useEffect(() => {
    generatePdf()
  },[])

  const generatePdf = () => {
    const doc = new jsPDF();

    // Definir opciones de formato y orientación del documento
    const options = {
      margin: { top: 20, left: 20, right: 20, bottom: 20 },
      format: "a4",
      orientation: "portrait",
    };
    // Obtener el contenido HTML del ref
    const content = contentRef.current;

    // Generar el PDF a partir del contenido HTML
    doc.fromHTML(content, options);

    // Descargar el PDF en el navegador
    doc.save("documento.pdf");
  };

  return (
    <div>
      <h1>Generador de PDF</h1>
      <div ref={contentRef}>
      <div style={{
                width: '100%',
                height: 120,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 40px',
                backgroundImage: `url(${Cabecera})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: "fixed",
                top: 0,
                left: 0
            }}>
                <div style={{ display: 'flex', color: '#ffffff', alignItems: 'center' }}>
                    <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoLAE} alt="Logo LAE" />
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 20, paddingLeft: 20, borderLeft: '2px solid #ffffff', height: 'fit-content', fontSize: 20 }}>
                        <p style={{ margin: 0, lineHeight: 1.2, fontWeight: 'bold' }}>HOJA DE VISITA</p>
                        <p style={{ margin: 0, lineHeight: 1.2 }}>Identificación de riesgos</p>
                    </div>
                </div>
                {/* <img style={{ height: 60, filter: "brightness(0) invert(1)" }} src={LogoMC} alt="Logo M&C" /> */}
            </div>
            <style jsx>{`
            div {
                font-size: 10px
            }
            table {
                border-collapse: collapse;
                width: 100%;
                table-layout: fixed;
            }
            th, td {
                border: 1px solid #000000;
                padding: 5px;
                line-height: 1.1;
                text-align: left
            }
            th {
                background-color: #eaecee;
            }
            p {
                margin: 0
            }
            ul {
                padding-left: 20px
            }
            li {
                margin-top: 5px
            }
            .header-space {
                height: 120px;
            }
            @page {
                size: A4 portrait;
                margin: 0 0 30px 0;
            }
        `}</style>
      </div>
      <button onClick={generatePdf}>Generar PDF</button>
    </div>
  );
}

export default PdfGenerator;