import React, { useState, useContext,useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { UsuarioContext } from "../../providers/userContext";
import {
  Box,
  TextField,
  Button,
} from "@mui/material";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { useLocation} from "react-router-dom";

const formContainerStyles = css`
  height: 19px !important; 
`;

const FormContainer = styled("div")`
  ${formContainerStyles}
`;
const FiltrosAAFFInformes = ({ onFilter, clearFilter,filtrosIniciales }) => {

  const user = useContext(UsuarioContext)
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const location = useLocation();

  function handleChange(evt) {
    const value = evt.target.value;
    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
    
  }

  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {

      clearAllFilters();
      localStorage.setItem("hasReloaded", "false"); 
    }
  }, [location]);

  const clearAllFilters = () => {
  setBusqueda({});
  clearFilter();
 }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    
    
    onFilter(busqueda);
  };


  return (
    <>
      <div className="buscadorFiltros">
        <div style={{width:"100%"}}>
          <Box
            mx={1}
            display={"block"}
            flexDirection={"row"}
            gap={2}
          >
            <TextField
              id="razonSocial-input"
              label="Razón Social"
              variant="outlined"
              name='razonSocial'
              className="filtros altura"
              // style={{ width: '223px', height:'20px !important'}}
              value={busqueda.aaff}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
              "aria-label": "controlled",
              }}
            />
            <TextField
              id="nif-input"
              label="NIF"
              variant="outlined"
              name='nif'
              className="filtros altura"
              // style={{ width: '223px' }}
              value={busqueda.nif}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
              "aria-label": "controlled",
              }}
            />
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={clearAllFilters}>
            <ImCross />
          </Button>
        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
              <BsSearch />
          </Button>
        </div>
      </div>
      <style jsx="true">
        {`
          .btn-buscador {

            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004d;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width: 40px;
            height: 40px;
            margin: 5px 10px 5px;
          }
          .btn-buscador:hover {
            color: black;
            background-color: #dee2e6;
          }
        `}
      </style>
    </>
  );
};

export default FiltrosAAFFInformes;
