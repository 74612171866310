import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { backToOwnAccount, logout } from "./../../services";
import { Avatar, Badge, Button, Divider, ListItemIcon, Menu, MenuItem, ListItem, Box, Typography, Tooltip } from "@mui/material";
import { Logout, Settings, SwitchAccount, AlternateEmail } from "@mui/icons-material";
import { UsuarioContext } from "../../providers/userContext";

const UserMenu = ({isMobile}) => {
  const user = useContext(UsuarioContext);
  const navigate = useNavigate();
  const [anchorElUserMenu, setAnchorElUserMenu] = useState(null);
  const openUserMenu = Boolean(anchorElUserMenu);
  const userName = user.apellidos ? `${user.nombre} ${user.apellidos}` : user.nombre;
  const showingName = user.ownData ? (user.ownData.apellidos ? `${user.ownData.nombre} ${user.ownData.apellidos}` : user.ownData.nombre) : userName;

  const handleClickUserMenu = (event) => {
    setAnchorElUserMenu(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUserMenu(null);
  };
  return (
    <>
      {user?.rol === "AAFF" && 
        <>
          <Button
            size="small"
            sx={{ ml: 2, px: 1, color: isMobile ? '#fff' : '#33383b' }}
            variant="outlined"
            startIcon={<AlternateEmail fontSize="small" />}
            onClick={() => window.location = `mailto:${user?.tecnico?.user?.email}`}
          >
            <Tooltip title={user?.tecnico?.nombre+" "+user?.tecnico?.apellidos}>
              Contacto técnico
            </Tooltip>
          </Button>

          {user?.consultores.map(con => 
            <Button
              size="small"
              sx={{ ml: 2, px: 1, color: isMobile ? '#fff' : '#33383b' }}
              variant="outlined"
              startIcon={<AlternateEmail fontSize="small" />}
              onClick={() => window.location = `mailto:${con?.user?.email}`}
            >
              <Tooltip title={con?.nombre+" "+con?.apellidos}>
                Contacto {con.r_consul_servsgroups_aaffs.length === 2 ? "consultor" : con.r_consul_servsgroups_aaffs.some(item => item.group_id === 1) ? "consultor CAE" : "consultor LOPD"}
              </Tooltip>
            </Button>
          )}
        </>
      }
      <Button
        variant="text"
        onClick={handleClickUserMenu}
        size="small"
        sx={{ ml: 2, px: 1, color: isMobile ? '#fff' : '#33383b' }}
        aria-controls={openUserMenu ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openUserMenu ? 'true' : undefined}
      >
        <Badge
          overlap="circular"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={
            user.ownData && <Avatar sx={{ width: 20, height: 20, fontSize: 10, right: 7, border: isMobile ? '2px solid #33383b' : '2px solid' }}>{userName[0]}</Avatar>
          }
        >
          <Avatar sx={{ width: 32, height: 32, fontSize: 16, mr: 1.5 }}>{showingName[0]}</Avatar>
        </Badge>
        <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'left', lineHeight: 1.3}}>
          <span>{showingName}</span>
          <Typography sx={{fontSize: 10}} color='primary'>{user.ownData && userName}</Typography>
        </Box>
      </Button>
      <Menu
        anchorEl={anchorElUserMenu}
        id="account-menu"
        open={openUserMenu}
        onClose={handleCloseUserMenu}
        onClick={handleCloseUserMenu}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1.5,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <ListItem>
          <Avatar>{showingName?.[0]}</Avatar> {showingName}
        </ListItem>
        <Divider sx={{my: 1}}/>
        {user.ownData &&
        <>
          <ListItem sx={{flexDirection: 'column', alignItems: 'start', fontSize: 12, mb: 1}}>
            <Typography color='primary'>Sesión iniciada como</Typography>
            {user.nombre}
          </ListItem>
          <Divider  sx={{my: 1}}/>
        </>}
        <MenuItem onClick={() => navigate("/perfil")}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Perfil
        </MenuItem>
        {user.ownData ?
          <MenuItem onClick={() => backToOwnAccount(user.ownData.token)}>
            <ListItemIcon>
              <SwitchAccount fontSize="small" />
            </ListItemIcon>
            Volver a mi cuenta
          </MenuItem> :
          <MenuItem onClick={logout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Cerrar sesión
          </MenuItem>
        }
      </Menu>
    </>
  )
}

export default UserMenu