import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Button, CircularProgress, Grid } from "@mui/material";
import { createCommercialCombo, createTechnicianTeam, getAllTecnicos } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { filterItems } from "../../../functions/funciones";
import { BsSearch } from "react-icons/bs";

const NuevoEquipoTecnicos = () => {
  const [technicians, setTecnicos] = useState([]);

  const [busqueda, setBusqueda] = useState([]);
  const [resultados, setResultados] = useState();

  const [tecnico, setTecnico] = useState({});
  const [selected, setSelected] = useState([])

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAllTecnicos().then((res) => {
      const tecnicos = [];
      res.forEach((c) => {
        tecnicos.push({
          id: c.id,
          nif: c.nif,
          nombre: c.nombre,
          apellidos: c.apellidos,
          busqueda: c.nombre + " " + c.apellidos,
          userId: c.user?.id
        });
      });
      setResultados(tecnicos);
      setTecnicos(tecnicos);
      setIsLoading(false);

    });
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const toastInfo = toast.info("Creando equipo");
    if (selected.length == 0) {
      return toast.update(toastInfo, {
        render: "No se han añadido técnicos",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
    }
    createTechnicianTeam({ ...tecnico, userIds: selected.map(s => s.userId) })
      .then((res) => {

        if (res.status == 200) {
          toast.update(toastInfo, {
            render: "Equipo creado con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });

          setTimeout(() => {
            window.location.replace("/equipos");
          }, 3000);
        } else if (res.status == 403) {
          toast.update(toastInfo, {
            render: "Error al crear el equipo",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        } else {
          toast.update(toastInfo, {
            render: "Ha ocurrido un error",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }


      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al crear el equipo");
      });
  }
  function handleChange(evt) {
    const value = evt.target.value;
    setTecnico({
      ...tecnico,
      [evt.target.name]: value,
    });
  }


  const buscar = (e) => {
    // e.preventDefault();

    setResultados(filterItems(busqueda, technicians.filter(c => !selected.some(s => s.id === c.id))))
  };
  useEffect(buscar, [busqueda]);

  const select = (tecnico) => {
    setSelected([...selected, tecnico]);
    setResultados(resultados.filter(c => c.id !== tecnico.id));
  }

  const unselect = (tecnico) => {
    const newSelected = selected.filter(c => c.id !== tecnico.id)
    setSelected(newSelected);
    setResultados(technicians.filter(c => !newSelected.some(s => s.id === c.id)));
  }


  return (
    <>
      <BreadCrumb rutas={[{ path: `/tecnicos`, label: `Técnicos` }, { path: `/equipos`, label: `Nuevo equipo` }]} style={{ margin: '0 10px' }} />
      <div className="pageContent">
        <div className="gridHead">Crear nuevo equipo</div>
        <form className="nuevoAAFFForm" onSubmit={handleSubmit}>
          <input
            type="text"
            required
            placeholder="Nombre"
            name="nombre"
            value={tecnico.nombre}
            onChange={handleChange}
          ></input>

          <div className="tableContainer" style={{ marginTop: 30, marginBottom: 30, padding: 0 }}>
            <table>
              <thead>
                <tr>
                  <th>Técnico</th>
                  <th>NIF</th>
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                </tr>
              </thead>
              <tbody>

                {selected.length == 0 ? <tr><td colSpan={3} style={{ textAlign: "center" }}>Selecciona técnicos para añadirlos al equipo</td></tr> : selected.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}>
                        <div
                          className="asignarButton"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => unselect(e)}
                        >
                          Eliminar
                        </div>
                      </td>
                    </tr>
                  );
                })}

              </tbody>
            </table>
          </div>
          <input
            type="submit"
            value="Guardar y crear"
          ></input>
        </form>
        <form className="nuevoAAFFForm" onSubmit={e => e.preventDefault()}>
          <input
            type="text"
            placeholder="Buscar tecnico..."
            required
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
          ></input>
        </form>
        <div className="tableContainer" style={{ marginTop: 10 }}>
          <table>
            <thead>
              <tr>
                <th>Técnico</th>
                <th>NIF</th>
                <th style={{ textAlign: "center" }}>Añadir</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={3} style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: '#92004D' }} />
                  </td>
                </tr>
              ) :
                Array.isArray(resultados) ? resultados.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{e.nombre + " " + e.apellidos}</td>
                      <td>{e.nif}</td>
                      <td style={{ textAlign: "center" }}>
                        <div
                          className="asignarButton"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            cursor: "pointer",
                          }}
                          onClick={() => select(e)}
                        >
                          Añadir
                        </div>
                      </td>
                    </tr>
                  );
                }) :
                  <tr><td colSpan={3} style={{ textAlign: "center" }}><CircularProgress style={{ color: '#92004D' }} /></td></tr>

              }

            </tbody>

          </table>
        </div>

      </div>
    </>
  );
};

export default NuevoEquipoTecnicos;
