import React, { useState, useEffect, useContext } from "react";
import { getConsultores, deleteConsultor } from "../../../services";
import { Link } from "react-router-dom";
import Add from "../../../icons/add.svg";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import Lupa from "../../../icons/lupa.svg";
import Delete from "../../../icons/delete.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import { DataTable } from "../../../components";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { UsuarioContext } from "../../../providers/userContext";
import { useLocation, useNavigate } from 'react-router-dom';
import { construirURLParamsFiltros, useRecogerURLParamsFiltros } from "../../../functions/parametrosURL/funcionesParamsURL";


const Consultores = () => {

  // const location = useLocation();

  // let params = new URLSearchParams(location.search);

  // const pagina = parseInt(params.get("pagina") ) ;
  // const limiteParam = parseInt(params.get("limite") ) ;
  const urlPadre = window.location.pathname;
  const navigate = useNavigate();
  const {filtroOrder} = useRecogerURLParamsFiltros();
  

  const user = useContext(UsuarioContext)
  const [consultores, setConsultores] = useState([]);
  const [recargar, setRecargar] = useState(true);
  const [loading, setLoading] = useState(true);
  // const [limite, setLimite] = useState(limiteParam);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const location = useLocation();


  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {

      fetchData();
      localStorage.setItem("hasReloaded", "false"); 
    }
  }, [location]);

 

  const fetchData = async ({ offset, limit, filtros = {}, order,orderBy,} = {}) => {
    
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,order,orderBy);

    // setLimite(limit)
    const payload = {
      filtros,
      params: {
        page: offset, limit, order,orderBy
      },
    };
    try {
      setLoading(true);
      const response = await getConsultores(payload);
      const { meta, data } = response;

      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const formatData = (data) => {
    return data.map((row, i) => ({
      id: i + 1,
      nombre: row.nombre + " " + row.apellidos,
      nif: row.nif,
      aaff: row.nif,
    }));
  };

  const consultoresRows = [

    {
      id: "nombre",
      numeric: false,
      disablePadding: false,
      label: "CONSULTOR",
      noObjeto:true,
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "aaff",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADORES FINCAS",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Link to={"/consultores/" + row.nif + "/aaff"}>
          <img
            src={Lupa}
            alt="Administradores de Fincas"
            className="tableIcon"
          ></img>
        </Link>
      )
    },
    user.rol === 'ADMIN' ?
      {
        id: "eliminar",
        numeric: false,
        disablePadding: false,
        label: "ELIMINAR",
        noObjeto: true,
        overrideFunc: (data, row) => (
          <img
            src={Delete}
            alt="ELIMINAR"
            className="tableIcon"
            onClick={() => eliminarConsultor(row)}
          ></img>
        )
      }
      : 
      {
      
        noObjeto:true
        
      }
      ,
  ]

  const eliminarConsultor = (com) => {
    let nombre = com.nombre + " " + com.apellidos;
    Swal.fire({
      title: "¿Quieres borrar este consultor?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Si, borrar",
    })
      .then((result) => {
        if (result.isConfirmed) {
          deleteConsultor(com.nif)
            .then((res) => {

              if (res.message === "CONSULTANT_DELETED") {
                toast.success("consultor eliminado");
                setRecargar(!recargar);
              } else {
                toast.error("No se ha podido elimiar el consultor");
              }
            })
            .catch((e) => {
              toast.error("No se ha podido elimiar el consultor");
              console.log(e);
            });
        }
      })
      .catch((e) => {
        toast.error("Ha ocurrido un error");
        console.log(e);
      });
  };

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{ backgroundImage: `url(${HeaderBackground})` }}
      >
        Consultores
      </div> */}
      <BreadCrumb rutas={[{ path: '/aaff', label: "Consultores" }]} style={{ margin: '0 10px' }} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            CONSULTORES
          </div>
        </div>
      </div>

      <div className="pageContent tableContainer">
        <div className="contentBar">
          {(user.rol === "ADMIN" || user.rol === "CONSUL") &&
            <>
              <div></div>
              <Link to="/consultores/nuevo">
                <div className="contentButton">
                  <img src={Add} alt="Más" />
                </div>
              </Link>
            </>
          }
        </div>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={consultoresRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                mensaje={"No hay consultores"}
              />
            </Box>
          </Box>
        </div>
        {/* <div className="tableWrapper">
          <table>
            <thead>
              <tr>
                <th></th>
                <th style={{ textAlign: "center" }}>Consultor</th>
                <th style={{ textAlign: "center" }}>NIF</th>
                <th style={{ textAlign: "center" }}>Administradores Fincas</th>
                {user.rol === "ADMIN" && 
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                }
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan="5" style={{ textAlign: "center" }}>
                    <CircularProgress style={{ color: "#92004D" }} />{" "}
                  </td>
                </tr>
              ) : (
                <></>
              )}
              {consultores.map((e, i) => {
                return (
                  <tr key={i}>
                    <td style={{ textAlign: "center" }}>{i + 1}</td>
                    <td style={{ textAlign: "center" }}>
                      {e.nombre + " " + e.apellidos}
                    </td>
                    <td style={{ textAlign: "center" }}>{e.nif}</td>
                    <td style={{ textAlign: "center" }}>
                      <Link to={"/consultores/" + e.nif + "/aaff"}>
                        <img
                          src={Lupa}
                          alt="Administradores de Fincas"
                          className="tableIcon"
                        ></img>
                      </Link>
                    </td>
                    {user.rol === "ADMIN" && 
                      <td style={{ textAlign: "center" }}>
                        <img
                          src={Delete}
                          alt="ELIMINAR"
                          className="tableIcon"
                          onClick={() => eliminarConsultor(e)}
                        ></img>
                      </td>
                    }
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
  );
};

export default Consultores;
