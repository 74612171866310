import React, { useState, useEffect, useContext } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { getAAFFId, getSuppListPropertiAaff } from "../../../services";
import { DataTable } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { formatoFechasEs } from "../../../functions/funciones";
import { useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';



const Comunidades = (props) => {
  const urlPadre = window.location.pathname;
  const navigate = useNavigate();

  const user = useContext(UsuarioContext);
  const [aaffs, setAaffs] = useState(0);
  const {filtroOrder} = useRecogerURLParamsFiltros();
  
  
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const fetchData = async ({ offset, limit, filtros = {},order,orderBy } = {}) => {
    
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,order,orderBy);
    
    try {
      const response = await getAAFFId(props.params.nif)
      setAaffs(response.data);
      const payload = {
        params: { page: offset, limit,order, orderBy  },
        filtros: {...filtros, aaff_id:response.data.id},
      };

      const fincas = await getSuppListPropertiAaff(payload)
      const { meta, data } = fincas;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
    
  }

  const formatData = (data) => {
    const formattedData = []
    data.map((row) => {
      formattedData.push({
        aaff_id: row?.aaff?.id,
        aaff_nif: row?.aaff?.nif,
        aff_name: row.aaff?.razonSocial,
        nif: row.nif,
        id: row.id,
        razonSocial: row.razonSocial || row.propertie?.razonSocial,
        direccion: row.direccion || row.propertie?.direccion,
        codigoPostal: row.codigoPostal || row.propertie?.codigoPostal,
        municipio: row.municipio || row.propertie?.municipio,
        createdAt: formatoFechasEs(row.createdAt),
        centrosTrabajo: row.workcenters,
        isWorkcenter: false, 
      })
      if(row.workcenters?.length > 0) {
        row.workcenters?.forEach((wc) => {
          formattedData.push({
            aff_name:"",
            razonSocial: wc.razonSocial,
            nif: "",
            createdAt: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            empleados: "",
            isWorkcenter: true,
          })
        })
      }
    });
    return formattedData;
  };
  const comunidadesRows = [
    {
      id: "aff_name",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR DE FINCAS",
      noObjeto:true,
    },
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      overrideFunc: (data, row) => (
      <Link to= {`/aaff/${row.aaff_nif}/comunidades/${row.nif}`}>
        {data}
      </Link>
    )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "FECHA ALTA",
    },
    {
      id: "direccion",
      numeric: false,
      disablePadding: false,
      label: "DIRECCION",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "C.P",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    }
  ];
  

  

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{ backgroundColor: "#f6f7fb", alignContent: "center" }}
      >
        <Typography variant="h4" color="#212529" fontWeight={"bold"}>
          Comunidades
        </Typography>
      </div> */}
      <BreadCrumb
        rutas={[{ path: "/aaff", label: "Administradores de fincas" }, { path: `/aaff/${aaffs.nif}/comunidades`, label: data?.rows?.find(e => e.aaff_nif === props.params.nif)?.aff_name }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
          </div>
          <div className="pageContent tableContainer">
            {/* <FiltrosComunidades onFilter={filtrarDatos} /> */}
            <Box>
              <Box sx={{ mb: 10 }}>
                <DataTable
                  data={data.rows}
                  isLoading={loading}
                  headers={comunidadesRows}
                  fetcher={fetchData}
                  currentPage={data.current_page}
                  meta={data.meta}
                  totalItems={data.total}
                  totalPages={data.totalPages}
                  nextPage={data.next_page}
                  
                  mensaje={"No hay comunidades"}
                >
                  {/* <Box
                    m={2}
                    display={"flex"}
                    flexDirection={"row"}
                    width={"100%"}
                    gap={2}
                  >
                    <Select
                      value={filter}
                      aria-label="controlled"
                      onChange={(e) => setFilter(e.target.value)}
                      sx={{ width: 150 }}
                    >
                      {aaffs.map((filtro) => (
                        <MenuItem value={filtro.id} key={filtro.id}>
                          {filtro.razonSocial}
                        </MenuItem>
                      ))}
                    </Select>
                    <TextField
                      id="outlined-basic"
                      label="Razón Social"
                      variant="outlined"
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                      inputProps={{
                        "aria-label": "controlled",
                      }}
                    />
                    <LaeButton onClick={handleFetchData} sx={{ width: 100 }}>
                      <Typography fontSize={14} fontWeight={"bold"}>
                        Buscar
                      </Typography>
                    </LaeButton>
                  </Box> */}
                </DataTable>
              </Box>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Comunidades);
