import React, { useState, useEffect, useContext } from 'react';
import noVisitada from '../icons/noVisitada.svg';
import notaNV from '../icons/notaNV.svg';
import { withRouter } from '../providers/withRouter';
import Swal from 'sweetalert2';
import { Tooltip } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material';
import { UsuarioContext } from '../providers/userContext';
import { noRealizadaAAFF } from '../services';
import { formatoFechas, formatoFechasEs } from '../functions/funciones';
import { toast } from 'react-toastify';

const theme = createTheme();

const NoVisitadoAAFF = (props) => {

  const user = useContext(UsuarioContext)
  const [noRealizado, setNoRealizado] = useState(false);
  const aaffProps = props.aaff
  const [activado, setNoActivado] = useState(false)
  const [ObsTexto, setObsTexto] = useState('')
  const [recargar, setRecargar] = useState(false)
  const [aaff, setAAFF] = useState({})

  useEffect(() => {
    const fecha = aaffProps?.unRealizedDate !== null ? formatoFechasEs(aaffProps?.unRealizedDate) : " "
    setAAFF(aaffProps)
    setNoRealizado(aaffProps?.unRealized === null ? false : aaffProps?.unRealized);
    setObsTexto(fecha+" - "+aaffProps?.observacionNoVisita)

  }, [])

  const handleCallback = () => {
    setRecargar(!recargar);
    setNoRealizado(true)
  };
  const handleCallbackDelete = () => {
    setRecargar(!recargar);
    setNoRealizado(false)
  };

  const comprobar = async () => {
      
    handleAdd().then((resultado) => {
      handleCallback(resultado)
    })
  };
  const quitar = async () => {
      
    handleDelete().then((resultado) => {
      handleCallbackDelete(resultado)
    })
  };

  const handleDelete = () => {
    return new Promise(async (resolve, reject) => {
      let toastInfo = undefined
      try {
        Swal.fire({
          title: '¿Eliminar motivo de la no visita?',
          confirmButtonText: 'Sí',
          confirmButtonColor: '#05811b',
          showCancelButton: true,
          cancelButtonText: 'No',
          cancelButtonColor: '#d33',
          focusConfirm: false,
        }).then(async (result) => {
          if(result.isConfirmed){
            const datos = {
              unRealized: null,
              unRealizedDate: null,
              observacionNoVisita: null
            }
            await noRealizadaAAFF(aaff.id, datos).then(() => {
              // toast.update(toastInfo, {
              //   render: "datos actualizados correctamente",
              //   type: toast.TYPE.SUCCESS,
              //   autoClose: true
              // });
              resolve(true);
            })
            
            setRecargar(!recargar)
            setNoActivado(false)
            resolve(true);
          }

        }).catch(error => {
          console.log(error)
          toastInfo = toast.error("No se han podido actualizar los datos");
          // toast.update(toastInfo, {
          //   render: "No se han podido actualizar los datos",
          //   type: toast.TYPE.ERROR,
          //   autoClose: true
          // });
        });
      } catch (error) {
        console.log(error)
        reject(error);
      }
    })
  }

  const handleAdd = () => {
    return new Promise(async (resolve, reject) => {
      let toastInfo = undefined
      try {
        let fincas = []
        Swal.fire({
          title: 'Motivo de la no visita',
          // html: `<textarea id="motivoTexto" style="width: 373px; height: 210px; font-size:11px; padding:10px;" className="swal2-input" ></textarea>`,
          html: `<select id="motivoTexto" style="width: 373px; height: auto; font-size:14px; padding:10px;" className="swal2-input" >
                        <option>El AAFF no quiere visita en la renovación</option>
                        <option>El AAFF no quiere que empecemos todavía las visitas</option>
                      </select>`,
          confirmButtonText: 'Guardar',
          confirmButtonColor: '#05811b',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          cancelButtonColor: '#d33',
          focusConfirm: false,

          preConfirm: () => {
            // toastInfo = toast.info("Guardando datos", { autoClose: false });
            const texto = Swal.getPopup().querySelector('#motivoTexto').value
            if (!texto) {
              Swal.showValidationMessage(`insertar texto`)
            }
            return { texto: texto }
          }
        }).then(async (result) => {
          if (result.value !== undefined && result.isConfirmed) {
            const datos = {
              unRealized: true,
              unRealizedDate: formatoFechas(new Date()),
              observacionNoVisita: result.value.texto
            }
            await noRealizadaAAFF(aaff.id, datos).then(() => {
              // toast.update(toastInfo, {
              //   render: "datos actualizados correctamente",
              //   type: toast.TYPE.SUCCESS,
              //   autoClose: true
              // });
              resolve(true);
            })
            
            setRecargar(!recargar)
            setNoActivado(true)
          }
        }).catch(error => {
          console.log(error)
          toastInfo = toast.error("No se han podido actualizar los datos");
          // toast.update(toastInfo, {
          //   render: "No se han podido actualizar los datos",
          //   type: toast.TYPE.ERROR,
          //   autoClose: true
          // });
        });
      } catch (error) {
        reject(error);
      }
    })
    
  }
  return (
    <>
    
      {user.rol === 'TECH' && noRealizado !== true &&
        <button type="button" onClick={comprobar}><img src={notaNV} alt="No visitado" className="tableIcon" /></button>
      }
      {noRealizado &&
        <ThemeProvider theme={theme}>
          <button type="button" onClick={quitar}>
            <Tooltip title={ObsTexto} /* classes={{ tooltip: classes.customWidth } }*/>
              <img src={noVisitada} alt="No visitado" className="tableIcon" />
            </Tooltip>
          </button>
        </ThemeProvider>
      }
    </>
  );
};

export default withRouter(NoVisitadoAAFF);
