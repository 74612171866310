import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Typography,

} from "@mui/material";

import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";

import { getAAFFs } from "../../../services";
import { DataTable, NoVisitado } from "../../../components";
import { useComunidades } from "../../../hooks";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosComunidadesTech from '../../../components/filtros/filtrosComunidadesTech';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import NoVisitadoAAFF from "../../../components/noVisitadoAAFF";
import { cambiarFormatoNombre, estadoServicio } from "../../../functions/funciones";

const Comunidades = () => {


  const user = useContext(UsuarioContext);
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const { loading, data, fetchData } = useComunidades();
  const [aaffs, setAaffs] = useState([]);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [recargar, setRecargar] = useState(0);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }


  const comunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR DE FINCAS",
      noObjeto:true,
      overrideFunc: (data, row) =>
        <Link to={`/aaff/${row.aaff_nif}/comunidades`} target="_blank">
          {data}
        </Link>
    },
    {
      id: "community",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDAD",
      noObjeto:true,
      overrideFunc: (data, row) =>
        <Link to={`/comunidades/${row.nif}`} target="_blank">
          {data}
        </Link>
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "FECHA ALTA",
      overrideFunc: (data, row) => row.nif === null ? null : 
        <Typography style={{ textAlign: 'left', width: '60px' }}>
          {data}
        </Typography>
    },
    {
      id: "fechaUltVisita",
      numeric: true,
      disablePadding: false,
      label: "FECHA ULT. VISITA",
      noObjeto:true,
      overrideFunc: (data, row) => 
        <Typography style={{ textAlign: 'left', width: '60px' }}>
          {data}
        </Typography>
    },
    {
      id: "direccion",
      numeric: false,
      disablePadding: false,
      label: "DIRECCION",
    },
    {
      id: "codigoPostal",
      numeric: false,
      disablePadding: false,
      label: "C.P",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
      overrideFunc: (data, row) => 
        <Typography>
          {cambiarFormatoNombre(data)}
        </Typography>
    },

    {
      id: "empleados",
      numeric: false,
      disablePadding: false,
      label: "EMPLEADOS",
      noObjeto:true,
      overrideFunc: (data, row) => 
        data !== "" ? data ? 'Sí' : 'No' : ""
    },
    {
      id: "estadoCae",
      numeric: false,
      disablePadding: false,
      label: "ESTADO CAE",
      overrideFunc: (data, row) => (
        !row.isWorkcenter ?
            <div className="activoSelect">
                <span style={{ color: data === 1 || data == null ? "#3eae3e"  : data === 2 || data === 3 ? '#FF8300' : data === 4 ? "#EE4534" : "#000" }}>
                  {data === "No contratada" ? data : estadoServicio(data)}
                </span>
            </div>
        : ""
      ),
    },
    {
      id: "noVisita",
      numeric: false,
      disablePadding: false,
      label: "NO VISITA",
      overrideFunc: (data, row) => (
        <Typography>
          {user.rol !== 'AAFF' ?
            row?.aaff?.unRealized !== undefined && row?.aaff?.unRealized && row?.sales[0]?.detailsCae[0]?.visitSheet_id === null ?
              <NoVisitadoAAFF aaff={row?.aaff} nif={row.aaff.nif} />
            :
              
              row?.sales[0]?.detailsCae[0]?.unRealized && row?.sales[0]?.detailsCae[0]?.visitSheet_id === null ?
                row.isWorkcenter ? 
                  <NoVisitado finca={row.finca} nif={row.id} tipo={"centroTrabajo"} />
                :
                  <NoVisitado finca={row.finca} nif={row.nif} tipo={"finca"} />
              : ''
          :''}
        </Typography>
      ),
    },
    // {
    //   id: "btn_to",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "",
    //   noObjeto:true,
    //   overrideFunc: (data, row) => row.nif === null ? null : data
    // },


  ];
  useEffect(() => {
    setRecargar(false);
    // getData();
  }, [recargar]);

  const getData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,order, orderBy);
    
    const payload = {
      filtros:{...filtros, tech_id: user.id},
      params: { page: offset, limit,order, orderBy },
    };
    const response = await getAAFFs(payload)
    setAaffs(response.data);
  }

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
    // fetchData({ offset: pagina, limit: limite, filtros: filtros });
  }

  return (
    <> 
      <BreadCrumb
        rutas={[{ path: "/comunidades", label: "Comunidades" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            INFORMACIÓN GENERAL
          </div>
          <div className="pageContent tableContainer">
            <FiltrosComunidadesTech onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
            <div className='tableWrapper'>
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={comunidadesRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    clearFilterActivo={clearFilterActivo}
                    setClearFilterActivo={setClearFilterActivo}
                    mensaje={"No hay comunidades"}
                    enumerate={true}
                    // propCaducada={true}
                  >

                  </DataTable>
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Comunidades);
