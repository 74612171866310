import React, { useState } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { DataTable, ProgressBarWithColors } from "../../../components";

import {
  Box,
  Typography,
} from "@mui/material";
import { TechListSuplier } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";

import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';



const proveedoresRows = [
  {
    id: "razonSocial",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
    overrideFunc: (data, row) => (
      <Link to={"/proveedores/" + row.nif}>
        {data}
      </Link>
    ),
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "cumplimiento",
    numeric: false,
    disablePadding: false,
    label: "CUMPLIMIENTO",
    noObjeto:true,
    overrideFunc: (row) => (
      <ProgressBarWithColors
        value={row}
        label={
          <Typography>
            {row}
          </Typography>
        }
      />
    ),
  },
]
const ProveedoresLista = () => {

  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {

    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy);

    const payload = {
      filtros,
      params: { page: offset, limit: limit, order, orderBy },
    };

    try {
      setLoading(true);
      const response = await TechListSuplier(payload);
      const { meta, data } = response;

      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      razonSocial: row.razonSocial,
      municipio: row.municipio,
      provincia: row.provincia,
      cumplimiento: row.porcentajeDocs,
      nif: row.nif
    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData();
  }

  return (
    <>
      <BreadCrumb rutas={[{ path: `/proveedores`, label: 'Proveedores' }]} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORES</div>
          <div className="tableContainer">
            <FiltrosProveedores onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
            <div style={{ display: "flex" }}>
              <div className="exportar">
                {/* <ExportarProveedoresLista busqueda={Resbusqueda} />  */}
              </div>
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    clearFilterActivo={clearFilterActivo}
                    setClearFilterActivo={setClearFilterActivo}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(ProveedoresLista);
