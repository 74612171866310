import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

export default function Asynchronous({ labelId, id, label, name, className, tipo, array, onOptionSelected, busquedaInput, valueOnLoad }) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [mostrarCargar, setMostrarCargar] = useState("none");
  
  let tiempoEspera;

  useEffect(() => {
    setValue(valueOnLoad)
  }, [valueOnLoad])
  useEffect(() => {
    if (inputValue) {
      setLoading(true);
      setMostrarCargar("block");
      setOptions([]);

      tiempoEspera = setTimeout(() => {
        
        busquedaInput(name, inputValue);
        setMostrarCargar("none");
        
      }, 1000);
      
    } else {
      setLoading(false);
      setMostrarCargar("none");
      setInputValue(''); 
      busquedaInput(name, inputValue);
      setOptions([]);
    }

    
    return () => clearTimeout(tiempoEspera);
  }, [inputValue]);

  useEffect(() => {
   
    if (array.length > 0 && inputValue) {
      setLoading(true);
      setOptions(array);
    }
    else{
      setOptions([]);
      setLoading(false);
    }

    
   
    
  }, [open, array]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (onOptionSelected) {
      onOptionSelected(name, newValue);
    }
    
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };
  
  return (
    <Autocomplete
      labelId={labelId}
      id={id}
      label={label}
      name={name}
      className={className}
      style={{ margin: 0 }}
      open={open}
      noOptionsText={inputValue  ? "No hay opciones" : "Escribe para buscar"}
      loadingText="Cargando..."
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.id === value?.id}
      getOptionLabel={(option) => {
        if (tipo === 'nombre') {
          return `${option.nombre} ${option.apellidos}`;
        } else if (tipo === 'razonSocial') {
          return `${option.razonSocial}`;
        } else {
          return '';
        }
      }}
      options={options}
      loading={loading}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          style={{ margin: 0, padding: 0 }}
          inputProps={{
            ...params.inputProps,
            style: { fontSize: '12px', margin: 0, padding: 0 }
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {<CircularProgress color="inherit" size={20} sx={{display:mostrarCargar}} /> }
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
