import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { createSupplier, getServicios } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { cambiarFormatoNombre } from "../../../functions/funciones";
import { verificarNif, verificarCorreo, verificarTelefono } from "../../../functions/validator/formValidator";

const NuevoProveedor = () => {
  const [proveedor, setProveedor] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [servicios, setServicios] = useState([]);

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const resServicios = await getServicios()
    setServicios(resServicios.data)
  }
  function handleSubmit(data) {
    const toastInfo = toast.info("Guardando proveedor", { autoClose: false });
    let nifCorrecto = verificarNif(data.nif);
    let emailCorrecto = verificarCorreo(data.email);
    let telefonoCorrecto = verificarTelefono(data.telefonoContacto);
    let errores = false;

    if (Object.keys(data).length === 0) {
      toast.update(toastInfo, {
        render: "Hay campos del proveedor vacíos",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
      errores = true;
    } else {
      if (nifCorrecto === false && data.nif != undefined) {
        toast.dismiss(toastInfo);
        toast.error("El nif introducido es incorrecto");
        errores = true;

      }
      if (emailCorrecto === false && data.email != undefined) {
        toast.dismiss(toastInfo);
        toast.error("El correo introducido es incorrecto");
        errores = true;
      }
      if (telefonoCorrecto === false && data.telefonoContacto != undefined) {
        toast.dismiss(toastInfo);
        toast.error("El telefono introducido es incorrecto");
        errores = true;

      }
      
    }
    if (!errores) {
      createSupplier(data)
        .then((res) => {
          console.log(res.data[0])
          if (res.data[0].status === 200) {
            toast.update(toastInfo, {
              render: res.data[0].msg,
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            setTimeout(() => {
              window.location.replace("/proveedores");
            }, 1500)
          }
          
          else if (res.data[0].status === 400) {
            toast.update(toastInfo, {
              render: res.data[0].msg,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
          else if (res.status === 403) {
            toast.update(toastInfo, {
              render: res.data.error,
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
          
          else {
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
    }
  })
      .catch ((err) => {
  toast.error("Error al crear el proveedor");
});
    }
    
  }
function handleChange(evt) {
  if (evt.target.name === 'servicio[]') {
    const selectedOptions = Array.from(evt.target.selectedOptions, option => parseInt(option.value));

    setProveedor({
      ...proveedor,
      servicio: selectedOptions,
    });
  } else {
    const value = evt.target.value;
    setProveedor({
      ...proveedor,
      [evt.target.name]: value,
    });
  }
}

function seleccionarMunicipios(e) {
  let provincia = ""
  let municipios = []
  provinces.map(p => {
    if (p.code === e.target.value) {
      provincia = p.name
    }
  })
  setProveedor({
    ...proveedor,
    provincia
  })
  municipalities.map(m => {
    if (m.provCode === e.target.value) {
      municipios.push({ ...m, name: cambiarFormatoNombre(m.name), value: m.name })
    }
  })
  setListadoMunicipios(municipios)
}
return (
  <>
    <BreadCrumb rutas={[{ path: '/proveedores', label: "Proveedores" }, { path: '/proveedores', label: "Nuevo" }]} style={{ margin: '0 10px' }} />
    <div className="pageContent">
      <div className="gridHead">
        Crear nuevo Proveedor
      </div>
      <form className="nuevoAAFFForm">
        <Grid
          container
          spacing={5}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Grid item xs={12} sm={6}>
            <input
              type="text"
              placeholder="NIF"
              name="nif"
              pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
              title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
              value={proveedor.nif}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="text"
              placeholder="Razón social"
              name="razonSocial"
              value={proveedor.razonSocial}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="email"
              placeholder="Email de contacto"
              name="email"
              value={proveedor.email}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="tel"
              placeholder="Telefono de contacto"
              name="telefonoContacto"
              value={proveedor.telefono}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="text"
              placeholder="Nombre de contacto"
              name="nombreContacto"
              value={proveedor.nombre}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <select
              name="provincia"
              onChange={(e) =>
                seleccionarMunicipios(e)
              }
            >
              <option value={proveedor.provincia}>Provincia</option>
              {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                return (
                  <option key={i} value={e.code}>
                    {e.name}
                  </option>
                );
              })}
            </select>
            {/* <input
                  type="text"
                  placeholder="Provincia"
                  value={proveedor.provincia}
                  onChange={(e) => this.setState({ provincia: e.target.value })}
                ></input> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <select
              name="municipio"
              onChange={handleChange}
            >
              <option value={proveedor.municipio}>Municipio</option>
              {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                return (
                  <option key={i} value={e.value}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </Grid>

          <Grid item xs={12} sm={6}>
            <input
              type="text"
              placeholder="direccion"
              name="direccion"
              value={proveedor.direccion}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              type="text"
              placeholder="Código postal"
              name="codigoPostal"
              value={proveedor.codigoPostal}
              onChange={handleChange}
            ></input>
          </Grid>
          <Grid item xs={12} sm={6}>
            <select
              name="servicio[]"
              multiple
              onChange={handleChange}
            >
              <option value={proveedor.servicio}>Servicio</option>
              {servicios.sort()?.map((e, i) => {
                return (
                  <option key={i} value={e.id}>
                    {e.nombre}
                  </option>
                );
              })}
            </select>
          </Grid>
        </Grid>
        <input type="button"
          value="Guardar y publicar"
          onClick={() => handleSubmit(proveedor)}></input>
      </form>
    </div>
  </>
);
}


export default NuevoProveedor;
