import React, { useState, useEffect } from "react";
import { withRouter } from "../../../providers/withRouter";
/* import LogoMC from "../../../images/MC.png" */
import LogoLAE from "../../../images/LogoLAE.png"
import salida from "../../../images/salida.png"
import salidaEmergencia from "../../../images/salidaEmergencia.png"
import riesgosElectricos from "../../../images/riesgosElectricos.png"
import { getFinca, getSale, getVisitSheet } from "../../../services";
import { formatoFechasEs } from "../../../functions/funciones";


const EvaluacionRiesgosRender = (props) => {

    const [finca, setFinca] = useState({})
    const fetchData = async () => {
       try {
        const response = await getFinca(props.params.nif)
        let finca = response
        const respSale = await getSale(props.params.id);
        const visitSheet_id = respSale.detailsCae[0].visitSheet_id;
        const respVisit = await getVisitSheet(visitSheet_id)
        finca.imgURL = `${process.env.REACT_APP_STORAGE_URL}${response.visitSheets[0].img}`
        finca.fechaVisita = formatoFechasEs(finca.visitSheets[0].createdAt)
        setFinca(response)
       } catch (error) {
        alert(error)
       }
    }

    useEffect(() =>{
        fetchData();
    },[])

    return ( 
        <>
            <table className="tablePage">
                <thead><tr><td>
                    <div className="header-space">&nbsp;</div>
                </td></tr></thead>
                <tbody>
                    <h1>IDENTIFICACIÓN DE RIESGOS</h1>
                    <h2><b>Comunidad de propietarios:</b> {finca?.razonSocial}</h2>
                    <h2><b>Centro de trabajo:</b> {finca?.direccion}</h2>
                    <h2><b>Fecha:</b> {finca?.fechaVisita}</h2>
                    <div id="imgFinca" style={{ width: '100%', paddingTop: '66.66%', backgroundImage: `url("${finca?.imgURL}")`, backgroundPosition: 'center', backgroundSize: 'cover', marginTop: 100 }}></div>
                    <div className="pagebreak"></div>
                    <h3>1. Introducción</h3>
                    <p>
                        A petición de {finca?.razonSocial} se realiza la visita con motivo de llevar a cabo un análisis de las condiciones de trabajo y realizar la Identificación de los peligros existentes en el centro de trabajo.<br />
                        Este documento es fiel reflejo del estado de situación del centro de trabajo y los riesgos que se identifican corresponden al análisis realizado en la fecha anteriormente indicada. Cualquier modificación o alteración de las instalaciones actuales conllevaría la necesidad de actualización del presente informe.< br />
                    </p>
                    <h3>2. Metodología</h3>
                    <p>
                        La identificación de riesgos se realizará mediante la observación del conjunto de las instalaciones y teniendo en cuenta a los diferentes proveedores de mantenimiento que puedan tener acceso a las mismas.<br />
                        Los técnicos que han llevado a cabo este informe valoran y ponderan los diferentes riesgos en función de su peligrosidad y el riesgo que implican para los trabajadores de aquellas empresas que presten servicios de mantenimiento en el centro de trabajo.<br />
                        Todos los riesgos detectados y valorados anteriormente tendrán sus correspondientes medidas correctoras, con el fin de eliminar o disminuir los riesgos. Las acciones correctoras tendrán un orden de preferencia según su peligrosidad.<br />
                    </p>
                    {/* eslint-disable-next-line */}
                    {/* <img src={evRiesg_1} style={{ width: '70%', margin: '0 15% 24px 15%' }} /> */}
                    
                    <h3>3. Datos de la comunidad</h3>
                    <p>Comunidad de propietarios: {finca?.razonSocial}</p>
                    <p>Dirección: {finca?.direccion}</p>
                    <p>Localidad: {finca?.localidad}</p>
                    <p>C.I.F.: {finca?.nif}</p>
                    <p>Sector: COMUNIDAD DE PROPIETARIOS</p>
                    <p>Centro de Trabajo Analizado: {finca?.direccion}, {finca?.codigoPostal}, {finca?.localidad}</p>
                    <h3>4. Datos de la identificación de riesgos</h3>
                    <p>Fecha de realización: {finca?.fechaRealizacion}</p>
                    {/* <div className="pagebreak"></div> */}
                    {/* <h3>3. Severidad / Probabilidad</h3> */}
                    {/* eslint-disable-next-line */}
                    {/* <img src={evRiesg_2} style={{ width: '100%', marginBottom: 24 }} /> */}
                    {/* <div className="pagebreak"></div> */}
                    {/* <h3>4. Valor del riesgo / prioridad</h3> */}
                    {/* eslint-disable-next-line */}
                    {/* <img src={evRiesg_3} style={{ width: '100%', marginBottom: 24 }} /> */}
                    <div className="pagebreak"></div>
                    {/* <h3>7. Identificación de riesgos</h3>
                    <p>A continuación, se detallan a modo de resumen los distintos elementos requeridos por los trabajadores.</p> */}
                    <h3>5. Identificación de riesgos y acciones correctoras</h3>
                    <p>
                        En este apartado se indican las diferentes zonas en las que se ha clasificado u organizado la comunidad de propietarios para la identificación de los factores de riesgo existentes en el centro.<br />
                        Para cada factor de riesgo encontrado, se identifican y estiman los riesgos existentes para los trabajadores tanto propias como externos, aplicando, para cada uno de ellos, las medidas de prevención necesarias para evitar o controlar la situación de riesgo. A continuación, se determinan las acciones preventivas a desarrollar en función de la visita realizada en la comunidad de propietarios:<br />
                    </p>
                    <table className="tableRiesgos">
                        <tbody>
                            <tr>
                                <th>Descripción del riesgo</th>
                                <th>Origen</th>
                                <th>Valoración del riesgo</th>
                                <th>Acción correctora</th>
                            </tr>
                            {finca?.p1_2 === 's' ?
                                <tr>
                                    <td>Golpes y cortes por objetos y herramientas</td>
                                    <td>General</td>
                                    <td>Moderado</td>
                                    <td>Sustituir los cristales afectados, por otros en correcto estado.</td>
                                </tr>
                                : null}
                            {finca?.p1_1 === 's' ?
                                <tr>
                                    <td>Caídas de personas a diferente nivel</td>
                                    <td>General</td>
                                    <td>Importante</td>
                                    <td>Reparar los desperfectos encontrados en el suelo. </td>
                                </tr>
                                : null}
                            {finca?.p3_4 === 'n' ?
                                <tr>
                                    <td>Caídas de personas a diferente nivel</td>
                                    <td>General</td>
                                    <td>Importante</td>
                                    <td>Cubrir las aberturas en el suelo o colocar barandillas, barras intermedias y plintos en todo el perímetro de los huecos. </td>
                                </tr>
                                : null}
                            {finca?.p1_4 === 's' ?
                                <tr>
                                    <td>Contactos eléctricos</td>
                                    <td>General</td>
                                    <td>Importante</td>
                                    <td>Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables. En especial las que crucen zonas de paso</td>
                                </tr>
                                : null}
                            {finca?.p1_5 === 's' ?
                                <tr>
                                    <td>Contactos eléctricos<br />Cajas de registro abiertas.</td>
                                    <td>General</td>
                                    <td>Tolerable</td>
                                    <td>Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). Documentar las revisiones. Cuadro eléctrico </td>
                                </tr>
                                : null}
                            {/* {finca?.p1_5 === 's' ?
                                <tr>
                                    <td>Contactos eléctricos</td>
                                    <td>General</td>
                                    <td>Moderado</td>
                                    <td>Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico </td>
                                </tr>
                                : null} */}
                            {finca?.p1_6 === 's' ?
                                <tr>
                                    <td>Caídas de personas a distinto nivel</td>
                                    <td>General</td>
                                    <td>Moderado</td>
                                    <td>Adecuar las rampas con tiras antideslizantes</td>
                                </tr>
                                : null}
                            {finca?.p1_7 === 's' ?
                                <tr>
                                    <td>Contactos eléctricos</td>
                                    <td>General</td>
                                    <td>Tolerable</td>
                                    <td>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado</td>
                                </tr>
                                : null}
                            {finca?.p1_8 === 'n' ?
                                <tr>
                                    <td>Contactos eléctricos</td>
                                    <td>General</td>
                                    <td>Importante</td>
                                    <td>Señalizar el riesgo de contacto eléctrico en los cuadros eléctricos de las instalaciones de la empresa mediante pictograma homologado (triángulo negro sobre fondo amarillo). </td>
                                </tr>
                                : null}
                            {finca?.p2_1 === 'n' ?
                                <tr>
                                    <td></td>
                                    <td>Ascensores</td>
                                    <td>Tolerable</td>
                                    <td>Revisar periódicamente los ascensores mediante una empresa de mantenimiento y por personal autorizado </td>
                                </tr>
                                : null}
                            {finca?.p2_2 === 'n' ?
                                <tr>
                                    <td></td>
                                    <td>Ascensores</td>
                                    <td>Tolerable</td>
                                    <td>Dotar de iluminación interior 24h. Revisarla periódicamente</td>
                                </tr>
                                : null}
                            {finca?.p2_3 === 'n' ?
                                <tr>
                                    <td></td>
                                    <td>Ascensores</td>
                                    <td>Moderado</td>
                                    <td>Dotar de iluminación exterior 24h. Revisarla periódicamente</td>
                                </tr>
                                : null}
                            {finca?.p2_4 === 'n' ?
                                <tr>
                                    <td></td>
                                    <td>Ascensores</td>
                                    <td>Moderado</td>
                                    <td>Dotar de alarma con conexión 24h. Revisarla periódicamente</td>
                                </tr>
                                : null}
                            {finca?.p2_6 === 'n' ?
                                <tr>
                                    <td></td>
                                    <td>Ascensores</td>
                                    <td>Moderado</td>
                                    <td>Comprobar y proteger el motor</td>
                                </tr>
                                : null}
                            {finca?.p2_5 === 'n' ?
                                <tr>
                                    <td></td>
                                    <td>Ascensores</td>
                                    <td>Moderado</td>
                                    <td>Cerrar el acceso a la sala de máquinas para personal no autorizado.</td>
                                </tr>
                                : null}
                            {finca?.p2_7 === 'n' ?
                                <tr>
                                    <td>Contacto eléctrico</td>
                                    <td>Ascensores</td>
                                    <td>Tolerable</td>
                                    <td>Señalizar el riesgo eléctrico</td>
                                </tr>
                                : null}
                            {finca?.p2_8 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Ascensores</td>
                                    <td>Moderado</td>
                                    <td>Disponer de un extintor de CO² en el interior de la sala de máquinas, a una altura máxima sobre su punto de anclaje de 1,20 m del suelo</td>
                                </tr>
                                : null}
                            {finca?.p2_9 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Ascensores</td>
                                    <td>Moderado</td>
                                    <td>Dispone de un extintor, preferentemente, de CO² en el exterior de la sala de maquinas </td>
                                </tr>
                                : null}
                            {finca?.p2_10 === 'n' ?
                                <tr>
                                    <td>Atrapamientos</td>
                                    <td>Ascensores</td>
                                    <td>Tolerable</td>
                                    <td>Instalar un cartel con el texto: “Cuarto de maquinaria del ascensor</td>
                                </tr>
                                : null}
                            {finca?.p2_10 === 'n' ?
                                <tr>
                                    <td>Atrapamientos</td>
                                    <td>Ascensores</td>
                                    <td>Tolerable</td>
                                    <td>Colocar un cartel con el texto “Se prohíbe la entrada a toda persona no autorizada</td>
                                </tr>
                                : null}
                            {finca?.p3_1 === 'n' ?
                                <tr>
                                    <td>Caída de personal a diferente nivel</td>
                                    <td>Azotea</td>
                                    <td>Moderado</td>
                                    <td>Delimitar y señalizar la zona de la azotea para evitar el acceso de cualquier persona ajena a la actividad.</td>
                                </tr>
                                : null}
                            {finca?.p3_1 === 'n' ?
                                <tr>
                                    <td>Caída de personal a diferente nivel</td>
                                    <td>Azotea</td>
                                    <td>Moderado</td>
                                    <td>Disponer de un acceso seguro a la zona de la azotea. Prohibir el acceso mediante escaleras de mano</td>
                                </tr>
                                : null}
                            {finca?.p3_2 === 'n' ?
                                <tr>
                                    <td>Caída de personal a diferente nivel</td>
                                    <td>Azotea</td>
                                    <td>Moderado</td>
                                    <td>Instalar escaleras de servicio para el acceso seguro a la zona de azoteas</td>
                                </tr>
                                : null}
                            {finca?.p3_3 === 'n' ?
                                <tr>
                                    <td>Caída de personal a diferente nivel</td>
                                    <td>Azotea</td>
                                    <td>Importante</td>
                                    <td>Proteger el perímetro de la zona de la azotea mediante barandillas. Estas deben disponer de pasamanos, barra intermedia y rodapié.</td>
                                </tr>
                                : null}
                            {finca?.p3_3 === 'n' ?
                                <tr>
                                    <td>Caída de personal a diferente nivel</td>
                                    <td>Azotea</td>
                                    <td>Importante</td>
                                    <td>Estudiar la posibilidad de instalar una línea de vida</td>
                                </tr>
                                : null}
                            {/* {finca?.p3_3 === 'n' ?
                                <tr>
                                    <td>Caída de personal a diferente nivel</td>
                                    <td>Azotea</td>
                                    <td>Importante</td>
                                    <td>Proporcionar a los trabajadores arnés de seguridad, cuerdas de seguridad y punto de amarre seguro para los trabajos en la zona de la azotea</td>
                                </tr>
                                : null} */}
                            {finca?.p3_4 === 'n' ?
                                <tr>
                                    <td>Caída de personal a diferente nivel</td>
                                    <td>Azotea</td>
                                    <td>Importante</td>
                                    <td>Suspender los trabajos en azoteas los días de viento fuerte o cuando las condiciones climáticas así lo aconsejen (lluvia, nieve, granizo, viento).</td>
                                </tr>
                                : null}
                            {finca?.p4_1 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Disponer e instalar extintores suficientes en las instalaciones. El número mínimo de extintores deberá ser el suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 metros.</td>
                                </tr>
                                : null}
                            {finca?.p4_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Revisar cada tres meses, de forma ocular, los extintores de la empresa</td>
                                </tr>
                                : null}
                            {finca?.p4_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Comprobación de la accesibilidad, señalización, buen estado aparente de conservación</td>
                                </tr>
                                : null}
                            {finca?.p4_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Inspección ocular de seguros, precintos, inscripciones, etc. </td>
                                </tr>
                                : null}
                            {finca?.p4_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Comprobación del peso y presión en su caso. </td>
                                </tr>
                                : null}
                            {finca?.p4_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Inspección ocular del estado externo de las partes metálicas (boquilla, válvula, manguera, etc.).  </td>
                                </tr>
                                : null}
                            {finca?.p4_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017</td>
                                </tr>
                                : null}
                            {finca?.p4_3 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Tolerable</td>
                                    <td>Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.</td>
                                </tr>
                                : null}
                            {finca?.p4_4 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Tolerable</td>
                                    <td>Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo.</td>
                                </tr>
                                : null}
                            {finca?.p4_6 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.</td>
                                </tr>
                                : null}
                            {finca?.p4_7 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Instalar luces de emergencia sobre las puertas de salida, para dotar de suficiente visibilidad el recorrido de evacuación y las salidas de emergencia ante una falta de iluminación</td>
                                </tr>
                                : null}
                            {finca?.p4_8 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Señalizar las direcciones de circulación en el interior del parking</td>
                                </tr>
                                : null}
                            {finca?.p4_9 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Tolerable</td>
                                    <td>Señalizar la dirección y sentido de acceso en las entradas al parking.</td>
                                </tr>
                                : null}
                            {finca?.p4_9 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Tolerable</td>
                                    <td>Instalar luz de advertencia giratoria en las entradas al parking, de manera que advierta de la salida de vehículos</td>
                                </tr>
                                : null}
                            {finca?.p4_10 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Importante</td>
                                    <td>Dotar de sistemas automáticos de extinción de incendios (rociadores)</td>
                                </tr>
                                : null}
                            {finca?.p4_11 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Importante</td>
                                    <td>Instalar sistemas de extracción de humos en las instalaciones</td>
                                </tr>
                                : null}
                            {finca?.p4_12 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Importante</td>
                                    <td>Revisar periódicamente la BIE por una empresa homologada y especializada.</td>
                                </tr>
                                : null}
                            {finca?.p4_13 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Revisar periódicamente los detectores de humo de las instalaciones o una empresa homologada y especializada</td>
                                </tr>
                                : null}
                            {finca?.p4_14 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Revisar periódicamente el correcto estado de funcionamiento de la central de alarmas, en especial los pulsadores mediante una empresa homologada y especializada</td>
                                </tr>
                                : null}
                            {finca?.p4_15 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Revisar periódicamente el correcto estado de funcionamiento de la central de alarmas, mediante una empresa homologada y especializada</td>
                                </tr>
                                : null}
                            {finca?.p4_16 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Parking</td>
                                    <td>Moderado</td>
                                    <td>Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado.</td>
                                </tr>
                                : null}
                            {finca?.p4_17 === 'n' ?
                                <tr>
                                    <td>Contactos eléctricos</td>
                                    <td>Parking</td>
                                    <td>Tolerable</td>
                                    <td>Señalizar el cuadro eléctrico con "señal riesgo eléctrico". Pictograma triangular amarillo con letras negras<br />
                                        <img src={riesgosElectricos} alt="riesgos electrícos" />
                                    </td>
                                </tr>
                                : null}
                            {finca?.p5_2 === 'n' ?
                                <tr>
                                    <td>Exposición a contaminantes químicos</td>
                                    <td>Jardín</td>
                                    <td>Importante</td>
                                    <td>Disponer de un armario cerrado bajo llave </td>
                                </tr>
                                : null}
                            {finca?.p5_3 === 'n' ?
                                <tr>
                                    <td>Exposición a contaminantes químicos</td>
                                    <td>Jardín</td>
                                    <td>Moderado</td>
                                    <td>Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen.</td>
                                </tr>
                                : null}
                            {finca?.p5_3 === 'n' ?
                                <tr>
                                    <td>Exposición a contaminantes químicos</td>
                                    <td>Jardín</td>
                                    <td>Moderado</td>
                                    <td>Proporcionar una copia de las fichas de datos de seguridad de los productos químicos al Servicio de prevención. Se actualizará la información en caso de utilización de nuevos productos.</td>
                                </tr>
                                : null}
                            {finca?.p5_3 === 'n' ?
                                <tr>
                                    <td>Exposición a contaminantes químicos</td>
                                    <td>Jardín</td>
                                    <td>Moderado</td>
                                    <td>Poner a disposición de los trabajadores la información contenida en las fichas de datos de seguridad de los productos químicos utilizados.</td>
                                </tr>
                                : null}
                            {finca?.p6_1 === 's' ?
                                <tr>
                                    <td>Exposición a contaminantes químicos</td>
                                    <td>Piscina</td>
                                    <td>Moderado</td>
                                    <td>Almacenar y manipular los productos fitosanitarios según las indicaciones de las Fichas de datos de seguridad de productos químicos.</td>
                                </tr>
                                : null}
                            {finca?.p6_2 === 'n' ?
                                <tr>
                                    <td>Caídas de personal a diferente nivel</td>
                                    <td>Piscina</td>
                                    <td>Moderado</td>
                                    <td>Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma.</td>
                                </tr>
                                : null}
                            {finca?.p6_2 === 'n' ?
                                <tr>
                                    <td>Caídas de personal a diferente nivel</td>
                                    <td>Piscina</td>
                                    <td>Moderado</td>
                                    <td>Extremar la precaución en los desplazamientos por el lugar de trabajo (borde de la piscina, plataformas flotantes, suelos mojados y con desnivel, etc.).</td>
                                </tr>
                                : null}
                            {finca?.p6_3 === 'n' ?
                                <tr>
                                    <td>Otros</td>
                                    <td>Piscina</td>
                                    <td>Moderado</td>
                                    <td>Disponer de un aro salvavidas en las inmediaciones de la piscina.</td>
                                </tr>
                                : null}
                            {finca?.p7_1 === 'n' ?
                                <tr>
                                    <td>Otros</td>
                                    <td>Instalación de Gas</td>
                                    <td>Importante</td>
                                    <td>Mantener cerrada la sala de gas</td>
                                </tr>
                                : null}
                            {finca?.p7_1 === 'n' || finca?.p7_2 === 'n' ?
                                <tr>
                                    <td>Otros</td>
                                    <td>Instalación de Gas</td>
                                    <td>Moderado</td>
                                    <td>Señalizar la sala de gas mediante cartel indicativo del riesgo</td>
                                </tr>
                                : null}
                            {finca?.p7_3 === 'n' ?
                                <tr>
                                    <td>Explosiones</td>
                                    <td>Instalación de Gas</td>
                                    <td>Moderado</td>
                                    <td>Disponer de un extintor de polvo ABC en las inmediaciones de la sala de gas </td>
                                </tr>
                                : null}
                            {finca?.p8_1 === 'n' ?
                                <tr>
                                    <td>Otros</td>
                                    <td>Instalación de Gasoil</td>
                                    <td>Importante</td>
                                    <td>Cerrar bajo llave la sala de gasoil</td>
                                </tr>
                                : null}
                            {finca?.p8_2 === 'n' ?
                                <tr>
                                    <td>Otros</td>
                                    <td>Instalación de Gasoil</td>
                                    <td>Moderado</td>
                                    <td>Señalizar la sala de gasoil</td>
                                </tr>
                                : null}
                            {finca?.p8_3 === 'n' ?
                                <tr>
                                    <td>Otros</td>
                                    <td>Instalación de Gasoil</td>
                                    <td>Moderado</td>
                                    <td>Señalizar el depósito de gasoil con el pictograma de inflamable.</td>
                                </tr>
                                : null}
                            {finca?.p8_4 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Instalación de Gasoil</td>
                                    <td>Moderado</td>
                                    <td>Dotar de cubeta de retención de fugas y vertidos accidentales </td>
                                </tr>
                                : null}
                            {finca?.p8_5 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Instalación de Gasoil</td>
                                    <td>Moderado</td>
                                    <td>Instalar un extintor de polvo ABC en las inmediaciones de la sala de gasoil </td>
                                </tr>
                                : null}
                            {finca?.p9_1 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Instalación gas ciudad</td>
                                    <td>Moderado</td>
                                    <td>Disponer de un extintor de polvo ABC en las inmediaciones de la sala de gas </td>
                                </tr>
                                : null}
                            {finca?.p9_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Instalación gas ciudad</td>
                                    <td>Moderado</td>
                                    <td>Disponer de cartel en las inmediaciones del cuarto de gas con el texto: “Cuarto de Gas. Peligro de explosión.</td>
                                </tr>
                                : null}
                            {finca?.p10_1 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Extintores</td>
                                    <td>Importante</td>
                                    <td>En todo edificio se dispondrán extintores en número suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 m.</td>
                                </tr>
                                : null}
                            {finca?.p10_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Extintores</td>
                                    <td>Moderado</td>
                                    <td>Los extintores deben ser adecuados al tipo de fuego previsible. Serán suficientes en número para que el recorrido real en cada planta desde cualquier origen de la evacuación hasta el extintor no supere los 15 m. Estarán bien ubicados, se revisarán periódicamente y se formará a los trabajadores en su correcto uso.</td>
                                </tr>
                                : null}
                            {finca?.p10_3 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Extintores</td>
                                    <td>Moderado</td>
                                    <td>Revisar cada tres meses el estado aparente de conservación, de carga y del botellín de gas impulsor. Verificar su carga con una periodicidad anual y cada 5 años retimbrar el extintor de acuerdo con el Reglamento de Aparatos a Presión sobre Extintores de Incendios.</td>
                                </tr>
                                : null}
                            {finca?.p10_4 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Extintores</td>
                                    <td>Tolerable</td>
                                    <td>Señalizar todos los equipos de protección contra incendios, con su respectivo pictograma.</td>
                                </tr>
                                : null}
                            {finca?.p10_5 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Extintores</td>
                                    <td>Moderado</td>
                                    <td>Situar de forma tal que el extremo superior del extintor se encuentre a una altura sobre el suelo menor que 1,20m </td>
                                </tr>
                                : null}
                            {finca?.p10_6 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Extintores</td>
                                    <td>Moderado</td>
                                    <td>Mantener en buen estado de conservación los extintores según el Reglamento de aparatos a presión sobre extintores de incendios.</td>
                                </tr>
                                : null}
                            {finca?.p11_1 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Iluminación</td>
                                    <td>Tolerable</td>
                                    <td>Equipar las vías y salidas de evacuación con iluminación de seguridad de suficiente intensidad según Reglamento actual. </td>
                                </tr>
                                : null}
                            {finca?.p11_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Iluminación</td>
                                    <td>Tolerable</td>
                                    <td>Equipar las salidas de la finca con iluminación de suficiente intensidad. </td>
                                </tr>
                                : null}
                            {finca?.p12_1 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Señalización</td>
                                    <td>Tolerable</td>
                                    <td>Señalizar las salidas de la finca con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.<br />
                                        <img src={salida} alt="Salida" />
                                    </td>
                                </tr>
                                : null}
                            {finca?.p12_2 === 'n' ?
                                <tr>
                                    <td>Incendios</td>
                                    <td>Señalización</td>
                                    <td>Tolerable</td>
                                    <td>Señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida.<br />
                                        <img src={salidaEmergencia} alt="Salida de emergencia" />
                                    </td>
                                </tr>
                                : null}
                        </tbody>
                    </table>
                    {finca?.p13_0 === 's' ?
                        <>
                            <h3>9. Identificación de riesgos, acciones correctoras y planificación preventiva en comunidades con trabajadores</h3>
                            <table className="tableRiesgos">
                                <tbody>
                                    <tr>
                                        <th>Descripción del riesgo</th>
                                        <th>Origen</th>
                                        <th>Valoración del riesgo</th>
                                        <th>Acción preventiva</th>
                                    </tr>
                                    {finca?.p13_1 === 's' ?
                                        <tr>
                                            <td>Posturas forzadas y/o estáticas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>El personal de limpieza debe disponer de los equipos de trabajo y material necesario para poder realizar su trabajo de la forma más segura y fácil posible de tal manera que minimice la realización de posturas forzadas al realizar las tareas de limpieza.</td>
                                        </tr>
                                        : null}
                                    {finca?.p13_2 === 's' ?
                                        <tr>
                                            <td>Posturas forzadas y/o estáticas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Palos alargaderas para limpiar cristales, techos, zonas de difícil acceso y todas aquellas que impliquen trabajos con las manos por encima de la cabeza.</td>
                                        </tr>
                                        : null}
                                    {finca?.p13_3 === 's' ?
                                        <tr>
                                            <td>Posturas forzadas y/o estáticas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Escaleras pequeñas para facilitar el acceso a zonas elevadas (armarios, estanterías, etc.) </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_3 === 's' ?
                                        <tr>
                                            <td>Posturas forzadas y/o estáticas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Estructuras de mopas adecuadas a las tareas y zonas a limpiar. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_3 === 's' ?
                                        <tr>
                                            <td>Posturas forzadas y/o estáticas</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Recogedores, mopas, escobas con mangos largos.</td>
                                        </tr>
                                        : null}
                                    {finca?.p13_3 === 's' ?
                                        <tr>
                                            <td>Posturas forzadas y/o estáticas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Carros para transportar el material. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_3 === 's' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Se dotará a los trabajadores de arneses de seguridad, cuerda y puntos de amarre seguros para la realización de trabajaos a alturas superiores a 2 metros. Registrar su entrega.</td>
                                        </tr>
                                        : null}
                                    {finca?.p13_3 === 's' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Establecer que las escaleras de mano se utilizarán sólo en aquellas circunstancias en las que el riesgo sea de bajo nivel o que por las características de los emplazamientos no se justifique el uso de otros equipos de trabajo más seguros (andamios, elevadores, etc.). </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_4 === 'n' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Informar a los trabajadores sobre el correcto uso de productos de limpieza </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_4 === 'n' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Se recomienda señalizar las zonas de trabajo, zonas de paso y zonas destinadas a almacén, de esta manera se asegura un correcto estado de orden y limpieza en las instalaciones. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_4 === 'n' ?
                                        <tr>
                                            <td>Exposición a contaminantes químicos</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Proporcionar una copia de las fichas de datos de seguridad de los productos químicos al Servicio de prevención. Se actualizará la información en caso de utilización de nuevos productos. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_4 === 'n' ?
                                        <tr>
                                            <td>Exposición a contaminantes químicos</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Poner a disposición de los trabajadores la información contenida en las fichas de datos de seguridad de los productos químicos utilizados. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_5 === 'n' ?
                                        <tr>
                                            <td>Caídas de personas a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Proporcionar al trabajador señal que indique “peligro suelo resbaladizo” </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_6 === 's' ?
                                        <tr>
                                            <td>Contactos eléctricos</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Realizar las tareas de mantenimiento (cambio de bombillas, fluorescentes, etc.) con la corriente eléctrica desconectada. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_7 === 's' ?
                                        <tr>
                                            <td>Caídas de personas a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Se dotará a los trabajadores de arneses de seguridad, cuerda y puntos de amarre seguros para la realización de trabajaos a alturas superiores a 2 metros. Registrar su entrega. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_8 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Proporcionar guantes de resistencia mecánica y de abrasión para la manipulación de bolsas de basura, cristales rotos, etc. Registrar su entrega. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_8 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Establecer como obligatorio el uso de guantes de seguridad para la manipulación de basuras. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_9 === 's' ?
                                        <tr>
                                            <td></td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Proporcionar a los trabajadores mascarillas de protección contra contaminantes biológicos. Registrar su entrega. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_10 === 'n' ?
                                        <tr>
                                            <td></td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Formar e informar al trabajador sobre los riesgos específicos de su puesto de trabajo </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_11 === 'n' ?
                                        <tr>
                                            <td></td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Se debe ofrecer la Vigilancia de la salud a todos los trabajadores, teniendo en cuanta la posible existencia de puestos de trabajo con reconocimiento obligatorio. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_11 === 'n' ?
                                        <tr>
                                            <td></td>
                                            <td>Trabajador</td>
                                            <td>???</td>
                                            <td>En el caso de renuncia por parte de los trabajadores a realizarse el reconocimiento médico, esta deberá documentarse por escrito. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_12 === 's' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma.  </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_12 === 's' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Extremar la precaución en los desplazamientos por el lugar de trabajo (borde de la piscina, plataformas flotantes, suelos mojados y con desnivel, etc.). </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_13 === 'n' ?
                                        <tr>
                                            <td>Exposición a contaminantes químicos</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Suministrar a los trabajadores los equipos de protección indicados en las etiquetas y fichas de los productos de limpieza utilizados. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_14 === 's' ?
                                        <tr>
                                            <td>Contacto con productos químicos</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Se establecerá y se verificará la utilización de guantes de protección en el uso de productos fitosanitarios del tipo "guantes contra productos químicos". </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_15 === 'n' ?
                                        <tr>
                                            <td>Contacto con productos químicos</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>El nivel de prestación y mantenimiento se adaptará a las indicaciones de las fichas de seguridad química y al folleto informativo del fabricante del EPI. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_15 === 'n' ?
                                        <tr>
                                            <td>Contacto con productos químicos</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>En la aplicación de productos fitosanitarios se establecerá y vigilará el uso obligatorio de ropa de trabajo adecuada, pantalón largo y manga larga. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_15 === 'n' ?
                                        <tr>
                                            <td>Contacto con productos químicos</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de los trabajadores. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_16 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Facilitar al trabajador escaleras de mano adecuadas para realizar la poda y el mantenimiento de los árboles. Esta debe estar provista de zapatas regulables o hincas; abrazaderas, ganchos o sistema de apoyo y sujeción en su parte superior (tipo apoyo en postes). </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_16 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Proporcionar a los trabajadores arnés de seguridad, cuerdas de seguridad y punto de amarre seguro para atarse durante la realización de las tareas de poda desde los árboles. Registrar su entrega. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_16 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Proporcionar al trabajador guantes de seguridad para su uso durante la manipulación de herramientas de poda manuales. Realizar el control de entrega del equipo. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_16 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Informar a los trabajadores sobre los riesgos y las medidas preventivas a seguir durante el uso de herramientas de poda manuales. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_16 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Proporcionar pantalla de protección facial/ocular con resistencia mecánica frente a impactos en operaciones de susceptibles de proyección de partículas como segado del césped o trabajos de poda. Se debe de registrar su entrega. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_17 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Velar por el uso, por parte del trabajador, del calzado y guantes de seguridad durante el uso de herramientas de mano y la manipulación de piezas pesadas. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_17 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Habilitar espacios y elementos donde ubicar las herramientas de mano, de tal manera que se consiga una ubicación ordenada de las herramientas (cajas de herramientas con ruedas, armarios, estanterías, etc.)  </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_17 === 's' ?
                                        <tr>
                                            <td>Golpes y cortes por objetos y herramientas</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Proporcionar al trabajador guantes de seguridad para la manipulación de herramientas de mano, manipulación de plantas o flores que presenten espinas, recogida de materiales cortantes, etc. Realizar el control de entrega del equipo. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_18 === 's' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Importante</td>
                                            <td>Informar a los trabajadores sobre los riesgos y medidas preventivas a seguir en el uso de escaleras de mano.  </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_18 === 's' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Facilitar al trabajador escaleras de mano adecuadas para realizar la poda y el mantenimiento de los árboles. Esta debe estar provista de zapatas regulables o hincas; abrazaderas, ganchos o sistema de apoyo y sujeción en su parte superior (tipo apoyo en postes).  </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_18 === 's' ?
                                        <tr>
                                            <td>Caída de personal a diferente nivel</td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Revisar periódicamente las escaleras de mano, asegurándose que:
                                                <ul>
                                                    <li>Disponen de zapatas antideslizantes</li>
                                                    <li>Se usan los dispositivos de agarre en el cabezal de la escalera cuando se trabaja con ella.</li>
                                                    <li>Los peldaños no están flojos, rotos, agrietados, etc.</li>
                                                    <li>Dispone de sistema anti apertura</li>
                                                </ul>
                                            </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_19 === 'n' ?
                                        <tr>
                                            <td></td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Informar de la mutua de accidentes al trabajador.  </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_20 === 'n' ?
                                        <tr>
                                            <td></td>
                                            <td>Trabajador</td>
                                            <td>Moderado</td>
                                            <td>Informar del centro asistencial más cercano. </td>
                                        </tr>
                                        : null}
                                    {finca?.p13_21 === 'n' ?
                                        <tr>
                                            <td></td>
                                            <td>Trabajador</td>
                                            <td>Tolerable</td>
                                            <td>Establecer un protocolo de comunicación entre el trabajador y la comunidad/administrador de la finca </td>
                                        </tr>
                                        : null}
                                </tbody>
                            </table>
                        </>
                        : null}
                    <h3>6. Conclusión</h3>
                    <p>
                        La identificación de riesgos se ha realizado conforme a la situación actual del centro de trabajo. El informe ha sido realizado por <span style={{textTransform:"capitalize"}}>{finca?.tecnico?.nombre}</span> , técnico en prevención de riesgos laborales. <br />
                    </p>
                </tbody>
            </table>
            <div className="pdf-header">
                <img src={LogoLAE} alt="Logo LAE" />
                {/* <img src={LogoMC} alt="Logo MC" /> */}
            </div>
            <style jsx>{`
            .tablePage{
                width: 100%;
            }
            .pdf-header {
                width: 100%;
                display: flex;
                justify-content: space-between;
                position: fixed;
                top: 0;
                left:50%
                background-color: #ffffff
            }
            .header-space, .pdf-header {
                height: 90px;
                
            }
            .pdf-header img {
                height: 60px
            }
            div {
                font-size: 10px
            }
            h1 {
                margin-top: 100px;
                margin-bottom: 60px;
                font-size: 20px;
            }
            h2 {
                font-size: 18px;
                font-weight: 400;
            }
            h3 {
                width: 100%;
                padding: 5px 10px;
                background-color: #f8f8f8;
                text-transform: uppercase;
                border: 1px solid #e6e6e6;
                margin-top: 25px;
                margin-bottom: 15px;
            }
            .tableRiesgos {
                border-collapse: collapse;
                width: 100%;
                table-layout: fixed;
                margin-bottom: 24px;
                
            }
            .tableRiesgos th, .tableRiesgos td {
                border: 1px solid #000000;
                padding: 5px;
                line-height: 1.1;
                text-align: justify;
            }
            .tableRiesgos th {
                background-color: #375491;
                color: #ffffff;
                font-size: 12px;
                font-weight: bold;
            }
            p {
                line-height: 1.8;
                text-align: justify;
            }
            br {
                display: block;
                margin: 24px 0;
                line-height:22px;
                content: " ";
            }
            @page {
                size: A4 portrait;
                margin: 2cm
            }
        `}</style>
        </>
     );
}

export default withRouter(EvaluacionRiesgosRender);