import axios from "axios";

const urlPath = "comment";

export const getComments = async (data) => {
    try {
      const response = await axios.post(`${urlPath}/list`,data.filtros);
  
      // console.log("response",response);
      return response.data;
    } catch (err) {
      console.log(err.response);
    }
};

//No se usa en el front
// export const get4Comments = async (id) => {
//     try {
//       const response = await axios.post(`${urlPath}/4comments/${id}`);
  
//       return response.data;
//     } catch (err) {
//       console.log(err.response);
//     }
// };

export const createComment = async (data) => {
    try {
        const response = await axios.post(urlPath, data);

        return response;
    } catch (err) {
        console.log(err.response);
    }
};
export const updateComment = async (id, data) => {
    try {
        const response = await axios.put(`${urlPath}/${id}`, data);

        return response;
    } catch (err) {
        console.log(err.response);
    }
};
//17382
export const deleteComment = async (id) => {
    try {
      const response = await axios.delete(`${urlPath}/${id}`);
  
      return response;
    } catch (err) {
      console.log(err.response);
    }
};