import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Tooltip, Typography } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable, LaeButton } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { filterAAFFs, loginAsAAFF } from "../../../services";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import {useLocation, useNavigate } from "react-router-dom";
import {Login} from '@mui/icons-material';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import criticalWarning from "../../../icons/criticalWarning.svg";


const AAFF = () => {
  const user = useContext(UsuarioContext);
  const [loading, setLoading] = useState(true);
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [busquedaActiva,setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  // useEffect(() => {
  //   fetchData();
  // }, [recargar]);

  const navigate = useNavigate();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {
   
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';
    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order,orderBy);

    const payload = {
      // aaff_id: 7,
      filtros,
      params: { page: offset, limit, order,orderBy },
    };

    try {
      setLoading(true);
      const response = await filterAAFFs(payload);
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  const formatData = (data) => {
    console.log(data)
    return data.map((row) => {
      const consultorCae = row.consultants?.find(objeto => objeto.r_consul_servsgroups_aaffs.some(group => group.group_id === 1))
      const consultorLopd = row.consultants?.find(objeto => objeto.r_consul_servsgroups_aaffs.some(group => group.group_id === 2))
      return {
        razonSocial: row.razonSocial || row.propertie?.aaff?.nombreContacto,
        nif: row.nif,
        id: row.id,
        comunidades: row.pendientesCount + row.visitadasCount + row.renovadasCount + row.renovadasVisitadasCount ?? 0,
        consultorCae: consultorCae ? `${consultorCae.nombre} ${consultorCae.apellidos}` : "",
        consultorLopd: consultorLopd ? `${consultorLopd.nombre} ${consultorLopd.apellidos}` : "",
        tecnico:
          row.technician?.id === "" ||
            row.technician?.id === null ||
            row.technician?.id === undefined ? (
            "-"
          ) : (
            <span>
              {row.technician?.nombre + " " + row.technician?.apellidos}
            </span>
          ),
        comercial:
          row.commercial?.id === "" ||
            row.commercial?.id === null ||
            row.commercial?.id === undefined ? (
            "-"
          ) : (
            <span>
              {row.commercial?.nombre + " " + row.commercial?.apellidos}
            </span>
          ),
        direction: row.direccion || row.propertie?.direccion,
        cp: row.codigoPostal || row.propertie?.codigoPostal,
        municipio: row.municipio || row.propertie?.municipio,
        provincia: row.provincia,
        servicios: row.gestionadaPor || row.propertie?.gestionadaPor,
        hojasVisita: row.visitSheets,
        // proveedores: numProveedoresAAFF(row.properties),
        proveedores: row.suppliersCount,
        // docsPendientes: docsPendientesProvs(row.properties),
        propCaducadas: row.hasPropCaducadas
        
      }
    });
  };

  const comunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
      overrideFunc: (data, row) => (
        <Link to={"/aaff/" + row.nif + "/comunidades"}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "consultorCae",
      numeric: false,
      disablePadding: false,
      label: "CONSULTOR CAE",
      noObjeto:true,
      overrideFunc: (data, row) => data ? data : "-"
    },
    {
      id: "consultorLopd",
      numeric: false,
      disablePadding: false,
      label: "CONSULTOR LOPD",
      noObjeto:true,
      overrideFunc: (data, row) => data ? data : "-"
    },
    // {
    //   id: "comercial",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "COMERCIAL",
    //   noObjeto:true,
    // },
    {
      id: "tecnico",
      numeric: false,
      disablePadding: false,
      label: "TECNICO",
      noObjeto:true,
    },
    {
      id: "comunidades",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES",
      noObjeto:true,
      overrideFunc: (row) => (
        <Typography>
          {row}
        </Typography>
      ),
    },

    {
      id: "proveedores",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDORES",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <p>
          {data}
        </p>
      )
    },
    {
      id: "propCaducadas",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto: true,
      overrideFunc: (data) => (
        <>
          {data ?
            <Tooltip title="Hay comunidades caducadas" arrow>
              <img src={criticalWarning} width={15} alt="comunidades caducadas" />
            </Tooltip>
            : null}
        </>
      ),
    },
    {
      id: "login",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <Tooltip title="Iniciar sesión como">
          <div className="bigBtn p-2" onClick={() => loginAsAAFF(row.nif)}>
            <Login fontSize="small" />
          </div>
        </Tooltip>
      )
    },

  ];

  
  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData({ offset: 1, limit: limite, filtros: filtros });
  }

  return (
    <>
      <BreadCrumb
        rutas={[{ path: "/aaff", label: "Administradores de fincas" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            ADMINISTRADORES DE FINCAS
          </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <FiltrosAAFF onFilter={filtrarDatos} clearFilter={clearFilters}  filtrosIniciales={busqueda}/>
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default withRouter(AAFF);
