import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { getAllTecnicos, getEquipo, updateEquipo } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { filterItems } from "../../../functions/funciones";
import { withRouter } from "../../../providers/withRouter";

const NuevoEquipoTecnicos = (props) => {
  const [technicians, setTecnicos] = useState([]);

  const [busqueda, setBusqueda] = useState([]);
  const [resultados, setResultados] = useState();

  const [selected, setSelected] = useState([]);

  const [equipo, setEquipo] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  


  useEffect(() => {
    getEquipo(props.params.id).then(t => {
      setEquipo(t)
      
    })
    getAllTecnicos().then((res) => {
      const tecnicos = [];
      res.forEach((c) => {
        tecnicos.push({
          id: c.id,
          nif: c.nif,
          nombre: c.nombre,
          apellidos: c.apellidos,
          busqueda: c.nombre + " " + c.apellidos,
          userId: c.user?.id,
          team_id: c.team_id
        });
      });
      const id = parseInt(props.params.id)

      setResultados(tecnicos.filter(e => e.team_id !== id));
      setTecnicos(tecnicos);
      setSelected(tecnicos.filter(e => e.team_id === id))
      setIsLoading(false);

    });
  }, [])

  function handleSubmit(e) {
    e.preventDefault();
    const toastInfo = toast.info("Editando equipo");
    // if (selected.length == 0) {
    //   return toast.update(toastInfo, {
    //     render: "No se han añadido técnicos",
    //     type: toast.TYPE.ERROR,
    //     autoClose: true,
    //   });
    // }
    updateEquipo({ ...equipo, userIds: selected.map(s => s.userId) })
      .then((res) => {
        if (res.status == 200) {
          toast.update(toastInfo, {
            render: "Equipo editado con éxito",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });

          setTimeout(() => {
            window.location.replace("/equipos");
          }, 3000);
        } else if (res.status == 403) {
          toast.update(toastInfo, {
            render: "Error al crear el equipo",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        } else {
          toast.update(toastInfo, {
            render: "Ha ocurrido un error",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
        }


      })
      .catch((err) => {
        console.log(err);
        toast.error("Error al crear el equipo");
      });
  }
  function handleChange(evt) {
    const value = evt.target.value;
    setEquipo({
      ...equipo,
      [evt.target.name]: value,
    });
  }


  const buscar = (e) => {
    // e.preventDefault();

    setResultados(filterItems(busqueda, technicians.filter(c => !selected.some(s => s.id === c.id))))
  };
  useEffect(buscar, [busqueda]);

  const select = (tecnico) => {
    setSelected([...selected, tecnico]);
    setResultados(resultados.filter(c => c.id !== tecnico.id));
  }

  const unselect = (tecnico) => {
    const newSelected = selected.filter(c => c.id !== tecnico.id)
    setSelected(newSelected);
    setResultados(technicians.filter(c => !newSelected.some(s => s.id === c.id)));
  }


  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        tecnicos / <b>Nuevo</b>
      </div> */}
      <BreadCrumb rutas={[{ path: `/tecnicos`, label: `Técnicos` }, { path: `/equipos`, label: `Nuevo equipo` }]} style={{ margin: '0 10px' }} />
      <div className="pageContent">
        <div className="gridHead">Editar equipo</div>
        <form className="nuevoAAFFForm" onSubmit={handleSubmit}>
          <input
            type="text"
            required
            placeholder="Nombre"
            name="nombre"
            value={equipo?.nombre}
            onChange={handleChange}
          ></input>

          <div className="tableContainer" style={{ marginTop: 30, marginBottom: 30, padding: 0 }}>
            <table>

              <thead>
                <tr>
                  <th>Técnico</th>
                  <th>NIF</th>
                  <th style={{ textAlign: "center" }}>Eliminar</th>
                </tr>
              </thead>

              <tbody>
                {isLoading ? (
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      <CircularProgress style={{ color: '#92004D' }} />
                    </td>
                  </tr>
                ) :
                  selected.length == 0 ? <tr><td colSpan={3} style={{ textAlign: "center" }}>Selecciona técnicos para añadirlos al equipo</td></tr> : selected.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td>{e.nombre + " " + e.apellidos}</td>
                        <td>{e.nif}</td>
                        <td style={{ textAlign: "center" }}>
                          <div
                            className="asignarButton"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              cursor: "pointer",
                            }}
                            onClick={() => unselect(e)}
                          >
                            Eliminar
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>

            </table>
          </div>
          <input
            type="submit"
            value="Guardar"
          ></input>
        </form>
        <form className="nuevoAAFFForm" onSubmit={e => e.preventDefault()}>
          <input
            type="text"
            placeholder="Buscar tecnico..."
            required
            value={busqueda}
            onChange={(e) => setBusqueda(e.target.value)}
          ></input>
        </form>
        <div className="tableContainer" style={{ marginTop: 10 }}>
          <table>
            <thead>
              <tr>
                <th>Técnico</th>
                <th>NIF</th>
                <th style={{ textAlign: "center" }}>Añadir</th>
              </tr>
            </thead>
            <tbody>
            {isLoading ? (
                  <tr>
                    <td colSpan={3} style={{ textAlign: "center" }}>
                      <CircularProgress style={{ color: '#92004D' }} />
                    </td>
                  </tr>
                ) :
              Array.isArray(resultados) ? resultados.map((e, i) => {
                return (
                  <tr key={i}>
                    <td>{e.nombre + " " + e.apellidos}</td>
                    <td>{e.nif}</td>
                    <td style={{ textAlign: "center" }}>
                      <div
                        className="asignarButton"
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          cursor: "pointer",
                        }}
                        onClick={() => select(e)}
                      >
                        Añadir
                      </div>
                    </td>
                  </tr>
                );
              }) :
                <tr><td colSpan={3} style={{ textAlign: "center" }}><CircularProgress style={{ color: '#92004D' }} /></td></tr>
              }
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};

export default withRouter(NuevoEquipoTecnicos);