import React, {useEffect, useState} from "react";
import "./Login.css"
//import { toast } from "react-toastify";
import Logo from "../images/LAE.png"
import BackgroundImage from "../images/loginFondo.jpg"
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword } from "../services";

const ChangePassword = () => {

    const [email, setEmail] = useState("")


    const handleChange = (evt) => {
        const value = evt.target.value;
        setEmail(value);
    }
    const handleSubmit = async () => {
        let toastInfo = toast.info("Enviando", { autoClose: false });
        const resp = await forgotPassword({email:email});
        if(resp.message === 'EMAIL_SEND_SUCCESSFULLY'){
            toast.update(toastInfo, {
                render: "Email enviado",
                type: toast.TYPE.SUCCESS,
                autoClose: true
            });
        }
    }


    return ( 
        <div style={{ backgroundImage: `url(${BackgroundImage})` }} className="backgroundDiv">
            <form className="loginForm">
                <div><img src={Logo} className="loginLogo" alt="Logo" /></div>
                <label htmlFor="email" style={{margin: '0 30px 20px 30px'}}>Inserta el email de usuario</label>
                <input type="email" id="email" name="email" required onChange={handleChange} onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''} placeholder="Correo electrónico" />
                <button type="button" onClick={(e) => handleSubmit(e) } onKeyPress={(e) => e.key === 'Enter' ? handleSubmit() : ''}>Enviar</button>
                <Link to="/login">
                    <div style={{
                                backgroundColor: "rgb(255, 255, 255)",
                                color: "rgb(48, 50, 72)",
                                margin: "10px auto 0",
                                padding: "8px 30px 0",
                                fontWeight: "normal",
                                fontSize: "10px"
                            }}>Volver a inicio
                    </div>
                </Link>
            </form>
        </div>
     );
}
 
export default ChangePassword;