import { CircularProgress } from "@mui/material"
import { pdf } from "@react-pdf/renderer"
import React, { useEffect, useState } from "react"

const PDFHandler = ({children: pdfContent}) => {
    const [pdfBlob, setPdfBlob] = useState(null);
    const [loading, setLoading] = useState(true);

    const loadPDF = async () => {
        try {
            const blob = await pdf(pdfContent).toBlob();
            setPdfBlob(blob);
            setLoading(false);
        } catch (err) {
            console.error("Error generando PDF:", err);
            setLoading(false);
        }
    };
    
    useEffect(() => {
        loadPDF();
    }, []);

    const handleDownload = () => {
        const url = URL.createObjectURL(pdfBlob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'documento.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };

    if (loading) {
        return <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} />;
    }

    try {
        // Intentar mostrar el PDF en un iframe
        const url = URL.createObjectURL(pdfBlob);
        return (
            <div>
                <iframe
                    src={url}
                    style={{width: '100%', height: '100vh'}}
                />
                <button 
                    onClick={handleDownload}
                    style={{
                        position: 'fixed',
                        bottom: '20px',
                        right: '20px',
                        padding: '10px 20px',
                        backgroundColor: '#92004D',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Descargar PDF
                </button>
            </div>
        );
    } catch (err) {
        return null;
    }
};

export default PDFHandler;