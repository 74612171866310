import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createSupplier, getServicios } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";
import { cambiarFormatoNombre } from "../../../functions/funciones";
import { withRouter } from "../../../providers/withRouter";
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { verificarCodigoPostal, verificarNif, verificarCorreo, verificarTelefono } from "../../../functions/validator/formValidator";
import './NuevaDelegacion.css';
import { forEach } from "underscore";

const NuevoDelegacion = (props) => {
  const [proveedor, setProveedor] = useState({});
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  useEffect(() => {
    fetchData();
    setProveedor({ parent_id: props.params.id });
  }, []);

  const fetchData = async () => {
    try {
      const resServicios = await getServicios();
      setServicios(resServicios.data);
    } catch (update) {
      console.update('update fetching services:', update);
    }
  };

  const handleSubmit = async (data) => {
    let nifCorrecto = verificarNif(data.nif);
    let correoCorrecto = verificarCorreo(data.email);
    let telefonoCorrecto = verificarTelefono(data.telefonoContacto);
    let codigoPostalCorrecto = verificarCodigoPostal(data.codigoPostal);

    const toastInfo = toast.info("Guardando datos delegación");


    const errores = [];

    if (!nifCorrecto && data.nif !== undefined) {
      errores.push("El NIF introducido es incorrecto");
    }

    if (!correoCorrecto && data.email !== undefined) {
      errores.push("El correo introducido es incorrecto");
    }


    if (errores.length > 0) {
      errores.forEach((error) => {
        toast.dismiss(toastInfo);
        toast.error(error);
      });
      return;
    }

    try {
      const res = await createSupplier(data);

      if (res.data[0].status === 200) {
        toast.update(toastInfo, {
          render: res.data[0].msg,
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        });
        window.history.back();
      } else if (res.data[0].status === 400) {
        toast.update(toastInfo, {
          render: res.data[0].msg,
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      } else if (res.status === 403) {
        toast.update(toastInfo, {
          render: "Error al crear la delegación",
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      }
    } catch (err) {
      console.error("Error creating supplier:", err);
      toast.update(toastInfo, {
        render: "Error al crear el proveedor",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
    }
  };



  function handleChange(evt) {
    const { name, value } = evt.target;
    setProveedor({
      ...proveedor,
      [name]: value,
    });
  };

  function seleccionarMunicipios(e) {
    const provincia = provinces.find(p => p.code === e.target.value)?.name;
    const municipios = municipalities
      .filter(m => m.provCode === e.target.value)
      .map(m => ({ ...m, name: cambiarFormatoNombre(m.name), value: m.name }));

    setListadoMunicipios(municipios);
    handleChange({ target: { name: 'provincia', value: provincia } });
  };

  function handleSelectChange(event) {
    const value = event.target.value;
    setSelectedServices(value);
    handleChange(event);
  };

  return (
    <>
      <BreadCrumb rutas={[{ path: '/delegaciones', label: "Delegación" }]} style={{ margin: '0 10px' }} />
      <div className="pageContent">
        <div className="gridHead">
          Crear nueva delegación
        </div>
        <form className="nuevoAAFFForm">
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="NIF"
                name="nif"
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
                value={proveedor.nif}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Razón social"
                name="razonSocial"
                value={proveedor.razonSocial}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                placeholder="Email de contacto"
                name="email"
                value={proveedor.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="tel"
                placeholder="Telefono de contacto"
                name="telefonoContacto"
                value={proveedor.telefono}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Nombre de contacto"
                name="nombreContacto"
                value={proveedor.nombre}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                name="provincia"
                onChange={(e) =>
                  seleccionarMunicipios(e)
                }
              >
                <option value={proveedor.provincia}>Provincia</option>
                {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <option key={i} value={e.code}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <select
                name="municipio"
                onChange={handleChange}
              >
                <option value={proveedor.municipio}>Municipio</option>
                {listadoMunicipios.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                  return (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Direccion"
                name="direccion"
                value={proveedor.direccion}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                placeholder="Codigo postal"
                name="codigoPostal"
                value={proveedor.codigoPostal}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6} className="selectServicio">
              <Select
                className="customSelect"
                labelId="demo-multiple-checkbox-label"
                id="demo-multiple-checkbox"
                displayEmpty
                multiple
                name="servicio"
                value={selectedServices}
                onChange={handleSelectChange}
                input={<OutlinedInput label="Servicio" />}
                IconComponent={(props) => (
                  <KeyboardArrowDownIcon {...props} fontSize="small" aria-hidden="true" />
                )}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <h6>Servicios</h6>;
                  }
                  return selected.map(id => servicios.find(servicio => servicio.id === id)?.nombre).join(', ');
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 224,
                      width: 250,
                      fontFamily: 'inherit',
                    },
                  },
                }}
              >
                <MenuItem disabled value="">
                  <em>Servicios</em>
                </MenuItem>
                {servicios.sort((a, b) => a.nombre.localeCompare(b.nombre)).map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    <Checkbox checked={selectedServices.indexOf(e.id) > -1} />
                    <ListItemText primary={e.nombre} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <input
            type="button"
            value="Guardar y publicar"
            onClick={() => handleSubmit(proveedor)}
          />
        </form>
      </div>
    </>
  );
}

export default withRouter(NuevoDelegacion);
