import React, { useState, useEffect, useContext } from 'react'
import BreadCrumb from "../navbar/Breadcrumb";
import { UsuarioContext } from '../../providers/userContext';
import {
    Box,
    TextField,
    Button,
    InputAdornment,
    IconButton,
    Alert
} from "@mui/material";
import { FiEye, FiEyeOff } from 'react-icons/fi'; 
import { changePassword } from '../../services';
import { toast } from "react-toastify";
//import { getAdmin } from '../../services/admin.service';
import CheckPass from './checkPass';
import AAFF from '../../routes/pages/aaff/AAFF';


const DatosPerfil = () => {

    const user = useContext(UsuarioContext)
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [error, setError] = useState('');
    const [passOk, setPassOk] = useState(false);
    const [envio, setEnvio] = useState(false);

    // useEffect(() => {
    //     fetchData();
    // },[])

    // const fetchData = async () => {
         
    //      if(user.rol === 'ADMIN'){
    //         const response = await getAdmin(user.user_id)
            
    //      }
    // }

    

    function handleChange(e) {
        setOldPassword(e.target.value);
    }

    const handleChangePassword = (e) => {
        setEnvio(false)
        // validarPass(e.target.value)
        setNewPassword(e.target.value);
        
        if (repeatPassword && e.target.value !== repeatPassword) {
            setError('Las contraseñas no coinciden');
        } else {
            setError('');
        }
        
    };
    
    const handleChangeRepeatPassword = (e) => {
        setEnvio(false)
        setRepeatPassword(e.target.value);
        
        if (newPassword && e.target.value !== newPassword) {
          setError('Las contraseñas no coinciden');
        } else {
          setError('');
        }
    };

    const passValida = async (isValid) => {
        
        setPassOk(isValid)
        
    }

    const handleSubmit = async () => {
        let toastInfo = undefined
        setEnvio(true)
        // const isValid = validarPass(newPassword)
        const isValid = passOk
        
        if(isValid){
            const data = {
                oldPassword,
                newPassword,
            }
    
            for (const key in data) {
                if (data.hasOwnProperty(key) && data[key] === '') {
                  setError('Rellena todos los campos');
                  return;
                }
            }
    
            if(error !== ''){
                if(newPassword !== repeatPassword){
                    setError('Las contraseñas no coinciden');
                    return
                }else {
                    setError('');
                    return
                }
            }
            
            toastInfo = toast.info("Actualizando contraseña", { autoClose: false });            
            const response = await changePassword(data)
            
            if( response.message === "Minimum eight characters, at least one letter and one number"){
                setError('La nueva contraseña debe tener mínimo 8 carácteres, al menos una letra minúscula y una mayúscula, un número y un caracter especial')
                return
            }
            if( response.error === "PASSWORD_NOT_MATCH"){
                toast.update( toastInfo, {
                    render: "La contraseña actual no coincide",
                    type: toast.TYPE.ERROR,
                    autoClose: true
                })
            }
    
            if( response.message === "PASSWORD_UPDATED"){
                toast.update( toastInfo, {
                    render: "La contraseña ha sido actualizada",
                    type: toast.TYPE.SUCCESS,
                    autoClose: true
                })
            }
            setOldPassword('')
            setNewPassword('')
            setRepeatPassword('')
        }else{
            return
        }
    }

    return ( 
        <>
            <BreadCrumb rutas={[{path:'/datosPerfil', label:`Datos perfil`}]} style={{ margin: '0 10px'}} />
            {user.rol === "AAFF" &&
                <AAFF />
            }
            <div className="listPage">
                <div className="gridCard">
                    {user.rol !== "AAFF" &&
                        <>
                            <div className="gridHead">INFORMACIÓN GENERAL</div>
                                <div className="gridSubhead editarSubhead">
                                <div>DATOS DE PERFIL</div>
                                <div
                                    className="editarBtn"
                                >
                                </div>
                            </div>
                            <div className="pageContent tableContainer">
                                <div className="datosGrid">
                                    <p>
                                        <b>Nombre:</b> {user.nombre}
                                    </p>
                                    <p>
                                        <b>Email:</b> {user.email}
                                    </p>
                                </div>
                            </div>
                        </>
                    }
                    <div className="gridSubhead editarSubhead">
                        <div>CAMBIO CONTRASEÑA</div>
                    </div>
                    <div className="tableContainer">
                        <Box
                            mx={1}
                            display={"block"}
                            flexDirection={"row"}
                            gap={2}
                        >
                            <TextField
                                type={showOldPassword ? 'text' : 'password'}
                                id="oldPassword-input"
                                label="Antigua contraseña"
                                variant="outlined"
                                name='oldPassword'
                                className="filtros"
                                value={oldPassword}
                                onChange={
                                    handleChange}
                                onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : "")}
                                inputProps={{
                                "aria-label": "controlled",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowOldPassword(!showOldPassword)}
                                            >
                                                {showOldPassword ? <FiEyeOff /> : <FiEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                // InputLabelProps={{ shrink: oldPassword !== '' }}
                            />
                            <TextField
                                type={showNewPassword ? 'text' : 'password'}
                                minLength="8"
                                id="newPassword-input"
                                label="Nueva contraseña"
                                variant="outlined"
                                name='NewPassword'
                                className="filtros"
                                value={newPassword}
                                onChange={handleChangePassword}
                                onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : "")}
                                inputProps={{
                                "aria-label": "controlled",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowNewPassword(!showNewPassword)}
                                            >
                                                {showNewPassword ? <FiEyeOff /> : <FiEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                            />
                            <TextField
                                type={showRepeatPassword ? 'text' : 'password'}
                                id="password_confirmation-input"
                                label="Repetir contraseña"
                                variant="outlined"
                                name='repeatPassword'
                                className="filtros"
                                value={repeatPassword}
                                onChange={handleChangeRepeatPassword}
                                onKeyPress={(e) => (e.key === "Enter" ? handleSubmit() : "")}
                                inputProps={{
                                "aria-label": "controlled",
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                            >
                                                {showRepeatPassword ? <FiEyeOff /> : <FiEye />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                            />
                            <Button className="btn-buscador" onClick={() => handleSubmit()}>
                                Cambiar
                            </Button>
                        </Box>
                        <div style={{backgroundColor:'#fff', margin:0 }}>
                            <p style={{ display: 'block', color: '#cf5c5c', fontWeight: 'normal', margin: '0 15 !important', padding: '0 15px' }}>{error}</p>
                        </div>
                        <div>
                            <CheckPass password={newPassword} enviar={envio} passValida={passValida} />
                        </div>
                    </div>
                </div>
            </div>
            <style jsx="true">
                {`
                    .btn-buscador {
                    padding: 5px 10px;
                    color: #ffffff;
                    background-color: #92004D;
                    border-radius: 4px;
                    font-weight: bold;
                    width: -moz-fit-content;
                    width: fit-content;
                    align-self: flex-end;
                    border: none;
                    min-width:40px;
                    height:40px;
                    margin: 5px 0;
                    }
                    .btn-buscador:hover{
                    color: black;
                    background-color: #dee2e6;
                    }    
                `}
            </style>
        </> 
    );
}
 
export default DatosPerfil;