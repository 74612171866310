import React, { useState, useEffect, useContext } from 'react'
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { withRouter } from "../../providers/withRouter";
import { Box, Button } from "@mui/material";
import { DataTable } from "..";
import {
  getInformeAlert,
  validateInformeAlert
} from "../../services";
import { formatoFechasEs } from '../../functions/funciones';
import FiltrosAlertasAAFF from '../filtros/filtrosAlertasAAFF';
import { FaCheck } from "react-icons/fa";
import { UsuarioContext } from '../../providers/userContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from '../../functions/parametrosURL/funcionesParamsURL';


const InformeAlert = ({valorTab}) => {

  const user = useContext(UsuarioContext)
  const [loading, setLoading] = useState(true);
  const {filtrosIniciales, filtroOrder} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [recargar, setRecargar] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [busquedaActiva,setBusquedaActiva] = useState(false);
  const [validating, setValidating] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [primeraCarga, setPrimeraCarga] = useState(true); 
  const navigate = useNavigate();
  

  const clearFilters = () => {
    if (user.rol === "CONSUL" && localStorage.getItem("hasReloaded") === "true") {
      setBusqueda({
        ...busqueda,
        consul_id: user.id 
      });
    }
    else{
      setBusqueda({});
    }
    
    setClearFilterActivo(true);
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order,orderBy } = {}) => {
    
    
    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';


    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
        filtros.consul_id = user.id
      } else if (filtros.consul_id === 0) {
        delete filtros.consul_id
      }
      setPrimeraCarga(false);
    }

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros,  order, orderBy,valorTab);


    const payload = {
      filtros,
      params: { page: offset, limit,order, orderBy },
    };
    try {
      setLoading(true);
      const response = await getInformeAlert(payload);
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setRecargar(false);
    }
  };

 

  const formatData = (data) => {
    return data.map((row, i) => {
      const consultor = row.consultants?.find(objeto => objeto?.r_consul_servsgroups_aaffs.group_id === 1)
      return {
        razonSocial: row.razonSocial || row.propertie?.aaff?.nombreContacto,
        nif: row.nif,
        fechaAlta: row.createdAt,
        consultorCae: consultor ? `${consultor.nombre} ${consultor.apellidos}` : "",
        validar: row.id
      }
    });
  };

  const comunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
      overrideFunc: (data, row) => (
        <Link to={"/aaff/" + row.nif + "/comunidades"}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "FECHA ALTA",
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    },
    {
      id: "consultorCae",
      numeric: false,
      disablePadding: false,
      label: "CONSULTOR CAE",
      noObjeto:true,

    },
    {
      id: "validar",
      numeric: false,
      disablePadding: false,
      label: "Realizada",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <>
          <Button style={{ background: validating ? '#7bc977' : 'green', color: 'white', minWidth: '5px' }} onClick={() => { validarAlerta(data) }} disabled={validating}><FaCheck /></Button>
        </>
      )
    },
  ];

  const validarAlerta = async (id) => {
    setValidating(true);
    try {

      const toastInfo = toast.info("Validando...", { autoClose: false });
      const response = await validateInformeAlert(id)
      if (response.status === 201) {
        toast.update(toastInfo, {
          render: "Alerta validada",
          type: toast.TYPE.SUCCESS,
          autoClose: true,
        });
        setRecargar(true);
        setLoading(true);
        fetchData();
      }
      if (response.status === 403) {
        toast.update(toastInfo, {
          render: "No se ha podido validar la alerta",
          type: toast.TYPE.ERROR,
          autoClose: true,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Error al validar la alerta");
    } finally {
      setValidating(false);
    }
  }

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
}

  return (
    <>

      <div className="pageContent tableContainer">

        <FiltrosAlertasAAFF onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                mensaje={"No hay tareas pendientes"}
                enumerate={true}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}

export default withRouter(InformeAlert);