import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { withRouter } from "../../../providers/withRouter";
import { UpdateSale, createProvsSinAcceso, deleteProvsSinAcceso, getSale, getServices, updateProvsSinAcceso } from '../../../services';
import { verificarFecha } from "../../../functions/validator/formValidator";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  CircularProgress,
  Select
} from "@mui/material";
import { formatoFechas } from "../../../functions/funciones";
import { resetWarningCache } from "prop-types";
import Swal from "sweetalert2";


const EditarVenta = (props) => {

  const [venta, setVenta] = useState({});
  const [editarDatos, setEditarDatos] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [provsSinAcceso, setProvsSinAcceso] = useState([]);
  const [servicios, setServicios] = useState([]);
  const [detailLopdId, setDetailLopdId] = useState(0);
  const [recargar, setRecargar] = useState(false);

  useEffect(() => {
    fetchData()
  }, [!recargar])


  const fetchData = async () => {
    const response = await getSale(props.params.ventaId)
    
    const datos = {
      servicio: response?.serviceprops?.nombre,
      servp_id: response?.serviceprops?.id,
      saleDate: formatoFechas(new Date(response?.saleDate)),
      nif: response?.properties?.nif,
      razonSocial: response?.workcenters === null ? response?.properties?.razonSocial : response.workcenters?.razonSocial,
      workers: response?.detailsLopd?.find(item => item.workers !== undefined)?.workers || false,
      nifLaborAdviser: response?.detailsLopd?.find(item => item.nifLaborAdviser !== '')?.nifLaborAdviser || '',
      reasonLaborAdviser: response?.detailsLopd?.find(item => item.reasonLaborAdviser !== '')?.reasonLaborAdviser || '',
      nifSpa: response?.detailsLopd?.find(item => item.nifSpa !== '')?.nifSpa || '',
      reasonSocialeSpa: response?.detailsLopd?.find(item => item.reasonSocialeSpa !== '')?.reasonSocialeSpa || '',
      surveillanceVideo: response?.detailsLopd?.find(item => item.surveillanceVideo !== undefined)?.surveillanceVideo || false,
      maintenance: response?.detailsLopd?.find(item => item.maintenance !== undefined)?.maintenance || false,
      nifCameraMaintenance: response?.detailsLopd?.find(item => item.nifCameraMaintenance !== '')?.nifCameraMaintenance || '',
      reasonCameraMaintenance: response?.detailsLopd?.find(item => item.reasonCameraMaintenance !== '')?.reasonCameraMaintenance || ''
    }

    const servicios = await filtrarServicios(response?.serviceprops?.id)
    setServicios(servicios)
    const provsSinAcceso = response?.detailsLopd?.flatMap(item => item?.provsSinAcceso) || [];
    setDetailLopdId(response?.detailsLopd?.find(item => item.id !== '')?.id || 0)
    setProvsSinAcceso(provsSinAcceso)
    setVenta(datos)
    setIsLoading(false);
  }

  const filtrarServicios = async (id) => {
    console.log("id", id)
    const {data} = await getServices()
    let servicios = [];
    if([1, 3, 6, 7].includes(id)){
      servicios = data.filter(s => [1, 3, 6, 7].includes(s.id))
    }else {
      servicios = data.filter(s => [2, 4].includes(s.id))
    }
    console.log(servicios) 
    return servicios
}

  function handleChange(evt) {
    const value = evt.target.value;
    if (evt.target.name === 'surveillanceVideo' && !value) {
      setVenta({
        ...venta,
        [evt.target.name]: value,
        maintenance: false
      });
    } else {
      setVenta({
        ...venta,
        [evt.target.name]: value,
      });
    }
  }

  const handleSubmit = () => {
    let data
    if (venta?.servicio.split(' ')[0] === "CAE") {
      data = {
        saleDate: venta?.saleDate,
        servp_id: venta?.servp_id
      }
      

    }
    if (venta?.servicio.split(' ')[0] === "LOPD") {
      data = venta
    }

    enviarDatos(props.params.ventaId, data)
  }

  const enviarDatos = async (id, data) => {
    console.log(data)
    let toastInfo = toast.info("Guardando...", { autoClose: false });
    try {
      let errores = false;
      let fechaCorrecta = verificarFecha(data.saleDate);
      if (fechaCorrecta === false) {
        toast.dismiss(toastInfo);
        toast.error("La fecha elecionada no puede ser superior a la actual");
        errores = true;
      }

      const respuesta = await UpdateSale(id, data);
      if (respuesta.status === 201 && errores === false) {
        toast.update(toastInfo, {
          render: "Venta editada correctamente",
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
        window.history.back();
      }
    } catch (error) {
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      console.log(error)
    }

  }

  const handleSaveItem = async (index) => {
    // const lopdId = provsSinAcceso.length > 0 ? provsSinAcceso[0].details_lopd_id : null
    const lopdId = detailLopdId
    const itemToSave = { ...provsSinAcceso[index], details_lopd_id: lopdId };
    let toastInfo = toast.info("Guardando datos", { autoClose: false });
    if (Object.values(itemToSave).some(value => value === "")) {
      // Mostrar mensaje de error o realizar otra acción
      toast.update(toastInfo, {
        render: "Algunos campos están vacíos",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      console.log("No se puede guardar: algunos campos están vacíos");
      return;
    }

    // Actualizar la lista después de guardar el item
    const updatedItems = [...provsSinAcceso];
    const { isNew, ...updatedItem } = itemToSave;
    updatedItems[index] = updatedItem
    console.log(updatedItems)
    await createProvsSinAcceso(updatedItems).then(res => {
      console.log(res)
      if(res.status === 200){
        toast.update(toastInfo, {
          render: res.msg,
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
      }

      if(res.status === 300){
        toast.dismiss(toastInfo);
        Swal.fire({
          title: "Proveedor desactivado",
          text: "Este proveedor está desactivado, quieres activarlo con los datos guardados o aplicarle los nuevos datos?",
          icon: "warning",
          showCancelButton: true,
          showDenyButton: true,
          confirmButtonColor: "rgb(51, 147, 71)",
          cancelButtonColor: "#d33",
          denyButtonColor: "#828282",
          confirmButtonText: "Aplicar nuevos datos!",
          denyButtonText: "Solo activar"
        }).then(async (result) => {
          let datos = {}
          if (result.isConfirmed) {
            datos = {...itemToSave, status:true, id: res.id}
          }
          if(result.isDenied){
            datos = {status:true, id: res.id}
          }
          await updateProvsSinAcceso(res.id, datos).then( r => {
            setRecargar(!recargar)
          })
          console.log(datos, res.id)
        });
      }

      if(res.status === 400){
        toast.update(toastInfo, {
          render: res.msg,
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        setRecargar(!recargar)
      }
    }).catch(error => {
      console.log(error)
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    })
    setProvsSinAcceso(updatedItems);
  };

  const handleAddItem = () => {
    const newItem = { nif: "", reasonSociale: "", serviceProvided: "", isNew: true };
    setProvsSinAcceso([...provsSinAcceso, newItem]);
  };

  const handleEditItem = async (index) => {
    let toastInfo = toast.info("Editando datos", { autoClose: false });
    const updatedItem = provsSinAcceso[index];
    const updatedItems = [...provsSinAcceso];
    updatedItems[index] = updatedItem;
    const response = await updateProvsSinAcceso(updatedItems[index].id, updatedItems[index]).then(res => {

      toast.update(toastInfo, {
        render: "Datos editados correctamente",
        type: toast.TYPE.SUCCESS,
        autoClose: true
      });
    }).catch(error => {
      console.log(error)
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    })
    setProvsSinAcceso(updatedItems);
  };
  const handleRemoveItem = async (index) => {
    const removedItem = provsSinAcceso[index];
    const updatedItems = provsSinAcceso.filter((_, i) => i !== index);
    const response = await deleteProvsSinAcceso(removedItem.id)
    setProvsSinAcceso(updatedItems);
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedItems = [...provsSinAcceso];
    updatedItems[index][name] = value;
    setProvsSinAcceso(updatedItems);
  };

  return (
    <>

      <BreadCrumb rutas={[{ path: `/servicios`, label: `Servicios` }, { path: `/venta`, label: `Editar` }]} style={{ margin: '0 10px' }} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            EDITAR SERVICIO
          </div>
        </div>
      </div>
      <div className="pageContent">
        <div className="tableContainer">
          {isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
            <>
              <Box
                mx={1}
                display={"flex"}
                flexDirection={"row"}
              // gap={2}
              >
                {/* <TextField
                  style={{ width: "50%", margin: "5px" }}
                  type="text"
                  label="Servicio"
                  variant="outlined"
                  name="servicio"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={venta?.servicio}
                /> */}
                <Select 
                  label="Servicio"
                  native 
                  name="servp_id" 
                  id="servp_id" 
                  style={{ width: "50%", margin: "5px" }}
                  onChange={handleChange} 
                  defaultValue={venta?.servp_id}
                >
                  {servicios?.map((e, i) => {
                    return (
                      <option key={i} value={e.id}>
                        {e.nombre}
                      </option>
                    );
                  })}
                </Select>
                <TextField
                  style={{ width: "50%", margin: "5px" }}
                  type="date"
                  label="Fecha venta"
                  variant="outlined"
                  name="saleDate"
                  value={formatoFechas(new Date(venta?.saleDate))}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </Box>
              <Box
                mx={1}
                display={"flex"}
                flexDirection={"row"}
              // gap={2}
              >
                <TextField
                  style={{ width: "50%", margin: "5px" }}
                  type="text"
                  label="NIF"
                  variant="outlined"
                  name="nif"
                  disabled
                  InputLabelProps={{ shrink: true }}
                  value={venta.nif}
                />
                <TextField
                  style={{ width: "50%", margin: "5px" }}
                  type="text"
                  label="Razón social"
                  variant="outlined"
                  name="razonSocial"
                  value={venta.razonSocial}
                  InputLabelProps={{ shrink: true }}
                  disabled
                  onChange={handleChange}
                />
              </Box>
              {venta?.servicio?.split(' ')[0] === "LOPD" &&
                <>
                  <Box
                    mx={1}
                    display={"flex"}
                    flexDirection={"row"}
                  // gap={2}
                  >
                    <TextField
                      select
                      style={{ width: "50%", margin: "5px" }}
                      label="Trabajadores"
                      name="workers"
                      value={venta?.workers || false}
                      fullWidth
                      onChange={handleChange}
                      inputProps={{
                        name: 'workers',
                        id: 'uncontrolled-native',
                      }}
                    >
                      <MenuItem value={true}>
                        Sí
                      </MenuItem>
                      <MenuItem value={false}>
                        No
                      </MenuItem>
                    </TextField>
                    <div style={{ width: "50%", margin: "5px" }} ></div>
                  </Box>
                  <Box
                    mx={1}
                    display={venta?.workers ? "flex" : "none"}
                    flexDirection={"row"}
                  // gap={2}
                  >
                    <TextField
                      style={{ width: "50%", margin: "5px" }}
                      type="text"
                      label="NIF asesor laboral"
                      variant="outlined"
                      name="nifLaborAdviser"
                      InputLabelProps={{ shrink: true }}
                      value={venta?.nifLaborAdviser}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{ width: "50%", margin: "5px" }}
                      type="text"
                      label="Razón social asesor laboral"
                      variant="outlined"
                      name="reasonLaborAdviser"
                      value={venta?.reasonLaborAdviser}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box
                    mx={1}
                    display={venta?.workers ? "flex" : "none"}
                    flexDirection={"row"}
                  // gap={2}
                  >
                    <TextField
                      style={{ width: "50%", margin: "5px" }}
                      type="text"
                      label="NIF SPA"
                      variant="outlined"
                      name="nifSpa"
                      InputLabelProps={{ shrink: true }}
                      value={venta?.nifSpa}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{ width: "50%", margin: "5px" }}
                      type="text"
                      label="Razón social SPA"
                      variant="outlined"
                      name="reasonSocialeSpa"
                      value={venta?.reasonSocialeSpa}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                    />
                  </Box>
                  <Box
                    mx={1}
                    display={"flex"}
                    flexDirection={"row"}
                  // gap={2}
                  >
                    <TextField
                      select
                      style={{ width: "50%", margin: "5px" }}
                      label="Video vigilancia"
                      value={venta?.surveillanceVideo || false}
                      name="surveillanceVideo"
                      fullWidth
                      onChange={handleChange}
                    // inputProps={{
                    //   name: 'surveillanceVideo',
                    //   id: 'uncontrolled-native',
                    // }}
                    >
                      <MenuItem value={true}>
                        Sí
                      </MenuItem>
                      <MenuItem value={false}>
                        No
                      </MenuItem>
                    </TextField>
                    {venta?.surveillanceVideo ?
                      <TextField
                        select
                        style={{ width: "50%", margin: "5px" }}
                        label="Mantenimiento"
                        value={venta?.maintenance || false}
                        name="maintenance"
                        fullWidth
                        onChange={handleChange}
                      >
                        <MenuItem value={true}>
                          Sí
                        </MenuItem>
                        <MenuItem value={false}>
                          No
                        </MenuItem>
                      </TextField>
                      :
                      <div style={{ width: "50%", margin: "5px" }} ></div>
                    }

                  </Box>
                  <Box
                    mx={1}
                    display={venta?.surveillanceVideo && venta?.maintenance ? "flex" : "none"}
                    flexDirection={"row"}
                  // gap={2}
                  >
                    <TextField
                      style={{ width: "50%", margin: "5px" }}
                      type="text"
                      label="NIF video vigilancia"
                      variant="outlined"
                      name="nifCameraMaintenance"
                      InputLabelProps={{ shrink: true }}
                      value={venta?.nifCameraMaintenance}
                      onChange={handleChange}
                    />
                    <TextField
                      style={{ width: "50%", margin: "5px" }}
                      type="text"
                      label="Razón social video vigilancia"
                      variant="outlined"
                      name="reasonCameraMaintenance"
                      value={venta?.reasonCameraMaintenance}
                      InputLabelProps={{ shrink: true }}
                      onChange={handleChange}
                    />
                  </Box>
                </>
              }
            </>
          }
          <Box
            mx={2}
            my={3}
            display={"flex"}
            flexDirection={"row"}
          // gap={2}
          >
            <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => handleSubmit()}>Guardar</Button>
          </Box>
        </div>
        {venta?.servicio?.split(' ')[0] === "LOPD" &&
          <div className="gridSubhead editarSubhead">
            <div>PROVEEDORES SIN ACCESO</div>
            {/* <div className="subheadBtn" onClick={deleteFincas}>
              <span style={{ marginRight: 10 }}>Eliminar fincas</span>{" "}
              <img src={Delete} alt="Eliminar" />
            </div> */}
          </div>
        }
        <div className="tableContainer">
          {venta?.servicio?.split(' ')[0] === "LOPD" &&
            <div>
              {provsSinAcceso.length === 0 ? <div style={{ margin: 15 }}><p>No hay proveedores sin acceso a datos</p></div> :
                provsSinAcceso?.map((item, index) => (
                  <div key={index}>
                    <Box
                      mx={1}
                      display={"flex"}
                      flexDirection={"row"}
                      alignItems={'center'}
                    // gap={2}
                    >
                      <TextField
                        style={{ width: "50%", margin: "5px" }}
                        type="text"
                        label="NIF"
                        variant="outlined"
                        name="nif"
                        onChange={(e) => handleInputChange(index, e)}
                        InputLabelProps={{ shrink: true }}
                        value={item.nif}
                      />
                      <TextField
                        style={{ width: "50%", margin: "5px" }}
                        type="text"
                        label="Razón Social"
                        variant="outlined"
                        name="reasonSociale"
                        onChange={(e) => handleInputChange(index, e)}
                        InputLabelProps={{ shrink: true }}
                        value={item.reasonSociale}
                      />
                      <TextField
                        style={{ width: "50%", margin: "5px" }}
                        type="text"
                        label="Servicio"
                        variant="outlined"
                        name="serviceProvided"
                        onChange={(e) => handleInputChange(index, e)}
                        InputLabelProps={{ shrink: true }}
                        value={item.serviceProvided}
                      />
                      {item.isNew ? (
                        <>
                          <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => handleSaveItem(index)}>Guardar</Button>
                          <div style={{ width: '11%' }}></div>
                        </>
                      ) : (
                        <>
                          <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => handleEditItem(index)} style={{ height: 30, marginRight: 5 }}>Editar</Button>
                          <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => handleRemoveItem(index)} style={{ height: 30 }}>Eliminar</Button>
                        </>
                      )}
                    </Box>
                  </div>
                ))}
              <Box
                mx={2}
                my={3}
                display={"flex"}
                flexDirection={"row"}
              // gap={2}
              >
                <Button className="btn-Secondary" variant="contained" color="secondary" onClick={handleAddItem}>Añadir</Button>
              </Box>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default withRouter(EditarVenta);
