import React from "react";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import Edificios from "../icons/edificios.svg";
import ComunidadesIcon from "../icons/comunidades.svg";
import ProveedorIcon from "../icons/proveedor.svg";
import VentasIcon from "../icons/ventas.svg";
import Inicio from "./pages/aaff/Inicio";
import Comunidades from "./pages/aaff/Comunidades";
import Comunidad from "./pages/aaff/Comunidad";
import Proveedores from "./pages/aaff/Proveedores";
import Proveedor from "./pages/aaff/Proveedor";
import AAFF from "./pages/aaff/AAFF";
import Ventas from "./pages/general/Ventas";
import { TbBuildingCommunity } from "react-icons/tb";
import DatosPerfil from "../components/perfil/datosPerfil";

export function RouteBundle(props) {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="comunidades" element={<Comunidades />} />
      <Route path="comunidades/:nif" element={<Comunidad />} />
      {props.user?.gruposServicios?.includes(1) && 
        <>
          <Route path="proveedores" element={<Proveedores />} />
          <Route path="proveedores/:nif" element={<Proveedor />} />
        </>
      }
      <Route path="aaff" element={<AAFF />} />
      <Route path="/servicios" element={<Ventas />} />
      <Route path="/perfil" element={<DatosPerfil />} />
      <Route path="*" element={<Navigate to='/' replace/>}/>
    </Routes>
  );
}

const refrescoLocal = () => {
  localStorage.setItem("hasReloaded", "true"); 
}

export function MenuLinksClientes(props) {
  return (
    <>
        {/* <li>
          <NavLink activeclassname="active" to="/aaff" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "",  color: props.showSidebar ? "#33383b" : "#fff"}}>
            <div className="menuBtn">
              <img src={Edificios} alt="Panel principal" />Panel principal
            </div>
          </NavLink>
        </li> */}
        <li>
          <NavLink activeclassname="active" to="/comunidades" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn" onClick={refrescoLocal}>
              <TbBuildingCommunity /> Comunidades
            </div>
          </NavLink>
        </li>
        {props.user?.gruposServicios?.includes(1) && 
          <li>
            <NavLink activeclassname="active" to="/proveedores" style={{ display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
              <div className="menuBtn" onClick={refrescoLocal}>
                <img src={ProveedorIcon} alt="Proveedores" />Proveedores
              </div>
            </NavLink>
          </li>
        }
        <li>
          <NavLink activeclassname="active" to="/servicios" style={{display: props.showSidebar ? "flex" : "block", alignItems: props.showSidebar ? "center" : "" }}>
            <div className="menuBtn" onClick={refrescoLocal}>
              <img src={VentasIcon} alt="Servicios" />
              Servicios
            </div>
          </NavLink>
        </li>
    </>
  )
}
