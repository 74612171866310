import { Link } from 'react-router-dom';

export default ({ to, children, target }) => {
  const isInternal = to.startsWith('/');

  if (isInternal) {
    return <Link to={to} target={target || "_self"}>{children}</Link>;
  } else {
    return (
      <a href={to} target={target || "_blank"} rel="noopener noreferrer">
        {children}
      </a>
    );
  }
};