import React, { useState } from "react";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import { createCons } from "../../../services/";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { verificarNif, verificarCorreo  } from "../../../functions/validator/formValidator";


const gestionCamposUndefined = (tipoDeCampo, camposRequeridos) => {
  for (const campo of camposRequeridos) {
    if (tipoDeCampo[campo] === undefined || tipoDeCampo[campo] === '') {
      return true;
    }
  }
  return false;
};

const NuevoConsultor = () => {
  const [consultor, setConsultor] = useState({});
  const camposRequeridos = ['nif', 'email', 'nombre', 'apellidos'];

  
  async function handleSubmit(data) {
    const toastInfo = toast.info("Guardando consultor");
    let nifCorrecto = verificarNif(data.nif);
    let correoCorrecto = verificarCorreo(data.email);
    let errores = false;
  
    
    if (Object.keys(data).length === 0) {
      toast.update(toastInfo, {
        render: "El consultor está vacío",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
      errores = true;
    }else {
      if (nifCorrecto === false && data.nif!=undefined) {
        toast.dismiss(toastInfo);
        toast.error("El nif introducido es incorrecto");
        errores = true;
      }
      if (correoCorrecto === false && data.email!=undefined) {
        toast.dismiss(toastInfo);
        toast.error("El correo introducido es incorrecto");
        errores = true;
      }
     
    }
  
    if (!errores) {
      if (gestionCamposUndefined(data, camposRequeridos)) {
        toast.dismiss(toastInfo);
        toast.error("Hay algún campo vacío en el consultor");
        errores = true;
      }
      else{
        try {

          let res = await createCons(data);   
          
            toast.update(toastInfo, {
              render: "Consultor creado con éxito",
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });
            window.location.replace("/consultores");
          
        } catch (err) {
          if (err.data.error === "ERROR_CONSULTANT_EXIST") {
            toast.error("El consultor ya existe");
          } 
          else{
            toast.error("Error al crear el consultor");
          }
          
        }
      }
      
    }
  }
  
  
  function handleChange(evt) {
    const value = evt.target.value;
    setConsultor({
      ...consultor,
      [evt.target.name]: value,
    });
  }

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Consultores / <b>Nuevo</b>
      </div> */}
      <BreadCrumb rutas={[{path:`/consultores`, label:`Consultores`}, {path:`/consultores`, label:`Nuevo`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="gridHead">Crear nuevo Consultor</div>
        <form className="nuevoAAFFForm">
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="NIF"
                name="nif"
                value={consultor.nif}
                onChange={handleChange}
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                required
                placeholder="Email"
                name="email"
                value={consultor.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Nombre"
                name="nombre"
                value={consultor.nombre}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Apellidos"
                name="apellidos"
                value={consultor.apellidos}
                onChange={handleChange}
              ></input>
            </Grid>
          </Grid>
          <input
            type="button"
            value="Guardar y publicar"
            onClick={() => handleSubmit(consultor)}
          ></input>
        </form>
      </div>
    </>
  );
};

export default NuevoConsultor;