import { toast } from "react-toastify";
import { plantillaProveedores, plantillaFincas, deleteFincasAAFF, plantillaMatcheo, plantillaAAFF, plantillaRenovaciones, plantillaFincasLOPD } from "../services";
import Swal from "sweetalert2";

export const subirListadoProveedores = async (evt) => {
  let toastInfo = toast.info("Subiendo proveedores...", { autoClose: false });

  const value = evt.target.files[0];
  try {
    if (value) {
      let itemsOk = []
      let itemsErr = []
      const res = await plantillaProveedores(value);


      if (res.status === 402) {
        toast.error(res.data.error);
        toast.dismiss(toastInfo);
        return;
      }

      res.forEach(item => {
        if (item.status === 200) {
          itemsOk.push(item.msg)
          toast.dismiss(toastInfo);

          toast.success(item.msg);

        }
        else if (item.status === 400) {
          itemsErr.push(item.msg)
          toast.dismiss(toastInfo);

        }
        else {
          toast.error("error al subir proveedores");
        }
      })


      const html = `
          <div style="display: flex;">
            <div style="flex: 1;">
              <ul style="font-size:13px; margin-top:10px; text-align:left;">
                ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
              </ul>
            </div>
          </div>
        `;
      if (itemsErr.length > 0) {
        Swal.fire({
          title: 'Error al subir estos proveedores',
          html: html,
          confirmButtonText: 'Cerrar',
          icon: 'error'
        });
      }
    }
  } catch (error) {
    toast.update(toastInfo, {
      render: "ha ocurrido un error",
      type: toast.TYPE.ERROR,
      autoClose: true
    });
  }

}

export const subirListadoRenovaciones = (evt) => {
  let toastInfo = toast.info("Subiendo Renovaciones...");
  const value = evt.target.files[0];
  if (value) {
    plantillaRenovaciones(value).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        toast.update(toastInfo, {
          render: "Fincas renovadas con éxito",
          type: toast.TYPE.SUCCESS,
          autoClose: true
        });
      } else {
        toast.update(toastInfo, {
          render: "error al renovar fincas",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
      }
    })
      .catch((err) => {
        toast.update(toastInfo, {
          render: "Ha ocurrido un error",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        console.error(err);
      });
  }
}

export const subirListadoLOPD = async (evt) => {
  const value = evt.target.files[0];
  if (value) {
    let toastInfo = toast.info("Subiendo fincas LOPD...", { autoClose: false });

    try {
      let itemsOk = []
      let itemsErr = []
      const res = await plantillaFincasLOPD(value);

      if (res.status === 402) {
        toast.error(res.data.error);
        toast.dismiss(toastInfo);
        return;
      }
      if (res.status === 403) {
        toast.error(res.data.error[0]);
        toast.dismiss(toastInfo);
        return;
      }
      if(res.data){
        res.data.forEach(item => {
          if (item.status === 200) {
            itemsOk.push(item.msg)
            toast.dismiss(toastInfo);
            toast.success(item.msg);
          }
          else if (item.status === 400) {
            itemsErr.push(item.msg)
            toast.dismiss(toastInfo);
  
          }
          else {
            toast.error("error al subir fincas");
          }
        })
      }else{
        console.log(res.error)
        const r = res.error?.[0] || "Ha ocurrido un error" 
        toast.update(toastInfo, {
          render: r,
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        return
      }

      const html = `
      <div style="display: flex;">
        <div style="flex: 1;">
          <ul style="font-size:13px; margin-top:10px; text-align:left;">
            ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
          </ul>
        </div>
      </div>
    `;
      if (itemsErr.length > 0) {
        Swal.fire({
          title: 'Error al subir fincas LOPD',
          html: html,
          confirmButtonText: 'Cerrar',
          icon: 'error'
        });
      }
    } catch (error) {
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    }


  }
}
export const subirListadoAAFF = async (evt) => {
  const value = evt.target.files[0];
  if (value) {
    let toastInfo = toast.info("Subiendo administradores de fincas...", { autoClose: false });
    try {
      let itemsOk = []
      let itemsErr = []
      const res = await plantillaAAFF(value);

      //console.log(res);
      if (res.status === 402) {
        toast.error(res.data.error);
        toast.dismiss(toastInfo);
        return;
      }
      if (res.status === 403) {
        toast.error(res.data.error[0]);
        toast.dismiss(toastInfo);
        return;
      }
      if(res.data){
        res.data.forEach(item => {
          if (item.status === 200) {
            itemsOk.push(item.msg)
            toast.dismiss(toastInfo);
            toast.success(item.msg);
          }
          else if (item.status === 400) {
            // console.log(item.msg);
            itemsErr.push(item.msg)
            toast.dismiss(toastInfo);
            // toast.error(item.msg);
          }
          else if (item.status === 402) {
            //console.log(item.msg);
            itemsErr.push(item.msg)
            toast.dismiss(toastInfo);
            // toast.error(item.msg);
          }
  
  
          else {
            toast.error("error al subir administradores de fincas");
          }
        })
      }else{
        //console.log(res.error)
        const r = res.error?.[0] || "Ha ocurrido un error" 
        toast.update(toastInfo, {
          render: r,
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        return
      }

      const html = `
        <div style="display: flex;">
          <div style="flex: 1;">
            <ul style="font-size:13px; margin-top:10px; text-align:left;">
              ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
            </ul>
          </div>
        </div>
      `;
      if (itemsErr.length > 0) {
        Swal.fire({
          title: 'Error al subir estos AAFF',
          html: html,
          confirmButtonText: 'Cerrar',
          icon: 'error'
        });
      }
    } catch (error) {
      // const err = error[0].length === 0 ? "Ha ocurrido un error" : error.error[0]
      console.log("error", error);
      // console.log(err);
      toast.update(toastInfo, {
        render: "Ha ocurrido un error",
        type: toast.TYPE.ERROR,
        autoClose: true
      });
    }


  }
}

export const subirListado = async (evt) => {
  // toast.info("Subiendo fincas...");
  let toastInfo = toast.info("Subiendo fincas CAE...", { autoClose: false });
  const value = evt.target.files[0];

  try {
    let itemsOk = []
    let itemsErr = []
    const res = await plantillaFincas(value);
    if (res.status === 402) {
      toast.error(res.data.error);
      toast.dismiss(toastInfo);
      return;
    }
    if (res.status === 403) {
      toast.error(res.data.error[0]);
      toast.dismiss(toastInfo);
      return;
    }
    if(res.data){
      res.data.forEach(item => {
        if (item.status === 200) {
          itemsOk.push(item.msg)
          toast.dismiss(toastInfo);
          toast.success(item.msg);
        }
        else if (item.status === 400) {
          itemsErr.push(item.msg)
          toast.dismiss(toastInfo);
          // toast.error(item.msg);
        }
        else {
          toast.error("error al subir fincas");
        }
      })
    }else{
      console.log(res.error)
      const r = res.error?.[0] || "Ha ocurrido un error" 
      toast.update(toastInfo, {
        render: r,
        type: toast.TYPE.ERROR,
        autoClose: true
      });
      return
    }
    

    const html = `
    <div style="display: flex;">
      <div style="flex: 1;">
        <ul style="font-size:13px; margin-top:10px; text-align:left;">
          ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
        </ul>
      </div>
    </div>
  `;
    if (itemsErr.length > 0) {
      Swal.fire({
        title: 'Error al subir estas fincas',
        html: html,
        confirmButtonText: 'Cerrar',
        icon: 'error'
      });
    }

  } catch (error) {
    toast.update(toastInfo, {
      render: "Ha ocurrido un error",
      type: toast.TYPE.ERROR,
      autoClose: true
    });
  }

}
export const subirListadoMatcheo = (evt) => {
  let toastInfo = toast.info("Realizando matcheo...", { autoClose: false });
  const value = evt.target.files[0];
  let itemsErr = [];

  if (value) {
    plantillaMatcheo(value)
      .then((res) => {
        toast.dismiss(toastInfo);

        if (res.status === 403) {
          const errorPattern = /^El campo \[\d\]\.servicio\[\d\] debe ser un número\.$/;
          if (errorPattern.test(res.data.error[0])) {
            itemsErr.push("El campo servicio debe ser un número.");
          } else {
            itemsErr.push(res.data.error[0]);
          }
        } else {
          res.data.forEach((item) => {
            if (item.status === 200) {
              toast.success(item.msg);
            }
            if (item.status === 400) {
              itemsErr.push(item.msg);
            }
          });
        }

        
        if (itemsErr.length > 0) {
          const html = `
            <div style="display: flex;">
              <div style="flex: 1;">
                <ul style="font-size:13px; margin-top:10px; text-align:left;">
                  ${itemsErr.map((item) => `<li style="margin:5px 0;">${item}</li>`).join('')}
                </ul>
              </div>
            </div>
          `;

          Swal.fire({
            title: 'Error al crear el match',
            html: html,
            confirmButtonText: 'Cerrar',
            icon: 'error'
          });
        }

        toast.dismiss(toastInfo); 
      })
      .catch((err) => {
        toast.update(toastInfo, {
          render: "Ha ocurrido un error",
          type: toast.TYPE.ERROR,
          autoClose: true
        });
        console.error(err);
      });
  }
};


export const eliminarFincas = (aaff) => {
  return new Promise(async (resolve, reject) => {
    try {
      Swal.fire({
        title: "¿Eliminar todas las fincas del aaff" + aaff.razonSocial + "?",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
      }).then((result) => {
    
        if (result.isConfirmed) {
          deleteFincasAAFF(aaff.nif)
            .then((res) => {
              if (res.message === "PROPERTIE_DELETED") {
                toast.success("fincas eliminada");
                resolve(true);
              } else {
                toast.error("Error al eliminar todas las fincas");
              }
            })
            .catch((error) => {
              console.log(error);
              toast.error("Error al eliminar todas las fincas");
              reject(error)
            });
        }
      });
    } catch (error) {
      console.log(error)
      toast.error("Ha ocurrido un error");
      reject(error)
    }
  })
}