import React, { useState, useEffect } from "react";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { DataTable } from "../../../components";
import { CircularProgress, Tabs, Tab, Box } from "@mui/material";
import { getAAFFs } from "../../../services";
import WelcomeAlert from "../../../components/alerts/welcomeAlert";
import TabPanel from "../../../components/tabs/Tabs";
import ReclamationAlert from "../../../components/alerts/reclamationAlert";
import InformeAlert from "../../../components/alerts/informeAlert";
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';

const AlertasAAFF = () => {

  const location = useLocation();

  let params = new URLSearchParams(location.search);

  const tabParam = parseInt(params.get("valorTab") ) ;
  
  const urlPadre = window.location.pathname;
  const navigate = useNavigate();

  
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(tabParam || 0);
  const [limite, setLimite] = useState(0);
  const {filtroOrder} = useRecogerURLParamsFiltros();
  

  

  useEffect(() => {
    fetchData();
  }, [value, limite]); 

  const fetchData = async ({ offset, limit, filtros = {} , order,orderBy} = {}) => {
    
    setLimite(limit);

    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order =   order || filtroOrder.orden || 'ASC';

    const payload = {
      filtros,
      params: { page: offset, limit: limite, order,orderBy },
    };
    try {
      const response = await getAAFFs(payload);
      const { meta, data } = response;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    
    construirURLParamsFiltros(navigate, 1, limite || 50, {}, newValue);
  };

  return isLoading ? (
    <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} />
  ) : (
    <>
      <BreadCrumb
        rutas={[{ path: "/alertas-aaff", label: `Alertas administradores de fincas` }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            ALERTAS ADMINISTRADORES DE FINCAS
          </div>
          <Box sx={{ padding: '10px 40px', border: 'none' }}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs example">
              <Tab style={{ color: "#92004D" }} label="BIENVENIDA" />
              <Tab style={{ color: "#92004D" }} label="RECLAMACIÓN" />
              <Tab style={{ color: "#92004D" }} label="INFORME" />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <WelcomeAlert   valorTab={0}/>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReclamationAlert  valorTab={1}/>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <InformeAlert  valorTab={2}/>
          </TabPanel>
        </div>
      </div>
      <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
      `}</style>
    </>
  );
}

export default AlertasAAFF;
