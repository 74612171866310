import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button, Tooltip } from "@mui/material";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import { DataTable, LaeButton, ProgressBarWithColors } from "../../../components";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import { editarAAFF, asignarTecnico, asignarConsultor, asignarComercial, filterAAFFs, loginAsAAFF } from "../../../services";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Add from "../../../icons/add.svg";
import Tick from "../../../icons/tick.svg";
import criticalWarning from "../../../icons/criticalWarning.svg";
import Warning from "../../../icons/warning.svg";
import { Login } from '@mui/icons-material';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';


const AAFF = () => {

  const user = useContext(UsuarioContext);
  //   const { loading, data, setPagination, pagination, fetchData } = useAAFFs();
  const [loading, setLoading] = useState(true);

  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();

  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [recargar, setRecargar] = useState(false);


  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState(
    {
      meta: {},
      rows: []
    });
  const [primeraCarga, setPrimeraCarga] = useState(true);


  const navigate = useNavigate();

  const location = useLocation();

 
  

  const clearFilters = () => {
    if (user.rol === "CONSUL" && localStorage.getItem("hasReloaded") === "true") {
      setBusqueda({
        consul_id: user.id 
      });
    }
    else if(user.rol === "CONSUL"){
      setBusqueda({
        consul_id: user.id 
      });
    }
    else{
      setBusqueda({});
    }
    
    setClearFilterActivo(true);
  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order, orderBy } = {}) => {

    orderBy = orderBy || filtroOrder.ordenadoPor || 'id';
    order = order || filtroOrder.orden || 'ASC';


    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
        filtros.consul_id = user.id;
      } else if (filtros.consul_id === "0") {
        delete filtros.consul_id;
      }
      setPrimeraCarga(false);
    }


    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy);

    // setOrder(sortOrder);
    // setOrderBy(sortBy);

    const payload = { filtros, params: { page: offset, limit: limit, order, orderBy } };

    try {
      setLoading(true);
      const response = await filterAAFFs(payload);
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function reasignar(a, b, c) {
    
    if (b === "consultor") {
      b = `consultor/${c}`;
    }
    Swal.fire({
      title: b === "consultor" ? "consultor" : `¿Cambiar ${b}?`,
      showDenyButton: b === "comercial" ? false : true,
      showCancelButton: true,
      confirmButtonText: "Cambiar",
      denyButtonText: `Desasignar`,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.replace("/aaff/" + a.nif + "/" + b);
      } else if (result.isDenied) {
        let desasignarPromesa;
        switch (b) {
          case "tecnico":
            desasignarPromesa = asignarTecnico(a.nif, null);
            break;
          case `consultor/${c}`:
            desasignarPromesa = asignarConsultor(a.id, null, c);
            break;
          case "comercial":
            desasignarPromesa = asignarComercial(a.nif, null);
            break;
          default:
            desasignarPromesa = Promise.resolve();
        }

        desasignarPromesa.then(() => {
          const prevLista = data.rows.map((item) => {
            if (a.nif === item.nif || a.id === item.id) {
              switch (b) {
                case 'tecnico':
                  return { ...item, tecnico: "" };
                case `consultor/1`:
                  return { ...item, consultorCae: "" };
                case `consultor/2`:
                  return { ...item, consultorLopd: "" };
                case 'comercial':
                  return { ...item, comercial: "" };
                default:
                  return item; // Si no hay coincidencia, devolvemos el item sin cambios
              }
            }
            return item;
          })
          console.log(prevLista)
          // setData((prevLista) =>
          //   prevLista.map((item) =>
          //     item.id === id ? { ...item, nombre: datoActualizado.nombre } : item
          //   )
          // );
          setData({ meta: data.meta, rows: prevLista })
          setRecargar(!recargar);
          toast.success(b + " desasignado");
        }).catch(error => {
          console.log(error);
          toast.error("Error al desasignar " + b);
        });
      }
    });
  }

  const formatData = (data) => {
    console.log(data)
    return data.map((row) => {
      const consultorCae = row.consultants?.find(objeto => objeto?.r_consul_servsgroups_aaffs.some(group => group.group_id === 1));
      const consultorLopd = row.consultants?.find(objeto => objeto?.r_consul_servsgroups_aaffs.some(group => group.group_id === 2));

      return {
        razonSocial: row.razonSocial || row.nombreContacto,
        nif: row.nif,
        // fechaEncargo: row.fechaEncargo || '',
        id: row.id,
        pendientesCount: row.pendientesCount,
        visitadasCount: row.visitadasCount,
        renovadasCount: row.renovadasCount,
        renovadasVisitadasCount: row.renovadasVisitadasCount,
        comunidadesVisitadas: row.visitadasCount / (row.pendientesCount + (row.visitadasCount || 1)) * 100 ?? 0,
        comunidadesRenovasVisitadas: row.renovadasVisitadasCount / (row.renovadasCount + (row.renovadasVisitadasCount || 1)) * 100 ?? 0,
        consultorCae: consultorCae ? `${consultorCae.nombre} ${consultorCae.apellidos}` : "",
        consultorLopd: consultorLopd ? `${consultorLopd.nombre} ${consultorLopd.apellidos}` : "",
        tecnico: row.technician?.id ? row.technician?.nombre + " " + row.technician?.apellidos : '',
        comercial: row.commercial?.id ? row.commercial?.nombre + " " + row.commercial?.apellidos : '',
        direction: row.direccion || row.propertie?.direccion,
        cp: row.codigoPostal || row.propertie?.codigoPostal,
        municipio: row.municipio || row.propertie?.municipio,
        provincia: row.provincia,
        empleados: (row.trabajadores || row.propertie?.trabajadores) ?? "No",
        servicios: row.gestionadaPor || row.propertie?.gestionadaPor,
        hojasVisita: row.visitSheets,
        visitada: row.visitada || row.propertie?.visitada,
        proveedores: row.suppliersCount,
        docsPendientes: row.hasPendingDocs,
        propCaducadas: row.hasPropCaducadas
        // renovarComunidades: row.canRenovate,
      }
    });
  };


  const cambiarFechaEncargo = (nif) => {

    Swal.fire({
      title: "Cambiar fecha encargo",
      html: `<input type="date" id="fecha" className="swal2-input">`,
      confirmButtonText: "Cambiar",
      focusConfirm: false,
      preConfirm: () => {
        const fecha = Swal.getPopup().querySelector("#fecha").value;
        if (!fecha) {
          Swal.showValidationMessage(`insertar fecha`);
        }
        return { fecha: fecha };
      },
    }).then((result) => {
      let data = {
        nif: nif,
        // fechaEncargo: formatoFechas(result.value.fecha),
      };

      editarAAFF(data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            toast.success("Datos editados con éxito");
            //setRecargar(!recargar);
          } else {
            let mensajes = [];

            res.data.error.map((err) => {
              mensajes = err;
              toast.error("error al editar");
            });
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Error al editar los datos");
        });
    });
  };

  const comunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
      overrideFunc: (data, row) => (
        <Link to={"/aaff/" + row.nif + "/comunidades"}>
          {data}
        </Link>
      )
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",

    },
    // {
    //   id: "fechaEncargo",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "FECHA ENCARGO",
    //   overrideFunc: (data, row) => (
    //     <Typography onClick={() => cambiarFechaEncargo(row.nif)} style={{ textAlign: 'left' }}>
    //       {data ? formatoFechasEs(data) : "-"}
    //     </Typography>
    //   ),
    // },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "consultorCae",
      numeric: false,
      disablePadding: false,
      label: "CONSULTOR CAE",
      noObjeto: true,
      overrideFunc: (data, row) => data ? (
        <span
          onClick={() => reasignar(row, "consultor", 1)}
          style={{ cursor: "pointer" }}
        >
          {data}
        </span>
      ) : (
        <Link to={"/aaff/" + row.nif + "/consultor/1"}>
          <div className="asignarButton">
            <img src={Add} alt="Más" />
            Asignar
          </div>
        </Link>
      )
    },
    {
      id: "consultorLopd",
      numeric: false,
      disablePadding: false,
      label: "CONSULTOR LOPD",
      noObjeto: true,
      overrideFunc: (data, row) => data ? (
        <span
          onClick={() => reasignar(row, "consultor", 2)}
          style={{ cursor: "pointer" }}
        >
          {data}
        </span>
      ) : (
        <Link to={"/aaff/" + row.nif + "/consultor/2"}>
          <div className="asignarButton">
            <img src={Add} alt="Más" />
            Asignar
          </div>
        </Link>
      )
    },
    {
      id: "comercial",
      numeric: false,
      disablePadding: false,
      label: "COMERCIAL",
      noObjeto: true,
      overrideFunc: (data, row) => data ? (
        <span
          onClick={() => reasignar(row, "comercial")}
          style={{ cursor: "pointer" }}
        >
          {data}
        </span>
      ) : (
        <Link to={"/aaff/" + row.nif + "/comercial"}>
          <div className="asignarButton">
            <img src={Add} alt="Más" />
            Asignar
          </div>
        </Link>
      )
    },
    {
      id: "tecnico",
      numeric: false,
      disablePadding: false,
      label: "TECNICO",
      noObjeto: true,
      overrideFunc: (data, row) => data ? (
        <span
          onClick={() => reasignar(row, "tecnico")}
          style={{ cursor: "pointer" }}
        >
          {data}
        </span>
      ) : (
        <Link to={"/aaff/" + row.nif + "/tecnico"}>
          <div className="asignarButton">
            <img src={Add} alt="Más" />
            Asignar
          </div>
        </Link>
      )
    },
    // {
    //   id: "comunidades",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "COMUNIDADES",
    //   overrideFunc: (row) => (
    //     <Typography>
    //       {row}
    //     </Typography>
    //   ),
    // },
    {
      id: "comunidadesVisitadas",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES VISITADAS",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <ProgressBarWithColors
          value={data}
          label={
            <Typography>
              {row.visitadasCount} /{" "}
              {row.pendientesCount + row.visitadasCount}
            </Typography>
          }
        />
      ),
    },
    {
      id: "comunidadesRenovasVisitadas",
      numeric: false,
      disablePadding: false,
      label: "COMUNIDADES RENOVADAS VISITADAS",
      noObjeto: true,
      overrideFunc: (data, row) =>
      (
        <ProgressBarWithColors
          value={data}
          label={
            <Typography>
              {row.renovadasVisitadasCount} /{" "}
              {row.renovadasCount + row.renovadasVisitadasCount}
            </Typography>
          }
        />
      ),
    },
    {
      id: "proveedores",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDORES",
      noObjeto: true,
      overrideFunc: (data, row) => (
        // /aaff/:nifAAFF/comunidades/:nifFinca/proveedores
        <Link to={"/aaff/" + row.nif + "/proveedores"}>
          {data}
        </Link>
      )
    },
    // {
    //   id: "propCaducadas",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "",
    //   noObjeto: true,
    //   overrideFunc: (data) => (
    //     <>
    //       {data ?
    //         <Tooltip title="Hay comunidades caducadas" arrow>
    //           <img src={criticalWarning} width={15} alt="comunidades caducadas" />
    //         </Tooltip>
    //         : null}
    //     </>
    //   ),
    // },
    {
      id: "docsPendientes",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <div style={{ display: 'flex' }}>
          {row.propCaducadas ? 
            <Tooltip title="Hay comunidades caducadas" arrow>
              <img src={criticalWarning} style={{ margin:2}} width={15} alt="comunidades caducadas" />
            </Tooltip> 
          : null}
          {data ?
              <Tooltip title="Hay documentos pendientesde validar" arrow>
                <img src={Warning} style={{ margin:2}} width={15} alt="pendientes" />
              </Tooltip>
          : null}
        </div>
      ),
    },
    {
      id: "login",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto: true,
      overrideFunc: (data, row) => (
        <Tooltip title="Iniciar sesión como">
          <div className="bigBtn p-2" onClick={() => loginAsAAFF(row.nif)}>
            <Login fontSize="small" />
          </div>
        </Tooltip>
      )
    },
    // {
    //   id: "renovarComunidades",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "RENOVAR COMUNIDADES",
    //   overrideFunc: (data, row) =>
    //     data ? (
    //       <Button className="btn-Secondary" variant="contained" color="secondary">
    //         <Link to={`/aaff/${row.nif}/renovar`} target={"_blank"} >Renovar</Link>
    //       </Button>
    //     ) : (
    //       <img src={Tick} width="30px" alt="todas renovadas" />
    //     ),
    // },
  ];


  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
    // fetchData();
  }

  return (
    <>
      <BreadCrumb rutas={[{ path: "/aaff", label: "Administradores de fincas" }]} style={{ margin: "0 10px" }} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">ADMINISTRADORES DE FINCAS</div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <div className="contentBar">
          <div className="contentBtns"></div>
          <Link to="/aaff/nuevo" target={"_blank"}>
            <div className="contentButton">
              <img src={Add} alt="Más" />
            </div>
          </Link>
        </div>
        <FiltrosAAFF onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data.current_page}
                meta={data.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
                enumerate={true}
              />
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

export default withRouter(AAFF);
