import React, { useState, useEffect, useMemo } from "react";
import Portada from "../../../images/PortadaRiesgos.png"
import Encabezado from "../../../images/EncabezadoRiesgos.png"
import Logo from "../../../images/LogoLAENEW.png"
import salida from "../../../images/salida.png"
import lucesEmergencia from "../../../images/lucesEmergencia.png"
import salidaEmergencia from "../../../images/salidaEmergencia.png"
import salidaEmergencia1 from "../../../images/salidaEmergencia1.png"
import extintor from "../../../images/extintor.png"
import ascensor from "../../../images/ascensor.png"
import peligro from "../../../images/peligro.png"
import { withRouter } from "../../../providers/withRouter";
import CircularProgress from '@mui/material/CircularProgress';
import { PDFViewer, Document, View, Page, Text, Image, StyleSheet, Link } from "@react-pdf/renderer"
import { getAAFFPropVisit, getStatement } from "../../../services";
import { formatoFechasEs } from "../../../functions/funciones";
import PDFHandler from "../../../components/pdfs/PDFHandler";
import { riesgosData } from "../../../components/aaff/riesgos";
import { Navigate, useLocation } from "react-router-dom";

const baseURL = window.location.origin;

const InformeRiesgos = (props) => {
    const styles = StyleSheet.create({
        docTitle: {
            position: 'absolute',
            left: 70,
            right: 70,
            bottom: '70px',
            textAlign: 'center',
            fontSize: '16px',
            color: '#B50655' || '#023867',
            fontFamily: 'Roboto',
            fontWeight: 700,
            textTransform: 'uppercase'
        },
        imgPrincipal: {
            height: '400px',
            width: '100%',
            marginTop: 30
        },
        link: {
            fontSize: '8px',
            marginLeft: 10,
        },
        body: {
            //paddingTop: 35,
            paddingBottom: 65,
            paddingHorizontal: '0cm',
        },
        table: {
            display: "table",
            width: "80%",
            borderStyle: "solid",
            borderWidth: 1,
            borderRightWidth: 0,
            borderBottomWidth: 1,
            margin: 'auto'
            // padding:'25px 0'
        },
        tableRiesgos: {
            display: "table",
            width: "auto",
            borderStyle: "solid",
            borderTopWidth: 0,
            //borderLeftWidth: 1,               
            borderRightWidth: 0,
            borderBottomWidth: 1,
            position: "relative",
            clipPath: "inset(0 0 0 10px)",
            overflow: "hidden",
            boxSizing: "border-box",
            fontStyle: "bold",
        },




        tableRowHeader: {
            marginTop: "30px",
            margin: "auto",
            flexDirection: "row",
        },
        tableRowHeader2: {
            marginTop: "30px",
            margin: "auto",
            flexDirection: "row",
            paddingTop: "5px",
            overflow: "hidden",
            boxSizing: "border-box",
            //borderLeftWidth:1 (revisar)
        },

        tableRow: {

            margin: "auto",
            flexDirection: "row",

        },
        tableColHeader: {
            width: "100%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 1,
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,

        },
        tableColHeader2: {
            width: "100%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
            paddingTop: 2, // Agrega un padding superior de 10px
            height: "auto", // Ajusta la altura automáticamente si es necesario
            overflow: "hidden", // Esconde el área de padding
            boxSizing: "border-box", // Incluye padding en el cálculo de ancho y altura
        },
        /* tableColHeader1: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent:'center',
            alignItems:'center',
            fontSize: 14 
        }, */
        tableCol: {
            width: "50%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'left'
        },
        /* tableCol1: { 
            width: "30%", 
            borderStyle: "solid", 
            borderWidth: 1, 
            borderLeftWidth: 0, 
            borderTopWidth: 0,
            display: 'flex',
            justifyContent:'center',
            alignItems:'center'
        },  */
        tableCell: {
            margin: "auto",
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 2,
            marginRight: 2,
            fontSize: 8
        },
        tableCellR: {
            margin: "auto",
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 2,
            marginRight: 2,
            fontSize: 6
        },
        tableColHeaderRiesgos1: {
            width: "15%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
            paddingTop: 2, // Agrega un padding superior de 10px
            height: "auto", // Ajusta la altura automáticamente si es necesario
            overflow: "hidden", // Esconde el área de padding
            boxSizing: "border-box", // Incluye padding en el cálculo de ancho y altura
        },
        tableColHeaderRiesgos2: {
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
            paddingTop: 2, // Agrega un padding superior de 10px
            height: "auto", // Ajusta la altura automáticamente si es necesario
            overflow: "hidden", // Esconde el área de padding
            boxSizing: "border-box", // Incluye padding en el cálculo de ancho y altura
        },
        tableColHeaderRiesgos3: {
            width: "30%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
            paddingTop: 2, // Agrega un padding superior de 10px
            height: "auto", // Ajusta la altura automáticamente si es necesario
            overflow: "hidden", // Esconde el área de padding
            boxSizing: "border-box", // Incluye padding en el cálculo de ancho y altura
        },
        tableColHeaderRiesgos4: {
            width: "45%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            backgroundColor: '#c31e64',
            color: '#ffffff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
            paddingTop: 2, // Agrega un padding superior de 10px
            height: "auto", // Ajusta la altura automáticamente si es necesario
            overflow: "hidden", // Esconde el área de padding
            boxSizing: "border-box", // Incluye padding en el cálculo de ancho y altura
        },
        tableColRiesgos1: {
            width: "15%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 1,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableColRiesgos2: {
            width: "10%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableColRiesgos3: {
            width: "30%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableColRiesgos4: {
            width: "45%",
            borderStyle: "solid",
            borderWidth: 1,
            borderLeftWidth: 0,
            borderTopWidth: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        },
        tableCellRiesgos: {
            margin: "auto",
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 2,
            marginRight: 2,
            fontSize: 6
        },
        tableCellRiesgos1: {
            margin: "auto",
            marginTop: 5,
            marginBottom: 5,
            marginLeft: 2,
            marginRight: 2,
            fontSize: 5
        },
        pdfHeader: {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'fixed',
            top: 0,
            backgroundColor: '#ffffff',
            /* margin:'2cm', */
        },
        pdfHeaderImg: {
            width: '100%',
            height: '60px',
            marginBottom: '30px',
        },
        tablePage: {
            width: '100%',
        },
        headerSpace: {
            height: '90px',
        },
        div: {
            fontSize: '10px'
        },
        h1: {
            /* marginLeft:'2cm',
            marginRight:'2cm', 
            marginTop: '50px',*/
            marginBottom: '30px',
            fontSize: '14px',
            fontWeight: '600',
        },
        h2: {
            fontSize: '11px',
            fontWeight: '600',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            marginTop: '30px',
            marginBottom: '30px',
        },
        h3: {
            fontSize: '9px',
            width: '100%',
            padding: '5px 10px',
            // backgroundColor: '#f8f8f8',
            textTransform: 'uppercase',
            // border: '1px solid #e6e6e6',
            // marginTop: '25px',
            // marginBottom: '15px',
            fontWeight: 'bold',
            /* marginLeft:'2cm',
            marginRight:'20cm', */
            textDecoration: 'underline'
        },
        h4: {
            color: '#ab2a3e',
            marginBottom: '10px',
            marginTop: '20px',
            fontSize: '9px',
            fontWeight: 'bold',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        h5: {
            fontSize: '10px',
            marginTop: '15px',
            marginBottom: '5px',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
        },
        //  tableRiesgos: {
        //     borderCollapse: 'collapse',
        //     width: '100%',
        //     tableLayout: 'fixed',
        //     marginBottom: '24px',
        //     flexDirection: 'row',
        //     borderBottomColor: '#bff0fd',
        //     backgroundColor: '#bff0fd',
        //     borderBottomWidth: 1,
        //     alignItems: 'center',
        //     textAlign: 'center',
        //     fontStyle: 'bold',
        //     flexGrow: 1,
        // }, 

        simpleTable: {
            borderCollapse: 'collapse',
            width: '80%',
            margin: '0 10% 24px 10%',
        },
        simpleTableTh: {
            textTransform: 'uppercase',
        },
        simpleTableTd: {
            border: '1px solid #000000',
            padding: '5px',
            lineHeight: '1.1'
        },
        ul: {
            // lineHeight: '1.8',
            // fontSize:'7px',
            flexDirection: "column",
            width: 150,
        },
        ol: {
            lineHeight: '1.8',
            flexDirection: "row",
            marginBottom: 4,
            fontSize: '7px',
        },
        p: {
            padding: '5px 10px',
            lineHeight: '1.8',
            textAlign: 'justify',
            /* marginLeft:'2cm',
            marginRight:'2cm', */
            fontSize: '7px',
        },
        imgP: {
            width: '100px',
            marginBottom: '30px',
        },
        subp: {
            lineHeight: '1.8',
            textAlign: 'justify',
            marginLeft: '8px',
            fontSize: '8px',
        },
        br: {
            display: 'block',
            margin: '10px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        brPlus: {
            marginTop: '210px'
        },
        brText: {
            display: 'block',
            margin: '2px 0 2px 0',
            lineHeight: '5px',
            content: " ",
        },
        footerSpace: {
            height: 50
        },
        espacio: {
            display: 'block',
            marginBottom: '5px'
        },
        page: {
            size: 'A4 portrait',
            margin: '2cm',
        },
        pagebreak: {
            clear: 'both',
            pageBreakAfter: 'always',
        }
    });
    
    const location = useLocation();
    const [fincas, setFincas] = useState([])
    const [riesgos, setRiesgos] = useState([])
    const [aaff, setAAFFS] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        fetchData()
    }, [])

    const token = localStorage.getItem('token');
    console.log(token)
  
    if(!token){
      return <Navigate replace to="/login" state={{from: location}} />
    }

    const fetchData = async () => {
        try {
            // const statementArrays = {};
            // const respRules = {
            //     3: "si",
            //     2: "si",
            //     5: "si",
            //     6: "si",
            //     7: "si",
            //     8: "si",
            //     9: "no",
            //     11: "no",
            //     12: "no",
            //     14: "no",
            //     15: "no",
            //     16: "no",
            //     17: "no",
            //     18: "no",
            //     19: "no",
            //     20: "no",
            //     22: "no",
            //     23: "no",
            //     24: "no",
            //     27: "no",
            //     28: "no",
            //     29: "no",
            //     30: "no",
            //     32: "no",
            //     33: "no",
            //     34: "no",
            //     35: "no",
            //     36: "no",
            //     37: "no",
            //     38: "no",
            //     39: "no",
            //     40: "no",
            //     41: "no",
            //     43: "si",
            //     47: "si",
            //     48: "no",
            //     49: "no",
            //     50: "no",
            //     51: "no",
            //     53: "no",
            //     54: "no",
            //     56: "no",
            //     57: "no",
            //     58: "no",
            //     59: "no",
            //     60: "no",
            //     61: "no",
            //     62: "no",
            // };
            const response = await getAAFFPropVisit(props.params.nif)

            // // Función para procesar preguntas
            // const procesarPreguntas = (questions, razonSocial) => {
            //     questions.forEach(question => {
            //         if (respRules.hasOwnProperty(question.statements_id) && 
            //             question.resp === respRules[question.statements_id]) {
            //             if (!statementArrays[question.statements_id]) {
            //                 statementArrays[question.statements_id] = [];
            //             }
            //             if (!statementArrays[question.statements_id].includes(razonSocial)) {
            //                 statementArrays[question.statements_id].push(razonSocial);
            //             }
            //         }
            //     });
            // };

            // // Procesar propiedades
            // response?.properties?.forEach(property => {
            //     if (property.workcenters?.length > 0) {
            //         // Priorizar los workcenters si existen
            //         property.workcenters.forEach(workcenter => {
            //             workcenter.visitSheets?.forEach(sheet => {
            //                 procesarPreguntas(sheet.questions, workcenter.razonSocial);
            //             });
            //         });
            //     } else {
            //         // Si no hay workcenters, procesar visitSheets de la propiedad
            //         property.visitSheets?.forEach(sheet => {
            //             procesarPreguntas(sheet.questions, property.razonSocial);
            //         });
            //     }
            // });
            // // const result = Object.fromEntries(
            // //     Object.entries(statementArrays).map(([key, value]) => [key, Array.from(value)])
            // // );
            // const updatedRiesgosData = riesgosData.map(riesgo => {
            //     return {
            //         ...riesgo,
            //         fincas: statementArrays[riesgo.key] || [] // Añadir el array correspondiente o un array vacío si no existe
            //     };
            // });
            // console.log(statementArrays);
            // console.log(updatedRiesgosData.filter(riesgo => riesgo.fincas && riesgo.fincas.length > 0))
            // const riesgosFincas = updatedRiesgosData.filter(riesgo => riesgo.fincas && riesgo.fincas.length > 0)
            const riesgosFincas = processResponse(response)
            // processRiesgosInBatches(riesgosFincas);
            setAAFFS(response)
            setFincas(response?.properties)
            setRiesgos(riesgosFincas)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const processResponse = (response) => {
        const statementArrays = {};
        const respRules = {
            3: "si",
            2: "si",
            5: "si",
            6: "si",
            7: "si",
            8: "si",
            9: "no",
            11: "no",
            12: "no",
            14: "no",
            15: "no",
            16: "no",
            17: "no",
            18: "no",
            19: "no",
            20: "no",
            22: "no",
            23: "no",
            24: "no",
            27: "no",
            28: "no",
            29: "no",
            30: "no",
            32: "no",
            33: "no",
            34: "no",
            35: "no",
            36: "no",
            37: "no",
            38: "no",
            39: "no",
            40: "no",
            41: "no",
            43: "si",
            47: "si",
            48: "no",
            49: "no",
            50: "no",
            51: "no",
            53: "no",
            54: "no",
            56: "no",
            57: "no",
            58: "no",
            59: "no",
            60: "no",
            61: "no",
            62: "no",
        };

        // Crear un Map para almacenar las fincas únicas
        const uniqueFincas = new Map();

        response?.properties?.forEach((property) => {
            if (property.workcenters?.length > 0) {
                property.workcenters.forEach((workcenter) => {
                    workcenter.visitSheets?.forEach((sheet) => {
                        sheet.questions.forEach((question) => {
                            if (respRules[question.statements_id] === question.resp) {
                                if (!statementArrays[question.statements_id]) {
                                    statementArrays[question.statements_id] = new Set();
                                }
                                statementArrays[question.statements_id].add(workcenter.razonSocial);
                            }
                        });
                    });
                });
            } else {
                property.visitSheets?.forEach((sheet) => {
                    sheet.questions.forEach((question) => {
                        if (respRules[question.statements_id] === question.resp) {
                            if (!statementArrays[question.statements_id]) {
                                statementArrays[question.statements_id] = new Set();
                            }
                            statementArrays[question.statements_id].add(property.razonSocial);
                        }
                    });
                });
            }
        });

        return riesgosData
            .map((riesgo) => ({
                ...riesgo,
                fincas: Array.from(statementArrays[riesgo.key] || [])
            }))
            .filter((riesgo) => riesgo.fincas.length > 0);
    };

    const RiesgoRow = React.memo(({ r, styles }) => {
        // Memoizar el renderizado de las fincas
        const fincasContent = useMemo(() => {
            // Si hay más de 50 fincas, mostrar las primeras 50 y un contador
            if (r.fincas.length > 150) {
                const displayedFincas = r.fincas.slice(0, 150);
                return (
                    <Text style={styles.tableCellRiesgos}>
                        {displayedFincas.join('\n')}
                        {`\n... y ${r.fincas.length - 150} más`}
                    </Text>
                );
            }
            return (
                <Text style={styles.tableCellRiesgos}>
                    {r.fincas.join('\n')}
                </Text>
            );
        }, [r.fincas, styles.tableCellRiesgos]);

        // Memoizar el contenido de medidas
        const medidasContent = useMemo(() => {
            if (r.key === 60 || r.key === 61) {
                return (
                    <>
                        <Text style={styles.tableCellRiesgos}>
                            {r.medidas[0]}
                        </Text>
                        {r.img && (
                            <Image
                                src={r.img}
                                style={{ width: 20, marginVertical: 10 }}
                                alt={r.textoImg}
                            />
                        )}
                        <Text style={styles.tableCellRiesgos}>
                            {r.medidas.slice(1).join('\n')}
                        </Text>
                    </>
                );
            }

            return (
                <>
                    <Text style={styles.tableCellRiesgos}>
                        {r.medidas.join('\n')}
                    </Text>
                    {r.img && (
                        <Image
                            src={r.img}
                            style={{
                                width: r.img === 'peligro' ? 20 : 30,
                            }}
                            alt={r.textoImg}
                        />
                    )}
                </>
            );
        }, [r.medidas, r.img, r.key, styles.tableCellRiesgos]);

        return (
            <View style={styles.tableRow}>
                <View style={styles.tableColRiesgos1}>
                    <Text style={styles.tableCellRiesgos}>
                        {r.riesgo.join('\n')}
                    </Text>
                </View>
                <View style={styles.tableColRiesgos2}>
                    <Text style={styles.tableCellRiesgos}>{r.origen}</Text>
                </View>
                <View style={styles.tableColRiesgos3}>
                    {medidasContent}
                </View>
                <View style={styles.tableColRiesgos4}>
                    {fincasContent}
                </View>
            </View>
        );
    }, (prevProps, nextProps) => {
        // Implementar una función de comparación personalizada
        return (
            prevProps.r.key === nextProps.r.key &&
            prevProps.r.fincas.length === nextProps.r.fincas.length
        );
    });


    return (
        <>
            {isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
                <PDFHandler style={{ width: "100%", height: "100vh" }}>
                    <Document size="A4" margin="2cm">
                        <Page>
                            <View>
                                <Image src={Portada} alt="Portada" style={{ height: "100vh" }} />
                                <Text style={styles.docTitle}>
                                    {aaff.razonSocial}
                                </Text>
                            </View>
                        </Page>

                        <Page style={styles.body}>
                            <View style={{ marginTop: 0, marginHorizontal: '0cm' }} fixed>
                                <View >
                                    <Image src={Encabezado} style={{ marginHorizontal: 'auto', marginTop: 20, width: '80%', float: 'left', display: 'inline' }} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>
                                    A continuación se detalla el resumen de los diferentes riesgos identificados en las comunidades de propietarios
                                </Text>
                                <Text style={styles.br}></Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRowHeader}>
                                        <View style={styles.tableColHeader}>
                                            <Text style={styles.tableCell}>INFORME DE RIESGOS</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Administrador de fincas:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.razonSocial}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>CIF:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.nif}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Localidad:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.municipio}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Teléfono:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.telefono}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>E-Mail:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.user?.email}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Técnico:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.technician ? aaff?.technician?.nombre + " " + aaff?.technician?.apellidos : ""}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Informe:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.fechaInforme ? formatoFechasEs(aaff?.fechaInforme) : ""}</Text>
                                        </View>
                                    </View>
                                    <View style={styles.tableRow}>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>Número de comunidades:</Text>
                                        </View>
                                        <View style={styles.tableCol}>
                                            <Text style={styles.tableCell}>{aaff?.properties?.length}</Text>
                                        </View>
                                    </View>
                                </View>
                                <Text style={styles.br}></Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.h3}>
                                    Objeto
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    El informe de riesgos tiene por objeto proponer medidas en base a los riesgos identificados en las comunidades de propietarios.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.h3}>
                                    Alcance
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    El presente informe afecta a todas las comunidades de propietarios visitadas de {aaff?.razonSocial}.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.h3}>
                                    Metodología
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    Para elaborar el informe se tendrán en cuenta los riesgos identificados durante la visita por parte del técnico de prevención de riesgos laborales, que propondrá medidas preventivas y/o correctoras con el fin de minimizar dichos riesgos.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.h3}>
                                    Ver Riegos
                                </Text>
                                <Text style={styles.link}>
                                    <Link
                                        src={`${baseURL}/informe-riesgos/${props.params.nif}`}
                                        target="_blank"
                                        style={{ textDecoration: 'underline', color: 'blue' }}
                                    >
                                        Informe de riesgos más a detalle
                                    </Link>
                                </Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View style={[styles.tableRiesgos, styles.brPlus]}>
                                    <>
                                        <View style={styles.tableRowHeader2} fixed>
                                            <View style={styles.tableColHeaderRiesgos1}>
                                                <Text style={styles.tableCell}>Riesgo identificado</Text>
                                            </View>
                                            <View style={styles.tableColHeaderRiesgos2}>
                                                <Text style={styles.tableCell}>Origen</Text>
                                            </View>
                                            <View style={styles.tableColHeaderRiesgos3}>
                                                <Text style={styles.tableCell}>Medidas Propuestas</Text>
                                            </View>
                                            <View style={styles.tableColHeaderRiesgos4}>
                                                <Text style={styles.tableCell}>Comunidades afectadas</Text>
                                            </View>
                                        </View>
                                        {riesgos.map((r, index) => (
                                            <RiesgoRow key={`${r.key}-${index}`} r={r} styles={styles} />
                                        ))}
                                    </>
                                </View>

                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.br}></Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    Agradecemos la atención prestada por el personal de {aaff?.razonSocial} en la gestión de las visitas a las comunidades así como en la continua colaboración con los proveedores a los que solicitamos la documentación necesaria para cumplir la coordinación de actividades empresariales.
                                </Text>
                                <Text style={styles.br}></Text>
                                <Text style={styles.p}>
                                    Quedamos a su entera disposición para aclarar cualquier duda,
                                </Text>
                                <Image alt='logo' src={Logo} style={styles.imgP} />
                            </View>
                        </Page>

                    </Document>
                </PDFHandler>
            }

        </>
    );
}

export default withRouter(InformeRiesgos);