import React, { useEffect, useState } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";

import {
  getFinca,
} from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import ComunidadDocs from "../../../components/Comunidad/ComunidadDocs";
import Ventas from "../general/Ventas";
import { cambiarFormatoNombre } from "../../../functions/funciones";
import { CircularProgress } from "@mui/material";
import ModalEditar from "./../admin/editarDatosComunidad";
import Editar from "../../../icons/editar.svg";

const Comunidad = (props) => {
  const [finca, setFinca] = useState({});
  const [modalEditarOpened, setModalEditarOpened] = useState(false);
  const [recargar, setRecargar] = useState(false);
  // const [proveedores, setProveedores] = useState([]);
  // const [recargar, setRecargar] = useState(false);
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    fetchData()

  }, [recargar]);

  const fetchData = async () => {

    // setRecargar(true);
   

    try {

      
      const response = await getFinca(props.params.nifFinca)
      setFinca(response);
      // setProveedores(response.suppliers.filter(objeto => objeto.status === true))
      setIsLoading(false);

    } catch (error) {
      console.log(error);
    }

  }

  return isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
    <>
      <ModalEditar
        finca={finca}
        opened={modalEditarOpened}
        handleClose={() => {
          setModalEditarOpened(false);
          setRecargar(!recargar);
        }}
      />
      {props.params.nif !== undefined ?
        <BreadCrumb rutas={[{ path: `/aaff`, label: `Administradores de fincas` }, { path: `/aaff/${props.params.nif}/comunidades`, label: `${finca.aaff.razonSocial}` }, { path: `/aaff/${props.params.nif}/comunidades`, label: `${finca.razonSocial}` }]} style={{ margin: '0 10px' }} />
        :
        <BreadCrumb rutas={[{ path: `/aaff/${props.params.nif}/comunidades`, label: `Comunidades` }, { path: `/aaff/${props.params.nif}/comunidades`, label: `${finca.razonSocial}` }]} style={{ margin: '0 10px' }} />
      }
      <div className="pageGrid" style={{ gridTemplateColumns: '1fr' }}>
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead editarSubhead">
            <div>DATOS COMUNIDAD</div>
            <div
              className="editarBtn"
              onClick={() => setModalEditarOpened(true)}
            >
              <span style={{ marginRight: 10 }}>Editar</span>{" "}
              <img src={Editar} alt="Editar" />
            </div>
          </div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(finca.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b> {finca.nombreContacto}
            </p>
            <p>
              <b>CP:</b> {finca.codigoPostal}
            </p>
            <p>
              <b>Email de contacto:</b> {finca.emailContacto}
            </p>
            <p>
              <b>Provincia:</b> {finca.provincia}
            </p>
            <p>
              <b>Telefono de contacto:</b> {finca.telefonoContacto}
            </p>
            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div className="gridSubhead">DOCUMENTOS COMUNIDAD</div>
          {/* <AcordeonDocs /> */}
         
          <Ventas nifFinca={props.params.nifFinca} />
        </div>
        {/* <div className="gridCard">
          <div className="gridHead">PROVEEDORES</div>
          <table style={{ margin: 15 }}>
            <thead>
              <tr
                style={{
                  fontSize: 10,
                  fontWeight: "normal",
                  textAlign: "center",
                }}
              >
              </tr>
            </thead>
            <tbody>
              {Array.isArray(proveedores)
                ? proveedores.map((e, i) => {
                    return (
                      <tr key={i}>
                        <td
                          style={{
                            width: "100%",
                            paddingBottom: 10,
                            paddingTop: 10,
                          }}
                        >
                          <Link to={"/proveedores/" + e.nif}>
                            {e.razonSocial}
                            <p style={{ opacity: 0.55, fontSize: 14 }}>
                              {e.serviceSupps.map((s, j) => {
                                return e.serviceSupps.length === j + 1
                                  ? s.nombre
                                  : s.nombre + ", ";
                              })}
                            </p>
                          </Link>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div> */}
      </div>
    </>
    ;
};

export default withRouter(Comunidad);
