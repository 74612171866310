import React, { useRef } from "react";
import Tick from "../../icons/tick.svg";
import Cross from "../../icons/cross.svg";
import Minus from "../../icons/minus.svg";
import Adjuntar from "../../icons/upload.svg";
import { Iconify } from "../../utilities";
import "../../styles/proveedor/declRes.css";

const IconCell = ({ declRes, onPendingClick }) => {
  // console.log(declRes);
  
  if (declRes?.pendiente) {
    return (
      <a onClick={onPendingClick}>
        <img src={Minus} alt="Pendiente" className="tableIcon" />
      </a>
    );
  }

  if (declRes?.validado) {
    return <img src={Tick} alt="Validado" className="tableIcon" />;
  }

  return <img src={Cross} alt="No validado" className="tableIcon" />;
};

const DataRow = ({
  row,
  onPendingClick,
  onViewClick,
  onAttachFile,
  showAlert,
}) => {
  const { razonSocial, declRes } = row;
  const inputRef = useRef(null);

  const handleAttachClick = async () => {
    // Muestra la alerta antes de abrir el selector de archivos
    const result = await showAlert({
      title: "Subir declaración responsable",
      text: "Confirma para continuar.",
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "rgb(130, 130, 130)",
      // preConfirm: () => {        
      //   const data = { aaff_id: idAAFF };
      //   return data;
      // },
    });

    if (result.isConfirmed) {
      // Si el usuario confirma, activa el input de archivo
      inputRef.current.click();
    }
  };

  return (
    
              <tr>
                <td>
                  <IconCell declRes={declRes} onPendingClick={onPendingClick} />
                </td>
                <td style={{ width: "100%" }}>{razonSocial}</td>
                <td
                  style={{
                    textAlign: "center",
                    // opacity: declRes?.ruta ===undefined ? 0.4 : 1,
                  }}
                >
                  <Iconify
                    icon="solar:eye-line-duotone"
                    alt="Ver"
                    title="Ver"
                    className={`tableIcon ${declRes?.ruta ? "" : "disabled"}`}
                    onClick={() => declRes?.ruta && onViewClick(declRes.ruta)}
                  />
                  
                </td>
                <td style={{ textAlign: "center" }}>
                <label>
                  <img src={Adjuntar} alt="Adjuntar" className="tableIcon" onClick={handleAttachClick} />
                </label>
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={(e) => onAttachFile(row, e.target.files[0], inputRef)}
                />
                </td>
              </tr>
              
    // <div className="row_table">
    //   <IconCell declRes={declRes} onPendingClick={onPendingClick} />
    //   <div className="text-cell">{razonSocial}</div>
    //   <Iconify
    //     icon="solar:eye-line-duotone"
    //     alt="Ver"
    //     title="Ver"
    //     className={`tableIcon ${declRes?.ruta ? "" : "disabled"}`}
    //     onClick={() => declRes?.ruta && onViewClick(declRes.ruta)}
    //   />
    //   <label>
    //     <img src={Adjuntar} alt="Adjuntar" className="tableIcon" onClick={handleAttachClick} />
    //   </label>
    //   <input
    //     type="file"
    //     ref={inputRef}
    //     style={{ display: "none" }}
    //     onChange={(e) => onAttachFile(row, e.target.files[0], inputRef)}
    //   />
    // </div>
  );
};

const DeclList = ({
  rows,
  onPendingClick,
  onViewClick,
  onAttachClick,
  showAlert,
  onAttachFile
}) => {
  return (
    rows.map((row, index) => (
      <DataRow
        key={row.id || index}
        row={row}
        onPendingClick={() => onPendingClick(row)}
        onViewClick={(ruta) => onViewClick(ruta)}
        onAttachFile={onAttachFile}
        showAlert={showAlert}
      />
    ))
  );
};

export default DeclList;
