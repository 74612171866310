import salida from "../../images/salida.png"
import lucesEmergencia from "../../images/lucesEmergencia.png"
import salidaEmergencia from "../../images/salidaEmergencia.png"
import salidaEmergencia1 from "../../images/salidaEmergencia1.png"
import extintor from "../../images/extintor.png"
import ascensor from "../../images/ascensor.png"
import peligro from "../../images/peligro.png"
const riesgosData = [
    {
        key: 3,
        riesgo: ["Golpes y cortes por objetos y herramientas"],
        origen: "Zonas comunes",
        medidas: ["Sustituir los cristales afectados, por otros en correcto estado"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 2,
        riesgo: ["Caídas de personas a distinto nivel"],
        origen: "Zonas comunes",
        medidas: ["Reparar los desperfectos encontrados en el suelo y/o paredes"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 5,
        riesgo: ["Contactos eléctricos", "Cables sueltos o en mal estado"],
        origen: "Zonas comunes",
        medidas: ["Canalizar todo el cableado de la instalación eléctrica mediante canaletas o pasacables", "En especial las que crucen zonas de paso"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 6,
        riesgo: ["Contactos eléctricos", "Cajas de registro abiertas"],
        origen: "Zonas comunes",
        medidas: ["Realizar revisiones periódicas de la instalación eléctrica y de los equipos eléctricos por personal autorizado (también revisar el cableado, conectores, enchufes, etc.). Documentar las revisiones", "Cerrar y tapar con su respectiva caja y adecuadamente el cuadro eléctrico"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 7,
        riesgo: ["Caídas de personas al mismo nivel"],
        origen: "General",
        medidas: ["Adecuar las rampas con tiras antideslizantes"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 8,
        riesgo: ["Contactos eléctricos", "Cuadro eléctrico abierto"],
        origen: "General",
        medidas: ["Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 9,
        riesgo: ["Contactos eléctricos"],
        origen: "General",
        medidas: ["Se recomienda señalizar el riesgo de contacto eléctrico en los cuadros eléctricos de las instalaciones mediante pictograma homologado"],
        opcion: "si",
        img: peligro,
        textoImg: "peligro riesgo eléctrico"
    },
    {
        key: 11,
        riesgo: ["Ascensores"],
        origen: "Ascensores",
        medidas: ["Revisar periódicamente los ascensores mediante una empresa de mantenimiento y por personal autorizado"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 12,
        riesgo: ["Ascensores"],
        origen: "Ascensores",
        medidas: ["Dotar de iluminación interior 24h. Revisarla periódicamente"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 14,
        riesgo: ["Ascensores"],
        origen: "Ascensores",
        medidas: ["Dotar de alarma con conexión 24h. Revisarla periódicamente"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 15,
        riesgo: ["Ascensores"],
        origen: "Ascensores",
        medidas: ["La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 16,
        riesgo: ["Ascensores"],
        origen: "Ascensores",
        medidas: ["La sala de máquinas deberá permanecer cerrada con acceso restringido a toda persona no autorizada"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 17,
        riesgo: ["Ascensores"],
        origen: "Ascensores",
        medidas: ["Se recomienda señalizar el riesgo eléctrico"],
        opcion: "no",
        img: peligro,
        textoImg: "peligro riesgo eléctrico"
    },
    {
        key: 18,
        riesgo: ["Incendios"],
        origen: "Ascensores",
        medidas: ["Se recomienda disponer de un extintor de CO² en el interior de la sala de máquinas (si no lo hay fuera), a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 19,
        riesgo: ["Incendios"],
        origen: "Ascensores",
        medidas: ["Se recomienda disponer de un extintor, preferentemente, de CO² en el exterior de la sala de máquinas"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 20,
        riesgo: ["Atrapamientos"],
        origen: "Ascensores",
        medidas: ["Se recomienda la instalación de un cartel con el texto: “Cuarto de maquinaria del ascensor”"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 20,
        riesgo: ["Atrapamientos"],
        origen: "Ascensores",
        medidas: ["Colocar un cartel con el texto “Se prohíbe la entrada a toda persona no autorizada”"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 22,
        riesgo: ["Caída de personal a distinto nivel"],
        origen: "Azotea",
        medidas: ["Delimitar y señalizar la zona de acceso a la azotea para evitar el acceso de cualquier persona ajena a la actividad"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 23,
        riesgo: ["Caída de personal a distinto nivel"],
        origen: "Azotea",
        medidas: ["Se recomienda instalar escaleras de servicio para el acceso seguro a la zona de azoteas , o valorar la instalación de un medio de acceso seguro"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 24,
        riesgo: ["Caída de personal a distinto nivel"],
        origen: "Azotea",
        medidas: ["Proteger el perímetro de la zona mediante barandillas. En caso de que la comunidad no tenga murete perimetral, éstas deben disponer de pasamanos, barra intermedia y rodapié", "En caso de ser una azotea no transitable o se realicen trabajaos verticales se deberá estudiar la posibilidad de instalar línea de vida o verificar que todo el personal que acceda a la misma cuente con medidas anticaída, como por ejemplo una línea de vida provisional"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 24,
        riesgo: ["Caída de personal a distinto nivel"],
        origen: "Azotea",
        medidas: ["Se recomienda la instalación de anclajes para las líneas de vida para realizar los trabajos con mayor seguridad", "En su defecto, se deberá verificar que el trabajador dispone de una línea de vida portátil"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 27,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Se recomienda instalar extintores suficientes en las instalaciones", "El número mínimo de extintores deberá ser el suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 metros"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 28,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Realizar revisiones periódicas reglamentarias de los extintores, según el Real Decreto 513/2017 por el que se aprueba el Reglamento de instalaciones de protección contra incendios"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 29,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación"],
        opcion: "no",
        img: extintor,
        textoImg: "extintor"
    },
    {
        key: 30,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual (antes 1.70m)", "Recomendamos que los extintores instalados antes de la entrada en vigor del RD 513/2017 deberán de irse adaptando a la nueva normativa gradualmente"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 32,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Se recomienda señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida según CTE."],
        opcion: "no",
        img: salidaEmergencia1,
        textoImg: "salida de emergencia"
    },
    {
        key: 33,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Se recomienda instalar luces de emergencia sobre las puertas de salida, para dotar de suficiente visibilidad el recorrido de evacuación y las salidas de emergencia"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 34,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Señalizar las direcciones de circulación en el interior del parking cuando éste supere los 500m2. Seguir normativa autonómica/municipal"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 35,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Señalizar la dirección y sentido de acceso en las entradas al parking cuando se disponga de dos o más puertas"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 36,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Se recomienda dotar de sistemas automáticos de extinción de incendios (rociadores)", "Seguir normativa autonómica/municipal"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 37,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Se recomienda instalar sistemas de extracción de humos en el parking siempre que éste supere los 500m2", "Seguir normativa autonómica/municipal"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 38,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Seguir normativa autonómica/municipal para la instalación y mantenimiento de la BIE"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 39,
        riesgo: ["Incendios"],
        origen: "Parking",
        medidas: ["Seguir normativa autonómica/municipal para la instalación y mantenimiento de detectores de humo"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 40,
        riesgo: ["Contactos eléctricos"],
        origen: "Parking",
        medidas: ["Proteger el cuadro eléctrico. Colocar tapa y mantenerlo cerrado"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 41,
        riesgo: ["Contactos eléctricos"],
        origen: "Parking",
        medidas: ["Señalizar el cuadro eléctrico con “señal riesgo eléctrico”. Pictograma triangular amarillo con letras negras"],
        opcion: "no",
        img: peligro,
        textoImg: "peligro"
    },
    {
        key: 43,
        riesgo: ["Exposición a contaminantes químicos"],
        origen: "Jardín",
        medidas: ["Disponer de un armario cerrado bajo llave  para guardar los productos químicos", "Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 47,
        riesgo: ["Exposición a contaminantes químicos"],
        origen: "Piscina",
        medidas: ["Almacenar y manipular los productos químicos según las indicaciones de las Fichas de datos de seguridad de productos químicos", "Disponer de un armario cerrado bajo llave para guardar los productos químicos", "Se solicitarán las Fichas de datos de seguridad química, a los proveedores, de todos los productos fitosanitarios que se utilizan y se pondrán a disposición de las personas que los manipulen"],
        opcion: "si",
        img: null,
        textoImg: ""
    },
    {
        key: 48,
        riesgo: ["Caídas de personal a diferente nivel"],
        origen: "Piscina",
        medidas: ["Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma", "Las piscinas en las que el acceso de niños a la zona de baño no esté controlado dispondrán de barreras de protección que impidan su acceso al vaso excepto a través de puntos previstos para ello, los cuales tendrán elementos practicables con sistemas de cierre y bloqueo. (Documento Básico SU Seguridad de Utilización Apartado Piscinas punto 1.1)", "Seguir normativa autonómica/municipal"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 49,
        riesgo: ["Caídas de personal a diferente nivel"],
        origen: "Piscina",
        medidas: ["Durante el trabajo en las inmediaciones de la piscina deberá respetarse la señalización y el vallado de la misma", "Las piscinas en las que el acceso de niños a la zona de baño no esté controlado dispondrán de barreras de protección que impidan su acceso al vaso excepto a través de puntos previstos para ello, los cuales tendrán elementos practicables con sistemas de cierre y bloqueo. (Documento Básico SU Seguridad de Utilización Apartado Piscinas punto 1.1)", "Seguir normativa autonómica/municipal"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 50,
        riesgo: ["Incendios"],
        origen: "Extintores",
        medidas: ["Los edificios construidos o rehabilitados a partir de 1996 deberán contar con los medios de extinción necesarios y suficientes según las características del edificio de acuerdo al NBE-CPI/96", "Para los construidos antes de ésta fecha siempre es muy recomendable su instalación"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 51,
        riesgo: ["Incendios"],
        origen: "Extintores",
        medidas: ["Se recomienda consultar con empresa especializada en extinción de incendios si los extintores disponibles son suficientes para las características del edificio y realizar el mantenimiento periódico de los mismos", "Según CTE se dispondrán extintores en número suficiente para que el recorrido real en cada planta desde cualquier origen de evacuación hasta un extintor no supere los 15 m"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 53,
        riesgo: ["Incendios"],
        origen: "Extintores",
        medidas: ["Señalizar los medios de extinción de incendios mediante pictograma fotoluminiscente (fondo rojo y letras blanca) instalando correctamente la señal tipo cartel indicativa del lugar de ubicación del extintor, de manera que indique claramente su ubicación.  Es muy importante que los extintores de incendios se encuentren colocados en lugares visibles y accesibles"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 54,
        riesgo: ["Incendios"],
        origen: "Extintores",
        medidas: ["Colgar los extintores a una altura máxima sobre su punto de anclaje de 1,20 m del suelo según normativa actual (antes 1.70m). Recomendamos que los extintores instalados antes de la entrada en vigor del RD 513/2017 deberán de irse adaptando a la nueva normativa gradualmente"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 54,
        riesgo: ["Incendios"],
        origen: "Iluminación",
        medidas: ["Se recomienda la instalación de iluminación de emergencia tanto en recorrido de evacuación como en las salidas de la finca", "La iluminación de emergencia está destinada a alertar a los usuarios de un edificio ante una situación de riesgo, e indicar los medios de evacuación. También está pensada para indicar junto con la señalización el protocolo de actuación dependiendo la situación, y la posterior evacuación"],
        opcion: "no",
        img: lucesEmergencia,
        textoImg: "luces de emergencia"
    },
    {
        key: 57,
        riesgo: ["Incendios"],
        origen: "Iluminación",
        medidas: ["Se recomienda equipar las vías y salidas de evacuación con iluminación de emergencia de suficiente intensidad  según Reglamento actual"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 58,
        riesgo: ["Incendios"],
        origen: "Iluminación",
        medidas: ["Se recomienda equipar las salidas de la finca con iluminación de emergencia de suficiente intensidad con el fin de reducir consecuencias en caso de emergencia y ayudar a la evacuación del edificio"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 59,
        riesgo: ["Incendios"],
        origen: "Señalización",
        medidas: ["Se recomienda la instalación de señalización de emergencia tanto en recorrido de evacuación como en las salidas de la finca"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
    {
        key: 60,
        riesgo: ["Incendios"],
        origen: "Señalización",
        medidas: ["Señalizar las salidas de la finca con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida"],
        opcion: "no",
        img: salida,
        textoImg: "salida de emergencia"
    },
    {
        key: 61,
        riesgo: ["Incendios"],
        origen: "Señalización",
        medidas: ["Señalizar los recorridos y las salidas de evacuación con pictogramas foto luminiscentes y homologados (letras blancas sobre fondo verde) a una altura y posición adecuadas en relación al ángulo visual, teniendo en cuenta posibles obstáculos, de forma que desde cualquier lugar se vea una señal que indique la salida", "La señalización de emergencia está destinada a alertar a los usuarios de un edificio ante una situación de riesgo, e indicar los medios de evacuación. También está pensada para indicar el protocolo de actuación dependiendo la situación, y la posterior evacuación"],
        opcion: "no",
        img: salidaEmergencia,
        textoImg: "salida de emergencia"
    },
    {
        key: 62,
        riesgo: ["Incendios"],
        origen: "Extintores",
        medidas: ["Los edificios construidos o rehabilitados a partir de 1996 deberán contar con los medios de extinción necesarios y suficientes según las características del edificio de acuerdo al NBE-CPI/96", "Para los construidos antes de ésta fecha siempre es muy recomendable su instalación"],
        opcion: "no",
        img: null,
        textoImg: ""
    },
];

export { riesgosData }