import React, { useState, useEffect, useContext } from 'react'
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { DataTable } from "../../components";
import Tick from "../../icons/tick.svg";
import Cross from "../../icons/cross.svg";
import { getAllConsultores, suppsNoDocs, validateNoDocSuppAlert } from "../../services";
import {
  daysDiff,
  formatoFechasEs,
} from "../../functions/funciones";
import {
  Box,
  Button,
  Typography,
} from "@mui/material";
import FiltrosAlertasProv from "../../components/filtros/filtrosAlertasProv";
import { FaCheck } from "react-icons/fa";
import { UsuarioContext } from '../../providers/userContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../functions/parametrosURL/funcionesParamsURL';


const NoDocumentsAlert = ({ valorTab }) => {


  const user = useContext(UsuarioContext)
  // const [Consultores, setConsultores] = useState([]);
  const { filtrosIniciales, filtroOrder } = useRecogerURLParamsFiltros();

  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [validating, setValidating] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });


  // useEffect(() => {
  //   // fetchData();
  //   getAllConsultores().then((res) => {
  //     setConsultores(res);
  //   });
  // }, [recargar, validating]);

  const [primeraCarga, setPrimeraCarga] = useState(true);

  const navigate = useNavigate();

  const clearFilters = () => {
    if (user.rol === "CONSUL" && primeraCarga) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id
      });
    }
    else {
      setBusqueda({});
    }


  }

  const fetchData = async ({ offset, limit, filtros = busqueda, order, orderBy } = {}) => {


    orderBy = orderBy || filtroOrder.ordenadoPor || 'ultimaReclamacion';
    order = order || filtroOrder.orden || 'ASC';

    if (primeraCarga) {
      if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
        filtros.consul_id = user.id
      } else if (filtros.consul_id === 0) {
        delete filtros.consul_id
      }
      setPrimeraCarga(false);
    }


    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros, order, orderBy, valorTab);

    const payload = {
      filtros,
      params: { page: offset, limit, order, orderBy, },
    };

    try {
      setLoading(true);
      const response1 = await suppsNoDocs(payload);
      const { meta, data } = response1;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {

    return data.map((row, i) => ({
      proveedor_id: row.id,
      razonSocial: row.razonSocial,
      esCreatedAt: !row.ultimaReclamacion,
      ultimaReclamacion: row.ultimaReclamacion || row.createdAt,
      //ultimaReclamacion: row.ultimaReclamacion || row.createdAt,
      nif: row.nif,
      planificacionPreventiva: row.noProcede ? 'No procede' : !row.docssupps?.find((d) => d.tipoDoc === 4)?.validado === true,
      evaluacionRiesgos: !row.docssupps?.find((d) => d.tipoDoc === 5)?.validado === true,
      vigilancia: row.noProcede ? 'No procede' : row.docssupps?.find((d) => d.tipoDoc === 7)?.fechaCaducidad || '',
      certificado: row.docssupps?.find((d) => d.tipoDoc === 6)?.fechaCaducidad || '',
      justificante: row.noProcede ? 'No procede' : row.docssupps?.find((d) => d.tipoDoc === 3)?.fechaCaducidad || '',
      seguro: row.docssupps?.find((d) => d.tipoDoc === 2)?.fechaCaducidad || '',
      validar: row.id
    }));
  };

  const proveedoresRows = [
    
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDOR",
      overrideFunc: (data, row) => <Link to={"/proveedores/" + row.nif}>{data}</Link>
    },
    {
      id: "ultimaReclamacion",
      numeric: false,
      disablePadding: false,
      label: "ÚLTIMA RECLAMACIÓN",
      overrideFunc: (data, row) => <span style={{color: daysDiff(new Date(data), new Date()) > 30 ? 'red' : 'black'}}>{formatoFechasEs(data)}</span>
      // overrideFunc: (data, row) => <span style={{ color: daysDiff(new Date(data), new Date()) > 30 ? 'red' : 'black' }}>{formatoFechasEs(data)}</span>
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "planificacionPreventiva",
      numeric: false,
      disablePadding: false,
      label: "PLANIFICACIÓN PREVENTIVA",
      noObjeto: true,
      overrideFunc: (data) => data === "No procede" ? data : <img style={{ width: "20px" }} src={data ? Cross : Tick} alt="vacio" />,

    },
    {
      id: "evaluacionRiesgos",
      numeric: false,
      disablePadding: false,
      label: "EVALUACIÓN RIESGOS",
      noObjeto: true,
      overrideFunc: (data) => <img style={{ width: "20px" }} src={data ? Cross : Tick} alt="vacio" />,
    },
    {
      id: "vigilancia",
      numeric: false,
      disablePadding: false,
      label: "Vigilancia salud",
      noObjeto: true,
      overrideFunc: (data) => data === "No procede" ? data : data ? <span style={{ color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>{formatoFechasEs(data)}</span> : '-',
    },
    {
      id: "certificado",
      numeric: false,
      disablePadding: false,
      label: "Certificado Seg. Social",
      noObjeto: true,
      overrideFunc: (data) => data ? <span style={{ color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>{formatoFechasEs(data)}</span> : '-',
    },
    {
      id: "justificante",
      numeric: false,
      disablePadding: false,
      label: "Justificante pago SPA",
      noObjeto: true,
      overrideFunc: (data) => data === "No procede" ? data : data ? <span style={{ color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>{formatoFechasEs(data)}</span> : '-',
    },
    {
      id: "seguro",
      numeric: false,
      disablePadding: false,
      label: "Seguro Resp. Civil",
      noObjeto: true,
      overrideFunc: (data) => data ? <span style={{ color: daysDiff(new Date(data), new Date()) >= 0 ? 'red' : 'black' }}>{formatoFechasEs(data)}</span> : '-',

    },
    {
      id: "validar",
      numeric: false,
      disablePadding: false,
      noObjeto: true,
      label: "Realizada",
      overrideFunc: (data, row) => (
        <>
          <Button style={{ background: validating ? '#7bc977' : 'green', color: 'white', minWidth: '5px' }} onClick={() => { handleCheck(data) }} disabled={validating}><FaCheck /></Button>
        </>
      )
    },
  ];

  const handleCallback = () => {
    setRecargar(!recargar);
  };

  const handleCheck = async (id) => {
    validarAlerta(id).then((resultado) => {
      handleCallback(resultado)
    })
  }

  const validarAlerta = async (id) => {
    return new Promise(async (resolve, reject) => {
      setValidating(true);
      try {
        const toastInfo = toast.info("Validando...", { autoClose: false });
        await validateNoDocSuppAlert(id).then(res => {
          toast.update(toastInfo, {
            render: "Alerta validada",
            type: toast.TYPE.SUCCESS,
            autoClose: true,
          });
          // setRecargar(true);
          setLoading(true);
          fetchData();
          resolve(true);
        }).catch((error) => {
          toast.update(toastInfo, {
            render: "No se ha podido validar la alerta",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
          reject(error)
        })
      } catch (error) {
        console.error(error);
        reject(error)
        toast.error("Error al validar la alerta");
      } finally {
        setValidating(false);
      }
    })
  }

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros);
    setBusquedaActiva(true);
  }

  return (
    <>
      <div className="listPage">
        <div className="gridCard">
          <div className="tableContainer">
            <FiltrosAlertasProv onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    meta={data.meta}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    mensaje={"No hay proveedores"}
                    segundaCol={true}
                    enumerate={true}
                    recargar={recargar}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NoDocumentsAlert;