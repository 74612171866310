import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../../../providers/withRouter";
import { DataTable, ProgressBarWithColors } from "../../../components";
import { docsPendientesProv, formatoFechasEs } from "../../../functions/funciones";
import Add from "../../../icons/add.svg";
import {
  Box,
  Select,
  Typography,
} from "@mui/material";
import { cambiarProveedor, deleteProveedor, getAAFFId, getConsultor, getOneAAFF, getProveedores } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";
import { UsuarioContext } from "../../../providers/userContext";
import Warning from "../../../icons/warning.svg";
import Delete from "../../../icons/delete.svg";
import { FormControl } from "react-bootstrap";
import { toast } from "react-toastify";
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import { useLocation, useNavigate } from "react-router-dom";

const ProveedoresConsultor = (props) => {

  const {filtrosIniciales} = useRecogerURLParamsFiltros();
  const [consultor, setConsultor] = useState({});
  const [aaff, setAAFF] = useState({});
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });

  const [primeraCarga, setPrimeraCarga] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (!aaff.id) {
      loadAAFF();
    }
    if (!consultor.id) {
      loadConsultor();
    }
    if (aaff.id && consultor.id) {
      fetchData();
    }
  }, [recargar, aaff, consultor]);
  const loadAAFF = async () => {
    const resAAFF = await getOneAAFF(props.params.nifAAFF);
    setAAFF(resAAFF)
  }
  const loadConsultor = async () => {
    const resConsul = await getConsultor(props.params.nifConsultor)
    setConsultor(resConsul)
  }

  const fetchData = async ({ offset, limit, filtros = busqueda } = {}) => {
    if (!aaff.id || !consultor.id) {
      return
    }
    if (primeraCarga) {
      // if (filtros.consul_id === undefined && user.rol === 'CONSUL') {
      //   filtros.consul_id = 5
      // } else if (filtros.consul_id === "0") {
      //   delete filtros.consul_id
      // }
      setPrimeraCarga(false);
    }

    construirURLParamsFiltros(navigate, offset || 1, limit || 50, filtros);
    // if ( user.rol ==='CONSUL') {

    // } else if (user.rol ==='ADMIN') {
    //     delete filtros.consul_id
    // }

    
    const payload = {
      filtros: { ...filtros, consul_id: consultor.id, aaff_id: aaff.id },
      params: { page: offset, limit },
    };

    
    try {
      setLoading(true);
      console.log("HOLA");
      const response = await getProveedores(payload);
      const { meta, data } = response;
      console.log(data)
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const formatData = (data) => {
    return data.map((row) => ({
      proveedor_name: row.razonSocial,
      ultimaReclamacion: row.ultimaReclamacion === null ? row.createdAt : row.ultimaReclamacion,
      nif: row.nif,

      telefono: row.telefonoContacto,
      email: row.emailContacto,
      municipio: row.municipio,
      provincia: row.provincia,
      trabajadores: row.noProcede === null ? false : row.noProcede,
      cumplimiento: row.porcentajeDocs,
      docsPendientes: row.hasPendingDocs,
      eliminarProv: <img src={Delete} onClick={() => eliminarProv(row.nif).then(res => handleSubirDocsCallback(res))} width={15} alt="eliminar" />,
    }));
  };

  const handleChangeTrabajadores = (evt, nif) => {
    const actualizarEstado = data.rows.map((row) => {
      if (row.nif === nif) {
        cambiarProveedor(nif, {
          noProcede: evt.target.value
        })
        return { ...row, trabajadores: evt.target.value === 'true' };

      }
      return row;
    });
    setData({ ...data, rows: actualizarEstado });
  };

  const proveedoresRows = [
    {
      id: "proveedor_name",
      numeric: false,
      disablePadding: false,
      label: "PROVEEDOR",
      overrideFunc: (data, row) =>
      (
        <Link to={"/proveedores/" + row.nif}>
          {data}
        </Link>
      )
    },
    {
      id: "ultimaReclamacion",
      numeric: false,
      disablePadding: false,
      label: "ÚLTIMA RECLAMACIÓN",
      overrideFunc: (data) => data ? formatoFechasEs(data) : '',
    },
    {
      id: "nif",
      numeric: false,
      disablePadding: false,
      label: "NIF",
    },
    {
      id: "telefono",
      numeric: false,
      disablePadding: false,
      label: "TELÉFONO",
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "EMAIL",
    },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "PROVINCIA",
    },
    {
      id: "cumplimiento",
      numeric: false,
      disablePadding: false,
      label: "CUMPLIMIENTO",
      overrideFunc: (row) => (
        <ProgressBarWithColors
          value={row}
          label={
            <Typography>
              {row}
            </Typography>
          }
        />
      ),
    },
    {
      id: "trabajadores",
      numeric: false,
      disablePadding: false,
      label: "TRABAJADORES",
      overrideFunc: (data, row) => (
        <div className="trabajadoresSelect">
          <Select
            label="Trabajadores"
            native
            name="trabajadores"
            id="trabajadores"
            style={{ textTransform: 'uppercase', padding: 0, fontSize: '11px', color: data ? "red" : 'green' }}
            onChange={(event) => handleChangeTrabajadores(event, row.nif)} value={data}
          >
            <option value={false}>Sí</option>
            <option value={true}>No</option>
          </Select>
        </div>
      ),
    },
    {
      id: "docsPendientes",
      numeric: false,
      disablePadding: false,
      label: "",
      overrideFunc: (row) => (
        <>
          {row > 0 ?
            <img src={Warning} width={15} alt="pendientes" />
            : null}
        </>
      ),
    },
    {
      id: "eliminarProv",
      numeric: false,
      disablePadding: false,
      label: "",
    },
  ]

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData();
  }

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  const handleSubirDocsCallback = () => {
    setRecargar(!recargar);
  };

  const eliminarProv = async (nif) => {
    return new Promise(async (resolve, reject) => {
      try {
        await deleteProveedor(nif).then(res => {
          resolve(true);
          setRecargar(!recargar);
        }).catch(reject)

      } catch (error) {
        reject(error);
      }
    })
  }

  return (
    <>
      <BreadCrumb rutas={[{path:'/consultores', label:`Consultores`}, {path:'/consultores', label:`${consultor.nombre + " " + consultor.apellidos}`}, {path:`/consultores/${props.params.nifConsultor}/aaff`, label:`Administradores de fincas`}, {path:`/aaff/${props.params.nifAAFF}/comunidades`, label:`${aaff.razonSocial}`}, { path: `/proveedores`, label: 'Proveedores' }]} />
      
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">LISTADO PROVEEDORES</div>
          <div className="tableContainer">
            <div className="contentBar">
              <div className="contentBtns">
                {/* <div className="listadoBtn" style={{ marginLeft: 0}} >
                <BsUpload alt="subir listado" style={{ marginRight: 5 }} />
                listado
              </div>
              <div className="descargarBtn">
                <a href="files/plantillaAAFF.xlsx" download="plantillaAAFF.xlsx">
                  <BsDownload alt="Descargar plantilla" style={{ marginRight: 5 }} />
                  plantilla
                </a>
              </div> */}

              </div>
              <Link to="/proveedores/nuevo" target={"_blank"}>
                <div className="contentButton">
                  <img src={Add} alt="Más" />
                </div>
              </Link>
            </div>
            <div style={{ display: "flex" }}>
              <FiltrosProveedores onFilter={filtrarDatos} clearFilters={clearFilters} filtrosIniciales={busqueda}/>
              {/* <div className="exportar">
                <ExportarProveedoresConsultor busqueda={Resbusqueda} /> 
              </div> */}
            </div>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    clearFilterActivo={clearFilterActivo}
                    setClearFilterActivo={setClearFilterActivo}
                    mensaje={"No hay proveedores"}
                    enumerate={true}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
          #trabajadores{
            padding:0 25px 0 0;
            border: none;
          }
          .trabajadoresSelect fieldset, .trabajadoresSelect select:hover{
            border-color: rgba(0, 0, 0, 0) !important;
          }               
      `}</style>
    </>
  );
};

export default withRouter(ProveedoresConsultor);
