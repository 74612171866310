import React, { useState } from "react";
import { toast } from "react-toastify";
import { Grid } from "@mui/material";
import { createCommercial } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { verificarNif, verificarCorreo } from "../../../functions/validator/formValidator";



const NuevoComercial = () => {
  const [comercial, setComercial] = useState({});

  async function handleSubmit(data) {
    const toastInfo = toast.info("Guardando comercial");

    let nifCorrecto = verificarNif(data.nif);
    let correoCorrecto = verificarCorreo(data.email);
    let errores = false;

    if (Object.keys(data).length === 0) {
      toast.update(toastInfo, {
        render: "El consultor está vacío",
        type: toast.TYPE.ERROR,
        autoClose: true,
      });
      errores = true;
    } else {
      if (nifCorrecto === false && data.nif !== undefined) {
        toast.dismiss(toastInfo);
        toast.error("El NIF introducido es incorrecto");
        errores = true;
      }

      if (correoCorrecto === false && data.email !== undefined) {
        toast.dismiss(toastInfo);
        toast.error("El correo introducido es incorrecto");
        errores = true;
      }

      const requiredFields = ['email', 'nif', 'nombre', 'apellidos'];
      for (const field of requiredFields) {
        if (!data[field]) {
          toast.dismiss(toastInfo);
          toast.error("Hay algún campo vacío");
          errores = true;
          break;
        }
      }

      if (!errores) {
        
        try {
          let res = await createCommercial(data);

          if (res.status === 201) {
            toast.update(toastInfo, {
              render: "Comercial creado con éxito",
              type: toast.TYPE.SUCCESS,
              autoClose: true,
            });

            window.location.replace("/comerciales");
          } else if (res.status === 202) {
            toast.update(toastInfo, {
              render: "Error al crear el comercial",
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
          if(res.status === 403){
            toast.update(toastInfo, {
              render: "Este comercial ya existe",
              type: toast.TYPE.ERROR,
              autoClose: true,
            });
          }
          

        } catch (err) {
          
          toast.update(toastInfo, {
            render: "Ha ocurrido un error",
            type: toast.TYPE.ERROR,
            autoClose: true,
          });
          
        }
      }
    }
  }


  function handleChange(evt) {
    const value = evt.target.value;
    setComercial({
      ...comercial,
      [evt.target.name]: value,
    });
  }

  return (
    <>
      {/* <div
        className="pageHeader"
        style={{
          backgroundImage: `url(${HeaderBackground})`,
          textAlign: "left",
        }}
      >
        Comerciales / <b>Nuevo</b>
      </div> */}
      <BreadCrumb rutas={[{ path: `/comerciales`, label: `Comerciales` }, { path: `/comerciales`, label: `Nuevo` }]} style={{ margin: '0 10px' }} />
      <div className="pageContent">
        <div className="gridHead">Crear nuevo Comercial</div>
        <form className="nuevoAAFFForm">
          <Grid
            container
            spacing={5}
            style={{ marginTop: 20, marginBottom: 20 }}
          >
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="NIF"
                name="nif"
                value={comercial.nif}
                onChange={handleChange}
                pattern="(^[0-9]{8}[A-Z]{1})$|^([A-Z]{1}[0-9]{8})$|^([A-Z]{1}[0-9]{7}[A-Z]{1}$)"
                title="Introduce un NIF válido (CIF o DNI con letra mayúscula)"
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="email"
                required
                placeholder="Email"
                name="email"
                value={comercial.email}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Nombre"
                name="nombre"
                value={comercial.nombre}
                onChange={handleChange}
              ></input>
            </Grid>
            <Grid item xs={12} sm={6}>
              <input
                type="text"
                required
                placeholder="Apellidos"
                name="apellidos"
                value={comercial.apellidos}
                onChange={handleChange}
              ></input>
            </Grid>
          </Grid>
          <input
            type="button"
            value="Guardar y publicar"
            onClick={() => handleSubmit(comercial)}
          ></input>
        </form>
      </div>
    </>
  );
};

export default NuevoComercial;
