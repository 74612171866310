import React, { useState } from 'react';
import { PDFViewer, Document, Page, Image, Text, View, StyleSheet, Font, Svg, Link } from "@react-pdf/renderer";
import rgpd from '../../images/auditoria_lopd.jpg'
import contratos from '../../images/documentacion-contratos.png'
import Firma from '../../images/Firma-Lae-Consulting.jpg'
import { withRouter } from '../../providers/withRouter';
import { useEffect } from 'react';
import { getSale } from '../../services';
import { decryptParameter, formatoFechasEs } from '../../functions/funciones';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../images/LogoLAE.png'
import RobotoRegular from "../../fonts/Roboto-Regular.ttf"
import RobotoBold from "../../fonts/Roboto-Bold.ttf"
import RobotoItalic from "../../fonts/Roboto-Italic.ttf"
import RobotoBoldItalic from "../../fonts/Roboto-BoldItalic.ttf"
import { FaRegSquare } from "react-icons/fa6";
import { IconToSvg } from './IconToSvg';
import PDFHandler from "./PDFHandler";

Font.register({
    family: 'Roboto',
    fonts: [
      { src: RobotoItalic, fontWeight: 400 },
      { src: RobotoRegular, fontWeight: 400 },
      { src: RobotoBold, fontWeight: 700 },
      { src: RobotoBoldItalic, fontWeight: 800 },
    ]
});

const DocumentacionLOPD = (props) => {
    
        const styles = StyleSheet.create({
            docTitle: {
                position: 'absolute',
                left: 70,
                right: 70,
                bottom: '70px',
                textAlign: 'center',
                fontSize: '16px',
                color: '#B50655'|| '#023867',
                fontFamily: 'Roboto',
                fontWeight: 700,
                textTransform: 'uppercase'
            },
            body: {
                paddingBottom: 65,
                paddingHorizontal: '0cm',
            },
            h1: {
                marginBottom: '30px',
                fontSize: '14px',
                fontFamily: 'Roboto',
                fontWeight: 700
            },
             h2: {
                fontSize: '11px',
                fontFamily: 'Roboto',
                fontWeight: 700,
                marginTop: '10px',
                marginBottom: '10px',
            },
            h3: {
                fontSize: '12px',
                width: '100%',
                padding: '5px 10px',
                // backgroundColor: '#f8f8f8',
                textTransform: 'uppercase',
                // border: '1px solid #e6e6e6',
                // marginTop: '20px',
                marginBottom: '15px',
                fontFamily: 'Roboto',
                fontWeight: 700
            },
            h4: {
                // color: '#ab2a3e',
                marginBottom: '10px',
                marginTop: '20px',
                fontSize: '10px',
                padding: '5px 10px',
                fontFamily: 'Roboto',
                fontWeight: 700
            },
            h5: {
                fontSize: '9px',
                marginTop: '15px',
                marginBottom: '5px',
            },
            p: {
                lineHeight: '1.8',
                textAlign: 'justify',
                padding: '5px 10px',
                /* marginLeft:'2cm',
                marginRight:'2cm', */
                fontSize: '9px',
            },
            ul: {
                // lineHeight: '1.8',
                // fontSize:'7px',
                paddingLeft: 10,
                marginVertical: 8,
                flexDirection: "column", 
            },
            ol: {
                lineHeight: '1.8',
                flexDirection: "row", 
                marginBottom: 4,
                fontSize: '9px',
            },
            br: {
                display: 'block',
                margin: '10px 0 2px 0',
                lineHeight: '5px',
                content: " ",
            },
            table: { 
                display: "table", 
                width: "100%", 
                borderStyle: "solid", 
                borderWidth: 1, 
                borderRightWidth: 0, 
                borderBottomWidth: 0, 
                margin:'auto'
                // padding:'25px 0'
            }, 
            tableRow: { 
                margin: "auto", 
                flexDirection: "row" 
            }, 
            tableCol: { 
                width: "50%", 
                borderStyle: "solid", 
                borderWidth: 1, 
                borderLeftWidth: 0, 
                borderTopWidth: 0,
                display: 'flex',
                justifyContent:'center',
                alignItems:'left'
            }, 
            tableColIzq: { 
                width: "25%", 
                borderStyle: "solid", 
                borderWidth: 1, 
                borderLeftWidth: 0, 
                borderTopWidth: 0,
                display: 'flex',
                justifyContent:'center',
                alignItems:'left'
            }, 
            tableColDer: { 
                width: "75%", 
                borderStyle: "solid", 
                borderWidth: 1, 
                borderLeftWidth: 0, 
                borderTopWidth: 0,
                display: 'flex',
                justifyContent:'center',
                alignItems:'left'
            }, 
            tableCell: { 
                margin: "auto", 
                marginTop: 5, 
                marginBottom: 5, 
                marginLeft:2,
                marginRight: 2,
                fontSize: 8 
            },
            tableRowHeader: { 
                marginTop:"30px",
                margin: "auto", 
                flexDirection: "row" 
            }, 
            tableColHeader: { 
                width: "100%", 
                borderStyle: "solid", 
                borderWidth: 1, 
                borderLeftWidth: 0, 
                borderTopWidth: 0,
                backgroundColor: '#c31e64',
                color: '#ffffff',
                display: 'flex',
                justifyContent:'center',
                alignItems:'center',
                fontSize: 14 
            }, 
            lista: {
                content: '✔',
                fontSize: 11,
                fontFamily: 'Roboto',
                fontWeight: 700,
                color:'#000000'
            },
            textoNormal: {
                fontFamily: 'Roboto',
                fontWeight: 400,
                fontSize: 10,
                fontStyle: 'normal',
            },
            textoItalic: {
                fontFamily: 'Roboto',
                fontWeight: 400
            },
            textoNegritaItalic: {
                fontFamily: 'Roboto',
                fontWeight: 800
            },
            textoNegrita: {
                fontFamily: 'Roboto',
                fontWeight: 700
            },
            icon: {
                width: 10,
                height: 10,
            },
            cuadrado: {
                width:10,
                height:10,
                border: '1px solid #000'
            },
            linea: {
                width:'90%', 
                height:'15px', 
                borderBottom: '1px solid #000000'

            },
            pageHeader: {
                marginTop: 20, 
                paddingBottom: 20, 
                marginHorizontal: '1cm'
            }
        })

    const backgroundImageUrl = rgpd; 

    const [isLoading, setIsLoading] = useState(true)
    const [finca, setFinca] = useState({})
    const [aaff, setAAFF] = useState({})
    const [detailsLOPD, setDetailsLOPD] = useState({})
    const [ProvSinAcceso, setProvSinAcceso] = useState([])

    
    const fechaActual = new Date();
    const nuevaFecha = new Date(fechaActual);
    const fechaVenta = nuevaFecha.setFullYear(fechaActual.getFullYear() + 1);
    

    useEffect(()=>{
        fetchData()
    },[])


    const fetchData = async () => {
        
        const response = await getSale(props.params.id)
        setFinca(response.properties)
        setAAFF(response.aaff)
        setDetailsLOPD(response.detailsLopd.find( item => item.sale_id === response.id))
        setProvSinAcceso(response.detailsLopd.find( item => item.sale_id === response.id).provsSinAcceso)
        
        setIsLoading(false)
    }

    const text = "La comunidad de propietarios ha firmado un contrato de encargo del tratamiento con el administrador de la finca. La comunidad de propietarios ha firmado contratos de encargo del tratamiento con proveedores con acceso a datos.";

    const maxLength = 100;  // Ajusta según la longitud máxima permitida en una página

    const contentArray = text.match(new RegExp(`.{1,${maxLength}}`, 'g')) || [];

    return ( 
        <>
            {isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> :

                <PDFHandler style={{ width: "100%", height: "100vh" }}>
                    <Document size="A4" margin="2cm">
                        <Page>
                            <View>
                                <Image src={backgroundImageUrl} style={{ width: '100vw', height: '100vh' }}/>
                                <Text style={styles.docTitle}>
                            {finca.razonSocial}
                            </Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>1.INTRODUCCIÓN</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante, el RGPD), y por el que se deroga la Directiva 95/46/CE, tiene por objeto armonizar los tratamiento de datos personales de todos los ciudadanos de los Estados Miembros mediante la aplicación de una norma única, que garantice un nivel coherente de protección de las personas en toda la UE, así como evitar divergencias que dificulten la libre circulación de datos dentro del mercado interior.</Text>
                                <Text style={styles.p}>El RGPD proporciona seguridad jurídica y transparencia a los operadores económicos, incluidas las micro, pequeñas y medianas empresas, y ofrece a las personas físicas de todos los Estados Miembros el mismo nivel de derechos y obligaciones protegidos jurídicamente y el mismo nivel de responsabilidades para los responsables y encargados del tratamiento, con el fin de garantizar una supervisión coherente del tratamiento de datos personales. Asimismo, proporciona sanciones equivalentes para todos los Estados Miembros, así como también la cooperación efectiva de las Autoridades de Control.</Text>
                                <Text style={styles.p}>En España, el RGPD se ha desarrollado mediante la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y Garantía de los Derechos Digitales (en adelante, la LOPDGDD).</Text>
                                <Text style={styles.p}>Tanto el RGPD como la LOPDGDD establecen una serie de obligaciones para responsables y encargados del tratamiento, respecto a las operaciones de tratamiento. Una de ellas, es la realización de un registro de actividades de tratamiento.</Text>
                                <Text style={styles.p}>A pesar de que con la aplicación de la normativa vigente en materia de protección de datos desaparece la obligación de notificar la creación de ficheros ante la Agencia Española de Protección de Datos (en adelante, la AEPD), en su lugar surgen un conjunto de medidas de carácter interno que el responsable y el encargado de los tratamientos deberán impulsar y tener documentadas a disposición de la AEPD. Estas medidas se concretan en el registro de actividades de tratamiento. El presente documento tiene como objeto documentar el registro de actividades de tratamiento en virtud a lo establecido por los artículos 30 de la RGPD y 31 de la LOPDGDD. Asimismo, mediante el presente documento, se analizan supuestos específicos en relación con el tratamiento de datos personales que pueden darse en una comunidad de propietarios, y que requieren de especial atención.</Text>
                                <Text style={styles.p}>El presente documento se mantendrá en todo momento actualizado por el delegado de protección de datos y en su defecto, por el responsable de privacidad. Debe ser revisado siempre que se produzcan cambios relevantes en la organización de la comunidad de propietarios.</Text>
                            </View>
                            <View style={{marginBottom: 20, paddingTop: 20, marginHorizontal: '1cm'}} fixed></View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>2. OBLIGACIONES EN MATERIA DE PROTECCIÓN DE DATOS</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>La normativa vigente en materia de protección de datos, así como la AEPD, establecen una serie de obligaciones para las comunidades de propietarios. A continuación, se enumeran las obligaciones que serán desarrolladas en los subsiguientes apartados del presente documento. </Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, { textDecoration: 'underline'}]}>Registro de actividades de tratamiento:</Text> La comunidad de propietarios de acuerdo con lo estipulado por los artículos 30 del RGPD y 31 de la LOPDGDD, deberá llevar a cabo un registro de los tratamientos en calidad de responsable de los datos personales. Este registro se encuentra ya elaborado en el apartado cuarto del presente informe. Asimismo, se requiere el desarrollo de un registro por parte de los administradores que actúan como encargados del tratamiento. Dicho registro se encuentra disponible en el apartado 5 del presente documento.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, { textDecoration: 'underline'}]}>Derecho de información:</Text> Deberá darse cumplimiento al deber de informar en el momento de recogida de los datos personales. En el apartado 7 del presente documento se ha introducido la cláusula informativa sobre el tratamiento de los datos personales de los propietarios y en el apartado 8, sobre empleados. Dichas cláusulas siguen las instrucciones de la AEPD, constando de una primera capa con información básica y una segunda capa con información desarrollada.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, { textDecoration: 'underline'}]}>Principios RGPD:</Text> Es importante recordar que el tratamiento de los datos personales se llevará a cabo conforme a los principios de licitud, lealtad, transparencia, limitación de la finalidad, minimización de los datos, exactitud, limitación del plazo de conservación, integridad, confidencialidad y responsabilidad proactiva.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, { textDecoration: 'underline'}]}>Conservación de los datos:</Text> Deberá procederse a la supresión de los datos personales cuando así lo solicite el titular de los mismos. No obstante, la normativa de protección de datos permite la conservación de los mismos durante el tiempo en el que pueda exigirse algún tipo de responsabilidad jurídica, o de la ejecución de un contrato.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, { textDecoration: 'underline'}]}>Comunicación de los datos personales a terceros:</Text> Con carácter general, queda prohibida la cesión o comunicación de los datos personales a terceras personas, salvo en alguno de los siguientes supuestos:</Text>
                                    </View>
                                        <View style={styles.ul}>
                                            <View style={[styles.ol, {paddingLeft: 20}]}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text style={{ width:280 }}>Consentimiento del titular de los datos personales.</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 20}]}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text style={{ width:280 }}>Cumplimiento de una obligación legal.</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 20}]}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text style={{ width:280 }}>Ejecución de un contrato.</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 20}]}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text style={{ width:280 }}>Misión realizada en interés público.</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 20}]}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text style={{ width:280 }}>Intereses vitales.</Text>
                                            </View>
                                            <View style={[styles.ol, {paddingLeft: 20}]}>
                                                <Text style={{ marginHorizontal: 8 }}>•</Text>
                                                <Text style={{ width:280 }}>Interés legítimo.</Text>
                                            </View>
                                        </View>
                                </View>
                            </View>
                            {/* <View style={{marginBottom: 20, paddingTop: 20, marginHorizontal: '1cm'}} fixed></View> */}
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>3. REGISTRO DE ACTIVIDADES DE TRATAMIENTO</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>De acuerdo con lo establecido por los artículos 30 del RGPD y 31 de la LOPDGDD, cada responsable del tratamiento llevará un registro de las actividades de tratamiento efectuadas bajo su responsabilidad. Dicho registro deberá contener toda la información indicada a continuación:</Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>El nombre y los datos de contacto del responsable y, en su caso, del corresponsable, del representante del responsable, y del delegado de protección de datos.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Los fines del tratamiento.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Una descripción de las categorías de interesados y de las categorías de datos personales.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Las categorías de destinatarios a quienes se comunicaron o comunicarán los datos personales, incluidos los destinatarios en terceros países u organizaciones internacionales.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>En su caso, las transferencias de datos personales a un tercer país o una organización internacional.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Cuando sea posible, los plazos previstos para la supresión de las diferentes categorías de datos.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Cuando sea posible, una descripción general de las medidas técnicas y organizativas de seguridad. </Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Asimismo, cada encargado del tratamiento deberá llevar un registro de todas las categorías de actividades de tratamiento efectuadas por cuenta de un responsable que contenga: </Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>El nombre y los datos de contacto del encargado o encargados y de cada responsable por cuenta del cual actúe el encargado, y, en su caso, el representante del responsable o del encargado, y del delegado de protección de datos.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Las categorías de tratamiento efectuados por cuenta de cada responsable.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>En su caso, las transferencias de datos personales a un tercer país u organización internacional.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={{ marginHorizontal: 8 }}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Cuando sea posible, una descripción general de las medidas técnicas y organizativas de seguridad.</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>Con la aplicación de la normativa vigente en materia de protección de datos, desaparece la obligación de notificar la creación de ficheros. Por lo que las comunidades de propietarios no deben realiza dicho trámite. En su lugar surgen un conjunto de medidas de carácter interno que el responsable y el encargado de los tratamientos deberán impulsar y tener documentadas a disposición de la AEPD. Estas medidas se concretan en el registro de actividades de tratamiento.</Text>
                                <Text style={styles.p}>A este respecto, todas las comunidades de propietarios deberán tener el registro de actividades referente a los tratamientos de propietarios, videovigilancia y/o empleados. Por su parte, los administradores de fincas, en cuanto actúan en calidad de encargados del tratamiento también tendrán que configurar su registro de actividades de tratamiento en relación con las comunidades de propietarios a las que prestan su servicio. Es decir, el administrador de fincas, como encargado del tratamiento de datos personales de una comunidad de propietarios, llevará un registro de todas las categorías de actividades de tratamiento efectuadas por cuenta del responsable que contenga la información facilitada anteriormente.</Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>4. REGISTRO DE ACTIVIDADES DE TRATAMIENTO POR CUENTA DEL RESPONSABLE</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>De acuerdo a lo indicado por el Comité Europeo de Protección de datos, el registro de actividades de tratamiento deber considerarse como una herramienta que permita al responsable y a la autoridad de control, si así lo solicitan, tener una perspectiva general de todas las actividades de tratamiento de los datos personales que una organización está llevando a cabo. Es por tanto, un requisito previo para la observancia de las normas y, como tal, una medida efectiva de rendición de cuentas. </Text>
                                <Text style={styles.p}>A continuación, se detalla cada uno de los tratamientos que se llevan a cabo en la comunidad de propietarios: </Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>4.1 PROPIETARIOS</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Responsable del tratamiento:</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>La comunidad de propietarios {finca.razonSocial} con CIF {finca.nif} y dirección en  {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia})</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Base jurídica</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>6.1. c) Tratamiento necesario para el cumplimiento de una obligación legal aplicable al responsable del tratamiento.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Fines del tratamiento</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Asegurar el correcto desenvolvimiento de la comunidad.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Categorías de Datos</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Nombre y apellidos, DNI/NIF/Documento identificativo, dirección, firma, teléfono, correo electrónico y datos bancarios. Así como cualquier otro dato que sea necesario para el correcto desenvolvimiento de la comunidad.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Categoría destinatarios</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Entidades bancarias, Hacienda Pública o Autonómica, entidades aseguradoras, administrador de fincas.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Categoría de afectados</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Propietarios</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Transferencias internacionales</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>No están previstas transferencias internacionales de los datos o a países de fuera de la UE o que no ofrezcan un nivel equivalente de protección.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Plazo supresión</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Los datos personales serán conservados durante el tiempo legalmente establecido.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableColIzq, { justifyContent: 'center' }]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>Medidas de seguridad</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>
                                                <Text>La comunidad de propietarios ha firmado un contrato de encargo del tratamiento con el administrador de la finca.{'\n'}{'\n'}</Text>    
                                                <Text>La comunidad de propietarios ha firmado contratos de encargo del tratamiento con proveedores con acceso a datos.{'\n'}{'\n'}</Text>    
                                                <Text>Se ha facilitado información completa y transparente sobre el tratamiento de los datos personales de los propietarios{'\n'}{'\n'}</Text>    
                                                <Text>La comunidad de propietarios no podrá publicar la deuda de un vecino en el tablón de anuncios. Tampoco se permite la comunicación singularizada de las deudas de los vecinos.{'\n'}{'\n'}</Text>    
                                                <Text>La comunidad de propietarios ha firmado los correspondientes contratos con los proveedores de servicios sin acceso a datos (empresas proveedoras de los servicios de limpieza, seguridad, extintores, etc.).{'\n'}{'\n'}</Text>    
                                                <Text>Se gestiona correctamente cualquier ejercicio de derecho por parte de un propietario.{'\n'}{'\n'}</Text>    
                                                <Text>No se transfieren datos personales a países que no guarden un nivel adecuado de protección, equivalente al reconocido por la legislación en la UE.{'\n'}{'\n'}</Text>    
                                                <Text>Se han establecido procedimientos para garantizar que la información sólo está accesible a personal autorizado.{'\n'}{'\n'}</Text>    
                                            </Text> 
                                        </View> 
                                    </View>
                                </View>
                            </View>
                        </Page>
                        {detailsLOPD.maintenance ?
                            <Page style={styles.body}>
                                <View style={styles.pageHeader} fixed>
                                    <View >
                                        <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                    </View>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <Text style={styles.h3}>4.2 VIDEOVIGILANCIA</Text>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <View style={styles.table}>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Responsable del tratamiento:</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>La comunidad de propietarios {finca.razonSocial} con CIF {finca.nif} y dirección en  {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia})</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Base jurídica</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>6.1.f) El tratamiento es necesario para la satisfacción de intereses legítimos perseguidos por el responsable del tratamiento o por un tercero, siempre que sobre dichos intereses no prevalezcan los intereses o los derechos y libertades fundamentales del interesado que requieran la protección de datos personales.{'\n'}</Text> 
                                                <Text style={styles.tableCell}>6.1.C) Ejecución del contrato laboral que el Responsable del Tratamiento mantiene en vigor con sus empleados</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Fines del tratamiento</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Mantener la seguridad patrimonial y la prevención y control de la comisión de hechos delictivos y/o controlar la actividad de los empleados.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Categorías de Datos</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Imágenes de propietarios y/o inquilinos, empleados y personas que accedan al inmueble.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Categoría destinatarios</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Se prevén cesiones siempre que existas encargados del tratamiento con respecto de las imágenes de videovigilancia. Estos encargados del tratamiento pueden ser los siguientes:</Text> 
                                                <Text style={styles.tableCell}>• Empresa proveedora del mantenimiento de las cámaras de videovigilancia.</Text> 
                                                <Text style={styles.tableCell}>• Administrador de fincas.</Text> 
                                                <Text style={styles.tableCell}>• Fuerzas y Cuerpos de Seguridad.</Text> 
                                                <Text style={styles.tableCell}>• Juzgados y Tribunales.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Categoría de afectados</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Personas que accedan al inmueble.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Transferencias internacionales</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>No están previstas transferencias internacionales de los datos a países de fuera de la UE o que no ofrezcan un nivel equivalente de protección.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Plazo supresión</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Los datos serán conservador durante un plazo máximo de un mes desde su captación. Las imágenes que se utilicen para denunciar delitos o infracciones se acompañarán a la denuncia y será posible su conservación para ser entregadas a las Fuerzas y Cuerpos de Seguridad o a los Juzgados y Tribunales que las requieren.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={[styles.tableCol, { justifyContent: 'center' }]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Medidas de seguridad</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>
                                                    <Text>Los datos personales se encuentran accesibles sólo al personal autorizado.{'\n'}{'\n'}</Text>    
                                                    <Text>El personal autorizado únicamente podrá acceder a las imágenes mediante un código de usuario y una contraseña.{'\n'}{'\n'}</Text>    
                                                    <Text>Una vez instalado el sistema, se deberá realizar un cambio regular de la contraseña, evitando las fácilmente deducibles{'\n'}{'\n'}</Text>    
                                                    <Text>Si el acceso se realiza con conexión a internet, se restringirá igualmente con un código de usuario y una contraseña (o cualquier otro medio que garantice la identificación y autenticación unívoca), que solo serán conocidas por las personas autorizadas a acceder a las imágenes. En ningún caso resultarán accesibles a los vecinos mediante canal de televisión comunitaria.{'\n'}{'\n'}</Text>    
                                                    <Text>Se deberán establecer carteles que informar de que se accede a una zona videovigilada. El cartel informará de forma clara que se trata de una zona videovigilada, la identidad del responsable del tratamiento y ante quién y dónde dirigirse para ejercer los derechos que prevé la normativa de protección de datos.{'\n'}{'\n'}</Text>    
                                                    <Text>No se transfieren datos personales a países que no guarden un nivel adecuado de protección, equivalente al reconocido por la legislación en la UE.</Text>    
                                                </Text> 
                                            </View> 
                                        </View>
                                    </View>
                                </View>
                            </Page>
                        : null}
                        {detailsLOPD.workers ? 
                            <Page style={styles.body}>
                                <View style={styles.pageHeader} fixed>
                                    <View >
                                        <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                    </View>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <Text style={styles.h3}>4.3 EMPLEADOS</Text>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <View style={styles.table}>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Responsable del tratamiento:</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>La comunidad de propietarios {finca.razonSocial} con CIF {finca.nif} y dirección en  {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia})</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Base jurídica</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>6.1.b) Tratamiento necesario para la ejecución de un contrato en el que el interesado es parte o para la aplicación a petición de éste de medidas precontractuales. Real Decreto Legislativo 2/2015, de 23 de octubre, por el que se aprueba el texto refundido de la Ley del Estatuto de los Trabajadores.{'\n'}</Text> 
                                                <Text style={styles.tableCell}>6.1.c) El tratamiento es necesario para el cumplimiento de una obligación legal.</Text> 
                                                <Text style={styles.tableCell}>6.1.a) El interesado dio su consentimiento para el tratamiento de datos personales.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Fines del tratamiento</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Gestión de la relación laboral, y más concretamente:{'\n'}</Text> 
                                                <Text style={styles.tableCell}>• Tomar una decisión sobre la contratación. </Text> 
                                                <Text style={styles.tableCell}>• Pagar la nómina y/o retribuciones que correspondan, así como aplicar las deducciones y retenciones que legalmente correspondan.</Text> 
                                                <Text style={styles.tableCell}>• Tomar decisiones respecto a revisiones salariales.</Text> 
                                                <Text style={styles.tableCell}>• Evaluación de posibles infracciones laborales y, en su caso, aplicación de las correspondientes y faltas disciplinarias.</Text> 
                                                <Text style={styles.tableCell}>• Llevar a cabo evaluaciones del empelado, para una tarea en particular y/o promoción profesional.</Text> 
                                                <Text style={styles.tableCell}>• Gestionar los procesos de formación</Text> 
                                                <Text style={styles.tableCell}>• Cumplir con las obligaciones en materia de salud, prevención de riesgos y seguridad en el empleo.</Text> 
                                                <Text style={styles.tableCell}>• Gestionar incidentes legales, incluidos los accidentes en el trabajo.</Text> 
                                                <Text style={styles.tableCell}>Gestionar y mantener un registro de bajas o ausencias.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Categorías de Datos</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Nombre y apellidos, DNI/CIF/Documento identificativo, número de registro de personal, número de Seguridad Social/Mutualidad, dirección, firma y teléfono y datos bancarios.</Text> 
                                                <Text style={styles.tableCell}>Bajas, historial de apercibimientos, sanciones y expedientes disciplinarios, datos de control de presencia, información y documentación del proceso de selección, contacto de emergencia, nómina.</Text> 
                                                <Text style={styles.tableCell}>Categorías especiales de datos: datos de salud (bajas por enfermedad, accidentes laborales y grado de discapacidad, sin inclusión de diagnósticos), afiliación sindical, a los exclusivos efectos del pago de cuotas sindicales (en su caso), representante sindical (en su caso), justificantes de asistencia de propios y de terceros.</Text> 
                                                <Text style={styles.tableCell}>Imágenes de videovigilancia.</Text> 
                                                <Text style={styles.tableCell}>Datos biométricos en caso de que la comunidad obtenga huellas dactilares para el control horario.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Categoría destinatarios</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Se prevén cesiones siempre que existan encargados del tratamiento con respecto de los datos de empleados. Estos encargados del tratamiento pueden ser los siguientes: asesores laborales y el servicio de prevención ajeno.</Text> 
                                                </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Categoría de afectados</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>Empleados.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Transferencias internacionales</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>No están previstas transferencias internacionales de los datos a países de fuera de la UE o que no ofrezcan un nivel equivalente de protección.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={styles.tableCol}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Plazo supresión</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>El responsable del tratamiento conservará los datos de carácter personal de los empleados durante la vigencia de la relación laboral. Y una vez finalizada la misma, conservará la información imprescindible durante un periodo máximo de cuatro años, periodo legalmente exigible en materia laboral y de seguridad social salvo que aplique cualquiera de las excepciones a la prescripción de acciones en dichas áreas, en cuyo caso podrá extenderse dicho periodo de conservación por el periodo que resulte aplicable.</Text> 
                                            </View> 
                                        </View>
                                        <View style={styles.tableRow}> 
                                            <View style={[styles.tableCol, { justifyContent: 'center' }]}> 
                                                <Text style={[styles.tableCell, styles.textoNegrita]}>Medidas de seguridad</Text> 
                                            </View> 
                                            <View style={styles.tableCol}> 
                                                <Text style={styles.tableCell}>
                                                    <Text>Los datos relativos a los empleados se encuentran accesibles sólo al personal autorizado.{'\n'}{'\n'}</Text>    
                                                    <Text>Se han firmado los correspondientes contratos de encargo del tratamiento con los proveedores con acceso a datos de empleados.{'\n'}{'\n'}</Text>    
                                                    <Text>El empleado ha sido informado sobre el tratamiento que se realiza sobre sus datos personales.{'\n'}{'\n'}</Text>    
                                                    <Text>Los empleados han firmado el correspondiente acuerdo de confidencialidad.{'\n'}{'\n'}</Text>    
                                                    <Text>Se han establecido procedimientos para garantizar que no se produzcan accesos no autorizados a datos personales.{'\n'}{'\n'}</Text>    
                                                    <Text>Se gestiona correctamente cualquier ejercicio de derechos por parte de un empleado.</Text>    
                                                    <Text>No se transfieren datos personales a países que no guarden un nivel adecuado de protección, equivalente al reconocido por la legislación en la UE.</Text>    
                                                </Text> 
                                            </View> 
                                        </View>
                                    </View>
                                </View>
                            </Page>
                        : null}
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>5. REGISTRO DE ACTIVIDADES DE TRATAMIENTO POR CUENTA DEL ENCARGADO (ADMINSITRADOR DE FINCAS)</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>En el régimen de propiedad horizontal, el administrador de fincas, que ejerce la administración de una o varias comunidades por encargo del responsable, actúa como encargado del tratamiento.</Text>
                                <Text style={styles.p}>Las principales obligaciones establecidas por la normativa de protección de datos recaen sobre el responsable del tratamiento, si bien los administradores de fincas, tanto en el ámbito de su función de asesoramiento y representación de las comunidades de propietarios, como actuando como encargados del tratamiento, deberán facilitar su cumplimiento y participar del mismo. Así para que la relación entre comunidad de propietarios (responsable del tratamiento) y el administrador (encargado del tratamiento) se ajuste a la normativa de protección de datos, es preciso que se cumplan con las obligaciones establecidas por la normativa.</Text>
                                <Text style={styles.p}>Entre las obligaciones del administrador de fincas como encargado del tratamiento se encuentra la obligación de llevar un registro de todas las categorías de actividades de tratamiento efectuadas por cuenta de un responsable.</Text>
                            </View>
                            
                            <View style={{ marginHorizontal: '1cm' }}>                             
                                 <View style={styles.table}>
                                    <View style={styles.tableRowHeader}> 
                                        <View style={styles.tableColHeader}> 
                                            <Text style={styles.tableCell}>Encargado del tratamiento: {aaff.razonSocial} con CIF: {aaff.nif} </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>RESPONSABLE DEL TRATAMIENTO</Text> 
                                        </View> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>TRATAMIENTOS REALIZADOS</Text> 
                                        </View> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>TRANSFERENCIAS INTERNACIONALES DE DATOS SI/NO</Text> 
                                        </View> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>MEDIDAS DE SEGURIDAD</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={styles.tableCell}>La comunidad de propietarios {finca.razonSocial} con CIF {finca.nif} </Text> 
                                        </View> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={styles.tableCell}>Acceso, conservación y uso de los datos personales de propietarios, empleados y videovigilancia de la comunidad de propietarios.</Text> 
                                        </View> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={styles.tableCell}>No.</Text> 
                                        </View> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={styles.tableCell}>
                                                <Text>Cuando el mismo ordenador o dispositivo se utilice para el tratamiento de datos personales y fines de uso personal se recomienda disponer de varios perfiles o usuarios distintos para cada una de las finalidades.{'\n'}{'\n'}</Text>    
                                                <Text>Se recomienda disponer de perfiles con derechos de administración para la instalación y configuración del sistema y usuarios sin privilegios o derechos de administración para el acceso a los datos personales.{'\n'}{'\n'}</Text>    
                                                <Text>Se garantizará la existencia de contraseñas para el acceso a los datos personales almacenados en sistemas electrónicos.{'\n'}{'\n'}</Text>    
                                                <Text>Cuando a los datos personales accedan distintas personas, para cada persona con acceso a los datos personales, se dispondrá de un usuario y contraseña específicos.{'\n'}{'\n'}</Text>    
                                                <Text>Se debe garantizar la confidencialidad de las contraseñas evitando que queden expuestas a terceros.{'\n'}{'\n'}</Text>    
                                                <Text>Establecer medidas técnicas de salvaguarda, como por ejemplo, actualización de ordenadores y dispositivos, establecimiento de Malware, Firewall, cifrado de datos y copias de seguridad.{'\n'}{'\n'}</Text>    
                                                <Text>Se debe garantizar el deber de confidencialidad respecto a los datos personales de la comunidad de propietarios.{'\n'}{'\n'}</Text>    
                                                <Text>Se deberán tratar los datos personales únicamente siguiendo las instrucciones del responsable.{'\n'}{'\n'}</Text>    
                                                <Text>Se asistirá al responsable, a través de medidas técnicas organizativas apropiadas, para que este pueda cumplir con su obligación de responder a solicitudes que tenga por objeto el ejercicio de los derechos de los interesados.{'\n'}{'\n'}</Text>    
                                                <Text>No se transfieren datos personales a países que no guarden un nivel adecuado de protección, equivalente al reconocido por la legislación en la UE.{'\n'}{'\n'}</Text>    
                                            </Text> 
                                        </View> 
                                    </View>
                                </View>
                            </View>
                        </Page>
                        {detailsLOPD.maintenance ?
                            <Page style={styles.body}>
                                <View style={styles.pageHeader} fixed>
                                    <View >
                                        <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                    </View>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <Text style={styles.h3}>6. INFORMACIÓN ADICIONAL SOBRE EL TRATAMIENTO DE VIDEOVIGILANCIA</Text>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <Text style={styles.p}>En conformidad con lo declarado por la AEPD, se instalarán en los distintos accesos a la zona videovigilada y, en un lugar visible, uno o varios carteles que informen de que se accede a una zona videovigilada. El cartel indicará de forma clara que se trata de una zona videovigilada, la identidad del responsable del tratamiento y ante quien y dónde dirigirse para ejercer los derechos que prevé la normativa de protección de datos.</Text>
                                    <Text style={styles.p}>Asimismo, se pondrá a disposición de los afectados la restante información que exige la legislación de protección de datos. La información puede estar disponible en conserjería, recepción, oficinas, tablones de anuncios o ser accesible a través de internet.</Text>
                                    <Text style={styles.p}>En caso de que así lo solicite el interesado, deberá proporcionarse la información adicional sobre el tratamiento de videovigilancia. A continuación, se facilita el documento de información adicional sobre el tratamiento de videovigilancia:</Text>
                                    <Text style={styles.p}>A modo excepcional, cuando no exista constancia del domicilio de algún propietario, al no haber facilitado este dato a la comunidad, se entenderá por domicilio a efectos de citaciones y notificaciones el piso o local perteneciente a la comunidad. Si intentada una citación o notificación al propietario fuese imposible practicarla, se podrá publicar en el tablón de anuncios de la comunidad o en otro lugar visible de uso general habilitado al efecto, la Convocatoria de la Junta de Propietarios. Para este supuesto, deberán hacerse constar, junto con el documento publicado, los motivos de dicha publicación, extendiéndose diligencia por la persona que ejerza las funciones de secretario.</Text>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <Text style={styles.h3}>6.1 INFORMACIÓN ADICIONAL SOBRE EL TRATAMIENTO DE VIDEOVIGILANCIA</Text>
                                </View>
                                <View style={{ marginHorizontal: '1cm' }}>
                                    <Text style={styles.p}>{finca.razonSocial} desea informarle de manera transparente, a través de la presente Política de Privacidad, sobre todos los tratamientos que realizamos con sus datos, las finalidades para las cuales los tratamos y los derechos que como interesado puede ejercer sobre sus datos personales, así como el tiempo durante el cual conservaremos éstos.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Quién es el responsable del tratamiento de tus datos?</Text>
                                    <Text style={styles.p}>Responsable del tratamiento: {finca.razonSocial}</Text>
                                    <Text style={styles.p}>Dirección postal {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia})</Text>
                                    <Text style={styles.p}>Dirección de correo electrónico: {finca.emailContacto}</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué es un dato y un tratamiento de datos de carácter personal?</Text>
                                    <Text style={styles.p}>Un dato personal es toda información sobre una persona física identificada o identificable. Se considera persona física identificable toda persona cuya identidad pueda determinarse directa o indirectamente, en particular mediante un identificador, como por ejemplo un nombre, un número de identificación, datos de localización, un identificador en línea o uno o varios elementos propios de la identidad física, fisiológica, genética, psíquica, económica, cultural o social de dicha persona.</Text>
                                    <Text style={styles.p}>Asimismo, el tratamiento de datos personales implica cualquier operación o conjunto de operaciones realizadas sobre datos personales o conjuntos de datos personales, ya sea por procedimientos automatizados o no, como la recogida, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, utilización, comunicación por transmisión, difusión o cualquier otra forma de habilitación de acceso, cotejo o interconexión, limitación, supresión o destrucción.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué datos captamos?</Text>
                                    <Text style={styles.p}>La comunidad de propietarios captará su imagen.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué tratamiento realizamos sobre sus datos personales y para qué finalidades tratamos los datos?</Text>
                                    <Text style={styles.p}>La actividad de tratamiento llevada por la comunidad es la videovigilancia. Asimismo, dicho tratamiento tiene como finalidad:</Text>
                                    <View style={styles.ul}>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>a)</Text>
                                            <Text style={{marginRight: '2cm'}}>Mantener la seguridad patrimonial y la prevención y control de la comisión de hechos delictivos.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>b)</Text>
                                            <Text style={{marginRight: '2cm'}}>Controlar la actividad de los empleados.</Text>
                                        </View>
                                    </View>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Cuál es la legitimación para el tratamiento de los datos?</Text>
                                    <Text style={styles.p}>De acuerdo con la normativa vigente en materia de protección de datos, necesitamos una base de legitimación para el tratamiento de sus datos personales.</Text>
                                    <Text style={styles.p}>Cuando el tratamiento de videovigilancia se realiza con la finalidad de mantener la seguridad de las personas, bienes e instalaciones, la base de legitimación es el interés público de {finca.nif}</Text>
                                    <Text style={styles.p}>Cuando el tratamiento de videovigilancia se realiza con la finalidad de controlar la actividad de los empleados, la base que legitima el tratamiento es la ejecución del contrato laboral suscrito entre las partes.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Durante cuánto tiempo tratamos los datos?</Text>
                                    <Text style={styles.p}>Conservaremos sus datos personales durante el plazo de un mes, salvo comunicación necesaria a las Fuerzas y Cuerpos de Seguridad, y/o Juzgados y Tribunales.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué derechos se pueden ejercitar?</Text>
                                    <Text style={styles.p}>Como interesado, puede ejercitar los siguientes derechos:</Text>
                                    <View style={styles.ul}>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de acceso: Tiene derecho a obtener por parte de {finca.razonSocial} confirmación de si se están tratando o no sus datos personales, y en su caso, qué datos trata.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de rectificación: Tiene derecho a obtener por parte de {finca.razonSocial}, la rectificación de los datos personales inexactos que le conciernan. En la solicitud de rectificación deberá indicar los datos que desea que se modifiquen.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de oposición: Tiene derecho a oponerse en cualquier momento, por motivos relacionados con su situación particular, a que datos personales que le conciernan sean objeto de un tratamiento basado en el interés legítimo de {finca.razonSocial}. En ese caso, {finca.razonSocial} dejará de tratar los datos personales, salvo que acreditemos motivos legítimos imperiosos para el tratamiento que prevalezcan sobre sus intereses, derechos y libertades, o para la formulación, el ejercicio o la defensa de reclamaciones.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de supresión: Tiene derecho a obtener por parte de {finca.razonSocial}, la supresión de los datos personales que le conciernan</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de portabilidad de los datos: Tiene derecho a que {finca.razonSocial} transfiera sus datos personales a otro responsable del tratamiento. Solo es posible ejercer este derecho cuando el tratamiento esté basado en la ejecución de un contrato o en su consentimiento y el tratamiento se realice por medios automatizados</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de limitación del tratamiento: Tiene derecho a solicitarnos que suspendamos el tratamiento de sus datos cuando: impugne la exactitud de los datos, mientras {finca.razonSocial} verifica dicha exactitud; haya ejercido el derecho de oposición al tratamiento de sus datos, mientras que se verifica si los motivos legítimos de {finca.razonSocial} prevalecen sobre los suyos como interesado.</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.p}>Puede ejercer sus derechos enviándonos su solicitud a la siguiente dirección postal {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia}) o a través de la dirección de correo electrónico {finca.emailContacto}.</Text>
                                    <Text style={styles.p}>Junto a su solicitud deberá aportar copia de su DNI o pasaporte. </Text>
                                    <Text style={styles.p}>Igualmente, le informamos de que en caso de que no quede satisfecho, puede presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD)</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿A quién se pueden comunicar los datos personales?</Text>
                                    <Text style={styles.p}>La comunidad de propietarios podrá ceder sus datos a las Fuerzas y Cuerpos de Seguridad, así como a Juzgados y Tribunales.</Text>
                                    <Text style={styles.p}>Asimismo, no se prevén transferencias internacionales.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>Contacto</Text>
                                    <Text style={styles.p}>Si tiene dudas sobre el tratamiento que realizamos sobre sus datos personales, puede ponerse en contacto a través de la dirección de correo electrónico {finca.emailContacto}.</Text>
                                </View>
                            </Page>
                        : null }
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>7. CLÁUSULA INFORMATIVA PARA PROPIETARIOS</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>Con el fin de cumplir con el deber de informar estipulado por la normativa vigente en materia de protección de datos, a continuación se facilita la cláusula actualizada conforme a las instrucciones de la Agencia Española de Protección de Datos.</Text>
                                <Text style={[styles.p, {textDecoration:'underline', lineHeight: 1.2}]}>AVISO: La AEPD, en su Guía para el cumplimiento del deber de informar, indica que para cumplir con el deber de informar, la información debe facilitarse en dos capas o niveles. Una primera capa que contiene información de forma resumida en el mismo momento y en el mismo medio en que se recojan los datos y una segunda capa con información adicional donde se prestarán detalladamente el resto de las informaciones y deberá estar disponible para el propietario si así lo solicita vía correo electrónico o correo postal. Asimismo, la AEPD en la Guía sobre el deber de informar establece que "la presentación preferente de esta primera capa es en forma de tabla".</Text>
                                <Text style={styles.h4}>7.1 INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS </Text>
                                
                                <Text style={styles.br} />
                                <Text style={[styles.p, {textDecoration:'underline', textAlign:'center', lineHeight: 1.2}]}>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS PARA ROPIETARIOS Y/O INQUILINOS</Text>
                                <Text style={[styles.p, {textDecoration:'underline', textAlign:'center', lineHeight: 1.2}]}>(A incluir en el acta de las juntas)</Text>
                                <Text style={styles.p}>Con el objeto de dar cumplimiento a la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, así como a las recomendaciones e instrucciones emitidas por la Agencia Española de Protección de Datos (en adelante, la AEPD), la comunidad de propietarios {finca.razonSocial}, le informa sobre el tratamiento de sus datos personales</Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRowHeader}> 
                                        <View style={styles.tableColHeader}> 
                                            <Text style={[styles.tableCell, styles.textoItalic, {textDecoration:'underline'}]}>INFORMACIÓN BÁSICA SOBRE PROTECCIÓN DE DATOS </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>RESPONSABLE</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Comunidad de propietarios {finca.razonSocial} con CIF {finca.nif} y dirección postal {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia}).</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>FINALIDAD</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Asegurar el correcto desenvolvimiento de la comunidad.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>LEGITIMACIÓN</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Cumplimiento de una obligación legal.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>DESTINATARIOS</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Sus datos no serán comunicados a terceros, salvo a los administradores de fincas y/o proveedores de servicios con acceso a datos, necesarios para el correcto desenvolvimiento de la comunidad.</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>DERECHOS</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Puede ejercer sus derechos a acceder, rectificar, oponerse, limitar, portar y suprimir los datos ante el responsable del tratamiento; además de acudir a la autoridad de control competente (AEPD).</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableColIzq}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita]}>INFORMACIÓN ADICIONAL</Text> 
                                        </View> 
                                        <View style={styles.tableColDer}> 
                                            <Text style={styles.tableCell}>Puede solicitar información adicional sobre el tratamiento de sus datos personales a través de la dirección de correo electrónico {finca.emailContacto}.</Text> 
                                        </View> 
                                    </View>
                                </View>
                                <Text style={styles.h4}>7.2 INFORMACIÓN ADICIONAL SOBRE PROTECCIÓN DE DATOS</Text>
                                <Text style={[styles.p, {textDecoration:'underline', textAlign:'center', lineHeight: 1.2}]}>INFORMACIÓN ADICIONAL SOBRE PROTECCIÓN DE DATOS</Text>
                                <Text style={styles.p}>{finca.razonSocial} desea informarle de manera transparente, a través de la presente Política de Privacidad, sobre todos los tratamientos que realizamos con sus datos, las finalidades para las cuales los tratamos y los derechos que como interesado puede ejercer sobre sus datos personales, así como el tiempo durante el cual conservaremos éstos.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Quién es el responsable del tratamiento de tus datos?</Text>
                                    <Text style={styles.p}>Responsable del tratamiento: {finca.razonSocial}</Text>
                                    <Text style={styles.p}>Dirección postal {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia})</Text>
                                    <Text style={styles.p}>Dirección de correo electrónico: {finca.emailContacto}</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué es un dato y un tratamiento de datos de carácter personal?</Text>
                                    <Text style={styles.p}>Un dato personal es toda información sobre una persona física identificada o identificable. Se considera persona física identificable toda persona cuya identidad pueda determinarse directa o indirectamente, en particular mediante un identificador, como por ejemplo un nombre, un número de identificación, datos de localización, un identificador en línea o uno o varios elementos propios de la identidad física, fisiológica, genética, psíquica, económica, cultural o social de dicha persona.</Text>
                                    <Text style={styles.p}>Asimismo, el tratamiento de datos personales implica cualquier operación o conjunto de operaciones realizadas sobre datos personales o conjuntos de datos personales, ya sea por procedimientos automatizados o no, como la recogida, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, utilización, comunicación por transmisión, difusión o cualquier otra forma de habilitación de acceso, cotejo o interconexión, limitación, supresión o destrucción.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué datos captamos?</Text>
                                    <Text style={styles.p}>Nombre y apellidos, DNI/NIF/Documento identificativo, dirección, firma, teléfono, correo electrónico y datos bancarios. Así como cualquier otro dato que sea necesario para el correcto desenvolvimiento de la comunidad</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué tratamiento realizamos sobre sus datos personales y para qué finalidades tratamos los datos?</Text>
                                    <Text style={styles.p}>La actividad de tratamiento llevada por la comunidad es la gestión de propietarios. Asimismo, dicho tratamiento tiene como finalidad asegurar el correcto desenvolvimiento de la comunidad.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Cuál es la legitimación para el tratamiento de los datos?</Text>
                                    <Text style={styles.p}>De acuerdo con la normativa vigente en materia de protección de datos, necesitamos una base de legitimación para el tratamiento de sus datos personales.</Text>
                                    <Text style={styles.p}>Para el tratamiento de datos personales, la base de legitimación es el cumplimiento de una obligación legal.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Durante cuánto tiempo tratamos los datos?</Text>
                                    <Text style={styles.p}>Los datos personales serán conservados durante el tiempo legalmente establecido.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿Qué derechos se pueden ejercitar?</Text>
                                    <Text style={styles.p}>Como interesado, puede ejercitar los siguientes derechos:</Text>
                                    <View style={styles.ul}>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de acceso: Tiene derecho a obtener por parte de {finca.razonSocial} confirmación de si se están tratando o no sus datos personales, y en su caso, qué datos trata</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de rectificación: Tiene derecho a obtener por parte de {finca.razonSocial}, la rectificación de los datos personales inexactos que le conciernan. En la solicitud de rectificación deberá indicar los datos que desea que se modifiquen.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de oposición: Tiene derecho a oponerse en cualquier momento, por motivos relacionados con su situación particular, a que datos personales que le conciernan sean objeto de un tratamiento basado en el interés legítimo de {finca.razonSocial}. En ese caso, {finca.razonSocial} dejará de tratar los datos personales, salvo que acreditemos motivos legítimos imperiosos para el tratamiento que prevalezcan sobre sus intereses, derechos y libertades, o para la formulación, el ejercicio o la defensa de reclamaciones.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de supresión: Tiene derecho a obtener por parte de {finca.razonSocial}, la supresión de los datos personales que le conciernan</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de portabilidad de los datos: Tiene derecho a que {finca.razonSocial} transfiera sus datos personales a otro responsable del tratamiento. Solo es posible ejercer este derecho cuando el tratamiento esté basado en la ejecución de un contrato o en su consentimiento y el tratamiento se realice por medios automatizados</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8}]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Derecho de limitación del tratamiento: Tiene derecho a solicitarnos que suspendamos el tratamiento de sus datos cuando: impugne la exactitud de los datos, mientras {finca.razonSocial} verifica dicha exactitud; haya ejercido el derecho de oposición al tratamiento de sus datos, mientras que se verifica si los motivos legítimos de {finca.razonSocial} prevalecen sobre los suyos como interesado.</Text>
                                        </View>
                                    </View>
                                    <Text style={styles.p}>Puede ejercer sus derechos enviándonos su solicitud a la siguiente dirección postal {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia}) o a través de la dirección de correo electrónico {finca.emailContacto}.</Text>
                                    <Text style={styles.p}>Junto a su solicitud deberá aportar copia de su DNI o pasaporte. </Text>
                                    <Text style={styles.p}>Igualmente, le informamos de que en caso de que no quede satisfecho, puede presentar una reclamación ante la Agencia Española de Protección de Datos (AEPD)</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>¿A quién se pueden comunicar los datos personales?</Text>
                                    <Text style={styles.p}>Sus datos no serán comunicados a terceros, salvo a los administradores de fincas y/o proveedores de servicios con acceso a datos, necesarios para el correcto desenvolvimiento de la comunidad.</Text>
                                    <Text style={styles.p}>Asimismo, no se prevén transferencias internacionales.</Text>
                                    <Text style={[styles.p, styles.textoNegrita]}>Contacto</Text>
                                    <Text style={styles.p}>Si tiene dudas sobre el tratamiento que realizamos sobre sus datos personales, puede ponerse en contacto a través de la dirección de correo electrónico {finca.emailContacto}.</Text>
                            </View>
                        </Page>
                        {detailsLOPD.workers ? 
                            <>
                                <Page style={styles.body}>
                                    <View style={styles.pageHeader} fixed>
                                        <View >
                                            <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                        </View>
                                    </View>
                                    <View style={{ marginHorizontal: '1cm' }}>
                                        <Text style={styles.h3}>8. CLÁUSULA INFORMATIVA PARA EMPLEADOS </Text>
                                    </View>
                                    <View style={{ marginHorizontal: '1cm' }}>
                                    <Text style={[styles.p, {textDecoration:'underline', lineHeight: 1.2}]}>AVISO: Mediante el presente documento la comunidad cumple con el deber de informar que ostenta en virtud de la normativa vigente en materia de protección de datos. La firma del presente documento será necesaria con el fin de poder acreditar que efectivamente la comunidad ha cumplido con el deber de informar.</Text>
                                        <Text style={[styles.p, styles.textoNegrita, {textAlign:'center'}]}>POLÍTICA DE PRIVACIDAD PARA EMPLEADOS</Text>
                                        <Text style={styles.p}>El objetivo de la presente Política de Privacidad es informarle de forma transparente sobre los tratamientos de datos personales que realiza {finca.razonSocial}.</Text>
                                        <Text style={styles.p}>1. RESPONSABLE DE LOS DATOS PERSONALES DE EMPLEADOS</Text>
                                        <Text style={styles.p}>El responsable de sus datos personales es {finca.razonSocial} con domicilio en {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia}) y correo electrónico {finca.emailContacto}.</Text>
                                        <Text style={styles.p}>2. DEFINICIÓN DE DATO PERSONAL Y TRATAMIENTO DE DATOS PERSONALES</Text>
                                        <Text style={styles.p}>Un dato personal es toda información sobre una persona física identificada o identificable. Se considera persona física identificable toda persona cuya identidad pueda determinarse directa o indirectamente, en particular mediante un identificador, como por ejemplo un nombre, un número de identificación, datos de localización, un identificador en línea o uno o varios elementos propios de la identidad física, fisiológica, genética, psíquica, económica, cultural o social de dicha persona.</Text>
                                        <Text style={styles.p}>Asimismo, el tratamiento de datos personales implica cualquier operación o conjunto de operaciones realizadas sobre datos personales o conjuntos de datos personales, ya sea por procedimientos automatizados o no, como la recogida, registro, organización, estructuración, conservación, adaptación o modificación, extracción, consulta, utilización, comunicación por transmisión, difusión o cualquier otra forma de habilitación de acceso, cotejo o interconexión, limitación, supresión o destrucción.</Text>
                                        <Text style={styles.p}>3. DATOS PERSONALES CAPTADOS POR LA COMUNIDAD</Text>
                                        <Text style={styles.p}>{finca.razonSocial} únicamente captará aquellos datos personales necesarios para la gestión de la relación laboral entre las partes:</Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}><Text style={styles.textoNegrita}>Cantidad de datos personales recogidos:</Text> Esta serie de medidas implican factores cualitativos y cuantitativos de los datos personales. Siguiendo las directrices de la AEPD, el responsable del tratamiento deberá considerar el volumen de datos personales tratados, el nivel de detalle, las diferentes categorías, la sensibilidad (categorías especiales de datos) y  los tipos de datos personales requeridos y necesarios para llevar a cabo una operación de tratamiento. Las medidas aplicables son las siguientes.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Número de Seguridad Social, datos de la cuenta bancaria, información tributaria.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Nómina.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Contacto de emergencia</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Información y documentación del proceso de selección que puede incluir referencias, CV, permiso de trabajo, etc.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Datos del control de presencia.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Historial de apercibimientos, sanciones y expedientes disciplinarios.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Bajas.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Afiliación sindical.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.p}>{finca.razonSocial} podrá captar categorías especiales de datos personales, como por ejemplo, información sobre la salud, con el fin de cumplir con las obligaciones en materia laboral, relacionadas con los empleados con discapacidades.</Text>
                                        <Text style={styles.p}>En todo caso, para el tratamiento por parte de {finca.razonSocial} de categorías especiales de datos personales, como por ejemplo, información sobre salud, origen étnico, orientación sexual, religión, etc. será necesario el consentimiento expreso del empleado. Dicho consentimiento podrá ser retirado en cualquier momento. Igualmente el empleado tiene derecho a decidir libremente si facilita dicha información y no hay consecuencia alguna por no hacerlo.</Text>
                                        <Text style={styles.p}>4. TRATAMIENTOS Y FINALIDADES REALIZADOS SOBRE LOS DATOS PERSONALES</Text>
                                        <Text style={styles.p}>Gestión de la relación laboral con empleados. Las finalidades del tratamiento son las siguientes:</Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Tomar una decisión sobre la contratación.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Tomar decisiones respecto a revisiones salariales.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Evaluación de posibles infracciones laborales y, en su caso, aplicación de las correspondientes y faltas disciplinarias.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Llevar a cabo evaluaciones del empelado, para una tarea en particular y/o promoción profesional.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Gestionar los procesos de formación.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Gestionar incidentes legales, incluidos los accidentes en el trabajo.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Gestionar y mantener un registro de bajas o ausencias.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Cumplir con las obligaciones en materia de salud, prevención de riesgos y seguridad en el empleo.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Llevar a cabo las correspondientes gestiones administrativas.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.p}>5. BASE DE LEGITIMACIÓN DEL TRATAMIENTO DE DATOS PERSONALES</Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>La ejecución del contrato de trabajo entre {finca.razonSocial} y el empleado.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>El cumplimiento de una obligación legal, cuando el tratamiento sea necesario para cumplir con una obligación legal a la que estemos sujetos.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.p}>6. TERCEROS CON ACCESO A DATOS DE EMPLEADOS</Text>
                                        <Text style={styles.p}>{finca.razonSocial} podrá compartir sus datos personales, en caso de que sea estrictamente necesario con:</Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Bancos y entidades bancarias con el fin de gestionar el pago de las nóminas.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Proveedores con acceso a datos con quienes se ha formalizado el correspondiente Contrato de Encargo del Tratamiento.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Terceros prestadores de servicios de salud en el trabajo.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.p}>{finca.razonSocial} no realizará transferencias internacionales de sus datos personales.</Text>
                                        <Text style={styles.p}>Sus datos personales podrán ser facilitados a la Administración Pública, a las autoridades competentes y/o Juzgados y Tribunales.</Text>
                                        <Text style={styles.p}>7. CONSERVACIÓN DE LOS DATOS PERSONALES</Text>
                                        <Text style={styles.p}>{finca.razonSocial} conservará sus datos de carácter personal durante la vigencia de la relación laboral. Y una vez finalizada la misma, conservará la información imprescindible durante un periodo máximo de cuatro años, periodo legalmente exigible en materia laboral y de seguridad social salvo que aplique cualquiera de las excepciones a la prescripción de acciones en dichas áreas, en cuyo caso podrá extenderse dicho periodo de conservación por el periodo que resulte aplicable.</Text>
                                        <Text style={styles.p}>8. DERECHOS DEL EMPLEADO</Text>
                                        <Text style={styles.p}>Como empleado, puede ejercitar los siguientes derechos:</Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}><Text style={{textDecoration:'underline'}}>Derecho de acceso:</Text> Tiene derecho a obtener por parte de {finca.razonSocial} confirmación de si se están tratando o no sus datos personales, y en su caso, qué datos trata.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}><Text style={{textDecoration:'underline'}}>Derecho de rectificación:</Text>Tiene derecho a obtener por parte de {finca.razonSocial}, la rectificación de los datos personales inexactos que le conciernan. En la solicitud de rectificación deberá indicar los datos que desea que se modifiquen.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}><Text style={{textDecoration:'underline'}}>Derecho de oposición:</Text>Tiene derecho a oponerse en cualquier momento, por motivos relacionados con su situación particular, a que datos personales que le conciernan sean objeto de un tratamiento basado en el interés legítimo de Razón Social. En ese caso, Razón Social dejará de tratar los datos personales, salvo que acreditemos motivos legítimos imperiosos para el tratamiento que prevalezcan sobre sus intereses, derechos y libertades, o para la formulación, el ejercicio o la defensa de reclamaciones.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}><Text style={{textDecoration:'underline'}}>Derecho de supresión:</Text>Tiene derecho a obtener por parte de {finca.razonSocial}, la supresión de los datos personales que le conciernan.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}><Text style={{textDecoration:'underline'}}>Derecho de portabilidad de los datos:</Text>Tiene derecho a que {finca.razonSocial} transfiera sus datos personales a otro responsable del tratamiento. Solo es posible ejercer este derecho cuando el tratamiento esté basado en la ejecución de un contrato o en su consentimiento y el tratamiento se realice por medios automatizados.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}><Text style={{textDecoration:'underline'}}>Derecho de limitación del tratamiento:</Text>Tiene derecho a solicitarnos que suspendamos el tratamiento de sus datos cuando: impugne la exactitud de los datos, mientras {finca.razonSocial} verifica dicha exactitud; haya ejercido el derecho de oposición al tratamiento de sus datos, mientras que se verifica si los motivos legítimos de {finca.razonSocial} prevalecen sobre los suyos como interesado.</Text>
                                            </View>
                                        </View>
                                        <Text style={styles.p}>En cualquier momento, podrá ejercitar estos Derechos, mediante un escrito y acreditando su personalidad dirigido a {finca.razonSocial}, en {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia}), o a través de la dirección de correo electrónico: {finca.emailContacto}.</Text>
                                        <Text style={styles.p}>Además, podrá presentar una reclamación ante la autoridad de control, en este caso, la Agencia Española de Protección de Datos, si considera que el tratamiento de datos personales infringe la legislación aplicable.</Text>
                                        <Text style={[styles.p]}>En {finca.municipio}, a .... de ................. de 20...</Text>
                                        <Text style={[styles.p]}>Firma del empleado:</Text>
                                    </View>
                                </Page>
                                <Page style={styles.body}>
                                    <View style={styles.pageHeader} fixed>
                                        <View >
                                            <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                        </View>
                                    </View>
                                    <View style={{ marginHorizontal: '1cm' }}>
                                        <Text style={[styles.p, styles.textoNegrita, {textAlign:'center'}]}>REGULARIZACIÓN DEL DEBER DE SECRETO DEL TRABAJADOR</Text>
                                        <Text style={[styles.p, {textDecoration:'underline', textAlign:'center'}]}>Modelo de Documento por el cual se regulariza el deber de secreto en la comunidad, ha de ser conocido por todo el personal de la comunidad implicado en el tratamiento de datos de carácter personal.</Text>
                                        <Text style={styles.p}>El abajo firmante, ___________________________, con DNI __________, y {finca.razonSocial} con NIF {finca.nif} y domicilio en {finca.direccion} {finca.municipio} {finca.codigoPostal} ({finca.provincia}), en el marco de la relación laboral que le une a {finca.razonSocial}, se compromete a: </Text>
                                        <View style={styles.ul}>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>1.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>En cumplimiento con el principio de confidencialidad establecido por la normativa vigente en materia de protección de datos y con los deberes laborales recogidos en el artículo 5 a) del Real Decreto Legislativo 2/2015, de 23 de octubre, por el que se aprueba el texto refundido de la Ley del Estatuto de los Trabajadores (a partir de ahora LET) el empleado se compromete a respetar el deber de secreto y confidencialidad, tratando diligentemente y de acuerdo a las reglas de la buena fe toda aquella información a la que pueda tener acceso como empleado de {finca.razonSocial} y no revelando a ninguna persona ajena a {finca.razonSocial}, sin el consentimiento debido dicha información, excepto en aquellos casos en los que sea necesario para dar el debido cumplimiento a sus obligaciones o por habérsele requerido por mandato legal o de la autoridad competente.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>2.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Utilizar la información que se menciona en el apartado anterior únicamente para el exclusivo desarrollo de sus funciones como empleado de {finca.razonSocial} y a no utilizarla de otra forma o con otra finalidad.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>3.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Las obligaciones de confidencialidad y secreto estarán vigentes mientras que dure la relación laboral e incluso cuando se extinga por cualquier causa.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>4.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>El empleado queda informado de que su incumplimiento puede generar el ejercicio de acciones disciplinarias por parte de Razón Social, tal y como establece el artículo 58 de la LET. Asimismo, el Código Penal, aprobado por la Ley Orgánica 10/1995 de 23 de noviembre, concretamente en sus artículos 197, 199, 278 y 279, se castiga con penas de hasta cinco años de prisión el quebrantamiento del deber anteriormente definido. En su artículo 279 se especifica que: "La difusión, revelación o cesión de un secreto de empresa llevada a cabo por quien tuviere legal o contractualmente obligación de guardar reserva, se castigará con la pena de prisión de dos a cuatro años y multa de doce a veinticuatro meses". Igualmente, cuando los hechos afecten a datos de carácter personal que revelen la ideología, religión, creencias, salud, origen racial o vida sexual, o la víctima fuere un menor de edad o un incapaz, se impondrán penas superiores (artículo 197.5).</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>5.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Cualquiera que tenga acceso a los datos de carácter personal de {finca.razonSocial}, deberá respetar la confidencialidad del contenido, así como velar en todo momento, por la calidad e integridad de los mismos. Los datos serán utilizados exclusivamente para los fines y funciones para los que fueron recabados. Asimismo, el empleado tiene la obligación de proteger y mantener en secreto las contraseñas utilizadas para la gestión correspondiente, así como modificarlas en el momento en que le sea requerido, ya sea por el sistema informático o por el propio responsable de seguridad.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>6.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>Igualmente, el empleado queda informado sobre el uso responsable que se debe realizar de las distintas herramientas informáticas que tenga a disposición para el desarrollo de su actividad laboral, en especial del uso del correo electrónico, del acceso a Internet, así como del uso de las diferentes herramientas informáticas.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>7.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>El empleado reconoce y acepta sin reservas que el uso de Internet, del correo electrónico, así como de las herramientas, programas, soportes o equipos informáticos suministrados por {finca.razonSocial} al empleado son para uso exclusivamente laboral o profesional. El empleado declara conocer que estos equipos y dispositivos, por motivos de seguridad, podrán ser en todo momento intervenidos por {finca.razonSocial} sin notificación previa.</Text>
                                            </View>
                                            <View style={styles.ol}>
                                                <Text style={[styles.lista, { marginHorizontal: 8 }]}>8.</Text>
                                                <Text style={{marginRight: '2cm', fontSize: 9}}>{finca.razonSocial} garantizará la desconexión digital en el ámbito laboral de equipos electrónicos que dispongan de sistemas de geolocalización.</Text>
                                            </View>
                                        </View>
                                        <Text style={[styles.p]}>En {finca.municipio}, a .... de ................. de 20...</Text>
                                        <Text style={[styles.p]}>Firma del empleado:</Text>
                                    </View>
                                </Page>
                            </>
                        : null }
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>9. ANÁLISIS DE SUPUESTOS ESPECÍFICOS</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={[styles.p, styles.textoNegrita]}>9.1  IMPAGO DE LOS RECIBOS DE LA COMUNIDAD POR LOS PROPIETARIOS</Text>
                                <Text style={styles.p}>De acuerdo con lo establecido por la Agencia Española de Protección de Datos en su Guía sobre Protección de Datos y Administración de Fincas, con carácter general, la publicación en el tablón de avisos de la comunidad de una relación de propietarios que no se encuentran al corriente en pago de sus cuotas no está amparada por la normativa de protección de datos.</Text>
                                <Text style={styles.p}>La publicación de la relación de propietarios con cuotas vencidas y pendientes de pago, mediante comunicación singularizada y periódica remitida a cada uno de ellos, tampoco encuentra cobertura en la normativa de protección de datos.</Text>
                                <Text style={styles.p}>Las anteriores publicaciones no están permitidas incluso en los supuestos en los que únicamente se hace mención al dato relativo a la vivienda, sin hacer mención expresa a la identificación personal del vecino deudor.</Text>
                                <Text style={styles.p}>No obstante, el artículo 16.2 de la Ley de Propiedad Horizontal permite la publicidad de la identidad de los deudores y de sus deudas con la comunidad, incorporando dichos datos a la Convocatoria de la Junta. </Text>
                                <Text style={styles.p}>A modo excepcional, cuando no exista constancia del domicilio de algún propietario, al no haber facilitado este dato a la comunidad, se entenderá por domicilio a efectos de citaciones y notificaciones el piso o local perteneciente a la comunidad. Si intentada una citación o notificación al propietario fuese imposible practicarla, se podrá publicar en el tablón de anuncios de la comunidad o en otro lugar visible de uso general habilitado al efecto, la Convocatoria de la Junta de Propietarios. Para este supuesto, deberán hacerse constar, junto con el documento publicado, los motivos de dicha publicación, extendiéndose diligencia por la persona que ejerza las funciones de secretario. </Text>
                                <Text style={[styles.p, styles.textoNegrita]}>9.2 ACCESO Y OBTENCIÓN DE COPIAS DE LA DOCUMENTACIÓN DE LA COMUNIDAD POR PARTE DE LOS PROPIETARIOS DE VIVIENDAS</Text>
                                <Text style={styles.p}>En la documentación de la comunidad de propietarios pueden encontrarse numerosas informaciones que incorporan datos personales, tales como datos identificativos y de contacto de los propietarios, números de sus cuentas corrientes, coeficientes de participación, consumos individuales, ingresos efectuados por los propietarios o deudas que estos mantengan con la comunidad, sentido del voto en la adopción de acuerdos, etcétera. Igualmente tendrá dicha consideración cualquier dato personal referido a los empleados que pudiera tener la comunidad de propietarios, como también la tendrán los datos relativos a honorarios de profesionales que abone la comunidad.</Text>
                                <Text style={styles.p}>La Ley de Propiedad Horizontal habilita diversas comunicaciones de datos personales, que a efectos del RGPD se encontrarían legitimadas en base al cumplimiento de una obligación legal. Desde el punto de vista de la protección de datos personales, sin perjuicio de que puedan realizarse las mencionadas comunicaciones, se tiene que tener en consideración también los principios de la normativa vigente en protección de datos, sobre todo el principio relativo a la minimización de los datos. </Text>
                                <Text style={styles.p}>En consecuencia, la comunicación de datos debe limitarse a aquellos que resulten adecuados, pertinentes y limitados para el cumplimiento de la finalidad que legitima el acceso a los mismos, que en estos supuestos viene referido al buen gobierno de la comunidad y a su control.</Text>
                                <Text style={styles.p}>Por ejemplo, no cumple con el requisito de idoneidad la comunicación de los directorios con los datos de domicilio de los propietarios o sus números de cuenta corriente, ya que estos datos no contribuyen a la finalidad de control de la buena administración de la comunidad de propietarios. Igualmente, la comunicación de las nóminas de los empleados de la comunidad resulta contraria al principio de proporcionalidad, en cuanto dichos datos no resultan relevantes para la finalidad de control de la gestión de la comunidad de propietarios. No obstante, se podrá informar a los propietarios de las retribuciones satisfechas a los empleados</Text>
                                <Text style={styles.p}>En cualquier caso, cuando se considere oportuno facilitar copia de la documentación, con las limitaciones derivadas de la aplicación de la normativa de protección de datos, deberán adoptarse las medidas de seguridad y, especialmente, las relativas a la gestión y salida de soportes y documentos, y traslado de documentación. </Text>
                                <Text style={[styles.p, styles.textoNegrita]}>9.3 EXHIBICIÓN DEL LIBRO DE ACTAS DE LA COMUNIDAD DE PROPIETARIOS A ENTIDADES FINANCIERAS</Text>
                                <Text style={styles.p}>La cuestión que se plantea es en el presente apartado es, si es conforme a la normativa de protección de datos que la representación legal de la comunidad deba exhibir el libro de actas ante una entidad bancaria para formalizar la apertura de una cuenta corriente o para el cambio anual de firmas de los cargos de la comunidad.</Text>
                                <Text style={styles.p}>La apertura y desenvolvimiento de una cuenta corriente con la entidad financiera provoca la necesidad de que esta tenga conocimiento de los datos de identificación de quien asume esa representación legal y de los cambios que se produzcan anualmente (o cuando fuere removidos de su cargo antes de este periodo) , así como de los datos personales de aquellos cargos de la comunidad a los que el presidente otorgue la facultad de firma, independiente o conjunta, para las operaciones referidas en dicha cuenta. </Text>
                                <Text style={styles.p}>Tales datos le pueden ser comunicados mediante la correspondiente certificación expedida por el administrador de la comunidad de propietarios u otro documento de apoderamiento legal, de cuya exactitud y veracidad responderán dichos órganos de gobierno ante la entidad financiera. Por tanto, la certificación expedida por el administrador será documento suficiente para formalizar ante la entidad financiera la apertura de una cuenta corriente o para el cambio anual de firmas de los cargos de la comunidad.</Text>
                                <Text style={[styles.p, styles.textoNegrita]}>9.4 VIDEOVIGILANCIA</Text>
                                <Text style={styles.p}>El acceso a las imágenes estará restringido a las personas por la comunidad de propietarios.</Text>
                                <Text style={styles.p}>El sistema de grabación se ubicará en un lugar vigilado o de acceso restringido.  A las imágenes grabadas accederá solo el personal autorizado, que deberá introducir un código de usuario y una contraseña. Una vez instalado el sistema, se recomienda el cambio regular de la contraseña, evitando las fácilmente deducibles.</Text>
                                <Text style={styles.p}>Si el acceso se realiza con conexión a internet, se restringirá igualmente con un código de usuario y contraseña (o cualquier otro medio que garantice la identificación y autenticación unívoca), que solo serán conocidos por las personas autorizadas a acceder a las imágenes. En ningún caso resultarán accesibles a los vecinos mediante el canal de televisión comunitaria. </Text>
                                <Text style={styles.p}>Las imágenes serán conservadas durante un plazo máximo de un mes desde su captación. Las imágenes que se utilicen para denunciar delitos o infracciones se acompañarán a la denuncia y será posible su conservación para ser entregadas a las Fuerzas y Cuerpos de Seguridad o a los Juzgados y Tribunales que las requieran. No podrán utilizarse para otro fin.</Text>
                                <Text style={styles.p}>La petición de imágenes por las Fuerzas y Cuerpos de Seguridad se realizará en el marco de actuaciones judiciales o policiales. En este caso, la comunidad conservará la solicitud. El requerimiento al titular del tratamiento será el documento que ampare a este para comunicar datos a las mismas o a los Juzgados y Tribunales que los requieran.</Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>10. CLÁUSULAS PARA PRESIDENTE</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h4}>10.1 IDENTIFICACIÓN DEL PRESIDENTE</Text>
                                <View style={styles.table}>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>Nombre del presidente</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>{finca.nombrePresidente}</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>DNI</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>{finca.nifPresidente}</Text> 
                                        </View> 
                                    </View>
                                </View>
                                <Text style={styles.br} />
                                <Text style={[styles.p, styles.textoNegrita, {textAlign:'center'}]}>REGULARIZACIÓN DEL DEBER DE CONFIDENCIALIDAD DEL PRESIDENTE</Text>
                                <Text style={[styles.p, {textDecoration:'underline', textAlign:'center'}]}>Modelo de Documento por el cual se regulariza el deber de secreto en la comunidad, ha de ser conocido por el presidente de la comunidad. </Text>
                                <Text style={[styles.p]}>El abajo firmante, {finca?.nombrePresidente === null || finca?.nombrePresidente === '' ? "____________" : finca?.nombrePresidente}, con DNI {finca?.nifPresidente === null || finca?.nifPresidente === '' ? "____________" : finca.nifPresidente}, en calidad de presidente de la comunidad {finca.razonSocial} con NIF {finca.nif}, se compromete a: </Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8 }]}>1.</Text>
                                        <Text style={{marginRight: '2cm', fontSize: 9}}>En cumplimiento con el principio de confidencialidad establecido por el artículo 5.1 del Reglamento (UE) 2016/679, del Parlamento Europeo y del Consejo , de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos (en adelante, el “RGPD”), el presidente se compromete a respetar el deber de secreto y confidencialidad, tratando diligentemente y de acuerdo a las reglas de la buena fe toda aquella información a la que pueda tener acceso como presidente de {finca.razonSocial} y no revelando a ninguna persona ajena a {finca.razonSocial}, sin el consentimiento debido dicha información, excepto en aquellos casos en los que sea necesario para dar el debido cumplimiento a sus obligaciones o por habérsele requerido por mandato legal o de la autoridad competente.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8 }]}>2.</Text>
                                        <Text style={{marginRight: '2cm', fontSize: 9}}>Utilizar la información que se menciona en el apartado anterior únicamente para el exclusivo desarrollo de sus funciones como presidente de {finca.razonSocial} y a no utilizarla de otra forma o con otra finalidad.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8 }]}>3.</Text>
                                        <Text style={{marginRight: '2cm', fontSize: 9}}>Las obligaciones de confidencialidad y secreto estarán vigentes mientras que ejerza como presidente e incluso cuando se finalice el cargo por cualquier causa.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, styles.textoNormal, { marginHorizontal: 8 }]}>4.</Text>
                                        <Text style={{marginRight: '2cm', fontSize: 9}}>Cualquiera que tenga acceso a los datos de carácter personal de {finca.razonSocial}, deberá respetar la confidencialidad del contenido, así como velar en todo momento, por la calidad e integridad de los mismos. Los datos serán utilizados exclusivamente para los fines y funciones para los que fueron recabados. Asimismo, el presidente tiene la obligación de proteger y mantener en secreto las contraseñas utilizadas para la gestión correspondiente, así como modificarlas en el momento en que le sea requerido, ya sea por el sistema informático o por el propio responsable de seguridad.</Text>
                                    </View>
                                    <Text style={[styles.p]}>En {finca.municipio}, a .... de ................. de 20...</Text>
                                    <Text style={[styles.p]}>Firma del presidente:</Text>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>10.2. CLÁUSULA PARA COMUNICACIONES VIA WHATSAPP</Text> 
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={[styles.h4, styles.textoNegrita, {textAlign:'center'}]}>CONSENTIMIENTO PARA REALIZAR COMUNICACIONES VÍA WHATSAPP</Text>
                                <Text style={[styles.p, styles.textoItalic]}>Con el objetivo de dar cumplimiento a la Ley Orgánica de Protección de Datos y Garantía de los Derechos Digitales y la Ley de Servicios de la Sociedad de la Información, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos, y siguiendo las Recomendaciones e Instrucciones emitidas por la Agencia Española de Protección de Datos (A.E.P.D.), {finca.razonSocial}, le informa</Text>
                                <Text style={[styles.p, styles.textoItalic]}>Que para poder ser incluido en un grupo de WhatsApp de la Comunidad así como realizar comunicaciones a través de sistemas de mensajería instantánea como WhatsApp se solicita su consentimiento expreso, siendo consciente de que los demás integrantes del grupo de WhatsApp de la Comunidad, conocerán su dato de contacto.</Text>
                                <Text style={[styles.p, styles.textoItalic]}>Acepto expresamente que mi dato de contacto sea utilizado para ser incluido en un grupo de WhatsApp de la Comunidad:</Text>
                                
                                <View style={{flexDirection: "row", width:'90%'}}>
                                    <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                    <Text style={[styles.p, {textDecoration:'underline'}]}>SÍ</Text>
                                </View>
                                <View style={{flexDirection: "row", width:'90%'}}>
                                    <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                    <Text style={[styles.p, {textDecoration:'underline'}]}>NO</Text>
                                </View>
                                
                                
                                <Text style={[styles.p, {marginTop:20}]}>Nombre y apellidos</Text>
                                <Text style={styles.p}>DNI:</Text>
                                <Text style={[styles.p]}>En {finca.municipio}, a .... de ................. de 20...</Text>
                                <Text style={[styles.p]}>Firma:</Text>
                                
                                <Text style={[styles.p, styles.textoItalic, {marginTop:50, fontSize: 7}]}>
                                    <Text style={styles.textoNegritaItalic}> Responsable del tratamiento</Text> de sus datos personales:
                                    {finca.razonSocial} con <Text style={styles.textoNegritaItalic}>NIF</Text> {finca.nif}.
                                    <Text style={styles.textoNegritaItalic}>Dir. Postal</Text> {finca.direccion} {finca.codigoPostal} {finca.municipio} ({finca.provincia})
                                    En nombre de la Comunidad tratamos la información que nos facilita con la <Text style={styles.textoNegritaItalic}> finalidad </Text> 
                                    de prestarles el servicio solicitado y/o realizar la facturación del mismo. La ejecución de un contrato supone la 
                                    <Text style={styles.textoNegritaItalic}> legitimación </Text>
                                    para el tratamiento de sus datos. Para tratamientos adicionales como el envío de comunicaciones comerciales o el uso y captación de su imagen, la base jurídica que legitima dichos tratamientos es su consentimiento. Sus datos formarán parte de nuestra base de datos durante el tiempo necesario para cumplir el servicio o las disposiciones legales. 
                                    <Text style={styles.textoNegritaItalic}> Destinatarios: </Text> 
                                    Los datos no se cederán a terceros salvo en los casos en que exista una obligación legal o comunicación estrictamente necesaria para dar cumplimiento a la relación que nos vincula; así como a los demás propietarios de la comunidad que estén incluidos en el grupo de  WhatsApp. 
                                    <Text style={styles.textoNegritaItalic}> Derechos: </Text> 
                                    Puede ejercer sus Derechos a acceder, rectificar, oponerse, limitar, portar y suprimir los datos ante el responsable del tratamiento; además de acudir a la autoridad de control competente (AEPD).
                                    <Text style={styles.textoNegritaItalic}> Información Adicional: </Text> 
                                    Puede solicitar información adicional ante la dirección del responsable.</Text>
                                <Text style={[styles.p, {textDecoration:'underline', lineHeight: 1.2}]}>AVISO: Los envíos masivos por WhatsApp deben ir bajo listas de difusión y nunca como grupos abiertos</Text>
                                <Text style={[styles.p, {textDecoration:'underline', lineHeight: 1.2}]}>AVISO: Nunca realizar la comunicación sin permiso inicial del propietario.</Text>
                            </View>
                            {/* <View style={{marginBottom: 20, paddingTop: 20, marginHorizontal: '1cm'}} fixed></View> */}
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>12. APLICACIÓN DE MEDIDAS DE PROTECCIÓN DE DATOS POR DEFECTO</Text> 
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>Siguiendo las directrices de la Guía de Protección de Datos por Defecto publicada por la AEPD en el mes de octubre de 2020, y en cumplimiento del segundo apartado del artículo 25 del RGPD, corresponde al responsable del tratamiento la implantación de las medidas de protección de datos por defecto</Text>
                                <Text style={[styles.p, styles.textoItalic]}>"El responsable del tratamiento aplicará las medidas técnicas y organizativas apropiadas con miras a garantizar que, por defecto, solo sean objeto de tratamiento los datos personales que sean necesarios para cada uno de los fines específicos del tratamiento. Esta obligación se aplicará a la cantidad de datos personales recogidos, a la extensión de su tratamiento, a su plazo de conservación y a su accesibilidad. Tales medidas garantizarán en particular que, por defecto, los datos personales no sean accesibles, sin la intervención de la persona, a un número indeterminado de personas físicas"</Text>
                                <Text style={styles.p}>La normativa aplicable en materia de protección de datos se muestra flexible a la hora de seleccionar las medidas para garantizar el cumplimiento de la misma, pudiendo optar por diferentes alternativas. Aunque el riesgo del tratamiento para los derechos y libertades sea escaso, no se debe ignorar la elección de medidas concretas de seguridad que se han de implementar por parte del responsable del tratamiento.</Text>
                                <Text style={styles.p}>El RGPD exige al responsable del tratamiento la configuración por defecto de tratamientos de datos personales que sean respetuosos con los principios de protección de datos personales, garantizando un tratamiento lo menos intrusivo posible. Mínima cantidad de datos personales, mínima extensión del tratamiento, mínimo plazo de conservación y mínima accesibilidad a datos personales.</Text>
                                <Text style={styles.p}>A continuación, se establecen una serie de medidas que deberán adoptarse por defecto en la comunidad.</Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, {textDecoration:'underline'}]}>Cantidad de datos personales recogidos:</Text> Esta serie de medidas implican factores cualitativos y cuantitativos de los datos personales. Siguiendo las directrices de la AEPD, el responsable del tratamiento deberá considerar el volumen de datos personales tratados, el nivel de detalle, las diferentes categorías, la sensibilidad (categorías especiales de datos) y los tipos de datos personales requeridos y necesarios para llevar a cabo una operación de tratamiento. Las medidas aplicables son las siguientes:</Text>
                                    </View>
                                    <View style={[styles.ul, {marginLeft:20}]}>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Captación de datos estrictamente necesarios.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Graduación de la extensión de los datos recogidos en función de los casos de uso.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Utilizar mecanismos de recogida escalonada de información necesaria para el tratamiento. Es decir, retrasar la recogida de datos hasta la fase en la que sean estrictamente necesarios.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Alternativas y voluntariedad en la información de contacto reclamada al titular: e-mail, postal, teléfono, etc.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Generalizar los datos: emplear por ejemplo rangos de edad en lugar de la edad concreta.</Text>
                                        </View>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, {textDecoration:'underline'}]}>La extensión del tratamiento:</Text> Los tratamientos de datos personales se limitarán a lo estrictamente necesario para cumplir con la finalidad oportuna. Las medidas a implementar son:</Text>
                                    </View>
                                    <View style={[styles.ul, {marginLeft:20}]}>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Posibilitar los ejercicios de derechos de oposición, limitación o supresión.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Minimizar la cantidad de copias temporales que se generen y reducir al máximo los tiempos de conservación, las transferencias y comunicaciones.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Seudonimización atendiendo a las operaciones de tratamiento que puedan existir.</Text>
                                        </View>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, {textDecoration:'underline'}]}>El periodo de conservación:</Text> Si un dato personal no se necesita después de ejecutar una fase del tratamiento, deberá ser suprimido. Las medidas a aplicar son:</Text>
                                    </View>
                                    <View style={[styles.ul, {marginLeft:20}]}>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Eliminación de los datos del titular al ejercer el derecho al olvido.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Establecer mecanismos de bloqueo de datos y borrado.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Mecanismos de anonimización de los datos cuando sea oportuno.</Text>
                                        </View>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={[styles.textoNegrita, {textDecoration:'underline'}]}>La accesibilidad de los datos:</Text> El responsable del tratamiento deberá establecer quién puede acceder a los datos personales. Deberá limitar la accesibilidad de los datos personales. Las medidas a aplicar son las siguientes:</Text>
                                    </View>
                                    <View style={[styles.ul, {marginLeft:20}]}>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Política de gestión de accesos en la que se especifican los datos a los que puede acceder cada persona con acceso a datos personales.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Sistemas de anomización y/o seudonimización de textos a difundir cuando sea oportuno.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Histórico sobre los últimos cambios llevados a cabo y el perfil que ha realizado el cambio.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Histórico de perfiles y entidades que han accedido a la información.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Facilitar el derecho al olvido de los titulares.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Configurar sistemas de alerta por acceso anómalos a los datos.</Text>
                                        </View>
                                        <View style={styles.ol}>
                                            <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                            <Text style={{marginRight: '2cm'}}>Trazabilidad de la comunicación entre responsables, encargados y subencargados.</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h3}>13. MEDIDAS DE ESPECIAL IMPORTANCIA A APLICAR EN LA COMUNIDAD</Text> 
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>A continuación, se señalan una serie de medidas indispensables que deberán ser cumplidas de acuerdo con lo establecido por la normativa  en materia de protección de datos.</Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={{textDecoration:'underline'}}>Deber de informar:</Text>Los propietarios y/o inquilinos, así como los empleados, deberán firmar las cláusulas sobre protección de datos recogidas en los apartados séptimo y octavo del presente documento. Mediante la firma de dichas cláusulas, la comunidad cumple con el deber de informar establecido por el artículo 13 del RGPD. Igualmente, en el apartado sexto, queda recogido el documento informativo sobre el tratamiento de videovigilancia para los casos en los que lo solicite el interesado.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={{textDecoration:'underline'}}>Contrato de encargado del tratamiento:</Text> En conformidad con el artículo 28 del RGPD deberá firmarse un contrato de encargado del tratamiento con cada proveedor de la comunidad que en el desempeño de sus funciones pueda tener acceso a datos de carácter personal.</Text>
                                        <Text style={{marginRight: '2cm'}}>Resulta de especial relevancia tener firmado el contrato de encargado del tratamiento con el administrador de fincas.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={{textDecoration:'underline'}}>Acceso a los datos personales:</Text> En todo momento deberá garantizarse que los datos personales captados son los mínimos imprescindibles para hacer frente a la finalidad perseguida. Igualmente, deberá asegurarse de que únicamente las personas autorizadas al respecto tienen acceso a los datos personales de los propietarios y empleados. Cabe destacar, la importancia de reparar a los supuestos específicos señalados en el apartado noveno del presente documento.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>*</Text>
                                        <Text style={{marginRight: '2cm'}}><Text style={{textDecoration:'underline'}}>Registro de actividades del tratamiento actualizado:</Text> La comunidad deberá llevar a cabo en todo momento, un registro actualizado de todos los tratamientos de datos personales efectuados. En la actualidad, la comunidad únicamente realiza el tratamiento de los datos de sus propietarios y/o inquilinos, videovigilancia y empleados. Si se plantease la posibilidad de realizar cualquier otro tratamiento de datos personales adicional, deberá comunicárselo a Lae Consulting Normativas, S.L. con la mayor brevedad posible, para que proceda con la actualización del registro.</Text>
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h4}>13.1 MODELOS DE COMUNICACIÓN O REGISTRO INTERNO ANTE LA VIOLACIÓN O BRECHA DE SEGURIDAD</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={[styles.p, {textDecoration:'underline'}]}>*La Comunidad cuenta con la obligación de registrar cualquier incidente o brecha de seguridad, independientemente de que deba ser notificada a la AEPD o no.</Text>
                                <View style={[styles.table, {width:'90%', marginTop:'30px'}]}>
                                    <View style={styles.tableRowHeader}> 
                                        <View style={styles.tableColHeader}> 
                                            <Text style={styles.tableCell}>MODELO DE REGISTRO INTERNO DE INCIDENTE DE SEGURIDAD</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>REGISTRO</Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>DÍA</Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>MES</Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>AÑO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {height:100}]}>
                                                1. Descripción del incidente de seguridad:{'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {height:100}]}>
                                                2. Medidas adoptadas para evitar que el incidente de seguridad vuelva a tener lugar: {'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {height:100}]}>
                                                Firma del Responsable de Seguridad: {'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View style={[styles.table, {width:'90%', marginTop:'30px'}]}>
                                    <View style={styles.tableRowHeader}> 
                                        <View style={styles.tableColHeader}> 
                                            <Text style={styles.tableCell}>MODELO DE COMUNICACIÓN DE LA VIOLACIÓN DE SEGURIDAD ANTE LA AUTORIDAD DE CONTROL COMPETENTE</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>REGISTRO</Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>DÍA</Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>MES</Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {textAlign:'center'}]}>AÑO</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                        <View style={[styles.tableCol, {width: "25%", height:20}]}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, {height:50}]}>
                                                Responsable de Seguridad: {'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {height:100}]}>
                                                1.Naturaleza de la violación de la seguridad de los datos personales:{'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {height:100}]}>
                                                2. Posibles consecuencias de la violación de seguridad de los datos personales: {'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {height:100}]}>
                                                3. Medidas adoptadas para poner remedio a la violación de la seguridad de los datos personales: {'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={[styles.tableCol, {width: "100%"}]}> 
                                            <Text style={[styles.tableCell, styles.textoNegrita, {height:50}]}>
                                                Firma del Responsable de Seguridad: {'\n'}     
                                            </Text> 
                                        </View> 
                                    </View>
                                </View>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h4}>13.2 PROCESOS DE GESTIÓN INTERNOS PARA ATENDER CUALQUIER PETICIÓN DE EJERCICIO DE DERECHOS.</Text> 
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={[styles.p, styles.textoNegrita]}>1. Recepción de la solicitud:</Text> 
                                <Text style={styles.p}>Cualquier petición de ejercicio de Derechos recepcionada en {finca.razonSocial} a través de cualquier punto de atención al cliente de cualquier dependencia o recibida por cualquier otro medio como correo electrónico o postal, debe ser escaneada de manera inmediata a la persona designada por el Responsable del Tratamiento</Text> 
                                <Text style={[styles.p, styles.textoNegrita]}>2. Coordinación:</Text> 
                                <Text style={styles.p}>El Responsable del Tratamiento, con el apoyo y asesoramiento del DPD valorará si el contenido del ejercicio de derechos se ajusta a las condiciones legales.</Text> 
                                <Text style={[styles.p, styles.textoNegrita]}>3. Respuesta</Text> 
                                <Text style={styles.p}>Cualquier proceso de los mencionados más adelante tiene como condición la necesidad de emitir respuesta al solicitante en el <Text style={styles.textoNegrita}>plazo máximo de un mes desde su recepción</Text>. La respuesta será siempre por escrito y comunicada mediante <Text style={{textDecoration:'underline'}}>burofax con copia y acuse de recibo o correo certificado</Text>.</Text> 
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h4}>FORMULARIO PARA EL EJERCICIO DEL DERECHO A ACCEDER, RECTIFICAR, SUPRIMIR, LIMITAR, PORTAR, OPONERSE.</Text> 
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                            <View style={[styles.table, {width:'90%'}]}>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>D./D.ª:</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>DNI:</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>Domicilio:</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>CP:</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>Población:</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>Provincia:</Text> 
                                        </View> 
                                    </View>
                                    <View style={styles.tableRow}> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}>Email:</Text> 
                                        </View> 
                                        <View style={styles.tableCol}> 
                                            <Text style={styles.tableCell}></Text> 
                                        </View> 
                                    </View>
                                </View>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>Solicita</Text><Text style={{fontSize:8}}> (marcar una de las opciones:)</Text></Text> 
                                <View>
                                    <View style={{flexDirection: "row", width:'90%'}}>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                        <Text style={[styles.p, {textDecoration:'underline'}]}>Ejercitar el derecho a obtener confirmación de si se están tratando mis datos personales y, en tal caso, a acceder a mis datos personales y a que se me remita dicha información por:</Text>
                                    </View>
                                    <View style={{flexDirection: "row"}}>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:35}]}></Text>
                                        <Text style={styles.p}>correo postal a la dirección anteriormente indicada.</Text>
                                    </View>
                                    <View style={{flexDirection: "row"}}>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:35}]}></Text>
                                        <Text style={styles.p}>email a la dirección anteriormente indicada.</Text>
                                    </View>
                                    <View style={{flexDirection: "row", width:'90%'}}>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                        <Text style={styles.p}>Ejercitar el derecho a <Text style={styles.textoNegrita}>rectificar</Text> mis datos de carácter personal, solicitando la modificación de los siguientes datos en el sentido que se indica a continuación:</Text>
                                    </View>
                                    <View style={{width:'95%', marginBottom:20}}>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                    </View>
                                    <View style={{flexDirection: "row", width:'90%'}}>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                        <Text style={styles.p}>Ejercitar el derecho a <Text style={styles.textoNegrita}>suprimir</Text> todos mis datos de carácter personal en los términos expuestos a continuación:</Text>
                                    </View>
                                    <View style={{width:'95%', marginBottom:20}}>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                    </View>
                                    <View style={{flexDirection: "row", width:'90%'}}>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                        <Text style={styles.p}>Ejercitar el derecho a <Text style={styles.textoNegrita}>limitar</Text> el tratamiento de mis datos de carácter personal en los términos expuestos a continuación:</Text>
                                    </View>
                                    <View style={{width:'95%', marginBottom:20}}>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                    </View>
                                    <View style={{flexDirection: "row", width:'90%'}} break>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                        <Text style={styles.p}>Ejercitar el derecho a <Text style={styles.textoNegrita}>portar</Text> mis datos de carácter personal en los términos expuestos a continuación:</Text>
                                    </View>
                                    <View style={{width:'95%', marginBottom:20}}>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                    </View>
                                    <View style={{flexDirection: "row", width:'90%'}}>
                                        <Text style={[styles.cuadrado, {marginTop:5, marginLeft:15}]}></Text>
                                        <Text style={styles.p}>Ejercitar el derecho a <Text style={styles.textoNegrita}>oponerme</Text> al tratamiento de mis datos de carácter personal en los términos expuestos a continuación:</Text>
                                    </View>
                                    <View style={{width:'95%', marginBottom:20}}>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                        <Text style={[styles.linea, {marginLeft:35}]}></Text>
                                    </View>
                                </View> 
                                <Text style={styles.p}>Con el fin de verificar que efectivamente usted es el titular de los datos personales, es necesario que aporte una copia de su DNI o Pasaporte.</Text>
                                <Text style={styles.p}>Para ejercer el derecho de rectificación de los datos personales, el interesado deberá acompañar, cuando sea necesario, la documentación justificativa de la inexactitud  o del carácter incompleto de los datos personales tratados.</Text>
                                <Text style={styles.p}>En cumplimiento de la Ley Orgánica de Protección y de Garantías de Derechos Digitales, se le facilita la siguiente Información Básica sobre el tratamiento de los datos personales: que proporcione en cualquier momento a {finca.razonSocial}:</Text>
                                <Text style={[styles.p, styles.textoItalic, {fontSize:7}]}>
                                    <Text style={styles.textoNegritaItalic}> Responsable del tratamiento: </Text>
                                    {finca.razonSocial} con CIF {finca.nif}. 
                                    <Text style={styles.textoNegritaItalic}> Finalidades del tratamiento: </Text> 
                                    Responder a su petición de ejercicio de derechos ARSULIPO. 
                                    <Text style={styles.textoNegritaItalic}> Bases jurídicas del tratamiento: </Text>
                                    El cumplimiento de las obligaciones legales.
                                    <Text style={styles.textoNegritaItalic}> Destinatarios de sus datos: </Text> 
                                    No se cederán datos a terceros salvo que exista una obligación legal. 
                                    <Text style={styles.textoNegritaItalic}> Plazo de conservación de sus datos: </Text> 
                                    durante los plazos que imponga la ley o durante los cuales puedan derivarse responsabilidades para la Sociedad. 
                                    <Text style={styles.textoNegritaItalic}> Derechos: </Text> 
                                    puede ejercer sus derechos legales, entre otros, acceder, rectificar, suprimir, limitar, portar, oponerse al tratamiento de sus datos de manera gratuita contactando con la Sociedad presencialmente o por correo ordinario en {finca.direccion}, {finca.codigoPostal}, {finca.municipio} ({finca.provincia}) o {finca.emailContacto} adjuntando en ambos casos copia legible de su DNI u otro documento que acredite oficialmente su identidad. También presentar una reclamación ante la Agencia Española de Protección de Datos (<Link src="https://www.aepd.es">www.aepd.es</Link>). </Text>
                                <Text style={styles.p}>Firma del cliente:</Text>
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.h4}>14. CONCLUSIONES AUDITORÍA PROTECCIÓN DE DATOS</Text> 
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={styles.p}>A la vista de los aspectos revisados y susceptibles de comisión de una infracción por parte de la comunidad de propietarios y de las consecuencias de las mismas, es necesario que para la atenuación de su riesgo ante cualquier infracción en materia de protección de datos, la comunidad de propietarios lleve a cabo las siguientes actuaciones:</Text>
                                <View style={styles.ul}>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Cumplir con el artículo 24 del RGPD y tener el informe permanentemente actualizado. LAE Consulting Normativas, S.L. estará a vuestra disposición para cualquier duda que os surja en el 902 211 151.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Mantener un registro de actividades de tratamiento actualizado en virtud a lo establecido por los artículos 30 del RGPD y 31 de la LOPDGDD.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Aplicar todas aquellas medidas establecidas en el registro de actividades de tratamiento desarrollado por Lae Consulting Normativas, S.L.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Informar a los titulares de los datos personales, sobre el tratamiento de sus datos personales mediante las correspondientes cláusulas.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Aplicar los procedimientos para supuestos específicos establecidos en la presente auditoría.</Text>
                                    </View>
                                    <View style={styles.ol}>
                                        <Text style={[styles.lista, { marginHorizontal: 8 }]}>•</Text>
                                        <Text style={{marginRight: '2cm'}}>Realizar auditorías a fin de detectar posibles puntos críticos en el cumplimiento de las exigencias legales previstas en la normativa vigente en protección de datos.</Text>
                                    </View>
                                </View>
                                <Text style={styles.p}>LAE Consulting Normativas, S.L.</Text>
                                <Image src={Firma} style={{width:"100px"}} />
                            </View>
                        </Page>
                        <Page style={styles.body}>
                            <View style={styles.pageHeader} fixed>
                                <View >
                                    <Image src={Logo} style={{marginTop: 10, width:'18%', paddingLeft:5}} />
                                </View>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <Text style={[styles.h4, {marginTop:0, textDecoration:'underline', textAlign:'center'}]}>ACUERDO DE PRESTACIÓN DE SERVICIOS SIN ACCESO DE DATOS</Text>
                            </View>
                            <View style={{ marginHorizontal: '1cm' }}>
                                <View style={{flexDirection: "row"}}>
                                    <Text style={[styles.p, {}]}><Text style={styles.textoNegrita}>Comunidad: </Text>..................</Text>
                                    <Text style={styles.p}><Text style={styles.textoNegrita}>CIF: </Text>..................</Text>
                                </View>
                                <View style={{flexDirection: "row"}}>
                                    <Text style={[styles.p, {}]}><Text style={styles.textoNegrita}>Proveedor: </Text>..................</Text>
                                    <Text style={styles.p}><Text style={styles.textoNegrita}>CIF del proveedor: </Text>..................</Text>
                                </View>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>Servicio prestado: </Text></Text>
                                <Text style={styles.p}>............ , a .... de .............. de 20...</Text>
                                <Text style={[styles.p, styles.textoNegrita, {textAlign: 'center'}]}>CLÁUSULAS</Text>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>PRIMERA.- </Text>El proveedor .................. no podrá acceder a ficheros, documentos o cualquier otro tipo de soporte que contenga datos de carácter personal, cuya titularidad corresponda a su cliente.</Text>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>SEGUNDA. -</Text> En el caso de que el proveedor .................. tuviese acceso a datos de carácter personal de manera accidental o fortuita, se compromete a no revelar, transferir, ceder o de cualquier otra forma comunicar dichos datos, bajo ningún concepto o circunstancia, ya sea verbalmente o por escrito, por medios electrónicos, papel o mediante acceso informático, ni siquiera para su conservación, a Terceros.</Text>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>TERCERA.-</Text> El proveedor .................. se compromete y obliga a guardar secreto de todos los datos de carácter personal que pudiera llegar a conocer o tener acceso en ejecución de los servicios contratados en la comunidad. Las anteriores obligaciones se extienden a cualquier fase del tratamiento que de esos datos pudiera realizarse y subsistirán aún después de terminados los mismos.</Text>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>CUARTA.-</Text> En el supuesto de que el proveedor .................. utilice o comunique los datos de carácter personal incumpliendo las estipulaciones del presente Acuerdo, será considerada Responsable de Tratamiento, respondiendo directamente de las infracciones en que hubiese incurrido personalmente.</Text>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>QUINTA.-</Text>El proveedor .................. deberá resarcir a la comunidad por los daños y perjuicios que pudieran derivarse del incumplimiento de las obligaciones previstas en el presente acuerdo.</Text>
                                <Text style={styles.p}><Text style={styles.textoNegrita}>SEXTA.- JURISDICCIÓN APLICABLE</Text> Ante cualquier disputa o conflicto que pueda surgir relativa al cumplimiento del presente Acuerdo, ambas partes se someten a los Juzgados y Tribunales de domilicio del Responsable del tratamiento.</Text>
                                <Text style={styles.p}>Y para que conste a los efectos oportunos, en prueba de conformidad de las partes, firman el presente Acuerdo, por duplicado, en el lugar y la fecha indicados en el encabezamiento.</Text>
                                <Text style={styles.p}></Text>
                                <View style={{flexDirection: "row", justifyContent:'space-between'}}>
                                    <Text style={[styles.p, {fontSize:8}]}>Administrador de fincas</Text>
                                    <Text style={[styles.p, {fontSize:8, textAlign:'left'}]}>Proveedor</Text>
                                </View>
                                <View style={{flexDirection: "row", justifyContent:'space-between'}}>
                                    <Text style={[styles.p, {fontSize:8}]}>Secretario - Administrador</Text>
                                    <Text style={[styles.p, {fontSize:8}]}></Text>
                                </View>
                                <View style={{flexDirection: "row", justifyContent:'space-between'}}>
                                    <Text style={[styles.p, {fontSize:8}]}>Fdo.________________</Text>
                                    <Text style={[styles.p, {fontSize:8}]}>Fdo.________________</Text>
                                </View>
                            </View>
                            <View style={{marginBottom: 20, paddingTop: 20, marginHorizontal: '1cm'}} fixed></View>
                        </Page>
                    </Document>
                </PDFHandler>
            }
        </>
     );
}
 
export default withRouter(DocumentacionLOPD);