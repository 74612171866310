import React, { useState, useEffect } from 'react';
import { getAAFF, renovacionFincasAAFF } from '../../../services';
import { withRouter } from '../../../providers/withRouter';
import BreadCrumb from '../../../components/navbar/Breadcrumb';
import { CircularProgress, Button, TextField, Box, MenuItem, Select, InputLabel, FormControl} from "@mui/material";
import { IoSave } from "react-icons/io5";
import { formatoFechasEs, formatoFechas } from '../../../functions/funciones';
import { toast } from 'react-toastify';
import Swal from "sweetalert2";

const ListaCheck = (props) => {
  const [seleccionados, setSeleccionados] = useState([]);
  const [seleccionGeneral, setSeleccionGeneral] = useState(false);
  
  const [aaff, setAAFF] = useState([]);
  const [fincas, setfincas] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [recargar, setRecargar] = useState(false);
//   const [fechaVenta, setFechaVenta] = useState(formatoFechas(new Date()));
  const [datos, setDatos] = useState({});
  const [typeVenta, setTypeVenta] = useState('text');
//   const fincas = [1,2,3,4,5,6,7,8,9];

//   const fincas = [
//     { nombre: 'finca 1', id: 1 },
//     { nombre: 'finca 2', id: 2 },
//     { nombre: 'finca 3', id: 3 },
//     { nombre: 'finca 4', id: 4 },
//   ];

  useEffect(() => {
    fetchData()
  }, [recargar]);

  const fetchData = async () => {
    const responseAaffs = await getAAFF(props.params.nif);
    setAAFF(responseAaffs);
    setfincas(responseAaffs.properties);   
    setIsloading(false);
  }

  // Función para manejar la selección de un elemento individual
  const handleSeleccionIndividual = (id) => {
    const nuevosSeleccionados = { ...seleccionados };
    nuevosSeleccionados[id] = !nuevosSeleccionados[id];
    setSeleccionados(nuevosSeleccionados);
  };

  // Función para manejar la selección general
  const handleSeleccionGeneral = () => {
    setSeleccionGeneral(!seleccionGeneral);
    const nuevosSeleccionados = {};
    fincas.forEach((finca) => {
        if(!finca.renovada){
            nuevosSeleccionados[finca.id] = !seleccionGeneral;
        }
    });

    setSeleccionados(nuevosSeleccionados);
  };

  
  const handleFocusVenta = () => setTypeVenta('date');
  const handleBlurVenta = () => {
    if (!datos.fechaVenta) setTypeVenta('text');
  };


  // Obtén los elementos seleccionados
  const elementosSeleccionados = fincas.filter((finca) => seleccionados[finca.id]);

  const handleRenovarfincas = async () => {
    try {
        elementosSeleccionados.forEach(elemento => {
            elemento.fechaVenta = datos.fechaVenta;
            elemento.servicio = parseInt(datos.servicio);
        });

        let itemsOk = []
        let itemsErr = []
          
        if(elementosSeleccionados.length === 0){
            toast.error("No has seleccionado ninguna comunidad")
        }
        
        else if(datos.fechaVenta === undefined || datos.servicio === undefined){
            toast.error("Algún campo esta vacío")
        }else{
            let toastInfo = toast.info("Renovando comunidades...", { autoClose: false });
            const response = await renovacionFincasAAFF(elementosSeleccionados)
            response.forEach(item => {
                if (item.status === 200) {
                    itemsOk.push(item.msg)
                    toast.dismiss(toastInfo);
                    toast.success(item.msg);
                }
                else if(item.status === 400){
                    itemsErr.push(item.msg)
                    toast.dismiss(toastInfo);
                }
                else {
                    toast.error("error al renovar fincas");
                }
            })
            const html = `
                <div style="display: flex;">
                    <div style="flex: 1;">
                    <ul style="font-size:13px; margin-top:10px; text-align:left;">
                        ${itemsErr.map(item => `<li style="margin:5px 0;">${item}</li>`).join('')}
                    </ul>
                    </div>
                </div>
            `;
            if(itemsErr.length >0){
                Swal.fire({
                    title: 'Error al renovar estas comunidades',
                    html: html,
                    confirmButtonText: 'Cerrar',
                    icon: 'error'
                });
            }
            setRecargar(!recargar)
        }
        
    } catch (error) {
        console.log(error)
    }
  }

  const handleChange = (evt) => {
    setDatos({
        ...datos,
        [evt.target.name]: evt.target.value
    })
  }


  return (
    <>
        <BreadCrumb rutas={[{path:'/aaff', label:`Administrador`}, {path:'/aaff', label:`${aaff.razonSocial}`}]} style={{ margin: '0 10px'}} />
        <div className="listPage">
          <div className="gridCard">
              <div className="gridHead">
                  ADMINISTRADORES DE FINCAS
              </div>
          </div>
        </div>
        <div className="pageContent tableContainer">
            <div className="contentBar">
                <div className="buscadorFiltros">
                    <div style={{width:'100%'}}>
                    <Box
                        mx={1}
                        display={"block"}
                        flexDirection={"row"}
                        gap={2}
                    >
                        <TextField
                            type={typeVenta}
                            id="fechaVenta"
                            label="Fecha de venta"
                            // variant="outlined"
                            name='fechaVenta'
                            className="filtros"
                            value={datos.fechaVenta}
                            onChange={handleChange}
                            onFocus={handleFocusVenta}
                            onBlur={handleBlurVenta}
                            inputProps={{
                            "aria-label": "controlled",
                            }}
                        />
                        <FormControl variant="outlined" className='filtros-select'>
                            <InputLabel id="servicio-label">Servicio</InputLabel>
                            <Select
                                labelId="servicio-label"
                                id="servicio-select"
                                label="Servicio"
                                name="servicio"
                                className="filtros"
                                value={datos?.servicio}
                                onChange={handleChange}
                            >
                            <MenuItem value="" style={{height: 40}}></MenuItem>
                            <MenuItem value="3" style={{height: 40}}>Cae-Renovación</MenuItem>
                            <MenuItem value="4" style={{height: 40}}>Lopd-Renovación</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    </div>
                    <div>
                        <Button className="btn-Secondary" variant="contained" color="secondary" onClick={() => handleRenovarfincas()}>
                            <span style={{fontSize:'15px'}}>Renovar</span>
                        </Button>
                    </div>
                </div>
            </div>
            <div className='tableWrapper'>
                <table>
                    <thead>
                        <tr>
                            <th>
                                <input id="check" className='checkBoxCustomTop' style={{ color: '#ffffff', marginLeft: '5px'}} type="checkbox" checked={seleccionGeneral} onChange={handleSeleccionGeneral} />
                                <label for="check" className='labelCustom'  style={{width: '25px', borderRadius: '5px', border: '3px solid #ffffff'}}></label>
                            </th>
                            <th>NIF</th>
                            <th>Comunidad</th>
                            <th>Fecha</th>
                            <th>Servicio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="15" style={{ textAlign: "center" }}>
                                    <CircularProgress style={{ color: "#92004D" }} />{" "}
                                </td>
                            </tr>
                        ): fincas.length === 0 ? (
                            <tr>
                                <td colSpan="17" style={{ textAlign: "center" }}>
                                No hay fincas
                                </td>
                            </tr>
                        ): fincas.map((finca) => (
                            <React.Fragment key={finca.id}>
                                <tr>
                                    <td>
                                        <input
                                            id={`check${finca.id}`}
                                            className='checkBoxCustom'
                                            type="checkbox"
                                            checked={seleccionados[finca.id]}
                                            onChange={() => handleSeleccionIndividual(finca.id)}
                                        />
                                        <label for={`check${finca.id}`} className='labelCustom'></label>
                                    </td>
                                    <td>
                                        {finca.nif}
                                    </td>
                                    <td>
                                        {finca.razonSocial}
                                    </td>
                                    <td>
                                        {!finca.fechaRenovacion ? formatoFechasEs(finca.updatedAt) : formatoFechasEs(finca.fechaRenovacion)}
                                    </td>
                                    <td>
                                        Cae
                                    </td>
                                </tr>
                                {/* {finca?.workcenters === undefined || finca?.workcenters.length === 0 ? null : (
                                    <>
                                        {finca?.workcenters.map((c, j) => {
                                            return (
                                            <tr
                                                key={j}
                                                style={{
                                                backgroundColor:
                                                    j % 2 === 0 ? "#fffbf4" : "#fff9ea",
                                                }}
                                            >
                                                <td></td>
                                                <td colSpan={3} width={"100px"}>
                                                {c.razonSocial}
                                                </td>
                                            </tr>
                                            )
                                        })}
                                    </>
                                )} */}
                            </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
        {/* <div>
            <label>
                <input type="checkbox" checked={seleccionGeneral} onChange={handleSeleccionGeneral} />
                Seleccionar Todos
            </label>

            <ul>
                {fincas.map((finca) => (
                <li key={finca.id}>
                    <label>
                    <input
                        type="checkbox"
                        checked={seleccionados[finca.id]}
                        onChange={() => handleSeleccionIndividual(finca.id)}
                    />
                    {finca.razonSocial}, {finca.nif}
                    </label>
                </li>
                ))}
            </ul>

            <div>
                <h3>Elementos Seleccionados:</h3>
                <ul>
                {elementosSeleccionados.map((finca) => (
                    <li key={finca.id}>{finca.razonSocial}, {finca.nif}</li>
                ))}
                </ul>
            </div>
        </div> */}
        <style jsx="true">
            {`
              .btn-buscador {
                padding: 5px 10px;
                color: #ffffff;
                background-color: #92004D;
                border-radius: 4px;
                font-weight: bold;
                width: -moz-fit-content;
                width: fit-content;
                align-self: flex-end;
                border: none;
                min-width:40px;
                height:40px;
                margin: 5px 0;
              }
              .btn-buscador:hover{
                color: black;
                background-color: #dee2e6;
              }    
            `}
          </style>
    </>
  );
};

export default withRouter(ListaCheck);