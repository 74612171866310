import React from "react";
import ModalSelector from "./ModalSelector";
import DataTable from "../Tables/DataTable";
import { useServiceToSale } from "./../../hooks";
import { Typography, Box, TextField } from "@mui/material";
import { Iconify } from "../../utilities";

const ServiceToSaleSelector = ({open, onClose}) => {
    const { loading, servicios, fetchData } = useServiceToSale(); 
    const headers = [ 
        {
            id: "id",
            numeric: false,
            disablePadding: false,
            label: "ID",
        },
        {
            id: "service_name",
            numeric: false,
            disablePadding: false,
            label: "NOMBRE",
        },
    ];

    const handleSelect = (row) => {
        onClose(row);
    };

    const handleFetchData = async () => {
        try {
            await fetchData();
        } catch (error) {
            console.log(error);
        }
    }
    return ( 
        <ModalSelector
            open={open}
            onClose={onClose}
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                p: 2,
                pt: 8,
                gap: 5,
                alignItems: "center",
            }}
        >
            <Box
                sx={{ position: "absolute", right: 10, top: 5 }}
                onClick={() => handleSelect()}
            >
                <Iconify icon="mdi:close" sx={{ height: 24, width: 24 }} />
            </Box>
            <Typography variant="h4">Seleccionar Servicio</Typography>
            <DataTable
                data={servicios?.rows}
                meta={servicios?.meta}
                isLoading={loading}
                headers={headers}
                onClickRow={handleSelect}
                fetcher={handleFetchData}
                clicable
                // currentPage={servicios?.current_page}
            >
            </DataTable>
        </ModalSelector>
    );
}
 
export default ServiceToSaleSelector;