import React, { useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { Link } from "react-router-dom";
import { getFinca } from "../../../services";
import {
  cambiarFormatoNombre,
} from "../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Ventas from "../general/Ventas";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../../components/tabs/Tabs";
import CircularProgress from "@mui/material/CircularProgress";
import { bool } from "prop-types";



const Comunidad = (props) => {
  const [finca, setFinca] = useState({});
  const [proveedores, setProveedores] = useState([]);
  const [value, setValue] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const hasFetchedData = useRef(false); 
 

  useEffect(() => {
    if (!hasFetchedData.current) {  
      fetchData();
      hasFetchedData.current = true; 
    }
  }, []); 

  const fetchData = async () => {

    try {
      const res = await getFinca(props.params.nif);
      setFinca(res);
      console.log(res)
      const ultimaVenta = res.sales[res.sales.length - 1];
      console.log(ultimaVenta)
      if(ultimaVenta.estado_id !== 4){ // si la comunidad es baja que no muestre sus proveedores
        setProveedores(res.suppliers);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 


  const handleChange = (event, newValue) => {
    setValue(newValue);
    
  };

  return  isLoading ? <CircularProgress style={{color:'#92004D', position: 'absolute', top: '50%', left: '50%'}} /> : 
    <>
      <BreadCrumb rutas={[{path:'/comunidades/', label:"Comunidades"}, {path:`/comunidades/${props.params.nif}`, label:`${finca?.razonSocial}`}]} style={{ margin: '0 10px'}} />
      <div className="pageContent">
        <div className="gridCard">
          <div className="gridHead">INFORMACIÓN GENERAL</div>
          <div className="gridSubhead">DATOS COMUNIDAD</div>
          <div className="datosGrid">
            <p>
              <b>Nombre comunidad:</b> {finca?.razonSocial}
            </p>
            <p>
              <b>Dirección:</b> {finca?.direccion}
            </p>
            <p>
              <b>NIF:</b> {finca?.nif}
            </p>
            <p>
              <b>Municipio:</b> {cambiarFormatoNombre(finca?.municipio)}
            </p>
            <p>
              <b>Persona de contacto:</b>{" "}
            </p>
            <p>
              <b>CP:</b> {finca?.codigoPostal}
            </p>
            <p>
              <b>Email:</b> {finca?.email}
            </p>
            <p>
              <b>Provincia:</b> {finca?.provincia}
            </p>
            <p>
              <b>Telefono:</b> {finca?.telefonoContacto}{" "}
            </p>
            <p>
              <b>Presidente:</b> {finca?.nombrePresidente}{" "}
            </p>
            {/* <p><b>Fecha de alta:</b> {new Date(finca.fechaAlta.seconds * 1000).toLocaleDateString()}</p> */}
          </div>
          <div style={{ paddingBottom:'5%' }}>
            <div className="gridSubhead">SERVICIOS COMUNIDAD</div>
            <Box sx={{ padding: '10px 40px', border: 'none' }}>
              { !finca?.gruposServicios || finca?.gruposServicios.length === 0 ? "No tiene documentos" :
                <Tabs value={value} onChange={handleChange} aria-label="tabs example">
                  { finca?.gruposServicios?.includes(1) && <Tab style={{ color:"#92004D" }} label="CAE" value={1} /> }
                  { finca?.gruposServicios?.includes(2) && <Tab style={{ color:"#92004D" }} label="LOPD" value={2} /> }
                </Tabs>
              }
            </Box>
            {finca?.gruposServicios?.map((grupoServicio) => (
                <TabPanel key={grupoServicio} value={value} index={grupoServicio}>
                    <div className="pageGrid" style={{ gap:0 }}>
                      {grupoServicio === 1 && (
                        <>
                          <div id="ventas" style={{ /* height: height + 'px' */ }}>
                            <Ventas nifFinca={props.params.nif} idFinca={finca.id} grupoServicio={1} />
                          </div>
                          <div id="proveedores" className="tableContainer">
                            <div className="gridHeadProv">PROVEEDORES</div>
                            <div style={{ /* height: height-80 + 'px', overflow: 'auto' */ }}>
                              <table>
                              {/* <thead>
                                <tr
                                  style={{
                                    fontSize: 10,
                                    fontWeight: "normal",
                                    textAlign: "center",
                                  }}
                                >
                                  <th></th>
                                </tr>
                              </thead> */}
                              <tbody>
                                {proveedores.length === 0 ? <small style={{ paddingLeft: '14px' }}>No tiene proveedores</small> : 
                                proveedores.map((e, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        style={{
                                          width: "100%",
                                          padding: 10,
                                        }}
                                      >
                                        <Link to={"/proveedores/" + e.nif}>
                                          {e.razonSocial}
                                          <p style={{ opacity: 0.55, fontSize: 14 }}>
                                            {e?.serviceSupps?.length > 0 ? e?.serviceSupps?.find( serv => serv.id === e.service_to_propertie?.service)?.nombre : null}
                                          </p>
                                        </Link>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                              </table>
                            </div>
                          </div>
                        </>
                      )}
                      {grupoServicio === 2 && (
                        <Ventas nifFinca={props.params.nif} grupoServicio={2}/>
                      )}
                      {/* Agrega más condiciones para otros grupos de servicio si es necesario */}
                    </div>
                  </TabPanel>
              ))}
          </div>
        </div>
      </div>
      <style jsx="true">{`
        .css-1aquho2-MuiTabs-indicator {
          background-color: #92004D;
        }
        .gridHeadProv {
          border-radius: 5px 5px 0 0;
          background-color: #92004D;
          color: #ffffff;
          padding: 10px 15px;
          font-weight: bold;
          font-size: 12px;
          height: 34px;
        }
        #ventas{
          width:70%;
        }
        #proveedores{
          width:30%;
        }
        .pageGrid{
          display:flex;
          flex-direction: row;
        }
        @media screen and (max-width: 992px) {
          #ventas{
            overflow-y: auto;
            width:100%;
          }
          #proveedores{
            width:100%;
          }
          .pageGrid{
            flex-direction: column;
          }
        }
      `}</style>
    </>
  
};

export default withRouter(Comunidad);
