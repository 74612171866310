import { useCallback } from "react";
import Swal from "sweetalert2";

export const useSwal = () => {
  const showAlert = useCallback(async (options) => {
    // Validar propiedades esenciales
    if (!options.title) {
      throw new Error("La propiedad 'title' es obligatoria.");
    }
    // if (!options.text) {
    //   throw new Error("La propiedad 'text' es obligatoria.");
    // }

    const defaultOptions = {
      icon: false, // Sin icono por defecto
      showCancelButton: true,
      showDenyButton: false, // Por defecto, no se muestra el botón "No"
      confirmButtonText: "Aceptar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#3085d6",
      denyButtonColor: "#FF5252",
      cancelButtonColor: "#d33",
    };

    const finalOptions = {
      ...defaultOptions,
      ...options,
      ...(options.icon !== undefined ? { icon: options.icon } : {}),
      showDenyButton: options.denyButtonText ? true : false,

    };

    const result = await Swal.fire(finalOptions);
    return result;
  }, []);

  return showAlert;
};
