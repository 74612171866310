import React, { useState } from 'react';
import { withRouter } from '../../../providers/withRouter';
import BreadCrumb from '../../../components/navbar/Breadcrumb';
import { BsDownload, BsUpload } from "react-icons/bs";
import { subirListado, subirListadoProveedores, subirListadoMatcheo, subirListadoAAFF, subirListadoRenovaciones, subirListadoLOPD } from '../../../functions/documentacion';

const DescargarPlantillas = () => {
    const [subida, setSubida] = useState(false);
    

    const handleSubidaFichero = (funcionSubida, id) => async (event) => {
        setSubida(true);
       

        try {
            await funcionSubida(event);
        } catch (error) {
            console.error(`Error al subir ${id}:`, error);
        } finally {
            setSubida(false);
            event.target.value = '';
            
        }
    };

    return ( 
        <>
            <BreadCrumb rutas={[{ path:'/descargar-plantillas', label:'Plantillas' }]} />
            <div className="listPage">
                <div className="gridCard">
                    <div className="gridHead">
                        PLANTILLAS
                    </div>
                </div>
            </div>    
            <div className="pageContent tableContainer">
                <div className='tableWrapper'>
                    <table>
                        <thead>
                            <tr>
                                <th>NOMBRE</th>
                                <th>DESCARGAR PLANTILLA</th>
                                <th>SUBIR LISTADO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Administradores de fincas</td>
                                <td>
                                    <div className="bigBtn" style={{ padding: '4px 0'}}>
                                        <div className="descargarBtn">
                                            <a href="/files/plantillaAAFF.xlsx" download="plantillaAAFF.xlsx">
                                                <BsDownload alt="descargar plantilla de administradores de fincas" />
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={`bigBtn ${subida ? 'disabled' : ''}`} style={{ padding: '4px 0', opacity: subida ? 0.5 : 1 }}>
                                        <label htmlFor="listadoAAFF">
                                            <div className="listadoBtn"><BsUpload alt="subir listado de administradores de fincas"/></div>
                                        </label>
                                        <input
                                            type="file"
                                            id="listadoAAFF"
                                            name="listadoAAFF"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            onChange={handleSubidaFichero(subirListadoAAFF, 'listadoAAFF')}
                                            className="inputfile"
                                            disabled={subida}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Fincas CAE</td>
                                <td>
                                    <div className="bigBtn" style={{ padding: '4px 0'}}>
                                        <div className="descargarBtn">
                                            <a href="/files/plantillaFincas.xlsx" download="plantillaFincas.xlsx">
                                                <BsDownload alt="descargar plantilla de fincas" />
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={`bigBtn ${subida ? 'disabled' : ''}`} style={{ padding: '4px 0', opacity: subida ? 0.5 : 1 }}>
                                        <label htmlFor="listadoFincas">
                                            <div className="listadoBtn"><BsUpload alt="subir listado de fincas"/></div>
                                        </label>
                                        <input
                                            type="file"
                                            id="listadoFincas"
                                            name="listadoFincas"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            onChange={handleSubidaFichero(subirListado, 'listadoFincas')}
                                            className="inputfile"
                                            disabled={subida}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Proveedores</td>
                                <td>
                                    <div className="bigBtn" style={{ padding: '4px 0'}}>
                                        <div className="descargarBtn">
                                            <a href="/files/plantillaProveedores.xlsx" download="plantillaProveedores.xlsx">
                                                <BsDownload alt="descargar plantilla de proveedores" />
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={`bigBtn ${subida ? 'disabled' : ''}`} style={{ padding: '4px 0', opacity: subida ? 0.5 : 1 }}>
                                        <label htmlFor="listadoProveedores">
                                            <div className="listadoBtn"><BsUpload alt="subir listado de Proveedores" /></div>
                                        </label>
                                        <input
                                            type="file"
                                            id="listadoProveedores"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            onChange={handleSubidaFichero(subirListadoProveedores, 'listadoProveedores')}
                                            className="inputfile"
                                            disabled={subida}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Matcheo</td>
                                <td>
                                    <div className="bigBtn" style={{ padding: '4px 0'}}>
                                        <div className="descargarBtn">
                                            <a href="/files/plantillaMatcheo.xlsx" download="plantillaMatcheo.xlsx">
                                                <BsDownload alt="descargar plantilla de Matcheo" />
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={`bigBtn ${subida ? 'disabled' : ''}`} style={{ padding: '4px 0', opacity: subida ? 0.5 : 1 }}>
                                        <label htmlFor="listadoMatcheo">
                                            <div className="listadoBtn"><BsUpload alt="subir listado de matcheo" /></div>
                                        </label>
                                        <input
                                            type="file"
                                            id="listadoMatcheo"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            onChange={handleSubidaFichero(subirListadoMatcheo, 'listadoMatcheo')}
                                            className="inputfile"
                                            disabled={subida}
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>Fincas LOPD</td>
                                <td>
                                    <div className="bigBtn" style={{ padding: '4px 0'}}>
                                        <div className="descargarBtn">
                                            <a href="/files/plantillaLOPD.xlsx" download="plantillaLOPD.xlsx">
                                                <BsDownload alt="descargar plantilla de lopd" />
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div className={`bigBtn ${subida ? 'disabled' : ''}`} style={{ padding: '4px 0', opacity: subida ? 0.5 : 1 }}>
                                        <label htmlFor="listadoLOPD">
                                            <div className="listadoBtn"><BsUpload alt="subir listado de fincas lopd"/></div>
                                        </label>
                                        <input
                                            type="file"
                                            id="listadoLOPD"
                                            name="listadoLOPD"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                            onChange={handleSubidaFichero(subirListadoLOPD, 'listadoLOPD')}
                                            className="inputfile"
                                            disabled={subida}
                                        />
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default withRouter(DescargarPlantillas);
