import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  MenuItem,
  Select,
  Typography,
  styled,
  Button,
  Tooltip,
} from "@mui/material";
import { toast } from "react-toastify";
import { withRouter } from "../../../providers/withRouter";
import { UsuarioContext } from "../../../providers/userContext";
import {
  DataTable,
  ProgressBarWithColors,
  SmoothModal,
} from "../../../components";
import { Iconify } from "../../../utilities";
import { useAAFFs } from "../../../hooks";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import FiltrosAAFF from "../../../components/filtros/filtrosAAFF";
import NoVisitadoAAFF from "../../../components/noVisitadoAAFF";
import noVisitada from "../../../icons/noVisitada.svg";
import notaNV from "../../../icons/notaNV.svg";
import { Link } from "react-router-dom";
import {
  updateComentsDetailsCaes
} from "../../../services";

import { useLocation, useNavigate } from 'react-router-dom';
import { useRecogerURLParamsFiltros, construirURLParamsFiltros } from './../../../functions/parametrosURL/funcionesParamsURL';
import { FaEye } from "react-icons/fa";
import criticalWarning from "../../../icons/criticalWarning.svg";

const motivosNoVisita = [
  "El AAFF no quiere visita en la renovación",
  "El AAFF no quiere que empecemos todavía las visitas",
];

const ColorButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#92004D",
  "&:hover": {
    backgroundColor: "#92004D",
  },
  alignItems: "center",
}));

const AAFF = () => {


  const user = useContext(UsuarioContext);

  const {filtrosIniciales} = useRecogerURLParamsFiltros();
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [recargar, setRecargar] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [comentario, setComentario] = useState(motivosNoVisita[0]);
  const [selectedAAFF, setSelectedAAFF] = useState(null);
  const [busquedaActiva, setBusquedaActiva] = useState(false);
  const [clearFilterActivo, setClearFilterActivo] = useState(false);
  const { loading, data, fetchData } = useAAFFs();


  // const handleOpenNewTab = ({ nif }) => {
  //   if (!nif) return;
  //   window.open(`aaff/${nif}/comunidades`, "_blank");
  // };

  // const handlePagination = ({ offset, limit }) => {
  //   const { id } = user
  //   // setPagination({ page: offset, limit });
  //   fetchData({ offset, limit, filtros: busqueda });
  // };

  const handleOpenModal = (id) => {
    setSelectedAAFF(id);
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setSelectedAAFF(null);
  };

  const navigate = useNavigate();
  const location = useLocation();

  const clearFilters = () => {
    setBusqueda({});
    setClearFilterActivo(true);
  }

  const comunidadesRows = [
    {
      id: "razonSocial",
      numeric: false,
      disablePadding: false,
      label: "ADMINISTRADOR",
      overrideFunc: (data, row) => (
        <Link to={"/aaff/" + row.nif + "/comunidades"}>
          {data}
        </Link>
      ),
    },
    // {
    //   id: "fechaEncargo",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "FECHA ENCARGO",
    // },
    {
      id: "municipio",
      numeric: false,
      disablePadding: false,
      label: "MUNICIPIO",
    },
    {
      id: "provincia",
      numeric: false,
      disablePadding: false,
      label: "Provincia",
    },

    {
      id: "communityNumbers",
      numeric: false,
      disablePadding: false,
      label: "Nº DE COMUNIDADES",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <Typography>
          {row.visitadas} /{row.comunidades}
        </Typography>
      ),
    },
    {
      id: "communityPercent",
      numeric: false,
      disablePadding: false,
      label: "COMPLETADO (VISITADO)",
      noObjeto:true,
      overrideFunc: (data, row) => (

        <ProgressBarWithColors
          value={
            (row.visitadas / (row.comunidades || 1)) * 100
          }
          label={
            parseFloat(
              ((row.visitadas / (row.comunidades || 1)) * 100).toFixed(2)
            ) + "%"
          }
        />
      ),
    },
    {
      id: "propCaducadas",
      numeric: false,
      disablePadding: false,
      label: "",
      noObjeto: true,
      overrideFunc: (data) => (
        <>
          {data ?
            <Tooltip title="Hay comunidades caducadas" arrow>
              <img src={criticalWarning} width={15} alt="comunidades caducadas" />
            </Tooltip>
            : null}
        </>
      ),
    },
    {
      id: "noVisitada",
      numeric: true,
      disablePadding: false,
      label: "NO VISITA",
      noObjeto:true,
      overrideFunc: (data, row) => (
        <>
          <NoVisitadoAAFF aaff={row} nif={row.nif} />
        </>
      ),
    },

  ];

  useEffect(() => {
    setRecargar(false);
  }, [recargar]);

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    // fetchData();
  }

  const handleSubmitMotivo = async () => {
    const data = {
      id: selectedAAFF,
      motivo: comentario
    };
    

    try {

      await updateComentsDetailsCaes(data);

      closeModal();
      toast.success("Datos guardados correctamente", {
        autoClose: true
      });
    } catch (error) {

      console.error("Error al guardar los datos:", error);
      toast.error("Error al guardar los datos", {
        autoClose: true
      });
    }
  };


  return (
    <>
      <SmoothModal open={openModal} onClose={closeModal}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5" fontWeight={"bold"}>
            Motivo de No Visita
          </Typography>

          <Box sx={{ width: "90%" }} >
            <Select native name="motivoNoVisita" id="motivoNoVisita" style={{ textTransform: 'uppercase' }} onChange={(e) => setComentario(e.target.value)} defaultValue={comentario}>
              <option value=""></option>
              {motivosNoVisita.map(motivo => {
                return (
                  <option key={motivo} value={motivo}>
                    {motivo}
                  </option>
                );
              })}
            </Select>
          </Box>

          <Box display={"flex"} flexDirection={"row"} gap={2}>
            <ColorButton onClick={handleSubmitMotivo}>
              <Typography>Guardar</Typography>
            </ColorButton>
            <Button onClick={closeModal}>Cancelar</Button>
          </Box>
        </Box>
      </SmoothModal>
      <BreadCrumb
        rutas={[{ path: "/aaff", label: "Administradores de fincas" }]}
        style={{ margin: "0 10px" }}
      />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            ADMINISTRADORES DE FINCAS
          </div>
        </div>
      </div>
      <div className="pageContent tableContainer">
        <FiltrosAAFF onFilter={filtrarDatos} clearFilter={clearFilters} filtrosIniciales={busqueda} />
        <div className="tableWrapper">
          <Box className="pageContent">
            <Box sx={{ mb: 10 }}>
              <DataTable
                data={data?.rows}
                isLoading={loading}
                headers={comunidadesRows}
                fetcher={fetchData}
                currentPage={data?.current_page}
                meta={data?.meta}
                totalItems={data.total}
                totalPages={data.totalPages}
                nextPage={data.next_page}
                filtros={busqueda}
                busquedaActiva={busquedaActiva}
                setBusquedaActiva={setBusquedaActiva}
                clearFilterActivo={clearFilterActivo}
                setClearFilterActivo={setClearFilterActivo}
                mensaje={"No hay administradores de fincas"}
              />
            </Box>
          </Box>
        </div>
      </div>

    </>
  );
};

export default withRouter(AAFF);
