import React, { useState, useEffect, useContext } from "react";

import SmoothModal from "../../../components/Modals/SmoothModal";
import { Box, Button, FormControl, InputLabel, Select, TextField, Typography } from "@mui/material";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { cambiarProveedor } from "./../../../services";
import { toast } from "react-toastify";


import {
    municipiosIniciales,
    cambiarFormatoNombre,
} from "../../../functions/funciones";

import { ordenarProvinciasYMunicipios } from "../../../functions/ordenarListas";

import LaeButton from "../../../components/Buttons/LaeButton";
import { UsuarioContext } from "../../../providers/userContext";

import { verificarCodigoPostal, verificarCorreo, verificarTelefono } from "../../../functions/validator/formValidator";

const EditarDatosProveedor = (props) => {


    const [editarDatos, setEditarDatos] = useState({});
    const [listadoMunicipios, setListadoMunicipios] = useState([]);

    useEffect(() => {
        setListadoMunicipios(
            municipiosIniciales(provinces, municipalities, props.data?.provincia)
        );
    }, [props.data]);


    function handleChange(evt) {
        const value = evt.target.value;
    
        setEditarDatos({
            ...editarDatos,
            [evt.target.name]: value
        });
    }
    const user = useContext(UsuarioContext);

    const editar = (data) => {
        let keys = Object.keys(data);
       
        let errores = false;

        keys.forEach(key => {
            switch (key) {
                case "codigoPostal":
                    let codigoPostalCorrecto = verificarCodigoPostal(data.codigoPostal);
                    if (!codigoPostalCorrecto) {
                        toast.error("El código postal debe ser de 5 caracteres");
                        errores = true;
                    }
                    break;
                case "emailContacto":
                    let correoCorrecto = verificarCorreo(data.emailContacto);
                    if (!correoCorrecto) {
                        toast.error("El email de contacto es incorrecto");
                        errores = true;
                    }
                    break;
                case "telefonoContacto":
                    let telefonoCorrecto = verificarTelefono(data.telefonoContacto);
                    if (!telefonoCorrecto) {
                        toast.error("El teléfono de contacto es incorrecto");
                        errores = true;
                    }
                    break;
                default:
                    break;
            }
        });


        if (errores) {
            return;
        }


        cambiarProveedor(user.nif, data).then(res => {
            
            if (res.status === 403) {
                toast.error(res.data.error[0]);
                
            }
            if (res.status >= 200 && res.status < 300) {
                toast.success("Datos editados con éxito");
                props.handleClose();
            }


        }).catch(err => {
            console.log(err);
            toast.error("Tienes algún campo vacio o mal selecionado ");
        });
    };



    return (
        // <SmoothModal open={showModal} onClose={onClose} sx={{ width: 800 }}>
        <SmoothModal open={props.showModal}
            onClose={props.handleClose} sx={{ width: 800 }}>
            <Box className="lae-flex lae-flex-column">
                <Typography variant="h4">Editar Datos</Typography>
                <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
                    <TextField
                        id="razonSocial"
                        name="razonSocial"
                        label="Nombre del Proveedor"
                        fullWidth
                        size="small"
                        defaultValue={props.data?.razonSocial}
                        onChange={handleChange}
                    ></TextField>
                    <TextField
                        id="direccion"
                        name="direccion"
                        label="Dirección"
                        fullWidth
                        size="small"
                        defaultValue={props.data?.direccion}
                        onChange={handleChange}
                    ></TextField>
                </Box>
                <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
                    <TextField
                        id="nombreContacto"
                        name="nombreContacto"
                        label="Persona de Contacto"
                        fullWidth
                        size="small"
                        defaultValue={props.data?.nombreContacto}
                        onChange={handleChange}
                    ></TextField>
                    <TextField
                        id="emailContacto"
                        name="emailContacto"
                        label="Email de Contacto"
                        fullWidth
                        size="small"
                        defaultValue={props.data?.emailContacto}
                        onChange={handleChange}
                    ></TextField>
                </Box>
                <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
                    <TextField
                        type="tel"
                        id="telefonoContacto"
                        name="telefonoContacto"
                        label="Teléfono"
                        fullWidth
                        size="small"
                        defaultValue={props.data?.telefonoContacto}
                        onChange={handleChange}
                    ></TextField>
                    <TextField
                        id="codigoPostal"
                        name="codigoPostal"
                        label="C.P"
                        fullWidth
                        size="small"
                        defaultValue={props.data?.codigoPostal}
                        onChange={handleChange}
                    ></TextField>
                </Box>
                <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
                    <FormControl variant="outlined" style={{ width:'100%'}}>
                        <InputLabel htmlFor="provincia">Provincia</InputLabel>
                        <Select
                            label="provincia"
                            native
                            name="provincia"
                            id="provincia"
                            style={{ textTransform: "uppercase" }}
                            onChange={handleChange}
                            defaultValue={props.data?.provincia}
                            size="small"
                            fullWidth
                        >
                            <option value=""></option>
                            {provinces.sort(ordenarProvinciasYMunicipios)?.map((e, i) => {
                                return (
                                    <option key={i} value={e.name}>
                                        {e.name}
                                    </option>
                                );
                            })}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" style={{ width:'100%'}}>
                        <InputLabel htmlFor="municipio">Municipio</InputLabel>
                        <Select
                            label="municipio"
                            native
                            name="municipio"
                            id="municipio"
                            style={{ textTransform: "uppercase" }}
                            onChange={handleChange}
                            defaultValue={props.data?.municipio}
                            fullWidth
                            size="small"
                        >
                            <option value=""></option>
                            {listadoMunicipios
                                .sort(ordenarProvinciasYMunicipios)
                                ?.map((e, i) => {
                                    return (
                                        <option key={i} value={e.value}>
                                            {e.name}
                                        </option>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Box>
                <Box className="lae-flex lae-flex-row" sx={{ gap: 3 }}>
                    <Button
                        variant="contained"
                        sx={{
                            flex: 1,
                            backgroundColor: "whitesmoke",
                            color: "#92004D",
                            ":hover": { backgroundColor: "#92004D", color: "white" },
                            margin: 0,
                            p: 1,
                        }}
                        disableElevation
                        onClick={props.handleClose}
                    >
                        Cancelar
                    </Button>
                    <LaeButton sx={{ flex: 1 }} onClick={() => editar(editarDatos)}>
                        Guardar
                    </LaeButton>
                </Box>
            </Box>
        </SmoothModal>
    );
};

export default React.memo(EditarDatosProveedor);
