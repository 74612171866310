import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import Minus from "../../../icons/minus.svg";
import Documento from "../../../icons/document.svg";
import Aviso from "../../../icons/email.svg";
import { Link } from "react-router-dom";
import HeaderBackground from "../../../images/banner_proveedores.jpg";
import { abrirArchivo } from "../../../providers/documentos";
import { UsuarioContext } from "../../../providers/userContext";
import { getProveedor, getUserPropertieList } from "../../../services";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import { cambiarFormatoNombre } from "../../../functions/funciones";
import CircularProgress from "@mui/material/CircularProgress";

const Proveedor = (props) => {
  const user = useContext(UsuarioContext);
  const [proveedor, setProveedor] = useState({});
  const [fincas, setFincas] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    getProveedor(props.params.nifProveedor)
      .then((res) => {
        let proveedor = {
          ...res,
          documentos: {
            lopd: { pendiente: true, ruta: "", validado: false },
            seguro: { pendiente: false, ruta: "", validado: true },
            vigilancia: { pendiente: false, ruta: "", validado: false },
            evaluacionRiesgos: { pendiente: false, ruta: "", validado: true },
          }
        }
        setProveedor(proveedor);
        getUserPropertieList(props.params.nifProveedor)
          .then((resP) => {
            setFincas(resP.properties)
          })
          setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        // window.location.replace("/");
      });
  }, []);

  return isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
    <>
      <>
        <BreadCrumb rutas={[{ path: '/proveedores/', label: "Proveedores" }, { path: `/proveedores/${props.params.nif}`, label: `${proveedor?.razonSocial}` }]} style={{ margin: '0 10px' }} />
        <div className="pageGrid">
          <div className="gridCard">
            <div className="gridHead">INFORMACIÓN GENERAL</div>
            <div className="gridSubhead">DATOS EMPRESA</div>
            <div className="datosGrid">
              <p>
                <b>Nombre comunidad:</b> {proveedor.razonSocial}
              </p>
              <p>
                <b>Dirección:</b> {proveedor.direccion}
              </p>
              <p>
                <b>NIF:</b> {proveedor.nif}
              </p>
              <p>
                <b>Municipio:</b> {cambiarFormatoNombre(proveedor?.municipio)}
              </p>
              <p>
                <b>Persona de contacto:</b> {proveedor.nombreContacto}
              </p>
              <p>
                <b>CP:</b> {proveedor.codigoPostal}
              </p>
              <p>
                <b>Email:</b> {proveedor.emailContacto}
              </p>
              <p>
                <b>Provincia:</b> {proveedor.provincia}
              </p>
              <p>
                <b>Telefono:</b> {proveedor.telefonoContacto}
              </p>
              <p>
                <b>Última reclamación:</b>{" "}
                {proveedor.ultimaReclamacion !== undefined &&
                  proveedor.ultimaReclamacion > proveedor.createdAt
                  ? new Date(proveedor.ultimaReclamacion).toLocaleDateString()
                  : "-"}
              </p>
            </div>
            <div className="gridSubhead">DOCUMENTOS EMPRESA</div>
            <table style={{ margin: 15 }}>
              <thead>
                <tr
                  style={{
                    fontSize: 10,
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  <th></th>
                  <th></th>
                  <th style={{ margin: 5 }}>Ver</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Certificado LOPD{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.pendiente && !proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(proveedor?.docssupps?.find(d => d.tipoDoc === 1)?.ruta)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Seguro de responsabilidad civil{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>
                      {proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.fechaCaducidad !== null
                        ? new Date(
                          proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.fechaCaducidad
                        ).toLocaleDateString()
                        : "-"}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.pendiente && !proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(proveedor?.docssupps?.find(d => d.tipoDoc === 2)?.ruta)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Justificante de pago de Servicio de Prevención Ajeno{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>
                      {proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.fechaCaducidad !==
                        null
                        ? new Date(
                          proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.fechaCaducidad
                        ).toLocaleDateString()
                        : "-"}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.pendiente && !proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(proveedor?.docssupps?.find(d => d.tipoDoc === 3)?.ruta)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Planificación preventiva{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.pendiente && !proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(
                          proveedor?.docssupps?.find(d => d.tipoDoc === 4)?.ruta
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Evaluación de riesgos{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.pendiente && !proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(
                          proveedor?.docssupps?.find(d => d.tipoDoc === 5)?.ruta
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Certificado corriente de pago Seguridad Social{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>
                      {proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.fechaCaducidad !==
                        null
                        ? new Date(
                          proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.fechaCaducidad
                        ).toLocaleDateString()
                        : "-"}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.pendiente && !proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(proveedor?.docssupps?.find(d => d.tipoDoc === 6)?.ruta)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Vigilancia de la salud del trabajador{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>
                      {proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.fechaCaducidad !==
                        null
                        ? new Date(
                          proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.fechaCaducidad
                        ).toLocaleDateString()
                        : "-"}
                    </small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.pendiente && !proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(proveedor?.docssupps?.find(d => d.tipoDoc === 7)?.ruta)
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {proveedor.documentos?.declaracionResponsable?.[user.id]
                      ?.pendiente ? (
                      <img src={Minus} alt="Pendiente" className="tableIcon" />
                    ) : proveedor.documentos?.declaracionResponsable?.[user.id]
                      ?.validado ? (
                      <img src={Tick} alt="Validado" className="tableIcon" />
                    ) : (
                      <img src={Cross} alt="No validado" className="tableIcon" />
                    )}
                  </td>
                  <td style={{ width: "100%" }}>
                    Declaración responsable{" "}
                    <small style={{ display: "block", margin: "5px 0" }}>-</small>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <img
                      style={!proveedor?.documentos?.declaracionResponsable?.pendiente && !proveedor?.documentos?.declaracionResponsable?.validado ? { opacity: 0.4, cursor: "default" } : {}}
                      src={Documento}
                      alt="Ver"
                      className="tableIcon"
                      onClick={() =>
                        abrirArchivo(
                          proveedor.documentos?.declaracionResponsable?.[user.id]
                            ?.ruta
                        )
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="gridCard">
            <div className="gridHead">FINCAS</div>
            <table style={{ margin: 15 }}>
              <thead>
                <tr
                  style={{
                    fontSize: 10,
                    fontWeight: "normal",
                    textAlign: "center",
                  }}
                >
                  <th></th>
                  <th>{/* Aviso */}</th>
                </tr>
              </thead>
              <tbody>
                {fincas?.length === 0 || fincas === undefined ? "No tiene comunidades" :
                fincas?.map((e, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ width: "100%", padding: "5px 0" }}>
                        <Link to={"/comunidades/" + e.nif}>{e.razonSocial}</Link>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        {/* <img src={Aviso} alt="Aviso" className="tableIcon" /> */}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    </>
};

export default withRouter(Proveedor);
