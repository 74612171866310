import axios from "axios";
import { loginAs } from "./aaffs.service";
import jwt_decode from "jwt-decode";

export const signIn = async ({ email, password }) => {
  const data = { email, password };
  try {
    const response = await axios.post("auth/login", data);
    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("numActivo", 0);
      //window.location.replace("/Inicio");
    }
    return response;
  } catch (err) {
    console.log(err.response);
    //return window.location.replace("/login")
  }
};
export const firstSignIn = async ({ email, password }) => {
  const data = { email, password };
  try {
    const response = await axios.post("auth/first-login", data);
    if (response.status === 200) {
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("numActivo", 0);
      window.location.replace("/");
    }
    return response;
  } catch (err) {
    console.log(err.response);
    return window.location.replace("/login")
  }
};
export const logout = async () => {
  localStorage.removeItem("token");
  localStorage.removeItem("numActivo");
  window.dispatchEvent(new Event("storage"));
};

export const loginAsAAFF = async (nifAAFF) => {
  const {token} = await loginAs(nifAAFF);
  localStorage.setItem("token", token);
  window.location = "/"
}
export const backToOwnAccount = (ownToken) => {
  localStorage.setItem("token", ownToken);
  window.location = "/"
}

export const forgotPassword = async (token) => {
  try {
    const response = await axios.post("auth/forgot-password", token);
    return response.data
  } catch (error) {
    console.log(error)
  }
}
export const resetPassword = async (datos) => {
  try {
    const response = await axios.post("auth/reset-password", datos);
    return response.data
  } catch (error) {
    console.log(error)
  }
}
export const checkToken = async (token) => {
  try {
    const response = await axios.post("auth/check-token", token);
    return response.data
  } catch (error) {
    console.log(error)
  }
}
export const deleteToken = async (token) => {
  try {
    const response = await axios.post("auth/delete-token", token);
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const changePassword = async (data) => {
  try {
    const response = await axios.post("auth/change-password", data);
  } catch (error) {
    console.log(error)
    return error.response
  }
}
export const changePasswordAgain = async (data) => {
  try {
    const response = await axios.post("auth/change-password-again", data);
    return response.data
  } catch (error) {
    console.log(error)
    return error.response
  }
}