import axios from "axios";

const urlPath = "sale";

export const getSalesToExcel = async (data) => {
  console.log(data)
  try {
    const response = await axios.post(`${urlPath}/getSale/filterToExcel`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSales = async (data) => {
  
  try {
    const response = await axios.post(`${urlPath}/getSale/filter`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getSalesPropsIds = async (id) => {
  try {
    const response = await axios.post(`${urlPath}/getSale`, {aaff_id:id});

    return response.data;
  } catch (error) {
    console.log(error);
  }
};
export const getVisitSheetIdFromSale = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/getVisitSheetIdFromSale/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const detailsSalesCAE = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/detailsSalesCAE/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSale = async (id) => {
  try {
    const response = await axios.get(`${urlPath}/getSale/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createSale = async (data) => {

  try {
    const response = await axios.post(urlPath, data);
    
    return response.data;
  } catch (err) {
    console.log(err);
    return (err);
  }
};

export const UpdateSale = async (id,data) => {

  try {

    const response = await axios.put(`${urlPath}/${id}`, data);
    
    return response;
  } catch (err) {
    console.log(err.response);
  }
};
export const DeleteSale = async (id) => {

  try {

    const response = await axios.delete(`${urlPath}/${id}`);
    
    return response;
  } catch (err) {
    console.log(err.response);
  }
};

export const updateComentsDetailsCaes = async (data) => {
 
  try {
    const response = await axios.put(`${urlPath}/updateComentsDetailsCaes/${data.id}`, data);
    
    return response;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};


export const noRealizada = async (id, data) => {
  try {
    const response = await axios.put(`${urlPath}/notVisit/${id}`, data);

    return response.data;
  } catch (error) {
    console.log(error);
  }
};


export const createProvsSinAcceso = async (data) => {

  try {

    const response = await axios.post(`${urlPath}/povsSinAcc`, data);
    return response.data;

  } catch (err) {
    console.log(err.response);
  }
};

export const updateProvsSinAcceso = async (id,data) => {

  try {
    
    const response = await axios.put(`${urlPath}/povsSinAcc/${id}`, data);
    return response;

  } catch (err) {
    console.log(err.response);
  }
};
export const deleteProvsSinAcceso = async (id) => {

  try {

    const response = await axios.delete(`${urlPath}/povsSinAcc/${id}`);
    
    return response;
  } catch (err) {
    console.log(err.response);
  }
};


export const getEstadosFincas = async () => {
  try {
    const response = await axios.get(`${urlPath}/states`);
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};