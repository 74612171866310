import React, { useState, useEffect, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import { listCommercials, listTecnicos, listCosultor, getConsultores } from "../../services";
import {
  cambiarFormatoNombre,
  municipiosIniciales,
} from "../../functions/funciones";
import { ordenarNombreYApellido, orderArray, ordenarProvinciasYMunicipios } from '../../functions/ordenarListas';
import { UsuarioContext } from "../../providers/userContext";
import {
  Box,
  TextField,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import SearchProgresState from "./SearchProgresState";
import { useLocation} from "react-router-dom";

const formContainerStyles = css`
  height: 19px !important; 
`;

const FormContainer = styled("div")`
  ${formContainerStyles}
`;
const FiltrosAAFF = ({ onFilter, consultorOculto,comercialOculto, clearFilter,filtrosIniciales }) => {


  const user = useContext(UsuarioContext);
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [comercial, setComercial] = useState([]);
  const [consultor, setConsultor] = useState([]);
  const [tecnico, setTecnico] = useState([]);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [value, setValue] = useState(null);
  const [sinConsul, setSinConsul] = useState(false);
  
  const location = useLocation();

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    setListadoMunicipios(
      municipiosIniciales(provinces, municipalities, busqueda.provincia)
    );
  }, [busqueda]);

  useEffect(() => {
    cargarUser();
  }, []);

  const cargarUser = () => {
    if(user.rol === "CONSUL" &&  sinConsul==false){
      setBusqueda({
        ...busqueda,
        consul_id: user.id 
      });
      
    }
    localStorage.setItem("hasReloaded", "false"); 
  }


  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {
      clearAllFilters();
      cargarUser();
      localStorage.setItem("hasReloaded", "false"); 
      
    }
    
  }, [location]);

  const clearAllFilters = () => {
    setBusqueda({});
    setSinConsul(false);
    clearFilter();
    cargarUser();

  };

  const getDatas = async () => {

    const consul =
      user.rol === "CONSUL"
        ? await getConsultores({ filtros: { id: user.id } })
        : [];

    
    if (user.rol === "CONSUL" && consul.data?.length > 0 && !consultorOculto ) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id,
      });

      setConsultor(ordenarNombreYApellido(consul.data));
    }

  };

  const seleccionarMunicipios = (e) => {
    let codProvincia = "";
    let municipios = [];
    provinces.map((p) => {
      if (p.name === e.target.value) {
        codProvincia = p.code;
      }
    });
    if (e.target.name === "provincia" || e.target.value === "") {
      setBusqueda({
        ...busqueda,
        [e.target.name]: e.target.value,
        municipio: "", // Establecer el campo municipio en vacío
      });
    } else {
      setBusqueda({
        ...busqueda,
        [e.target.name]: e.target.value,
      });
    }
    municipalities.map((m) => {
      if (m.provCode === codProvincia) {
        municipios.push({
          ...m,
          name: cambiarFormatoNombre(m.name),
          value: m.name,
        });
      }
    });
    setListadoMunicipios(municipios);
  };

  const handleAsynchronousChange = (name, selectedValue) => {
    setBusqueda({
      ...busqueda,
      [name]: selectedValue?.id,
    });
  };

  const busquedaInput = (name, valorBusqueda) => {

    

    if (name === 'commer_id' && (busqueda.commer_id !== undefined || valorBusqueda)&& !comercialOculto) {

      listCommercials({
        filtros: {
          nombreA: valorBusqueda
        }, params: {}
      }).then(res => {
        setComercial(ordenarNombreYApellido(res.data))
      })
    }

    if (name === 'tech_id' && (busqueda.tech_id !== undefined || valorBusqueda)) {

      listTecnicos({
        filtros: {
          nombreA: valorBusqueda
        }, params: {}
      }).then(res => {
        setTecnico(ordenarNombreYApellido(res.data))
      })
    }


    if (name === 'consul_id' && (busqueda.consul_id !== undefined || valorBusqueda)) {

      listCosultor({
        filtros: {
          nombreA: valorBusqueda
        }, params: {}
      }).then(res => {
        setConsultor(ordenarNombreYApellido(res.data))
      })
    }

  };

  function handleChange(evt) {
    const value = evt.target.value;

    setBusqueda({
      ...busqueda,
      [evt.target.name]: value,
    });
  }

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    //console.log("busqueda",busqueda);
    if(busqueda.consul_id == undefined){
      setSinConsul(true);
      
    }
    onFilter(busqueda);
  };

  return (
    <>
      <div className="buscadorFiltros">
        <div style={{ width: "100%" }}>
          <Box mx={1} display={"block"} flexDirection={"row"} gap={2}>
            <TextField
              id="razonSocial-input"
              label="Razón Social"
              variant="outlined"
              name="razonSocial"
              className="filtros altura"
              value={busqueda.razonSocial || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <TextField
              id="nif-input"
              label="NIF"
              variant="outlined"
              name="nif"
              className="filtros altura"
              value={busqueda.nif || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            <TextField
              id="email-input"
              label="Email"
              variant="outlined"
              name="emailContacto"
              className="filtros altura"
              value={busqueda.emailContacto || ""}
              onChange={handleChange}
              onKeyPress={(e) => (e.key === "Enter" ? getBusqueda() : "")}
              inputProps={{
                "aria-label": "controlled",
              }}
            />
            
            {user.rol === "SUPP" ? null : (
              <>
                <FormControl variant="outlined" className="filtros-select">
                  <InputLabel id="provincia-label">Provincia</InputLabel>
                  <Select
                    labelId="comunidad-label"
                    id="province-select"
                    label="Provincia"
                    name="provincia"
                    className="filtros"
                    value={busqueda.provincia || ""}
                    onChange={seleccionarMunicipios}
                    onKeyPress={(e) =>
                      e.key === "Enter" ? getBusqueda() : ""
                    }
                  >
                    <MenuItem value="" style={{ height: 40 }}></MenuItem>
                    {provinces
                      .sort(ordenarProvinciasYMunicipios)
                      ?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl variant="outlined" className="filtros-select">
                  <InputLabel id="municipio-label">Municipios</InputLabel>
                  <Select
                    labelId="municipio-label"
                    id="municipio-select"
                    label="Municipio"
                    name="municipio"
                    className="filtros"
                    value={busqueda?.municipio || ""}
                    onChange={handleChange}
                    onKeyPress={(e) =>
                      e.key === "Enter" ? getBusqueda() : ""
                    }
                  >
                    <MenuItem value="" style={{ height: 40 }}></MenuItem>
                    {listadoMunicipios
                      .sort(ordenarProvinciasYMunicipios)
                      ?.map((e, i) => {
                        return (
                          <MenuItem key={i} value={e.name}>
                            {e.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </>
            )}
            
            {user.rol === "TECH" || user.rol === "SUPP" || user.rol === "COMM" ? null : (
              <>
              {comercialOculto ? null : (
                <FormControl variant="outlined" className="filtros-select">
                  <SearchProgresState
                    labelId="comercial-label"
                    id="comercial-select"
                    label="Comercial"
                    name="commer_id"
                    className="filtros"
                    tipo="nombre"
                    array={comercial}
                    onOptionSelected={handleAsynchronousChange}
                    busquedaInput={busquedaInput}
                    valueOnLoad={comercial.find(c => c.id === busqueda.commer_id)}
                  />
                </FormControl>
              )}

                <FormControl variant="outlined" className="filtros-select">
                  <SearchProgresState
                    labelId="tecnico-label"
                    id="tecnico-select"
                    label="Tecnico"
                    name="tech_id"
                    className="filtros"
                    tipo="nombre"
                    array={tecnico}
                    onOptionSelected={handleAsynchronousChange}
                    busquedaInput={busquedaInput}
                    valueOnLoad={tecnico.find(c => c.id === busqueda.tech_id)}
                  />
                </FormControl>
                {consultorOculto ? null : (
                  <FormControl variant="outlined" className="filtros-select">
                    <SearchProgresState
                      labelId="consultor-label"
                      id="consultor-select"
                      label="Consultor"
                      name="consul_id"
                      className="filtros"
                      tipo="nombre"
                      array={consultor}
                      onOptionSelected={handleAsynchronousChange}
                      busquedaInput={busquedaInput}
                      valueOnLoad={consultor.find(c => c.id === busqueda.consul_id)}
                    />
                  </FormControl>
                )}

              </>


            )}
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={clearAllFilters}>
            <ImCross />
          </Button>
        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
            <BsSearch />
          </Button>
        </div>
      </div>
      <style jsx="true">
        {`
          .btn-buscador {

            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004d;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width: 40px;
            height: 40px;
            margin: 5px 10px 5px;
          }
          .btn-buscador:hover {
            color: black;
            background-color: #dee2e6;
          }
        `}
      </style>
    </>
  );
};



export default FiltrosAAFF;
