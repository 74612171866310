import React, { useState, useEffect, useContext } from 'react'
import { getCommListSuplier, getCommercial, getOneAAFF } from '../../../services';
import { withRouter } from '../../../providers/withRouter';
import { UsuarioContext } from '../../../providers/userContext';
import BreadCrumb from '../../../components/navbar/Breadcrumb';
import { Link } from 'react-router-dom';
import { TiArrowBack } from 'react-icons/ti';
import { DataTable, ProgressBarWithColors } from '../../../components';
import { Box, Typography } from '@mui/material';
import FiltrosProveedores from "../../../components/filtros/filtrosProveedores";

const proveedoresRows = [
  {
    id: "proveedor_name",
    numeric: false,
    disablePadding: false,
    label: "PROVEEDOR",
    overrideFunc: (data, row) => (
      <Link to={"/proveedores/" + row.nif}>
        {data}
      </Link>
    )
  },
  {
    id: "municipio",
    numeric: false,
    disablePadding: false,
    label: "MUNICIPIO",
  },
  {
    id: "provincia",
    numeric: false,
    disablePadding: false,
    label: "PROVINCIA",
  },
  {
    id: "cumplimiento",
    numeric: false,
    disablePadding: false,
    label: "CUMPLIMIENTO",
    overrideFunc: (row) => (
      <ProgressBarWithColors
        value={row}
        label={
          <Typography>
            {row}
          </Typography>
        }
      />
    ),
  },
]


const Proveedores = (props) => {
  const user = useContext(UsuarioContext)
  const [proveedores, setProveedores] = useState([]);
  const [comercial, setComercial] = useState({});
  const [aaff, setAaff] = useState({});
  const [busqueda, setBusqueda] = useState({});
  const [loading, setLoading] = useState(true);
  const [recargar, setRecargar] = useState(false);
  const [limite, setLimite] = useState(0);
  const [busquedaActiva,setBusquedaActiva] = useState(false);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  useEffect(() => {
    fetchData();
  }, [recargar])



  const fetchData = async ({ offset, limit, filtros }) => {
    try {
      const a = await getOneAAFF(props.params.nif);
      console.log(a)
      setAaff(a)
      const payload = {
        filtros: { ...filtros, aaff_id: a.id },
        params: { page: offset, limit },
      };
      const response = await getCommListSuplier(payload)
      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  const formatData = (data) => {
    return data.map((row) => ({
      proveedor_name: row.razonSocial,
      nif: row.nif,
      municipio: row.municipio,
      provincia: row.provincia,
      cumplimiento: row.porcentajeDocs
    }));
  };

  const filtrarDatos = (filtros) => {
    setBusqueda(filtros)
    setBusquedaActiva(true);
    fetchData({ offset: 1, limit: limite, filtros });
  }

  return (
    <>
      <BreadCrumb rutas={[{ path: '/comerciales', label: "Comerciales" }, { path: `/comerciales`, label: `${user.nombre}` }, { path: `/aaff/${aaff.nif}/comunidades`, label: `${aaff.razonSocial}` }, { path: '/proveedores', label: 'proveedores' }]} style={{ margin: '0 10px' }} />
      <div className="listPage">
        <div className="gridCard">
          <div className="gridHead">
            LISTADO PROVEEDORES
            <Link to={`/comerciales/${props.params.nifComercial}/aaff`}>
              <div className="gridHeadRight">
                {/* {"<< volver al listado de Administradores de Fincas"} */}
                <TiArrowBack style={{ fontSize: 23 }} />
              </div>
            </Link>
          </div>
          <div className="tableContainer">
            <FiltrosProveedores onFilter={filtrarDatos} filtrosIniciales={busqueda}/>
            <div className="tableWrapper">
              <Box className="pageContent">
                <Box sx={{ mb: 10 }}>
                  <DataTable
                    data={data.rows}
                    isLoading={loading}
                    headers={proveedoresRows}
                    fetcher={fetchData}
                    currentPage={data.current_page}
                    meta={data.meta}
                    totalItems={data.total}
                    totalPages={data.totalPages}
                    nextPage={data.next_page}
                    filtros={busqueda}
                    busquedaActiva={busquedaActiva}
                    setBusquedaActiva={setBusquedaActiva}
                    mensaje={"No hay proveedores"}
                  />
                </Box>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(Proveedores);