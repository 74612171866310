import axios from "axios";

const urlPath = "supplier";

export const getServicios = async () => {
  try {
    const response = await axios.get(`${urlPath}/services`);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const getProveedores = async (data) => {
  console.log("proveedores");
  try {
    const response = await axios.post(`${urlPath}/filter`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const getProveedor = async (nif) => {
  try {
    const response = await axios.get(`${urlPath}/${nif}`);

    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};

export const getUserPropertieList = async (nif) => {
  
  try {
    const response = await axios.get(`${urlPath}/UserProperties/${nif}`);

    return response.data;

  } catch (err) {
    console.log(err.response);
  }
   
}

export const getSuppliersListPropertie = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/SuppliersPropertie`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getAaffListSuplier = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/AaffListSuplier`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const getCommListSuplier = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/CommListSuplier`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const TechListSuplier = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/TechListSuplier`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
  }
};
export const createSupplier = async (data) => {
  let datos = {
    razonSocial: data.razonSocial,
    nif: data.nif,
    email: data.email,
    telefonoContacto: data.telefonoContacto,
    nombreContacto: data.nombreContacto,
    municipio: data.municipio,
    provincia: data.provincia,
    direccion: data.direccion,
    codigoPostal: data.codigoPostal,
    parent_id: data.parent_id,
    servicio: data.servicio
  };
  try {
    const response = await axios.post(urlPath, datos);

    return response;
  } catch (err) {
    console.log(err);
    return err;

  }
};
export const cambiarProveedor = async (nif, data) => {
  try {
    const response = await axios.put(`${urlPath}/${nif}`, data);
    return response;
    
  } catch (err) {
    console.log(err.response);
  }
};

export const getAlertas = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/list/alert`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

export const SuppListPropertiAaff = async () => {
  try {
    const response = await axios.post(`${urlPath}/SuppListPropertiAaff`);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const deleteProveedor = async (nif) => {
  try {
    const response = await axios.delete(`${urlPath}/${nif}`);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};

// DOCS

export const getAllSuppDocs = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/getAllSuppDocs`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const suppsNoDocs = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/suppsNoDocs`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const validateNoDocSuppAlert = async (id) => {
  try {
    const response = await axios.post(`${urlPath}/validateNoDocsSupp/${id}`);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const suppsWithDocs = async (data) => {
  try {
    const response = await axios.post(`${urlPath}/suppsWithDocs`, data.filtros, {
      params: data.params,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const validateWithDocSuppAlert = async (id, ids) => {
  const datos = {
    id,
    ids
  }
  console.log(datos)
  try {
    const response = await axios.post(`${urlPath}/validateWithDocsSupp`, datos);
    return response;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const postSuppDoc = async ({ nif, data, file }) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify(data));
    const response = await axios.post(`docsSupp/${nif}/doc`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export const getDeclResOfSupplier = async (nifProv) => {
  try {
    const response = await axios.get(`${urlPath}/${nifProv}/declRes`);
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
export const sendEmailDocsCad = async (id, nifSupp, razonSocialSupp, email) => {
  const datos = {
    user_id:id,
    email,
    razonSocial: razonSocialSupp
  }
  try {
    const response = await axios.post(`${urlPath}/sendEmail/${nifSupp}`, datos, {nifSupp});
    return response.data;
  } catch (err) {
    console.log(err.response);
    return err.response;
  }
};
