import React, { useContext, useState } from "react";
import { Box, Typography } from "@mui/material";
import { UsuarioContext } from "../../../providers/userContext";
import { useEffect } from "react";
import { getFinca, getListarDocsFincaProv,  getSales, guardarFechaDocLeido } from "../../../services";
import moment from "moment";
import provinces from "@doncicuto/es-provinces";
import municipalities from "@doncicuto/es-municipalities";
import {
  municipiosIniciales,
  cambiarFormatoNombre,
} from "../../../functions/funciones";
import { withRouter } from "../../../providers/withRouter";
import Tick from "../../../icons/tick.svg";
import Cross from "../../../icons/cross.svg";
import BreadCrumb from "../../../components/navbar/Breadcrumb";
import Documento from "../../../icons/document.svg";
import CircularProgress from "@mui/material/CircularProgress";


const Comunidad = (props) => {
  const user = useContext(UsuarioContext);
  const nif = props?.params?.nifFinca ?? null;

  const [recargar, setRecargar] = useState(false);
  const [info, setInfo] = useState(0);
  const [listadoMunicipios, setListadoMunicipios] = useState([]);
  const [listDocs, setListDocs] = useState([]);
  const [salesCae, setSalesCae] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const docsLeer = [{ id: 1, nombre: "Identificación de riesgos y medidas preventivas" }, { id: 2, nombre: "Plan de emergencia" }]

  const fetchData = async () => {
    try {
      const response = await getFinca(nif);
      setInfo(response);
      const { data } = await getSales({ filtros: { prop_id: response.id }, params: {} });


      const resultadosFiltrados = data.find(
        objeto => objeto.servp_id === 1 || objeto.servp_id === 3
      );

      const respListDocs = await getListarDocsFincaProv(resultadosFiltrados.id)
    
      setListDocs(respListDocs);
      setSalesCae(resultadosFiltrados)

      setListadoMunicipios(
        municipiosIniciales(provinces, municipalities, response?.provincia)
      );
      setIsLoading(false);
      
    } catch (error) {
      console.log(error);
    }
  };
  const docLeido = async (id, doc_id) => {
    let nombre = ''
    if (id === 1) {
      nombre = 'identificacion-riesgos'
    }
    if (id === 2) {
      nombre = 'plan-emergencia'
    }
    const ruta = `/doc-render/${props.params.nif}/${salesCae.id}/${nombre}`
    window.open(ruta, '_blank');
    await guardarFechaDocLeido(doc_id, user.id).then(setRecargar(!recargar))
  }

  useEffect(() => {
    fetchData();
  }, [recargar]);
  return isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
    <>
      <BreadCrumb rutas={[{ path: "/aaff", label: "Administradores de fincas" }, { path: `/aaff/${props.params.nif}/comunidades`, label: info?.aaff?.razonSocial }, { path: `/comunidades/${info?.nif}`, label: info?.razonSocial }]} />


      <div className="listPage">
        <div className="gridCard">
          <div
            className="gridHead"
            style={{
              backgroundColor: "#92004D",
              display: "flex",
              flexDirection: " row",
            }}
          >
            <Typography fontWeight="bold">INFORMACION GENERAL</Typography>
          </div>
        </div>
        <Box sx={{ mb: 2 }}>
          <Box
            className="lae-flex lae-flex-column"
            sx={{
              backgroundColor: "#fff",
              padding: 2,
              mb: 1,
              borderRadius: "0 0 10px 10px",
              boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
              padding: 0
            }}
          >
            <div className="gridSubhead editarSubhead">
              <div>DATOS COMUNIDAD</div>
            </div>
            <Box className="lae-flex lae-flex-row">
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3, padding: '16px' }}>
                <Typography>Nombre comunidad: {info?.razonSocial}</Typography>
                <Typography>NIF: {info?.nif}</Typography>
                <Typography>
                  Persona de contacto: {info?.nombreContacto}
                </Typography>
                <Typography>
                  Email de contacto: {info?.emailContacto}
                </Typography>
                <Typography>
                  Telefono de contacto: {info?.telefonoContacto}
                </Typography>
              </Box>
              <Box className="lae-flex lae-flex-column" sx={{ flex: 3.3 }}>
                <Typography>Dirección: {info?.direccion}</Typography>
                <Typography>Municipio: {cambiarFormatoNombre(info?.municipio)}</Typography>
                <Typography>CP: {info?.codigoPostal}</Typography>
                <Typography>Provincia: {info?.provincia}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box className="lae-flex lae-flex-row" sx={{ width: "100%" }}>
          <Box sx={{ flex: 1 }}>
            <div className="gridCard">
              <div className="gridHead" style={{ backgroundColor: "#92004D" }}>
                <Typography fontWeight="bold">
                  DOCUMENTOS DE COMUNIDAD
                </Typography>
                </div>
            </div>

            <Box
              className="lae-flex lae-flex-column"
              sx={{
                backgroundColor: "#fff",
                padding: 2,
                mb: 1,
                borderRadius: "0 0 10px 10px",
                boxShadow: "0.1px 2px 10px rgba(0, 0, 0, 0.1)",
                justifyContent: "space-between",
              }}
            >
              <Box className="lae-flex lae-flex-row">
                <Box sx={{ flex: 8 }}>
                  <picture></picture>
                  <Typography></Typography>
                </Box>
                <Box
                  className="lae-flex lae-flex-row"
                  sx={{
                    flex: 2,
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ width: '100px', textAlign: "center" }}>Última lectura</Typography>
                  <Typography style={{ width: '100px', textAlign: "center" }}>Ver</Typography>
                </Box>
              </Box>

              {Object.values(docsLeer).map((dt, i) => {
                return (
                  <Box key={i} className="lae-flex lae-flex-row" sx={{ pr: 0.4 }}>
                    <Box sx={{ flex: 8 }} style={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>
                        {salesCae?.detailsCae && salesCae?.detailsCae.length > 0 && salesCae.detailsCae[0]?.visitada ?
                          <img src={Tick} alt="Visitada" className="tableIcon" />
                          :
                          <img src={Cross} alt="No visitada" className="tableIcon" />
                        }
                      </Typography>
                      <Typography variant="body2">
                        {listDocs[i]?.nombre === "identificacion de riesgos" ? "Identificación de riesgos y medidas preventivas" : "Plan de emergencia"}
                      </Typography>
                    </Box>
                    <Box
                      className="lae-flex lae-flex-row"
                      sx={{
                        flex: 2,
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={{ width: '100px', textAlign: "center" }}>
                        {listDocs[i]?.suppRead[0]?.r_doc_reads.updatedAt
                          ? moment(listDocs[i].suppRead[0].r_doc_reads.updatedAt).format("DD/MM/YYYY")
                          : "----"}
                      </Typography>
                      <img
                        style={salesCae?.detailsCae && salesCae?.detailsCae.length > 0 && !salesCae.detailsCae[0]?.visitada ? { opacity: 0.4, cursor: "default" } : {}}
                        src={Documento}
                        alt="Ver"
                        className="tableIcon"
                        onClick={() => salesCae?.detailsCae && salesCae?.detailsCae.length > 0 && salesCae.detailsCae[0]?.visitada ? docLeido(docsLeer[i].id, listDocs[i].id, listDocs[i]?.suppRead[0]?.r_doc_reads) : null}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </div>
    </>

};

export default withRouter(Comunidad);
