import React, { useState, useEffect, useMemo } from "react";
import Portada from "../../../images/PortadaRiesgos.png"
import Encabezado from "../../../images/EncabezadoRiesgos.png"
import Logo from "../../../images/LogoLAENEW.png"
import { withRouter } from "../../../providers/withRouter";
import CircularProgress from '@mui/material/CircularProgress';
import { riesgosData } from "../../../components/aaff/riesgos";
import { formatoFechasEs } from "../../../functions/funciones";
import { Navigate, useLocation } from "react-router-dom";
import { getAAFFPropVisit } from "../../../services";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";

const baseURL = window.location.origin;

const InformeRiesgos = (props) => {

    const location = useLocation();
    const [fincas, setFincas] = useState([])
    const [riesgos, setRiesgos] = useState([])
    const [aaff, setAAFFS] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    
    useEffect(() => {
        fetchData()
    }, [])
    
    const token = localStorage.getItem('token');
    console.log(token)
  
    if(!token){
      return <Navigate replace to="/login" state={{from: location}} />
    }

    const fetchData = async () => {
        try {
            console.log(props)
            const response = await getAAFFPropVisit(props.params.nif)
            console.log(response)
            const riesgosFincas = processResponse(response)
            setAAFFS(response)
            setFincas(response?.properties)
            setRiesgos(riesgosFincas)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const processResponse = (response) => {
        const statementArrays = {};
        const respRules = {
            3: "si",
            2: "si",
            5: "si",
            6: "si",
            7: "si",
            8: "si",
            9: "no",
            11: "no",
            12: "no",
            14: "no",
            15: "no",
            16: "no",
            17: "no",
            18: "no",
            19: "no",
            20: "no",
            22: "no",
            23: "no",
            24: "no",
            27: "no",
            28: "no",
            29: "no",
            30: "no",
            32: "no",
            33: "no",
            34: "no",
            35: "no",
            36: "no",
            37: "no",
            38: "no",
            39: "no",
            40: "no",
            41: "no",
            43: "si",
            47: "si",
            48: "no",
            49: "no",
            50: "no",
            51: "no",
            53: "no",
            54: "no",
            56: "no",
            57: "no",
            58: "no",
            59: "no",
            60: "no",
            61: "no",
            62: "no",
        };

        // Crear un Map para almacenar las fincas únicas
        const uniqueFincas = new Map();

        response?.properties?.forEach((property) => {
            if (property.workcenters?.length > 0) {
                property.workcenters.forEach((workcenter) => {
                    workcenter.visitSheets?.forEach((sheet) => {
                        sheet.questions.forEach((question) => {
                            if (respRules[question.statements_id] === question.resp) {
                                if (!statementArrays[question.statements_id]) {
                                    statementArrays[question.statements_id] = new Set();
                                }
                                statementArrays[question.statements_id].add(workcenter.razonSocial);
                            }
                        });
                    });
                });
            } else {
                property.visitSheets?.forEach((sheet) => {
                    sheet.questions.forEach((question) => {
                        if (respRules[question.statements_id] === question.resp) {
                            if (!statementArrays[question.statements_id]) {
                                statementArrays[question.statements_id] = new Set();
                            }
                            statementArrays[question.statements_id].add(property.razonSocial);
                        }
                    });
                });
            }
        });

        return riesgosData
            .map((riesgo) => ({
                ...riesgo,
                fincas: Array.from(statementArrays[riesgo.key] || [])
            }))
            .filter((riesgo) => riesgo.fincas.length > 0);
    };

    const RiesgoRow = React.memo(({ r }) => {
        // Memoizar el renderizado de las fincas
        const fincasContent = useMemo(() => {
            return (
                <div>
                    {r.fincas.map((finca, fincaIndex) => (
                    <React.Fragment key={fincaIndex}>
                        <p>{finca}</p>
                    </React.Fragment>
                    ))}
                </div>
            );
        }, [r.fincas]);

        // Memoizar el contenido de medidas
        const medidasContent = useMemo(() => {
            if (r.key === 60 || r.key === 61) {
                return (
                    <>
                        <div>
                            <p>
                                {r.medidas[0]}
                            </p>
                        </div>
                        {r.img && (
                            <img
                                src={r.img}
                                style={{ width: 20, marginVertical: 10 }}
                                alt={r.textoImg}
                            />
                        )}
                        <div>
                            <p>
                                {r.medidas.slice(1).join('\n')}
                            </p>
                        </div>
                    </>
                );
            }

            return (
                <>
                    <div>
                        <p>
                            {r.medidas.join('\n')}
                        </p>
                    </div>
                    {r.img && (
                        <img
                            src={r.img}
                            style={{
                                width: r.img === 'peligro' ? 20 : 30,
                            }}
                            alt={r.textoImg}
                        />
                    )}
                </>
            );
        }, [r.medidas, r.img, r.key]);

        return (
            <tr>
                <td>
                    <p>
                        {r.riesgo.join('\n')}
                    </p>
                </td>
                <td>
                    <p>{r.origen}</p>
                </td>
                <td>
                    {medidasContent}
                </td>
                <td>
                    {fincasContent}
                </td>
            </tr>
        );
    }, (prevProps, nextProps) => {
        // Implementar una función de comparación personalizada
        return (
            prevProps.r.key === nextProps.r.key &&
            prevProps.r.fincas.length === nextProps.r.fincas.length
        );
    });

    return ( 
        <>
            {isLoading ? <CircularProgress style={{ color: '#92004D', position: 'absolute', top: '50%', left: '50%' }} /> :
                <div style={{ marginHorizontal: '1cm'}}>
                    <div style={{ textAlign: 'center'}}>
                        <img src={Encabezado} style={{ marginHorizontal: 'auto', marginTop: 20, width: '80%', display: 'inline' }} />
                    </div>
                    <div className="content">
                        <p className="mb-3">
                            A continuación se detalla el resumen de los diferentes riesgos identificados en las comunidades de propietarios
                        </p>
                        <table id="tablaPrincipal">
                            <thead>
                                <tr>
                                    <th colSpan={2} className="tableColHeader">
                                        INFORME DE RIESGOS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Administrador de fincas:</td>
                                    <td>{aaff?.razonSocial}</td>
                                </tr>
                                <tr>
                                    <td>CIF:</td>
                                    <td>{aaff?.nif}</td>
                                </tr>
                                <tr>
                                    <td>Localidad:</td>
                                    <td>{aaff?.municipio}</td>
                                </tr>
                                <tr>
                                    <td>Teléfono:</td>
                                    <td>{aaff?.telefono}</td>
                                </tr>
                                <tr>
                                    <td>E-Mail:</td>
                                    <td>{aaff?.user?.email}</td>
                                </tr>
                                <tr>
                                    <td>Técnico:</td>
                                    <td>{aaff?.technician ? aaff?.technician?.nombre + " " + aaff?.technician?.apellidos : ""}</td>
                                </tr>
                                <tr>
                                    <td>Informe:</td>
                                    <td>{aaff?.fechaInforme ? formatoFechasEs(aaff?.fechaInforme) : ""}</td>
                                </tr>
                                <tr>
                                    <td>Número de comunidades:</td>
                                    <td>{aaff?.properties?.length}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <h3>Objeto</h3>
                        <p>El informe de riesgos tiene por objeto proponer medidas en base a los riesgos identificados en las comunidades de propietarios.</p>
                        <br />
                        <h3>Alcance</h3>
                        <p>El presente informe afecta a todas las comunidades de propietarios visitadas de {aaff?.razonSocial}.</p>
                        <br />
                        <h3>Metodología</h3>
                        <p>Para elaborar el informe se tendrán en cuenta los riesgos identificados durante la visita por parte del técnico de prevención de riesgos laborales, que propondrá medidas preventivas y/o correctoras con el fin de minimizar dichos riesgos.</p>
                        <br />
                        {/* <table>
                            <thead>
                                <tr>
                                    <th className="tableColHeader" width="15%">Riesgo identificado</th>
                                    <th className="tableColHeader" width="15%">Origen</th>
                                    <th className="tableColHeader" width="30%">Medidas propuestas</th>
                                    <th className="tableColHeader" width="40%">Comunidades afectadas</th>
                                </tr>
                            </thead>
                            <tbody>
                                {riesgos.map((r, index) => (
                                    <RiesgoRow key={`${r.key}-${index}`} r={r} />
                                ))}
                            </tbody>
                        </table> */}
                        <div>
                            {riesgos.map((r, index) => (
                                <Accordion key={r.key} style={{ margin: 0 }}>
                                <AccordionSummary
                                    style={{ borderBottom: '1px solid #999'}}
                                //   expandIcon={<ExpandMoreIcon />}
                                //   aria-controls={`panel${index}-content`}
                                //   id={`panel${index}-header`}
                                >
                                        <div style={{ width:'100%', display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                            <div style={{ width:'50%' }}>
                                                <Typography style={{ textAlign: 'center'}}>
                                                    <b>Riesgo identificado:</b> 
                                                </Typography>
                                                {r.riesgo.map((riesgo, i) => (
                                                    <Typography key={i} style={{ textAlign: 'center'}}>
                                                        {riesgo}
                                                    </Typography>
                                                ))}
                                            </div>
                                            <div style={{ width:'50%' }}>
                                                <Typography style={{ textAlign: 'center'}}>
                                                    <b>Origen:</b>
                                                </Typography>
                                                <Typography style={{ textAlign: 'center'}}>
                                                    {r.origen}
                                                </Typography>
                                            </div>
                                        </div>
                                </AccordionSummary>
                                <AccordionDetails style={{ padding: 0 }}>
                                    <div>
                                        <div style={{ display:'flex', alignItems:'center', justifyContent:'space-around', padding: '20px 0', borderBottom: '1px solid #999', backgroundColor: '#c31e64',color: '#ffffff'}}>
                                            <div style={{ width:'50%', textAlign:'center' }}>
                                                <Typography variant="subtitle2">Medidas propuestas:</Typography>
                                            </div>
                                            <div style={{ width:'50%', textAlign:'center' }}>
                                                <Typography variant="subtitle2">Comunidades afectadas:</Typography>
                                            </div>
                                        </div>
                                        <div  style={{ display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                                            <div style={{ width:'50%' }}>
                                                {r.medidas.map((medida, i) => (
                                                    <Typography key={i} style={{ textAlign:'center' }}>{medida}</Typography>
                                                ))}
                                            </div>
                                            <div style={{ width:'50%' }}>
                                                {r.fincas.map((finca, i) => (
                                                    <Typography key={i} style={{ textAlign:'center' }}>{finca}</Typography>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </div>
                </div>
            }
            <style jsx="true">
                {`
                    .content{
                        width: 80%;
                        margin: 30px auto 0;
                    }
                    p {
                        padding: 5px 10px;
                        line-height: 1.8;
                        text-align: justify;
                        font-size: 12px !important;
                    }
                    h1 {
                        margin-bottom: 30px;
                        font-size: 12px;
                        fontWeight: 600;
                    }
                    h2 {
                        font-size: 11px;
                        font-weight: 600;
                        margin-Top: 30px;
                        margin-Bottom: 30px;
                    }
                    h3 {
                        font-size: 15px;
                        width: 100%;
                        padding: 5px 10px;
                        text-transform: uppercase;
                        font-weight: bold;
                        text-decoration: underline
                    }
                    h4 {
                        color: #ab2a3e;
                        margin-bottom: 10px;
                        margin-top: 20px;
                        font-size: 9px;
                        font-weight: bold;
                    }
                    h5 {
                        font-size: 10px;
                        margin-top: 15px;
                        margin-bottom: 5px;
                    }
                    table {
                        display: table;
                        width: 100%;
                        border: 1px solid #000;
                        margin: auto;
                        border-collapse: collapse;
                    }
                    table.tablaPrincipal td {
                        width:50%;
                    }
                    table td {
                        border: 1px solid black;
                        padding: 8px;
                        text-align: left;
                    }
                    .tableColHeader {
                        background-color: #c31e64;
                        color: #ffffff;
                        text-align: center;
                        font-size: 12px;
                        padding: 10px 0;
                    }
                `}
            </style>
        </>
    );
}
 
export default withRouter(InformeRiesgos);