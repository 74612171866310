import React, { useState, useEffect } from "react";
import { getAllTecnicos } from "../../../services";
import CircularProgress from "@mui/material/CircularProgress";
import { ExportToExcel } from "../../../providers/export-excel";
import { withRouter } from "../../../providers/withRouter";

const ExportarListaTecnicos = (props) => {
  const [tecnicos, setTecnicos] = useState([]);
  const [isLoading, setIsloading] = useState(true);

  // useEffect(() => {
  //   let fincas = [];
  //   let resultado = [];
  //   let aaff = [];
  //   let tech = [];
  //   let promises = [];
  //   promises.push(
  //     getFincasAAFF().then((res) => {
  //       console.log(res)
  //       fincas = res;
  //     }),
  //     getAllTecnicos().then((resT) => {
  //       tech = resT;
  //     })
  //   );

  //   Promise.all(promises).then(() => {
  //     tech.forEach((t) => {
  //       let data = {
  //         tech_id: t.id,
  //       };

  //       getAAFFs(data).then((res) => {
  //         console.log(res)
  //         let fincasPendientes = 0;
  //         let fincasVisitadas = 0;
  //         let fincasRenovadas = 0;
  //         let fincasRenovadasVisitadas = 0;
  //         let nfincas = 0;
  //         if (res.data.length === 0) {
  //           t.porcentaje = parseFloat(
  //             ((fincasVisitadas / (nfincas || 1)) * 100).toFixed(2)
  //           );
  //           t.fincasPendientes = fincasPendientes;
  //           t.estimacion =
  //             Math.round((fincasPendientes / 160 + Number.EPSILON) * 100) / 100;
  //           t.porcentajeRenovadas = parseFloat(
  //             (
  //               (fincasRenovadasVisitadas / (fincasRenovadasVisitadas || 1)) *
  //               100
  //             ).toFixed(2)
  //           );
  //           t.fincasRenovadas = fincasRenovadas;
  //           t.estimacionRenovadas =
  //             Math.round(
  //               (fincasRenovadasVisitadas / 160 + Number.EPSILON) * 100
  //             ) / 100;
  //           delete t.user;
  //           delete t.zona;
  //           delete t.id;
  //           delete t.apellidos;
  //         } else {
  //           res.data.forEach((a) => {
  //             fincas.map((f) => {
  //               if (a.nif == f.aaff?.nif) {
  //                 nfincas++;
  //                 if (f.visitada && !f.renovada && !f.renovadaVisitada) {
  //                   fincasVisitadas++;
  //                 }
  //                 if (f.renovada) {
  //                   fincasRenovadas++;
  //                 }
  //                 if (f.renovadaVisitada) {
  //                   fincasRenovadasVisitadas++;
  //                 }
  //                 if (!f.visitada) {
  //                   fincasPendientes++;
  //                 }
  //               }
  //             });
  //             t.nombre = t.nombre + " " + t.apellidos;
  //             t.porcentaje = parseFloat(
  //               ((fincasVisitadas / (nfincas || 1)) * 100).toFixed(2)
  //             );
  //             t.fincasPendientes = fincasPendientes;
  //             t.estimacion =
  //               Math.round((fincasPendientes / 160 + Number.EPSILON) * 100) /
  //               100;
  //             t.porcentajeRenovadas = parseFloat(
  //               (
  //                 (fincasRenovadasVisitadas / (fincasRenovadasVisitadas || 1)) *
  //                 100
  //               ).toFixed(2)
  //             );
  //             t.fincasRenovadas = fincasRenovadas;
  //             t.estimacionRenovadas =
  //               Math.round(
  //                 (fincasRenovadasVisitadas / 160 + Number.EPSILON) * 100
  //               ) / 100;
  //             delete t.user;
  //             delete t.zona;
  //             delete t.id;
  //             delete t.apellidos;
  //           });
  //         }
  //       });
  //       resultado.push(t);
  //       setTecnicos(resultado);
  //     });
  //   });
  // }, []);
  useEffect(() => {
    fetchData();
  },[])

  const fetchData = async () => {
    let resultado = [];
    let tech = [];
    let promises = [];
    const tecnicos = await getAllTecnicos();
    
    // Recorrer los técnicos
    tecnicos.forEach((tecnico, index) => {
      console.log(tecnico)
      let nfincas = 0, porcentaje = 0, estimacion = 0, pendientes = 0, visitadas = 0, renovadas = 0, renovadaVisitadas = 0;
        tecnico.aaff.forEach(aaff => {
          nfincas += aaff.properties?.length || 0
            aaff.properties.forEach(f => {
                if (f.pendiente) pendientes++;
                if (f.visitada) visitadas++;
                if (f.renovada) renovadas++;
                if (f.renovadaVisitada) renovadaVisitadas++;
            });
          });
          
          let total = pendientes + visitadas
          let totalRenovadas = renovadas + renovadaVisitadas
          tecnico.equipo = tecnico.technicianteams?.nombre
          tecnico.total = nfincas
          tecnico.porcentaje = parseFloat(
            (
              visitadas / ( total || 1 )) * 100
            ).toFixed(2)
          tecnico.fincasPendientes = pendientes
          tecnico.fincasVisitadas = visitadas
          // tecnico.estimacion = Math.round((pendientes / 160 + Number.EPSILON) * 100) / 100;
          tecnico.fincasRenovadas = renovadas;
          tecnico.fincasRenovadasVisitadas = renovadaVisitadas;
          // tecnico.estimacionRenovadas = 
          //     Math.round(
          //       (renovadaVisitadas / 160 + Number.EPSILON) * 100
          //     ) / 100;
          tecnico.porcentajeRenovadas = parseFloat(
            (
              (renovadaVisitadas / (totalRenovadas || 1)) * 100
            ).toFixed(2)
          );
          delete tecnico.aaff
          delete tecnico.user
          delete tecnico.team_id
          delete tecnico.technicianteams
          tech.push(tecnico)
      });

      setTecnicos(tech)
    // let totalPendientesVisitadas = pendientes + visitadas;
    // let totalRenovadasVisitadas = renovadas + renovadaVisitadas;

    // let porcentajePendientesVisitadas = (pendientes / totalPendientesVisitadas) * 100;
    // let porcentajeRenovadasVisitadas = (renovadas / totalRenovadasVisitadas) * 100;

    // console.log(`Pendientes: ${pendientes}`);
    // console.log(`Visitadas: ${visitadas}`);
    // console.log(`Renovadas: ${renovadas}`);
    // console.log(`Renovada Visitadas: ${renovadaVisitadas}`);
    // console.log(`Porcentaje de pendientes respecto a visitadas: ${porcentajePendientesVisitadas.toFixed(2)}%`);
    // console.log(`Porcentaje de renovadas respecto a renovada visitadas: ${porcentajeRenovadasVisitadas.toFixed(2)}%`);
  }
  return (
    <>
      {tecnicos.length === props.tecnicos.length ? (
        <ExportToExcel apiData={tecnicos} fileName={"Listado de técnicos"} />
      ) : (
        <span className="bigBtn" style={{ cursor: "context-menu" }}>
          <CircularProgress
            style={{
              color: "#fff",
              width: "16px",
              height: "16px",
              marginRight: "10px",
            }}
          />{" "}
          Generando listado
        </span>
      )}
    </>
  );
};

export default withRouter(ExportarListaTecnicos);
