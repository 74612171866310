import { useState, useEffect } from "react";

import { listCentroTrabajo } from "../services";

function useCentrosTrabajo() {
  const [limite, setLimite] = useState(0);
  const [data, setData] = useState({
    meta: {},
    rows: [],
  });
  const [loading, setLoading] = useState(true);

  const formatData = (data) => {
    return data.map((row) => ({
      id: row.id,
      name: row.razonSocial,
      renovado: row.renovado,
      prop_id: row.prop_id,
      renovadoVisitado: row.renovadoVisitado,
      aaff_id: row?.propertie?.aaff?.id,
      commer_id: row?.propertie?.aaff?.commer_id
    }));
  };

  const fetchData = async ({ offset, limit, filtros }) => {
    setLimite(limit)
    const payload = {
      params: {limit, page: offset},
      filtros,
    };
    try {
      setLoading(true);
      const response = await listCentroTrabajo(payload);

      const { meta, data } = response;
      setData({
        meta,
        rows: formatData(data.data),
      });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    fetchData,
    loading,
    formatData,
    limite
  };
}

export default useCentrosTrabajo;
