import React, {useState, useEffect} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";
import { withRouter } from "../../../providers/withRouter";
import { AaffListPropertie, AaffListPropertieExport } from "../../../services";


const ListadoFincas = (props) => {
    //const listado = props?.fincas
    const [fincas, setFincas] = useState([])   
    const [isLoading, setIsLoading] = useState(false)   

    const fetchData = async ({offset, limit} ={}) => {
        setIsLoading(true)
        const payload = {
        filtros: {aaff_id: props.aaff_id},
        params: { page: offset, limit },
        };
        const response = await AaffListPropertieExport(payload)
        const data = response;
        console.log(data)
        let num = 0
        data?.forEach( l => {
            num += l?.workcenters?.length >0 ? l?.workcenters?.length : 1
        })
        

        let f = [];
        data?.forEach(e => {
            let fincas = {
            razonSocial:e.razonSocial,
            nif: e.nif,
            direccion: e.direccion,
            municipio: e.municipio,
            provincia: e.provincia,
            proveedores: e.proveedores || e.proveedores === null ? e.suppliers.filter(objeto => objeto.status === true).length : "No tiene"
            /* activo: e.activo === undefined ? "Activa" : e.activo === "true" || e.activo === true ? "Activa" : "Inactiva",
            servicios: e.servicios === undefined ? "CAE" : e.servicios === "true" || e.servicios === true ? "SPA" : "CAE",
            empleados: e.empleados === undefined ? "No" : e.empleados === "true" || e.empleados === true ? "Sí" : "No" */
            }
            if(e.workcenters.length > 0 ){
                e.workcenters.map( ct => {
                    let data = {
                        razonSocial:ct.razonSocial,
                        nif: e.nif
                    }
                    f.push(data)
                })
            }else{
                f.push(fincas)
            }
            setIsLoading(false)
            setFincas(f)
        })
    }

    useEffect(() => {
        if(props.aaff_id) fetchData()
    },[])
    
    return ( 
        <>
            {!isLoading ?
                <ExportToExcel apiData={fincas} fileName={"Listado de fincas "+props.params.nif} />
            :
                <span className='bigBtn' style={{ cursor:'context-menu' }}><CircularProgress style={{color:'#fff', width:'16px', height: '16px', marginRight: '10px'}} /> Generando listado</span>
            }
        </>
     );
}
 
export default withRouter(ListadoFincas);