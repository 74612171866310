import React, { useState, useEffect, useContext } from "react";
import { BsSearch } from "react-icons/bs";
import { ImCross } from "react-icons/im";
import { getAllConsultores, listCosultor } from "../../services";
import {
  Box,
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";
import { UsuarioContext } from "../../providers/userContext";
import SearchProgresState from "./SearchProgresState";
import { ordenarNombreYApellido } from '../../functions/ordenarListas';
import { useLocation } from 'react-router-dom';

const FiltrosAlertasAAFF = ({ onFilter, clearFilter, filtrosIniciales }) => {
  const user = useContext(UsuarioContext);
  const [busqueda, setBusqueda] = useState(filtrosIniciales);
  const [consultor, setConsultor] = useState([]);
  const location = useLocation();
  const [sinConsul, setSinConsul] = useState(false);
  
  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    cargarUser();
  }, []);

  const cargarUser = () => {
    if (user.rol === "CONSUL" && sinConsul == false) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id
      });
    }
    localStorage.setItem("hasReloaded", "false");
  }

  useEffect(() => {
    if (localStorage.getItem("hasReloaded") === "true") {
      getDatas(); 
      clearAllFilters();
      cargarUser();
      localStorage.setItem("hasReloaded", "false"); 
    }
  }, [location]);

  const clearAllFilters = () => {
    setSinConsul(false);
    clearFilter();
    setBusqueda({});
    cargarUser();
  };

  const getDatas = async () => {
    const consul = user.rol === "TECH" || user.rol === "SUPP" ? '' : await getAllConsultores();
    setConsultor(consul);
    if (user.rol === "CONSUL" && consul.length > 0) {
      setBusqueda({
        ...busqueda,
        consul_id: user.id  
      });
    }
  };

  const handleAsynchronousChange = (name, selectedValue) => {
    setBusqueda({
      ...busqueda,
      [name]: selectedValue?.id,
    });
  };

  const busquedaInput = (name, valorBusqueda) => {
    if (name === 'consul_id') {  
      listCosultor({
        filtros: {
          nombreA: valorBusqueda
        },
        params: {}
      }).then(res => {
        setConsultor(ordenarNombreYApellido(res.data))
      });
    }
  };

  const getBusqueda = () => {
    for (let key in busqueda) {
      if (
        busqueda[key] === "" ||
        busqueda[key] === null ||
        busqueda[key] === undefined
      ) {
        delete busqueda[key];
      }
    }
    if(busqueda.consul_id == undefined){
      setSinConsul(true);
      
    }
    onFilter(busqueda);
  };

  return (
    <>
      <div className="buscadorFiltros">
        <div style={{ width: "100%" }}>
          <Box
            mx={1}
            display={"block"}
            flexDirection={"row"}
            gap={2}
          >
            <FormControl variant="outlined" className="filtros-select">
              <SearchProgresState
                labelId="consultor-label"
                id="consultor-select"
                label="Consultor"
                name="consul_id" 
                className="filtros"
                tipo="nombre"
                array={consultor}
                onOptionSelected={handleAsynchronousChange}
                busquedaInput={busquedaInput}
                valueOnLoad={consultor.find(c => c.id === busqueda.consul_id)}  // Cambiado a consul_id
              />
            </FormControl>
          </Box>
        </div>
        <div>
          <Button className="btn-buscador" onClick={clearAllFilters}>
            <ImCross />
          </Button>
        </div>
        <div>
          <Button className="btn-buscador" onClick={() => getBusqueda()}>
            <BsSearch />
          </Button>
        </div>
      </div>
      <style jsx="true">
        {`
          .btn-buscador {
            padding: 5px 10px;
            color: #ffffff;
            background-color: #92004d;
            border-radius: 4px;
            font-weight: bold;
            width: -moz-fit-content;
            width: fit-content;
            align-self: flex-end;
            border: none;
            min-width: 40px;
            height: 40px;
            margin: 5px 10px 5px;
          }
          .btn-buscador:hover {
            color: black;
            background-color: #dee2e6;
          }
        `}
      </style>
    </>
  );
};

export default FiltrosAlertasAAFF;
