import React from "react";
import { getAAFF, getAllCommercials, asignarComercial, getOnlyAAFF } from "../../../services";
import { withRouter } from "../../../providers/withRouter";
import { toast } from "react-toastify";
import HeaderBackground from "../../../images/banner_administradores.jpg";
import { Grid } from "@mui/material";
import Add from "../../../icons/add.svg";
import { filterItems } from "../../../functions/funciones";
import BreadCrumb from "../../../components/navbar/Breadcrumb";

class AsignarComercial extends React.Component {
  constructor() {
    super();
    this.state = {
      nombre: "",
      aaff: "undefined",
      resultados: undefined,
      comerciales: [],
    };
  }

  componentDidMount() {
    getOnlyAAFF(this.props.params.nif).then((res) => {
      this.setState({ aaff: res });
    });
    getAllCommercials().then((res) => {
      let comerciales = [];
      res.forEach((c) => {
        comerciales.push({
          id: c.id,
          nif: c.nif,
          nombre: c.nombre,
          apellidos: c.apellidos,
          busqueda: c.nombre + " " + c.apellidos,
        });
        
        this.setState({ comerciales: comerciales });
      });
    });
  }

  buscar = (e) => {
    e.preventDefault();
    
    this.setState({
      resultados: filterItems(this.state.nombre, this.state.comerciales),
    });
  };
  /*   filterItems(query, array) {
    return array.filter(function(el) {
        //this.setState({comerciales:el})
        
        return el.busqueda.toLowerCase().indexOf(query.toLowerCase()) > -1;
    })
  } */

  render() {
    //if (this.state.aaff === undefined) return null
    return (
      <>
        <BreadCrumb
          rutas={[{ path: "/aaff", label: "Administradores de fincas" }, { path: `/aaff/${this.state.aaff.nif}`, label: this.state.aaff.razonSocial }]}
          style={{ margin: "0 10px" }}
        />
        <div className="pageContent">
          <div className="listPage">
            <div className="gridCard">
                <div className="gridHead">
                    ASIGNAR COMERCIAL
                </div>
            </div>
          </div>
          <form className="nuevoAAFFForm" onSubmit={this.buscar}>
            <Grid
              container
              spacing={5}
              style={{ marginTop: 20, marginBottom: 20 }}
            >
              <Grid item xs={12} sm={4}>
                <input
                  type="text"
                  placeholder="Nombre"
                  required
                  value={this.state.nombre}
                  onChange={(e) => this.setState({ nombre: e.target.value })}
                ></input>
              </Grid>
            </Grid>
            <input type="submit" value="Buscar"></input>
          </form>
          <Resultados
            resultados={this.state.resultados}
            aaff={this.state.aaff}
          />
        </div>
      </>
    );
  }
}

class Resultados extends React.Component {
  asignar = (resultado) => {
    
    asignarComercial(this.props.aaff.nif, resultado.id).then((res) => {
      
      window.location.replace("/aaff");
    });
  };
  render() {
    if (this.props.resultados === undefined) return null;
    return (
      <div className="tableContainer" style={{ marginTop: 30 }}>
        <table>
          <thead>
            <tr>
              <th>Comercial</th>
              <th>NIF</th>
              <th style={{ textAlign: "center" }}>Asignar</th>
            </tr>
          </thead>
          <tbody>
            {this.props.resultados.map((e, i) => {
              return (
                <tr key={i}>
                  <td>{e.nombre + " " + e.apellidos}</td>
                  <td>{e.nif}</td>
                  <td style={{ textAlign: "center" }}>
                    <div
                      className="asignarButton"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        cursor: "pointer",
                      }}
                      onClick={() => this.asignar(e)}
                    >
                      <img src={Add} alt="Más" />
                      Asignar
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default withRouter(AsignarComercial);
