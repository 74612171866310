import React, {useState, useEffect} from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { ExportToExcel } from "../../../providers/export-excel";
import { withRouter } from "../../../providers/withRouter";
import Excel from "../../../icons/excel.svg"
import { getSalesToExcel } from "../../../services";


const ListadoFincas = (props) => {
    //const listado = props?.fincas
    const [listado, setListado] = useState([])   
    const [isLoading, setIsLoading] = useState(false)   
    const [show, setShow] = useState(false)   

    const fetchData = async () => {
        setShow(true)
        setIsLoading(true)
        console.log(props.filtros)
        const response = await getSalesToExcel(props.filtros)
        console.log(response)
        let f = []
        response.forEach( d => {
            let finca = {
                nif: d.properties?.nif,
                razonSocial: d.properties.razonSocial,
                fechaAlta: new Date(d.properties.createdAt),
                fechaRenovacion: d.properties.fechaRenovacion === null ? "" : new Date(d.properties.fechaRenovacion),
                fechaNoVisita: d.detailsCae[0].unrealizedDate === null ? "" : new Date(d.detailsCae[0].unrealizedDate),
                observacionesNoVisita: d.detailsCae[0].observaciones,
                aaff: d.aaff.razonSocial,
                aaffNif: d.aaff.nif,
                tecnico: d.technicians.nombre+" "+d.technicians.apellidos
            }
            f.push(finca)
            setIsLoading(false)
            setListado(f)
        })
    }
    
    const handleExportComplete = () => {
        setShow(false);
    };
   
    return ( 
        <>
            {!show ?
                <button className='bigBtn' onClick={() => fetchData()}><img src={Excel} alt="Generar listado" width="30px" /> Generar listado</button>
            :
                !isLoading ?
                    <ExportToExcel apiData={listado} fileName={"Gestión fincas"} onComplete={handleExportComplete}/>
                :
                    <span className='bigBtn' style={{ cursor:'context-menu' }}><CircularProgress style={{color:'#fff', width:'16px', height: '16px', marginRight: '10px'}} /> Generando listado</span>
            }
        </>
     );
}
 
export default withRouter(ListadoFincas);